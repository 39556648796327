<template>
  <div class="accordion-item">
    <ion-item
      mode="md"
      :class="['header', 'ion-text-wrap', { bordered: withBorderedTitle }]"
      lines="none"
      :disabled="disabled"
      @click.prevent.stop="openSection()"
    >
      <ion-text :class="['ion-text-wrap', { 'small-header': smallHeaderTitle }]">
        <slot name="header" />
      </ion-text>

      <icons-provider
        :slot="smallHeaderTitle ? 'start' : 'end'"
        :icon-props="{
          width: smallHeaderTitle ? '20' : '26',
          height: smallHeaderTitle ? '20' : '26',
          fill: 'var(--ion-color-medium)',
        }"
        :name="isOpen ? AppIconsEnum.ChevronUp : AppIconsEnum.ChevronDown"
      />
    </ion-item>

    <transition name="slide-fade">
      <div v-show="isOpen">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { IonItem, IonText } from '@ionic/vue';
import { ref } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';

const props = defineProps({
  withBorderedTitle: {
    type: Boolean,
    default: false,
  },
  smallHeaderTitle: {
    type: Boolean,
    default: false,
  },
  closedIsDefault: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const isOpen = ref<boolean>(props.closedIsDefault ? false : true);

const openSection = () => {
  isOpen.value = !isOpen.value;
};

const open = () => {
  isOpen.value = true;
};

const close = () => {
  isOpen.value = false;
};

defineExpose({
  openSection,
  open,
  close,
});
</script>

<style scoped lang="scss">
.accordion-item {
  .bordered::after {
    content: '';
    display: block;
    margin-right: 16px;
    border-bottom: 1px solid var(--ion-color-medium);
  }

  .header {
    --padding-start: 0;
    --inner-padding-start: 0;
    --background: transparent;

    &:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    ion-text {
      padding: app-padding(lg) 0 app-padding(lg) 0;
      font-size: 1.2rem;

      &.small-header {
        font-size: 0.9rem;
      }
    }

    ion-icon {
      transition: all 0.2s ease-out;
      margin-inline-end: 0.5rem;
    }
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.05s ease-out;
}
.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}
</style>
