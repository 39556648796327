import { alertController } from '@ionic/vue';
import { remove } from 'lodash';

import { isFileVersionGuard } from '@/helpers/guards';

import {
  AccessByRoleEnum,
  ActionAccessEnum,
  AppIconsEnum,
  DocMenuOptionsFlagEnum,
  DocShareOptionsEnum,
  AppLoadingTypeEnum,
  DocumentTypeEnum,
  FileActionEnum,
  FileStatusEnum,
  ShareArchiveLinkType,
  ShareEntityType,
  UploadFileTypes,
  UserRoleEnum,
} from '@/enums';
import {
  DateHelper,
  isAnyMobile,
  openDocsMoveFileModal,
  openDocsUploadFileModal,
  openFileFollowersModal,
  openFileHistoryModal,
  openFileRelationsModal,
  openImagesViewerModal,
  openLink,
  openShareArchiveLinkModal,
  openTitleChangeModal,
  shareEntity,
  useAccess,
  useDocs,
  useFilesHybrid,
  useMenu,
  useOfficeHelper,
  useToasts,
  createConfirmationAlert,
  openDocsAttachmentModal,
  useLoading,
} from '@/helpers';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import { useAuthStore, useDocStore, useNetworkStore, useUserStore } from '@/store';
import type { FileModel, DocModel, DocsMenuItemModel } from '@/types';

type IUseFileActions = {
  isImage: (file: FileModel) => boolean;
  isVideo: (file: FileModel) => boolean;
  isAudio: (file: FileModel) => boolean;
  isOffice: (file: FileModel) => boolean;
  isPDF: (file: FileModel) => boolean;
  isPreviewAvailable: (file: FileModel) => boolean;
  checkIfOutdated: (file: FileModel | null, date?: string) => Promise<{ isOutdated: boolean; fileVersion?: FileModel }>;
  imageView: (file: FileModel) => void;
  openFile: (file: FileModel) => Promise<void>;
  editFile: (file: FileModel) => Promise<boolean>;
  downloadFile: (file: FileModel, status?: FileStatusEnum) => Promise<boolean>;
  renameFile: (file: FileModel) => Promise<boolean>;
  moveFile: (file: FileModel) => Promise<boolean>;
  deleteFile: (file: FileModel) => Promise<boolean>;
  uploadNewVersion: (file: FileModel) => Promise<boolean>;
  restoreFileVersion: (file: FileModel) => void;
  goToCurrentVersion: (file: FileModel) => void;
  getActionsMenuItems: (doc: FileModel, flag: DocMenuOptionsFlagEnum) => DocsMenuItemModel[];
  getPreviewMenuItems: (
    file: FileModel,
    showAllItems: boolean
  ) => { menuItems: DocsMenuItemModel[]; filteredItems: DocsMenuItemModel[] };
  whichActionToMake: (payload: {
    ev: Event;
    action: FileActionEnum | DocShareOptionsEnum;
    file: FileModel;
    status?: FileStatusEnum;
    flag?: DocMenuOptionsFlagEnum;
  }) => Promise<boolean | undefined>;
  /** Use to remove a relation from a file */
  removeRelation: (fileId: number, relationWikiId: number | null, relationFileId: number | null) => Promise<boolean>;
  /** Use to add a relation to a file */
  addRelation: (fileId: number) => Promise<boolean>;
};

export const useFileActions = (): IUseFileActions => {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();
  const accessHelper = useAccess();

  const docStore = useDocStore();
  const officeHelper = useOfficeHelper();

  function isOffice(file: FileModel): boolean {
    return useOfficeHelper().isOfficeFile(file.type);
  }
  function isVideo(file: FileModel): boolean {
    return useFilesHybrid().fileIsVideo(file);
  }
  function isAudio(file: FileModel): boolean {
    return useFilesHybrid().fileIsAudio(file);
  }

  function isPDF(file: FileModel): boolean {
    return useFilesHybrid().fileIsPDF(file);
  }

  function isImage(file: FileModel): boolean {
    return useFilesHybrid().fileIsImage(file);
  }

  function isPreviewAvailable(file: FileModel): boolean {
    return isImage(file) || isVideo(file) || isAudio(file) || isPDF(file) || isOffice(file);
  }

  async function imageView(file: FileModel) {
    await openImagesViewerModal(0, [{ file: file, text: '' }]);
  }

  async function openFile(file: FileModel): Promise<void> {
    if (isImage(file)) {
      await router.push({
        name: ROUTES_NAME.FILE_BY_ID,
        params: { id: file.id },
      });
      return;
    }

    const result = await useFilesHybrid().openFile(file);

    if (result === FileStatusEnum.Error) {
      showSonnerToast(t('errorResponse'), false);
    }
  }

  async function editFile(file: FileModel): Promise<boolean> {
    if (isOffice(file)) {
      const result = (await officeHelper.openOfficeView(
        file.id.toString(),
        false,
        {
          title: file.name,
          publishedBy: file.author,
          group: file.group,
          type: file.type,
        },
        true,
        false,
        file.group?.id ?? null,
        file.parentFolderId ?? null
      ))
        ? FileStatusEnum.Success
        : FileStatusEnum.Error;

      if (result === FileStatusEnum.Success) {
        await docStore.updateCurrentFile(true);
        return true;
      }

      if (result === FileStatusEnum.Error) {
        showSonnerToast(t('files.fileFailedOpenForEdit'), false);
        return false;
      }
    }
    return false;
  }

  async function downloadFile(file: FileModel, status?: FileStatusEnum): Promise<boolean> {
    if (status) status = FileStatusEnum.Loading;

    const downloadFileResult = await useFilesHybrid().downloadFile(file);

    if (status) status = downloadFileResult;

    showSonnerToast(
      downloadFileResult === FileStatusEnum.Success ? t('files.successDownloaded') : t('files.failedDownloaded'),
      downloadFileResult === FileStatusEnum.Success
    );

    return downloadFileResult === FileStatusEnum.Success;
  }

  async function renameFile(file: FileModel): Promise<boolean> {
    const result = await openTitleChangeModal(null, file.name, true, file?.description || '');

    if (!result) {
      console.error('Failed to rename file');
      return false;
    }

    const renameFileResult = (await docStore.renameFile(file.id, result.title, result.text))
      ? FileStatusEnum.Success
      : FileStatusEnum.Error;

    showSonnerToast(
      renameFileResult === FileStatusEnum.Success ? t('files.fileSuccessRenamed') : t('files.fileFailedRenamed'),
      renameFileResult === FileStatusEnum.Success
    );

    return renameFileResult === FileStatusEnum.Success;
  }

  async function moveFile(file: FileModel): Promise<boolean> {
    const result = await openDocsMoveFileModal(null);

    if (!result) {
      console.error('Failed to move file');
      return false;
    }

    const moveFileResult = (await docStore.moveFile(result.folderId, result.groupId, file.id))
      ? FileStatusEnum.Success
      : FileStatusEnum.Error;

    showSonnerToast(
      moveFileResult === FileStatusEnum.Success ? t('files.fileSuccessMoved') : t('files.fileFailedMoved'),
      moveFileResult === FileStatusEnum.Success
    );

    return moveFileResult === FileStatusEnum.Success;
  }

  async function deleteFile(file: FileModel): Promise<boolean> {
    let deleteResult: FileStatusEnum | null = null;
    const loading = useLoading();
    const docStore = useDocStore();

    const _confirmDeletionAlert = async (file: FileModel): Promise<boolean> => {
      return new Promise((resolve) => {
        alertController
          .create({
            message: `${t('documents.popup.deleteFile')} <strong>${file.name}?</strong>`,
            buttons: [
              { text: t('no'), role: 'cancel', cssClass: 'custom-alert-buttons', handler: () => resolve(false) },
              { text: t('yes'), role: 'confirm', cssClass: 'custom-alert-buttons', handler: () => resolve(true) },
            ],
          })
          .then((alert) => alert.present());
      });
    };

    const _hasRelationsAlert = async (file: FileModel): Promise<FileStatusEnum | null> => {
      return new Promise<FileStatusEnum | null>((resolve) => {
        alertController
          .create({
            message: t('documentsRelations.hasRelations'),
            buttons: [
              {
                text: t('documentsRelations.deleteWithAllRelations'),
                cssClass: 'custom-alert-buttons',
                handler: async () => {
                  resolve(await _handleFileDeletion(file));
                },
              },
              {
                text: t('documentsRelations.deleteWithReplace'),
                cssClass: 'custom-alert-buttons',
                handler: async () => {
                  const result = await _handleReplaceFlow(file);
                  resolve(result);
                },
              },
            ],
          })
          .then((alert) => alert.present());
      });
    };

    const _handleFileDeletion = async (file: FileModel): Promise<FileStatusEnum | null> => {
      const confirmed = await _confirmDeletionAlert(file);
      if (!confirmed) return null;

      return (await useDocStore().deleteFile(file.id)) ? FileStatusEnum.Success : FileStatusEnum.Error;
    };

    const _handleReplaceFlow = async (file: FileModel): Promise<FileStatusEnum> => {
      try {
        const result = await openDocsAttachmentModal(null, true, false);
        if (!result || result.length === 0) return FileStatusEnum.Error;

        const selectedDoc = result[0];
        const isWiki = selectedDoc.documentType === DocumentTypeEnum.Wiki;
        const isFile = selectedDoc.documentType === DocumentTypeEnum.File;

        if (isWiki) {
          return (await useDocStore().deleteWithWikiReplace(file.id, selectedDoc.data.id))
            ? FileStatusEnum.Success
            : FileStatusEnum.Error;
        }

        if (isFile) {
          return (await useDocStore().deleteWithFileReplace(file.id, selectedDoc.data.id))
            ? FileStatusEnum.Success
            : FileStatusEnum.Error;
        }

        return FileStatusEnum.Error;
      } catch (error) {
        console.error('[ERROR] Failed to delete with replace:', error);
        return FileStatusEnum.Error;
      }
    };

    const _handlePostDeletion = (deleteResult: FileStatusEnum) => {
      if (router.currentRoute.value.name === ROUTES_NAME.FILE_BY_ID) {
        router.back();
      }
      showSonnerToast(
        deleteResult === FileStatusEnum.Success ? t('files.fileSuccessDeleted') : t('files.fileFailedDeleted'),
        deleteResult === FileStatusEnum.Success
      );
    };

    try {
      await loading.create(t('loading'), AppLoadingTypeEnum.OtherLoading);
      const hasRelations = (await docStore.getRelations(file.id)).length > 0;
      await loading.dismiss(AppLoadingTypeEnum.OtherLoading);

      if (hasRelations) {
        deleteResult = await _hasRelationsAlert(file);
      } else {
        deleteResult = await _handleFileDeletion(file);
      }

      if (deleteResult !== null) {
        _handlePostDeletion(deleteResult);
      }

      return deleteResult === FileStatusEnum.Success;
    } catch (error) {
      console.error('[ERROR] File deletion failed:', error);
      return false;
    }
  }

  async function uploadNewVersion(file: FileModel): Promise<boolean> {
    const result = await openDocsUploadFileModal(UploadFileTypes.SingleAnyFile, true, file, true);
    if (result === undefined) {
      return false;
    }

    const uploadNewVersionResult = result ? FileStatusEnum.Success : FileStatusEnum.Error;

    showSonnerToast(
      uploadNewVersionResult === FileStatusEnum.Success
        ? t('files.fileSuccessUploaded')
        : t('files.fileFailedUploaded'),
      uploadNewVersionResult === FileStatusEnum.Success
    );

    return uploadNewVersionResult === FileStatusEnum.Success;
  }

  async function _showHistory(id: number): Promise<void> {
    await openFileHistoryModal(id);
  }

  async function restoreFileVersion(file: FileModel) {
    if (file.fileId) {
      const result = await docStore.restoreFileVersion(file.fileId, file.id);
      if (!result) {
        showSonnerToast(t('files.fileFailedRestoreVersion'), false);
        return;
      }
      showSonnerToast(t('files.fileSuccessRestoreVersion'), true);
      await goToCurrentVersion(file);
    }
  }

  async function goToCurrentVersion(file: FileModel) {
    if (file.fileId) {
      docStore.setCurrentFileVersion(null);

      await docStore.getCurrentFile(file.fileId);
    }
  }

  async function _showRelations(id: number, canEdit: boolean): Promise<void> {
    await openFileRelationsModal(id, canEdit);
  }

  async function _markOfficial(id: number): Promise<boolean> {
    return await docStore.markOfficial(id);
  }

  async function _follow(id: number): Promise<boolean> {
    return await docStore.follow(id);
  }

  async function _unfollow(id: number): Promise<boolean> {
    return await docStore.unfollow(id);
  }

  async function _showFollowers(id: number): Promise<void> {
    await openFileFollowersModal(id);
  }

  async function checkIfOutdated(
    doc: FileModel | null,
    date?: string
  ): Promise<{ isOutdated: boolean; fileVersion?: FileModel }> {
    if (!doc) return { isOutdated: false };

    try {
      const currentFile = docStore.current.data?.data as FileModel;
      let currentFileByDate: FileModel | undefined;
      let checkDate: number;
      let latestEditDate: number;
      const requestDate = DateHelper.addSecondsToDateInISO(
        date ? new Date(date).toISOString() : new Date().toISOString(),
        5
      );

      if (date || !currentFile) {
        currentFileByDate = await docStore.getHistoricalFileByDate(doc.fileId || doc.id, requestDate);
        if (!currentFileByDate) {
          console.error('Error during checking if outdated: no latest file');
          return { isOutdated: false };
        }
        checkDate = new Date(currentFileByDate.editedAt).getTime();
        latestEditDate = new Date(doc.editedAt).getTime();
        return { isOutdated: checkDate < latestEditDate, fileVersion: currentFileByDate };
      } else {
        checkDate = new Date(doc.editedAt).getTime();
        latestEditDate = new Date(currentFile.editedAt).getTime();
        docStore.setIsOutdated(checkDate < latestEditDate);
      }

      return { isOutdated: checkDate < latestEditDate };
    } catch (e) {
      console.error('Error during checking if outdated:', e);
      return { isOutdated: false };
    }
  }

  function _showParentItem(items: DocsMenuItemModel[], flag: DocMenuOptionsFlagEnum): boolean {
    return items.length > 1 && flag === DocMenuOptionsFlagEnum.All;
  }

  function _getShareMenuItems(
    fileAccess: ActionAccessEnum[] | undefined,
    isFileVersion: boolean,
    flag: DocMenuOptionsFlagEnum
  ): DocsMenuItemModel[] {
    const shareParentElement = [
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        value: FileActionEnum.Share,
        disabled: isFileVersion,
      },
    ];

    const shareItems: DocsMenuItemModel[] = [
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        value: DocShareOptionsEnum.Share,
        disabled: isFileVersion || !useAccess().canShareForBKG(!!fileAccess?.includes(ActionAccessEnum.Share)),
      },
      {
        title: t('files.menu.sendAsArchiveToEmail'),
        icon: AppIconsEnum.Mail,
        value: DocShareOptionsEnum.SendAsArchiveToEmail,
        disabled: isFileVersion || !fileAccess?.includes(ActionAccessEnum.ShareAsArchive),
      },
    ].filter(({ disabled }) => !disabled);

    return _showParentItem(shareItems, flag) ? shareParentElement : shareItems;
  }

  function getActionsMenuItems(
    file: FileModel,
    flag: DocMenuOptionsFlagEnum = DocMenuOptionsFlagEnum.All
  ): DocsMenuItemModel[] {
    const { t } = useI18n();
    const networkStore = useNetworkStore();
    const isCurrentRoutePreview = router.currentRoute.value.name === ROUTES_NAME.FILE_BY_ID;

    const currentCompanyId: string = useAuthStore().companyRowId;
    const isBKGNetwork: boolean = useMenu().companiesList['BKG'] === currentCompanyId;
    const currentUserRoleId: number = useUserStore().current?.roleId ?? UserRoleEnum.User;
    const isShowFollowers: boolean =
      networkStore.showFollowLists && ((isBKGNetwork && currentUserRoleId >= UserRoleEnum.Moderator) || !isBKGNetwork);

    const allowSeeDocHistory = networkStore.settings?.allowSeeDocHistory ?? AccessByRoleEnum.Off;
    const isFileVersion = isFileVersionGuard(file);
    /** @note doc history is visible only for Office and PDF files*/
    const typesConditionDocHistory = isOffice(file) || isPDF(file);
    /** @note doc history is visible only if there are several versions of files */
    const lengthConditionDocHistory = useDocStore().getHistory.length > 1;

    const fileAccess = isFileVersion ? useDocStore().current.data?.data.access : file.access;

    const menuItems: DocsMenuItemModel[] = [
      {
        title: file.isOfficial ? t(`files.menu.unOfficial`) : t(`files.menu.official`),
        icon: file.isOfficial ? AppIconsEnum.Star : AppIconsEnum.StarOutline,
        value: FileActionEnum.MarkOfficial,
        disabled: isFileVersion || !fileAccess?.includes(ActionAccessEnum.MarkAsOfficial),
      },
      {
        title: file.isFollowed ? t(`subscribe.unfollow`) : t(`subscribe.follow`),
        icon: file.isFollowed ? AppIconsEnum.NotificationsOff : AppIconsEnum.Notifications,
        value: file.isFollowed ? FileActionEnum.Unfollow : FileActionEnum.Follow,
        disabled: isFileVersion,
      },
      {
        title: isAudio(file) ? t('files.menu.listen') : t('files.menu.view'),
        icon: isAudio(file) ? AppIconsEnum.Autoplay : AppIconsEnum.Eye,
        value: FileActionEnum.Open,
        disabled: isFileVersion || isCurrentRoutePreview || isImage(file),
      },
      {
        title: t('files.menu.edit'),
        icon: AppIconsEnum.PencilSquare,
        value: FileActionEnum.Edit,
        disabled: isFileVersion || !isOffice(file) || !fileAccess?.includes(ActionAccessEnum.Edit),
      },
      {
        title: t('files.menu.download'),
        icon: AppIconsEnum.Download,
        value: FileActionEnum.Download,
        disabled: isFileVersion,
      },
      ..._getShareMenuItems(fileAccess, isFileVersion, flag),
      {
        title: t('files.menu.goToCurrentVersion'),
        icon: AppIconsEnum.GoBack,
        value: FileActionEnum.GoToCurrentVersion,
        disabled: !isFileVersion,
      },
      {
        title: t('files.menu.restore'),
        icon: AppIconsEnum.History,
        value: FileActionEnum.Restore,
        disabled: !isFileVersion || !fileAccess?.includes(ActionAccessEnum.Edit),
      },
      {
        title: t(`openInNewWindow`),
        icon: AppIconsEnum.ExternalLink,
        value: FileActionEnum.OpenInNewWindow,
        disabled: isCurrentRoutePreview || isAnyMobile,
      },
      {
        title: t(`files.menu.showHistory`),
        icon: AppIconsEnum.DocVersions,
        value: FileActionEnum.ShowHistory,
        disabled:
          !accessHelper.checkNetworkSettingAccess(allowSeeDocHistory) ||
          !isCurrentRoutePreview ||
          !typesConditionDocHistory ||
          !lengthConditionDocHistory,
      },
      {
        title: t(`documentsRelations.showRelations`),
        icon: AppIconsEnum.Link,
        value: FileActionEnum.ShowRelations,
        disabled: isFileVersion || !isCurrentRoutePreview,
      },
      {
        title: t(`files.menu.showFollowers`),
        icon: AppIconsEnum.Users,
        value: FileActionEnum.ShowFollowers,
        disabled: isFileVersion || !isShowFollowers || !isCurrentRoutePreview,
      },
      {
        title: t('files.menu.rename'),
        icon: AppIconsEnum.Letters,
        value: FileActionEnum.Rename,
        disabled: isFileVersion || !fileAccess?.includes(ActionAccessEnum.Rename),
      },
      {
        title: t('files.menu.move'),
        icon: AppIconsEnum.Move,
        value: FileActionEnum.Move,
        disabled: isFileVersion || !fileAccess?.includes(ActionAccessEnum.Move),
      },
      {
        title: t('files.menu.newVersion'),
        icon: AppIconsEnum.Upload,
        value: FileActionEnum.NewVersion,
        disabled: isFileVersion || !fileAccess?.includes(ActionAccessEnum.NewVersion),
      },
      {
        title: t('appPopoverMenu.delete.title'),
        icon: AppIconsEnum.Remove,
        value: FileActionEnum.Delete,
        disabled: isFileVersion || !fileAccess?.includes(ActionAccessEnum.Delete),
      },
    ].filter(({ disabled }) => !disabled);

    const itemsMap: Record<DocMenuOptionsFlagEnum, DocsMenuItemModel[]> = {
      [DocMenuOptionsFlagEnum.All]: menuItems,
      [DocMenuOptionsFlagEnum.Share]: _getShareMenuItems(fileAccess, isFileVersion, flag),
    };

    return itemsMap[flag];
  }

  function getPreviewMenuItems(
    file: FileModel,
    showAllItems: boolean
  ): { menuItems: DocsMenuItemModel[]; filteredItems: DocsMenuItemModel[] } {
    if (!file) return { menuItems: [], filteredItems: [] };

    const fileToolbarItems: Array<FileActionEnum | DocShareOptionsEnum> = [
      FileActionEnum.MarkOfficial,
      FileActionEnum.Follow,
      FileActionEnum.Unfollow,
      FileActionEnum.Open,
      FileActionEnum.Edit,
      FileActionEnum.Download,
      FileActionEnum.Share,
      DocShareOptionsEnum.Share,
      DocShareOptionsEnum.SendAsArchiveToEmail,
    ];

    const fileVersionToolbarItems: Array<FileActionEnum> = [
      FileActionEnum.GoToCurrentVersion,
      FileActionEnum.Restore,
      FileActionEnum.ShowHistory,
    ];

    const toolbarItems = isFileVersionGuard(file) ? fileVersionToolbarItems : fileToolbarItems;

    let maxItems;
    showAllItems ? (maxItems = toolbarItems.length) : (maxItems = 0);
    const splicedItems = toolbarItems.splice(0, maxItems);
    const menuItems = getActionsMenuItems(file);
    const filteredItems = remove(menuItems, ({ value }) => splicedItems.includes(value));
    return { menuItems, filteredItems };
  }

  async function whichActionToMake(payload: {
    ev: Event;
    action: FileActionEnum | DocShareOptionsEnum;
    file: FileModel;
    status?: FileStatusEnum;
  }): Promise<boolean | undefined> {
    const { ev, action, file, status } = payload;
    type FileActionHandler = (file: FileModel, status?: FileStatusEnum) => Promise<boolean | undefined>;
    const fileActionHandlers: Record<FileActionEnum | DocShareOptionsEnum, FileActionHandler> = {
      [FileActionEnum.Open]: async (file) => {
        await openFile(file);
        return undefined;
      },
      [FileActionEnum.Edit]: async (file) => await editFile(file),
      [FileActionEnum.Download]: async (file, status) => await downloadFile(file, status),
      [FileActionEnum.Rename]: async (file) => await renameFile(file),
      [FileActionEnum.Move]: async (file) => await moveFile(file),
      [FileActionEnum.Share]: async (file) => {
        return await useDocs().openFileActionsMenu({ ev, file, flag: DocMenuOptionsFlagEnum.Share });
      },
      [DocShareOptionsEnum.Share]: async (file) => {
        return await shareEntity(file, ShareEntityType.File);
      },
      [DocShareOptionsEnum.SendAsArchiveToEmail]: async (file) =>
        await openShareArchiveLinkModal(file.id, ShareArchiveLinkType.File),
      [FileActionEnum.SendAsArchiveToEmail]: async () => undefined,
      [FileActionEnum.Delete]: async (file) => await deleteFile(file),
      [FileActionEnum.NewVersion]: async (file) => await uploadNewVersion(file),
      [FileActionEnum.MarkOfficial]: async (file) => await _markOfficial(file.id),
      [FileActionEnum.Follow]: async (file) => await _follow(file.id),
      [FileActionEnum.Unfollow]: async (file) => await _unfollow(file.id),
      [FileActionEnum.OpenInNewWindow]: async () => {
        const resolvedPath = router.resolve({
          name: ROUTES_NAME.FILE_BY_ID,
          params: { id: file.id },
        }).href;
        await openLink(new URL(resolvedPath, window.location.origin).href);
        return undefined;
      },
      [FileActionEnum.Restore]: async (file) => {
        await restoreFileVersion(file);
        return undefined;
      },
      [FileActionEnum.GoToCurrentVersion]: async (file) => {
        await goToCurrentVersion(file);
        return undefined;
      },
      [FileActionEnum.ShowFollowers]: async (file) => {
        await _showFollowers(file.id);
        return undefined;
      },
      [FileActionEnum.ShowHistory]: async (file) => {
        await _showHistory(file.fileId ?? file.id);
        return undefined;
      },
      [FileActionEnum.ShowRelations]: async (file) => {
        await _showRelations(file.id, file.access.includes(ActionAccessEnum.Edit) ?? false);
        return undefined;
      },
    };

    const handler = fileActionHandlers[action];
    if (!handler) {
      console.warn(`No handler defined for action: ${action}`);
      return undefined;
    }
    return await handler(file, status);
  }

  async function removeRelation(
    fileId: number,
    relationWikiId: number | null,
    relationFileId: number | null
  ): Promise<boolean> {
    if (!fileId) {
      console.warn('[WARN] File id is not defined');
      return false;
    }

    return new Promise<boolean>(async (resolve) => {
      const message = t('documentsRelations.delete.confirm');
      const buttons = [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
          handler: () => {
            resolve(false);
          },
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            try {
              const relationWiki = relationWikiId ?? null;
              const relationFile = relationFileId ?? null;

              const response = relationWiki
                ? await useDocStore().removeRelationWiki(fileId, relationWiki)
                : relationFile
                  ? await useDocStore().removeRelationFile(fileId, relationFile)
                  : null;
              if (response) {
                showSonnerToast(t('documentsRelations.delete.success'), true);
                resolve(true);
              } else {
                resolve(false);
              }
            } catch (error) {
              console.error('[ERROR] Failed to remove relation:', error);
              resolve(false);
            } finally {
              await alertController.dismiss();
            }
          },
        },
      ];

      await createConfirmationAlert(message, buttons, '');
    });
  }

  async function addRelation(wikiId: number): Promise<boolean> {
    if (!wikiId) {
      console.warn('[WARN] Wiki id is not defined');
      return false;
    }

    const result = await openDocsAttachmentModal(null, true, false);

    const _wiki: DocModel | null = result?.[0].documentType === DocumentTypeEnum.Wiki ? result?.[0] : null;
    const _file: DocModel | null = result?.[0].documentType === DocumentTypeEnum.File ? result?.[0] : null;

    if (_wiki) {
      const response = await useDocStore().addRelationWiki(wikiId, _wiki.data.id);
      return response;
    }

    if (_file) {
      const response = await useDocStore().addRelationFile(wikiId, _file.data.id);
      return response;
    }

    console.warn('[WARN] Wiki or file is not defined');
    return false;
  }

  return {
    isImage,
    isVideo,
    isAudio,
    isOffice,
    isPDF,
    isPreviewAvailable,
    downloadFile,
    renameFile,
    moveFile,
    deleteFile,
    uploadNewVersion,
    restoreFileVersion,
    goToCurrentVersion,
    whichActionToMake,
    editFile,
    openFile,
    imageView,
    getActionsMenuItems,
    getPreviewMenuItems,
    checkIfOutdated,
    removeRelation,
    addRelation,
  };
};
