import { modalController } from '@ionic/vue';

import { componentForceReadModal, openNetworkAcceptRulesModal, openPasswordChangeModal } from './modalComponents';

import { RequirementsEnum } from '@/enums';
import { useErrors } from '@/helpers';
import { useI18n } from '@/i18n';
import { $api } from '@/services';
import { useAuthStore, useNetworkStore, usePostStore, useUserStore } from '@/store';
import type { ResponseRulesModel } from '@/types';

type IUseRequirements = {
  check(mode: RequirementsEnum): Promise<boolean | undefined>;
  requirementsCheckInProcess(): Promise<boolean>;
};

export const useRequirements = (): IUseRequirements => {
  const { t } = useI18n();

  const check = async (mode: RequirementsEnum): Promise<boolean | undefined> => {
    const { handleError } = useErrors();

    if (!useAuthStore().isAuth()) {
      console.error('🚀 ~ [Requirements check] Error checking process - no authorization');
      return false;
    }

    //NOTE: If the network change process is running, skip the script
    if (useNetworkStore().settingNetwork) return;

    //NOTE: If the checking process already is running, skip the script
    if (await requirementsCheckInProcess()) return;

    const current = await useUserStore().currentUser();
    if (!current) {
      console.error('🚀 ~ [Requirements check] Error checking process - no user');
      return false;
    }

    let usageRulesStatus = true;
    let passwordStatus = true;
    let forceToReadStatus = true;

    switch (mode) {
      case RequirementsEnum.All:
        usageRulesStatus = await _checkUsageRulesFromUserData();
        passwordStatus = await _checkMustChangePassword();
        forceToReadStatus = await _checkAllForcedAsRead();
        break;
      case RequirementsEnum.UsageRules:
        usageRulesStatus = await _checkUsageRulesFromUserData();
        break;
      case RequirementsEnum.Password:
        passwordStatus = await _checkMustChangePassword();
        break;
      case RequirementsEnum.ForceToRead:
        forceToReadStatus = await _checkAllForcedAsRead();
        break;
    }

    if (!usageRulesStatus || !passwordStatus || !forceToReadStatus) {
      console.error(`🚀 ~ [Requirements check] The process has been finalized - ${t('reqNotMet')}`);
      handleError({
        show: true,
        error: undefined,
        message: `[Requirements check] The process has been finalized - ${t('reqNotMet')}`,
      });
    }

    return usageRulesStatus && passwordStatus && forceToReadStatus;
  };

  //NOTE: Check that the network rules are accepted
  const _checkUsageRulesFromUserData = async (): Promise<boolean> => {
    const { handleError } = useErrors();

    if (useUserStore().current?.isPolicyAccepted) return true;

    const response = await $api.account.getUsageRules();
    if (response.statusCode === 200) {
      const { data } = response as ResponseRulesModel;
      const result = await openNetworkAcceptRulesModal(data.id, data.title, data.text);
      return result ?? false;
    } else {
      handleError({
        show: true,
        error: undefined,
        message: t('administration.usageRules.fetchError'),
      });
      return false;
    }
  };

  //NOTE: Check that the password has been successfully changed
  const _checkMustChangePassword = async (): Promise<boolean> => {
    if (!useUserStore().current?.mustChangePassword) return true;

    const result = await openPasswordChangeModal(false);
    return result ?? false;
  };

  //NOTE: Check that all required posts have been read
  const _checkAllForcedAsRead = async (): Promise<boolean> => {
    const response = await usePostStore().postsRequiredForRead();
    if (!response.length) return true;

    const result = await componentForceReadModal();
    return result ?? false;
  };

  const requirementsCheckInProcess = async (): Promise<boolean> => {
    return await modalController.getTop().then(async (modal) => {
      return modal &&
        (modal?.id === 'usage-rules-modal' ||
          modal?.id === 'password-change-modal' ||
          modal?.id === 'force-to-read-modal')
        ? true
        : false;
    });
  };

  return {
    check,
    requirementsCheckInProcess,
  };
};
