<template>
  <div v-if="showDates" class="milestone-dates">
    <div v-if="showStartDate" class="start-date">
      {{ getStartDate1() }}
    </div>

    <div v-else-if="showDueDate" class="end-date">
      {{ getDueDate1() }}
    </div>

    <template v-else>
      <div class="start-date">
        {{ getStartDate2() }}
      </div>
      <div class="end-date">
        {{ getDueDate2() }}
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import type { ComputedRef } from 'vue';
import { computed } from 'vue';

import { DateHelper, formatDateHelper } from '@/helpers';
import { useProjectsStore } from '@/store';
import type { TaskManagementMilestoneModel } from '@/types';
import { useI18n } from '@/i18n';

const props = defineProps({
  milestoneId: {
    type: Number,
    required: true,
  },
});

//#region Variables
const { t } = useI18n();
const projectsStore = useProjectsStore();

const milestone: ComputedRef<TaskManagementMilestoneModel | undefined> = computed(() =>
  projectsStore.getMilestoneById(props.milestoneId)
);

const showDates: ComputedRef<boolean> = computed(
  () => !!milestone.value && (!milestone.value?.dateStart || !milestone.value.dateDue)
);
const showStartDate: ComputedRef<boolean> = computed(() => !!milestone.value?.dateStart);
const showDueDate: ComputedRef<boolean> = computed(() => !!milestone.value?.dateDue);
//#endregion

//#region Methods
function getStartDate1(): string {
  return t('taskManagement.milestones.dateStart') + ': ' + formatDateHelper(milestone.value?.dateStart ?? '', 'year');
}

function getDueDate1(): string {
  return t('taskManagement.milestones.dateDue') + ': ' + formatDateHelper(milestone.value?.dateDue ?? '', 'year');
}

function getStartDate2(): string {
  if (!milestone.value?.dateStart) return '';

  return formatDateHelper(
    milestone.value.dateStart,
    milestone.value?.dateDue && DateHelper.yearsIsEqual(milestone.value.dateStart, milestone.value.dateDue)
      ? 'month'
      : 'year'
  );
}

function getDueDate2(): string {
  if (!milestone.value?.dateDue) return '';

  return (
    '-' +
    formatDateHelper(
      milestone.value.dateDue,
      DateHelper.yearsIsEqual(milestone.value.dateStart, milestone.value.dateDue) ? 'month' : 'year'
    )
  );
}
//#endregion
</script>

<style scoped lang="scss">
.milestone-dates {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: nowrap;
  overflow: hidden;
  white-space: nowrap;
  color: var(--ion-color-medium);
}
</style>
