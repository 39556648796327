export const enum TokenModeEnum {
  Form = 'form',
  Redirect = 'redirect',
  Code = 'code',
}

export const enum UserVerificationTypeEnum {
  VerificationByCompanyEmailSuffix = 'VerificationByCompanyEmailSuffix',
  VerificationByUserParameter = 'VerificationByUserParameter',
  VerificationByUserSync = 'VerificationByUserSync',
  VerificationByOauth = 'VerificationByOauth',
}

/**
 * @link https://gitlab.united-grid.com/intra/core/-/blob/dev/Intra.Core.Types/Api/Enums/GrantTypes.cs
 */
export const enum OAuthGrantTypeEnum {
  /** password + login => { homeCode, token } */ Password = 'Password',
  /** code => { homeCode, token } */ SignedAuthorizationCode = 'SignedAuthorizationCode',
  /** homeCode => { token } */ HomeAuthorizationCode = 'HomeAuthorizationCode',

  /** @deprecated */ ClientCredentials = 'ClientCredentials',
  /** @deprecated */ AuthorizationCode = 'AuthorizationCode',
  /** @deprecated */ UserExternalId = 'UserExternalId',
  /** @deprecated */ HomeAuthorizationOld = 'HomeAuthorizationOld',
}

/**
 * @link https://gitlab.united-grid.com/intra/core/-/blob/dev/Intra.Core.Types/Networks/Enums/OauthClientType.cs
 */
export const enum OAuthClientTypeEnum {
  Web = 'Web',
  Api = 'Api',
  ApiCore = 'ApiCore',

  /**
   * @deprecated Use External - after https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1792
   */
  Ionic = 'Ionic',
  External = 'External',
}

export const enum LoginTypeEnum {
  Form = 'form',
  Uri = 'uri',
}
