import axios from '@/services/axios';
import type { ResponseErrorModel, ResponseBadgesModel, ResponseUsersModel } from '@/types';

export class BadgesApiService {
  async getBadges(): Promise<ResponseBadgesModel | ResponseErrorModel> {
    return axios.get('/badges/all');
  }
  async getBadgesById(ids: number[]): Promise<ResponseBadgesModel | ResponseErrorModel> {
    return axios.post(
      `/badges/byIds`,
      { badgesIds: ids },
      {
        headers: {
          withoutToast: true,
        },
      }
    );
  }
  async getUsersByBadgeId(badgeId: number): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/badges/${badgeId}/users`);
  }
  async autocomplete(search: string): Promise<ResponseBadgesModel | ResponseErrorModel> {
    return axios.get('/badges/autocomplete', { params: { search } });
  }
}
