export const enum NotificationPushTypeEnum {
  Group = 'group',
  Message = 'message',
  Post = 'post',
  NewFollower = 'newFollower',
}

export const enum NotificationsFilterEnum {
  All = 'all',
  Unread = 'unread',
}

/**
 * @link https://gitlab.united-grid.com/intra/core/-/blob/dev/Intra.Core/Enums/Notifications/NotificationsPushActions.cs
 */
export const enum NotificationsPushActionsEnum {
  None = 'None',
  ExternalUrlCreate = 'ExternalUrlCreate',

  Post = 'Post',
  PostInGroup = 'PostInGroup',
  PostCreate = 'PostCreate',
  PostLiked = 'PostLiked',
  PostCommentCreate = 'PostCommentCreate',
  PostCommentAfterYouComment = 'PostCommentAfterYouComment',
  PostCommentToYourUserItem = 'PostCommentToYourUserItem',
  PostCommentMarkedAsHelpful = 'PostCommentMarkedAsHelpful',
  PostCommentCreateMentioned = 'PostCommentCreateMentioned',
  PostCommentLiked = 'PostCommentLiked',
  PostAnnouncement = 'PostAnnouncement',
  PostAnnouncementInGroup = 'PostAnnouncementInGroup',
  PostPlannedCreated = 'PostPlannedCreated',
  PostPlannedFailed = 'PostPlannedFailed',

  Message = 'Message',
  MessageCreate = 'MessageCreate',

  User = 'User',
  UserCreate = 'UserCreate',
  UserNewFollower = 'UserNewFollower',
  UserNewManager = 'UserNewManager',
  UserNewSubordinate = 'UserNewSubordinate',
  UserNewLevel = 'UserNewLevel',

  Group = 'Group',
  GroupCreate = 'GroupCreate',
  GroupAdded = 'GroupAdded',
  GroupInvited = 'GroupInvited',
  GroupRequestJoin = 'GroupRequestJoin',
  GroupRequestJoinDeny = 'GroupRequestJoinDeny',

  Wiki = 'Wiki',
  WikiCreate = 'WikiCreate',
  WikiNewVersion = 'WikiNewVersion',
  WikiMentioned = 'WikiMentioned',

  File = 'File',
  FileCreate = 'FileCreate',
  FileNewVersion = 'FileNewVersion',

  Task = 'Task',
  TaskCreated = 'TaskCreated',
  TaskClosed = 'TaskClosed',
  TaskReopened = 'TaskReopened',
  TaskArchived = 'TaskArchived',
  TaskDeArchived = 'TaskDeArchived',
  TaskTitleUpdated = 'TaskTitleUpdated',
  TaskDescriptionUpdated = 'TaskDescriptionUpdated',
  TaskAssigneeChanged = 'TaskAssigneeChanged',
  TaskMilestoneChanged = 'TaskMilestoneChanged',
  TaskDateStartUpdated = 'TaskDateStartUpdated',
  TaskDateDueUpdated = 'TaskDateDueUpdated',
  TaskTagsAdded = 'TaskTagsAdded',
  TaskTagsRemoved = 'TaskTagsRemoved',
  TaskFilesAdded = 'TaskFilesAdded',
  TaskFilesRemoved = 'TaskFilesRemoved',
  TaskWikisAdded = 'TaskWikisAdded',
  TaskWikisRemoved = 'TaskWikisRemoved',
  TaskLinksAdded = 'TaskLinksAdded',
  TaskLinksRemoved = 'TaskLinksRemoved',
  TaskParticipantsAdded = 'TaskParticipantsAdded',
  TaskParticipantsRemoved = 'TaskParticipantsRemoved',
  TaskCommentCreated = 'TaskCommentCreated',
  TaskCommentUpdated = 'TaskCommentUpdated',
  TaskCommentDeleted = 'TaskCommentDeleted',
  TaskMoved = 'TaskMoved',
  TaskReporterChanged = 'TaskReporterChanged',
}
