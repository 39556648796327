import type { DocumentExtensionEnum } from '@/enums';
import { WikiMajorFilterEnum } from '@/enums/wiki';
import type {
  CreateWikiPayload,
  UpdateWikiPayload,
  ResponseSuccessModel,
  ResponseErrorModel,
  ResponseWikiCreateModel,
  ResponseWikiHistoryModel,
  ResponseWikiHistoryByIdModel,
  ResponseWikiModel,
  ResponseWikisModel,
  ResponseUsersModel,
  ResponseWikiTemplateModel,
  ResponseWikiTemplatesModel,
  SaveWikiTemplateModel,
  CreateWikiTemplateModel,
  ResponseLockModel,
  ResponseDocsRelationsModel,
} from '@/types';
import axios from '@/services/axios';
import { getCommonHeaders } from '@/services/getters/getCommonHeaders';

export class WikiApiService {
  //#region Basic API
  async getWikiById(wikiId: number): Promise<ResponseWikiModel | ResponseErrorModel> {
    return axios.get(`/wiki/byId/${wikiId}`);
  }
  async create(payload: CreateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    const newPayload = updateWikiPayload(payload);
    return axios.post('/wiki/create', newPayload);
  }
  async update(payload: UpdateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    const newPayload = updateWikiPayload(payload);
    return axios.post('/wiki/update', newPayload);
  }
  async download(id: number, documentExtension: DocumentExtensionEnum): Promise<Blob | ResponseErrorModel> {
    return axios.get(`/storage/wiki/${id}?type=${documentExtension}`, {
      responseType: 'blob',
    });
  }
  async markAsOfficial(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/markAsOfficial/${id}`);
  }
  async delete(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/delete/${id}`);
  }
  async deleteWithWikiReplace(
    wikiId: number,
    relationWikiId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteWithReplace/${wikiId}?relationWikiId=${relationWikiId}`);
  }
  async deleteWithFileReplace(
    wikiId: number,
    relationFileId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteWithReplace/${wikiId}?relationFileId=${relationFileId}`);
  }
  //#endregion

  //#region Relations
  async getRelations(id: number): Promise<ResponseDocsRelationsModel | ResponseErrorModel> {
    return axios.get(`/wiki/relations/${id}`);
  }
  async addRelationWiki(wikiId: number, relationWikiId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/addRelation/${wikiId}?relationWikiId=${relationWikiId}`);
  }
  async addRelationFile(wikiId: number, relationFileId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/addRelation/${wikiId}?relationFileId=${relationFileId}`);
  }
  async removeRelationWiki(wikiId: number, relationWikiId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/removeRelation/${wikiId}?relationWikiId=${relationWikiId}`);
  }
  async removeRelationFile(wikiId: number, relationFileId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/removeRelation/${wikiId}?relationFileId=${relationFileId}`);
  }
  //#endregion

  //#region Followers
  async getFollowers(id: number): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/wiki/followers/${id}`);
  }
  async follow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/${id}/follow`);
  }
  async unfollow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/${id}/unfollow`);
  }
  //#endregion

  //#region History
  async getHistory(id: number): Promise<ResponseWikiHistoryModel | ResponseErrorModel> {
    return axios.get(`/wiki/history/${id}?page=1`);
  }
  async getHistoryLoadMore(loadMoreUrl: string): Promise<ResponseWikiHistoryModel | ResponseErrorModel> {
    return axios.get(loadMoreUrl);
  }
  async getHistoryById(id: number): Promise<ResponseWikiHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/wiki/historyById/${id}`);
  }
  /** @note Wiki history by date. Method returns the closest wiki history by provided date */
  async getHistoryByDate(
    id: number,
    date: string,
    majorFilter: WikiMajorFilterEnum
  ): Promise<ResponseWikiHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/wiki/historyByDate/${id}?date=${date}&majorFilter=${majorFilter}`);
  }
  /** @note Method now has a parameter isMajor which signals the saving version is major */
  async updateHistoryById(payload: UpdateWikiPayload): Promise<ResponseWikiCreateModel | ResponseErrorModel> {
    return axios.post('/wiki/updateHistory', payload);
  }
  async rollback(wikiId: number, historyId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/rollback`, { wikiId, historyId });
  }

  async deleteVersion(wikiId: number, versionId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/${wikiId}/history/${versionId}`);
  }
  //#endregion

  //#region Templates
  async getTemplateById(id: number): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.get(`/wiki/templateById/${id}`);
  }
  async getTemplates(): Promise<ResponseWikiTemplatesModel | ResponseErrorModel> {
    return axios.get('/wiki/templates?page=1');
  }
  async getTemplatesLoadMore(loadMoreUrl: string): Promise<ResponseWikiTemplatesModel | ResponseErrorModel> {
    return axios.get(loadMoreUrl);
  }
  async createTemplate(data: CreateWikiTemplateModel): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.post('/wiki/createTemplate', data, {
      headers: {
        withoutToast: true,
      },
    });
  }
  async updateTemplateById(data: SaveWikiTemplateModel): Promise<ResponseWikiTemplateModel | ResponseErrorModel> {
    return axios.post(`/wiki/updateTemplate`, data, {
      headers: {
        withoutToast: true,
      },
    });
  }
  async deleteTemplateById(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/deleteTemplate/${id}`);
  }
  //#endregion

  //#region Tags
  async addTag(id: number, tagTexts: string[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/wiki/addTag', { id, tagTexts });
  }
  async removeTag(id: number, tagId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/wiki/removeTag/${id}?tagId=${tagId}`);
  }
  //#endregion

  //#region Lock
  async lockEdit(id: number): Promise<ResponseLockModel | ResponseErrorModel> {
    return axios.post(`/wiki/lockEdit/${id}`);
  }
  async unlockEdit(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/wiki/unlockEdit/${id}`);
  }
  /** @note Not in use at the moment */
  async checkLock(id: number): Promise<ResponseLockModel | ResponseErrorModel> {
    return axios.post(`/wiki/checkLock/${id}`);
  }
  //#endregion

  //#region Contributions
  /**
   * @note Not in use at the moment
   */
  async getContributionsByUserId(userId: number): Promise<ResponseWikiModel[] | ResponseErrorModel> {
    return axios.get(`/wiki/contributions?userId=${userId}`);
  }
  //#endregion

  //#region Search
  async autocomplete(searchText: string): Promise<ResponseWikisModel | ResponseErrorModel> {
    return axios.get(`/wiki/autocomplete?searchText=${searchText}`);
  }
  //#endregion

  //#region Compare
  /**
   * This function makes an API call to the Node.js server to get the Git diff of the two texts
   *
   * @todo Make JWT authentication for the server
   * @todo Add error handling
   * @todo Add loading handling
   * @todo Refactor the function to use the axios library
   */
  async getDiff(requestBody: { originalText: string; updatedText: string }): Promise<string | null> {
    const url = `${import.meta.env.VITE_NODE_SERVER_URL_DEV}/wiki/gitDiff/`;
    let resultString = '';

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getCommonHeaders(),
      },
      body: JSON.stringify(requestBody),
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response.text().then((data) => {
            resultString = data;
          });
        } else {
          console.error('Fetch Error:', response);
          return null;
        }
      })
      .catch((error) => {
        console.error('Fetch Error:', error);
        return null;
      });

    return resultString;
  }
  //#endregion
}

/**
 * Changing the payload to include `visible` field that is equal to `isVisible` field
 * @note This is a temporary solution to the problem.
 * @link https://gitlab.united-grid.com/intra/core/-/issues/1261
 */
function updateWikiPayload(payload: CreateWikiPayload | UpdateWikiPayload): CreateWikiPayload | UpdateWikiPayload {
  if (!payload?.content) {
    console.warn('No content found in payload');
    return payload;
  }

  const finalPayload: CreateWikiPayload | UpdateWikiPayload = {
    ...payload,
    content: {
      ...payload.content,
      head: {
        ...payload.content.head,
        settings: {
          ...payload.content.head.settings,
          visible: payload.content.head.settings.isVisible,
        },
      },
      content: {
        ...payload.content.content,
        settings: {
          ...payload.content.content.settings,
          visible: payload.content.content.settings.isVisible,
        },
      },
      body: payload.content.body?.map((b) => ({
        ...b,
        settings: {
          ...b.settings,
          visible: b.settings.isVisible,
        },
      })),
      participants: {
        ...payload.content.participants,
        settings: {
          ...payload.content.participants.settings,
          visible: payload.content.participants.settings.isVisible,
        },
      },
    },
  };

  return finalPayload;
}
