<template>
  <div ref="containerRef" class="mentions-container">
    <div v-if="showMentionsList" class="list" :style="{ top: getPosition() }">
      <ion-list mode="md">
        <ion-list-header v-if="chooseUsersIsActive && users.length > 0" lines="none">
          <ion-label>{{ $t('appMenu.people') }}</ion-label>
        </ion-list-header>
        <ion-item v-for="item in users" :key="`user_${item.id}`" lines="none" @click.stop="onUserChoose(item)">
          <user-list-item :user="item" with-subtitle size="small" />
        </ion-item>

        <ion-list-header v-if="chooseGroupsIsActive && groups.length > 0" lines="none">
          <ion-label>{{ $t('appMenu.groups') }}</ion-label>
        </ion-list-header>
        <ion-item v-for="item in groups" :key="`group_${item.id}`" lines="none" @click.stop="onGroupChoose(item)">
          <group-list-item :group="item" stats-disabled size="small" />
        </ion-item>

        <ion-list-header v-if="chooseTopicsIsActive && topics.length > 0" lines="none">
          <ion-label>{{ $t('appMenu.topics') }}</ion-label>
        </ion-list-header>
        <ion-item
          v-for="item in topics"
          :key="`topic_${item.id}`"
          lines="none"
          class="small"
          @click.stop="onTopicChoose(item)"
        >
          <ion-label class="small">
            {{ item.label }}
            <p v-if="item?.description">{{ item.description }}</p>
          </ion-label>
        </ion-item>

        <ion-list-header v-if="chooseEmailIsActive && emailData !== ''" lines="none">
          <ion-label>{{ $t('preferences.email.email') }}</ion-label>
        </ion-list-header>
        <ion-item v-if="emailData !== ''" lines="none" @click.stop="onEmailChoose(emailData)">
          <ion-label class="small">
            <h2>{{ emailData }}</h2>
          </ion-label>
        </ion-item>
      </ion-list>
    </div>

    <div v-if="mentionMode === MentionModeEnum.ByMentionInput" class="chip-block">
      <ion-chip
        v-for="(item, userIndex) in select.selectedUsers"
        :key="`users_${userIndex}`"
        outline
        @click.stop="deleteUser(userIndex)"
      >
        <ion-label>{{ item.fullName }}</ion-label>
        <icons-provider
          :icon-props="{
            width: '16',
            height: '16',
          }"
          class="ml-sm"
          :name="AppIconsEnum.Close"
        />
      </ion-chip>

      <ion-chip
        v-for="(item, groupIndex) in select.selectedGroups"
        :key="`groups_${groupIndex}`"
        outline
        @click.stop="deleteGroup(groupIndex)"
      >
        <ion-label>{{ item.title }}</ion-label>
        <icons-provider
          :icon-props="{
            width: '16',
            height: '16',
          }"
          class="ml-sm"
          :name="AppIconsEnum.Close"
        />
      </ion-chip>

      <ion-chip
        v-for="(item, emailIndex) in select.selectedEmails"
        :key="`emails_${emailIndex}`"
        outline
        @click.stop="deleteEmail(emailIndex)"
      >
        <ion-label>{{ item }}</ion-label>
        <icons-provider
          :icon-props="{
            width: '16',
            height: '16',
          }"
          class="ml-sm"
          :name="AppIconsEnum.Close"
        />
      </ion-chip>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel, IonChip, IonItem, IonList, IonListHeader } from '@ionic/vue';
import { useElementBounding, useWindowSize, watchDebounced } from '@vueuse/core';
import { trimStart } from 'lodash';
import type { ComputedRef, PropType } from 'vue';
import { ref, reactive, watch, computed } from 'vue';
import { useRoute } from 'vue-router';

import { UserListItem, GroupListItem, IconsProvider } from '@/components';
import { UsersFilterEnum, GroupsTypeEnum, UserRoleEnum, MentionModeEnum, AppIconsEnum } from '@/enums';
import { toShortUserModel } from '@/helpers';
import { ROUTES_NAME } from '@/router';
import { useGroupsStore, useTopicStore, useUserStore } from '@/store';
import type { UserModel, GroupModel, PostMentionsDataCreateModel, UserShortModel, TopicModel } from '@/types';

const props = defineProps({
  caret: {
    type: Number,
    default: 0,
  },
  message: {
    type: String,
    required: true,
  },
  chooseUsersIsActive: {
    type: Boolean,
    required: true,
  },
  chooseTopicsIsActive: {
    type: Boolean,
    required: true,
  },
  chooseGroupsIsActive: {
    type: Boolean,
    required: true,
  },
  chooseEmailIsActive: {
    type: Boolean,
    required: true,
  },
  multiple: {
    type: Boolean,
    default: true,
  },
  mentionMode: {
    type: String as PropType<MentionModeEnum>,
    required: true,
  },
  //NOTE: If mentionMode === MentionModeEnum.ByMentionInput, then use this setting to show the window
  showModal: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['onChangeData', 'onChooseUser', 'onChooseGroup', 'onChooseTopic']);

const route = useRoute();
const userStore = useUserStore();
const groupStore = useGroupsStore();
const topicStore = useTopicStore();

const caret: ComputedRef<number> = computed(() => props.caret);
const message: ComputedRef<string> = computed(() => props.message);
const emailData = ref<string>('');

const containerRef = ref(null);
const { y } = useElementBounding(containerRef);
const { height: windowHeight } = useWindowSize();

const getPosition = () => {
  return y.value > windowHeight.value / 1.5 ? '-332px' : '0px';
};

const showMentionsList: ComputedRef<boolean> = computed(
  () =>
    props.message !== '' &&
    !listIsEmpty.value &&
    (props.mentionMode === MentionModeEnum.ByMentionInput ? props.showModal : true)
);

const listIsEmpty: ComputedRef<boolean> = computed(
  () => users.value.length === 0 && groups.value.length === 0 && topics.value.length === 0 && !emailData.value.length
);
const currentUserId: ComputedRef<number> = computed(() => userStore.current?.id ?? 0);

const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);

const isGroupAdmin: ComputedRef<boolean> = computed(
  () =>
    groupData.value?.adminIds.includes(currentUserId.value) ||
    currentUserRoleId.value >= UserRoleEnum.SuperAdministrator
);

const currentGroupId: ComputedRef<number | null> = computed(() =>
  route.name === ROUTES_NAME.GROUP_BY_ID ? Number(route.params.id) : null
);

const groupData: ComputedRef<GroupModel | null> = computed(() =>
  currentGroupId.value ? groupStore.getGroupById(currentGroupId.value) : null
);

const groupParticipants: ComputedRef<UserModel[]> = computed(
  () => userStore.getUsersFromGroupId(currentGroupId.value).data
);

const groups: ComputedRef<GroupModel[]> = computed(() => {
  if (!props.chooseGroupsIsActive) return [];

  const result = groupStore.getSearchedGroups().data;
  if (currentGroupId.value && groupData.value?.type === GroupsTypeEnum.PrivateHidden && !isGroupAdmin.value) {
    const filteredGroups = result.filter((group) => groupParticipants.value.map((u) => u.id).includes(group.id));
    return filteredGroups;
  }
  return result;
});

const users: ComputedRef<UserModel[]> = computed(() => {
  if (!props.chooseUsersIsActive) return [];

  const result = userStore.getUsersChosen(UsersFilterEnum.ChosenOthers).data;
  if (currentGroupId.value && groupData.value?.type === GroupsTypeEnum.PrivateHidden && !isGroupAdmin.value) {
    const filteredUsers = result.filter((user) => groupParticipants.value.map((u) => u.id).includes(user.id));
    return filteredUsers;
  }
  return result;
});

const topics = ref<TopicModel[] | []>([]);

const select = reactive({
  selectedEmails: [],
  selectedGroups: [],
  selectedUsers: [],
  selectedTopics: [],
}) as {
  selectedEmails: string[];
  selectedGroups: { id: number; title: string }[];
  selectedUsers: UserShortModel[];
  selectedTopics: { id: number; label: string }[];
};

const data = reactive<PostMentionsDataCreateModel>({
  userEmails: [],
  groupIds: [],
  users: [],
});

//#region search by caret mode

//NOTE: this function is used to search for users and groups by alias @
const _searchByAlias = async (text: string): Promise<void> => {
  if (props.chooseUsersIsActive)
    await userStore.chooseUserAutocomplete(text, UsersFilterEnum.ChosenOthers, false, currentGroupId.value);
  if (props.chooseGroupsIsActive) await groupStore.canPostGroups(text);
};

//NOTE: this function is used to search for topics by hash tag #
const _searchByHashTag = async (text: string): Promise<void> => {
  if (props.chooseTopicsIsActive) topics.value = await topicStore.topicsAutocomplete(text);
};

//NOTE: this function is used to search by caret mode mention
const searchByCaretMode = async (
  text: string,
  searchBy: 'alias' | 'hashTag',
  indexOfAlias: number,
  indexOfHashTag: number
): Promise<void> => {
  if (searchBy === 'alias') {
    const trimmedText = text.slice(indexOfAlias);
    if (trimmedText.length > 1 && !trimmedText.includes(' ')) {
      await _searchByAlias(trimStart(trimmedText, '@'));
    } else {
      resetAllSearches();
    }
  }

  if (searchBy === 'hashTag') {
    const trimmedText = text.slice(indexOfHashTag);
    if (trimmedText.length > 1 && !trimmedText.includes(' ')) {
      await _searchByHashTag(trimStart(trimmedText, '#'));
    } else {
      resetAllSearches();
    }
  }
};

watchDebounced(
  caret,
  async () => {
    if (props.mentionMode === MentionModeEnum.ByMentionInput) return;
    const message = props.caret < props.message.length ? props.message.substring(0, props.caret) : props.message;

    const indexOfAlias = message.lastIndexOf('@');
    const indexOfHashTag = message.lastIndexOf('#');
    if (~indexOfAlias || ~indexOfHashTag) {
      await searchByCaretMode(message, ~indexOfAlias ? 'alias' : 'hashTag', indexOfAlias, indexOfHashTag);
    } else {
      resetAllSearches();
    }
  },
  { debounce: 250, maxWait: 1500 }
);

//#endregion

//#region search by mention input mode

const searchByMentionInputMode = async (text: string) => {
  if (props.chooseUsersIsActive)
    await userStore.chooseUserAutocomplete(text, UsersFilterEnum.ChosenOthers, false, currentGroupId.value);

  if (props.chooseGroupsIsActive) await groupStore.canPostGroups(text);

  if (props.chooseEmailIsActive) {
    if (!text.includes('@')) {
      emailData.value = text + '@gmail.com';
    } else {
      emailData.value = text;
    }
  }
};

watchDebounced(
  message,
  async () => {
    if (props.mentionMode === MentionModeEnum.ByCaret) return;

    if (message.value !== '') {
      searchByMentionInputMode(message.value);
    }
  },
  { debounce: 250, maxWait: 1500 }
);

watch(select, () => {
  data.userEmails = select.selectedEmails;
  data.groupIds = [];
  data.users = select.selectedUsers;
  select.selectedGroups.forEach((element) => {
    data.groupIds.push(element.id);
  });
  emit('onChangeData', data);
});

//#endregion

const onUserChoose = async (user: UserModel) => {
  const isCurrentUserId = currentUserId.value === user.id;
  const isUserAlreadySelected = select.selectedUsers.some((n) => n.id === user.id);
  const index = props.message.slice(0, props.caret).lastIndexOf('@');

  if (props.mentionMode === MentionModeEnum.ByMentionInput && !isCurrentUserId && !isUserAlreadySelected) {
    if (props.multiple) {
      select.selectedUsers.unshift(toShortUserModel(user));
    } else {
      select.selectedUsers = [toShortUserModel(user)];
    }
  }

  if (!user.mainAlias) console.warn('[WARN] User alias not found');

  resetAllSearches();
  emit('onChooseUser', { alias: user.mainAlias, index });
};

const onGroupChoose = (group: GroupModel) => {
  const isSelected = select.selectedGroups.some((n) => n.id === group.id);
  const index = props.message.slice(0, props.caret).lastIndexOf('@');

  if (props.mentionMode === MentionModeEnum.ByMentionInput && !isSelected) {
    const newGroup = { id: group.id, title: group.title };

    if (props.multiple) {
      select.selectedGroups.unshift(newGroup);
    } else {
      select.selectedGroups = [newGroup];
    }
  }

  if (!group.mainAlias) console.warn('[WARN] Group alias not found');

  resetAllSearches();
  emit('onChooseGroup', { alias: group.mainAlias, index });
};

const onTopicChoose = (topic: TopicModel) => {
  const isSelected = select.selectedTopics.some((n) => n.id === topic.id);
  const index = props.message.slice(0, props.caret).lastIndexOf('#');

  if (props.mentionMode === MentionModeEnum.ByMentionInput && !isSelected) {
    const newTopic = { id: topic.id, label: topic.label };

    if (props.multiple) {
      select.selectedTopics.unshift(newTopic);
    } else {
      select.selectedTopics = [newTopic];
    }
  }

  if (!topic.label) console.warn('[WARN] Topic label not found');

  emit('onChooseTopic', { alias: topic.label, index });
  resetAllSearches();
};

const onEmailChoose = (email: string) => {
  const isSelected = select.selectedEmails.includes(email);

  if (props.mentionMode === MentionModeEnum.ByMentionInput && !isSelected) {
    if (props.multiple) {
      select.selectedEmails.unshift(email);
    } else {
      select.selectedEmails = [email];
    }

    resetAllSearches();
  }
};

const resetAllSearches = () => {
  if (users.value.length) userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenOthers);
  if (groups.value.length) groupStore.resetSearchedGroups();
  emailData.value = '';
  topics.value = [];
};

const deleteUser = (index: number) => {
  select.selectedUsers.splice(index, 1);
};
const deleteGroup = (index: number) => {
  select.selectedGroups.splice(index, 1);
};
const deleteEmail = (index: number) => {
  select.selectedEmails.splice(index, 1);
};
</script>

<style scoped lang="scss">
.empty {
  border: unset;
  border-bottom: 1px solid var(--ion-color-medium);
  border-radius: unset;
}
.chip-block {
  margin-left: 1rem;
  margin-top: 0.5rem;

  ion-chip {
    border-radius: app-radius(md);
    margin: 0.5rem 0.5rem 0 0;
    border: 1px solid var(--ion-color-light-tint);
  }
}

.mentions-container {
  position: relative;
  z-index: 100;
  .list {
    border: 1px solid var(--ion-color-light-custom);
    border-radius: app-radius(md);
    position: absolute;
    left: 0;
    width: 30%;
    min-width: 240px;
    overflow: hidden;
    z-index: 3;
    background: var(--ion-color-light-background-contrast);
    box-shadow: 0 2px 7px 2px var(--ion-color-custom-element-lighter);

    ion-list {
      height: 276px;
      overflow-y: scroll;
      padding-inline: 1rem;
      margin: 0;
      background: var(--ion-color-light-background-contrast);
      scrollbar-width: thin;

      ion-item {
        --padding-start: 0;
        --padding-end: 0;
        --background: rgba(255, 255, 255, 0);
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }
      ion-list-header {
        padding-inline-start: 0;
        text-transform: none;
        text-align: center;
        background: unset;
        ion-label {
          background: unset;
          padding: 0;
          color: var(--ion-color-medium);
          font-size: 0.9rem;
          text-align: left;
        }
      }
    }
  }
}
</style>
