import { HubConnectionState } from '@microsoft/signalr';
import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import { $api } from '@/services';
import { getServiceHost } from '@/services/getters/getServiceHost';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  AuthFormModel,
  TokenRequestPayload,
  ResponseAuthTokenModel,
  ResponseAuthHomeCodeModel,
  AuthTokenModel,
} from '@/types';
import { useAppStore } from '@/store';
import { OAuthGrantTypeEnum, TokenModeEnum } from '@/enums/auth';
import type { WebSocketModel } from '@/ws/types';

type AuthState = {
  host: string;
  grantType: OAuthGrantTypeEnum | null;
  authType: 'Form' | 'Enterprise' | 'External' | null;
  homeAccessToken: string;
  homeValidUntil: string;
  accessToken: string;
  validUntil: string;
  coreId: string;
  companyRowId: string;
  userId: number | null;
  userRowId: string;
  webSocket: string;
  signalRConnectionId: string;
  signalRConnectionStatus: HubConnectionState;
  wsAvailable: boolean;
  errors: ErrorMessageModel[];
};

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    host: getServiceHost(),
    grantType: null,
    authType: null,
    homeAccessToken: '',
    homeValidUntil: '',
    accessToken: '',
    validUntil: '',
    coreId: '',
    companyRowId: '',
    userId: null,
    userRowId: '',
    webSocket: import.meta.env.VITE_APP_SIGNALR_URL,
    signalRConnectionId: '',
    signalRConnectionStatus: HubConnectionState.Disconnected,
    wsAvailable: true,
    errors: [],
  }),
  getters: {
    getWebSocketModel(state): WebSocketModel | null {
      const baseUrl = `https://${this.host}`;
      if (!baseUrl || state.userId === null || state.webSocket.length === 0) {
        return null;
      }

      return {
        baseUrl,
        userId: state.userId,
        coreId: state.coreId,
        companyRowId: state.companyRowId,
        userRowId: state.userRowId,
        webSocket: state.webSocket,
        version: useAppStore().appVersion,
      };
    },
  },
  actions: {
    //#region Setters
    setGrantType(grantType: OAuthGrantTypeEnum) {
      this.grantType = grantType;
    },
    setAuthType(type: 'Form' | 'Enterprise' | 'External' | null) {
      this.authType = type;
    },
    setUserId(userId: number) {
      this.userId = userId;
    },
    setAuthData(model: Partial<AuthTokenModel>) {
      this.$patch((state) => {
        state.homeAccessToken = model.homeAccessToken ?? state.homeAccessToken;
        state.homeValidUntil = model.homeValidUntil ?? state.homeValidUntil;
        state.accessToken = model.accessToken ?? state.accessToken;
        state.validUntil = model.validUntil ?? state.validUntil;
        state.coreId = model.coreId ?? state.coreId;
        state.companyRowId = model.companyRowId ?? state.companyRowId;
        state.userId = model.userId ?? state.userId;
        state.userRowId = model.userRowId ?? state.userRowId;
      });
    },
    setSignalRConnectionId(id: string) {
      this.signalRConnectionId = id;
    },
    setSignalRConnectionStatus(status: HubConnectionState) {
      this.signalRConnectionStatus = status;
    },
    setWsAvailable(isAvailable: boolean) {
      this.wsAvailable = isAvailable;
    },
    setHost(host: string) {
      this.host = host;
    },
    setNetwork(companyRowId: string, webSocket: string, isWaitingForCompleteLogin: boolean) {
      this.companyRowId = companyRowId;
      this.webSocket = webSocket;
      useAppStore().isWaitingForCompleteLogin = isWaitingForCompleteLogin;
    },
    //#endregion

    //#region Helpers
    isAuth(): boolean {
      const hasRequiredFields = (): boolean => {
        return (
          Boolean(this.homeAccessToken) &&
          Boolean(this.accessToken) &&
          Boolean(this.coreId) &&
          Boolean(this.companyRowId) &&
          this.userId !== null &&
          this.userId > 0 &&
          Boolean(this.userRowId) &&
          Boolean(this.grantType)
        );
      };

      return hasRequiredFields() && !this.isHomeAccessTokenExpired() && !this.isAccessTokenExpired();
    },

    isHomeAccessTokenExpired(): boolean {
      return Date.parse(this.homeValidUntil) < Date.now() || !this.homeAccessToken;
    },

    isAccessTokenExpired(): boolean {
      return Date.parse(this.validUntil) < Date.now() || !this.accessToken;
    },
    //#endregion

    //#region API
    async homeCode(data: AuthFormModel): Promise<boolean> {
      this.errors = [];
      const response = await $api.auth.homeCode(data);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthHomeCodeModel;
        this.setGrantType(OAuthGrantTypeEnum.HomeAuthorizationCode);
        /**
         * @note Temporary solution since api does not return homeAccessToken / homeValidUntil here
         * instead it returns accessToken / validUntil
         * @todo Refactor
         * @link https://gitlab.united-grid.com/intra/...
         */
        this.setAuthData({
          homeAccessToken: model.data.accessToken,
          homeValidUntil: model.data.validUntil,
          coreId: model.data.coreId,
          companyRowId: model.data.companyRowId,
          userId: model.data.userId,
          userRowId: model.data.userRowId,
        });
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async token(mode: TokenModeEnum, payload?: TokenRequestPayload): Promise<boolean> {
      const { force, silent, login, password } = payload ?? {};

      // Check if refresh needed
      if (!this.isAccessTokenExpired() && !force) {
        console.warn(`[WARN] [/oauth/token]: already authorized. accessToken is OK, ${JSON.stringify(this.$state)}`);
        return false;
      }

      if (mode === TokenModeEnum.Form) {
        return this.tokenByForm(login, password, silent);
      }

      if (mode === TokenModeEnum.Code) {
        return this.tokenByCode(silent);
      }

      // if (mode === TokenModeEnum.Redirect) {
      //   return this.tokenByRedirect(silent);
      // }

      return false;
    },

    async tokenByForm(login?: string, password?: string, silent: boolean = false): Promise<boolean> {
      // Validate inputs
      if (!login || !password) {
        console.warn(`[WARN] [/oauth/token]: login or password are NOT OK, login: ${login}, password: ${password}`);
        return false;
      }

      const body = { grantType: this.grantType, login, password };

      useAppStore().setIsLoading(true);
      const response = await $api.auth.token(body, silent);
      useAppStore().setIsLoading(false);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthTokenModel;
        this.setGrantType(OAuthGrantTypeEnum.Password);
        this.setAuthData(model.data);
        return true;
      }

      return false;
    },

    async tokenByCode(silent: boolean = false): Promise<boolean> {
      // Check home token validity
      if (this.isHomeAccessTokenExpired()) {
        console.warn(
          `[WARN] [/oauth/token]: unable to authorize. homeAccessToken is EXPIRED, ${JSON.stringify(this.$state)}`
        );
        return false;
        // TODO: redirect to login page
      }

      const body = {
        grantType: this.grantType,
        code: this.homeAccessToken,
        uid: this.companyRowId,
      };

      useAppStore().setIsLoading(true);
      const response = await $api.auth.token(body, silent);
      useAppStore().setIsLoading(false);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthTokenModel;
        this.setGrantType(OAuthGrantTypeEnum.HomeAuthorizationCode);
        this.setAuthData(model.data);
        return true;
      }

      return false;
    },

    async tokenByRedirect(code: string, grantType: OAuthGrantTypeEnum): Promise<boolean> {
      const body = {
        grantType,
        code,
      };

      useAppStore().setIsLoading(true);
      const response = await $api.auth.token(body, false);
      useAppStore().setIsLoading(false);

      if (response.statusCode === 200) {
        const model = response as ResponseAuthTokenModel;
        this.setGrantType(grantType);
        /**
         * @note Temporary solution since api does not return homeAccessToken / homeValidUntil here
         * instead it returns accessToken / validUntil
         * @todo Refactor
         * @link https://gitlab.united-grid.com/intra/...
         */
        this.setAuthData(model.data);
        return true;
      }

      return false;
    },
    //#endregion
  },
  persist: true,
});
