import { alertController, modalController } from '@ionic/vue';
import { cloneDeep, pick, remove } from 'lodash';
import { openDocBrowserContextSheet } from './actionSheetComponents';
import { isBlob } from './guards';
import {
  openChooseUserModal,
  openShareArchiveLinkModal,
  openTitleChangeModal,
  openWikiFollowersModal,
  openWikiTemplateSaveModal,
  openWikiTemplatesModal,
} from './modalComponents';
import {
  ActionAccessEnum,
  AppIconsEnum,
  AppLoadingTypeEnum,
  DocumentExtensionEnum,
  DocumentTypeEnum,
  EditModeEnum,
  FileStatusEnum,
  GroupPageTabEnum,
  GroupsAccessEnum,
  ShareArchiveLinkType,
  ShareEntityType,
  UserRoleEnum,
} from '@/enums';
import {
  WikiActionEnum,
  WikiDownloadOptionsEnum,
  WikiEditControlsEnum,
  WikiEditOptionsEnum,
  WikiMajorFilterEnum,
  WikiMenuOptionsFlagEnum,
  WikiSaveModeEnum,
  WikiShareOptionsEnum,
  WikiVersionEnum,
} from '@/enums/wiki';
import {
  createCellTemplate,
  createConfirmationAlert,
  formatDateHelper,
  generateTableOfContentsHtml,
  isAnyMobile,
  isNativeMobile,
  openDocsActionsPopover,
  openDocsAttachmentModal,
  openDocsMoveFileModal,
  openLink,
  openWikiHistoryModal,
  openWikiRelationsModal,
  openWikiSavePopover,
  openWikiTemplatesPopover,
  shareEntity,
  toShortUserModel,
  useAccess,
  useErrors,
  useFilesHybrid,
  useLoading,
  useMenu,
  useToasts,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { defaultAdvancedWikiParticipantsModel, defaultUser } from '@/models';
import router, { ROUTES_NAME } from '@/router';
import {
  useAppStore,
  useAuthStore,
  useDocStore,
  useGroupsStore,
  useNetworkStore,
  useUserStore,
  useWikiStore,
} from '@/store';
import type {
  AppActionButton,
  CreateWikiPayload,
  CreateWikiTemplateModel,
  DocModel,
  EditControls,
  EditOptions,
  MediaModel,
  MenuItemModel,
  TabCategories,
  UpdateWikiPayload,
  WikiActionPayload,
  WikiActionsMenuModel,
  WikiControlPayload,
  WikiEditFormModel,
  WikiHistoricalModel,
  WikiHistoryModel,
  WikiModel,
  WikiOptionPayload,
  WikiTemplateModel,
} from '@/types';
import { ColumnRegular, VGridVueTemplate } from '@revolist/vue3-datagrid';
import { AppTableActionBtn } from '@/components';

type IUseWiki = {
  getPreviewMenuItems: (
    wiki: WikiModel,
    showAllItems: boolean,
    versionId?: number
  ) => { menuItems: WikiActionsMenuModel[]; filteredItems: WikiActionsMenuModel[] };
  getEditOptions: (mode: EditModeEnum) => EditOptions[];
  getEditControls: (mode: EditModeEnum) => EditControls[];
  getTemplatesTableHeader: () => ColumnRegular[];
  /**
   * @deprecated
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2331#note_114559
   * @link ...
   *
   * @description
   * Use to check if the user is allowed to create a wiki in the given group or in the general feed.
   * If `groupId` is NOT null, checks access for the group.
   * If `groupId` is null, checks access for the general feed.
   */
  getCreateAccess: (groupId: number | null) => boolean;
  /** Use to get historical buttons for a wiki version */
  getHistoricalButtons: () => AppActionButton[];
  /** Use to get the avatar of the user who modified the wiki */
  getHistoricalModifiedByAvatar: (wiki: WikiHistoryModel) => MediaModel | null;
  handleAction: (payload: WikiActionPayload) => Promise<number | boolean | undefined>;
  handleOption: (payload: WikiOptionPayload) => Promise<void>;
  handleControl(payload: WikiControlPayload): Promise<void>;
  preventEdit: (id: number) => Promise<boolean>;
  openActionsMenu: (payload: {
    ev: Event;
    wiki: WikiModel;
    flag?: WikiMenuOptionsFlagEnum;
    menuItems?: WikiActionsMenuModel[];
    versionId?: number;
  }) => Promise<number | boolean | undefined>;

  /** Use to remove a relation from a wiki */
  removeRelation: (wikiId: number, relationWikiId: number | null, relationFileId: number | null) => Promise<boolean>;
  /** Use to add a relation to a wiki */
  addRelation: (wikiId: number) => Promise<boolean>;

  showReasonAlert: (message: string) => Promise<void>;
  goBack: () => Promise<void>;
};

export function useWiki(): IUseWiki {
  //#region Helpers
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();
  //#endregion

  //#region Private methods
  /** @private */
  async function _deleteTemplate(templateId: number, templateName: string): Promise<void> {
    const deleteTemplate = async () => {
      const result = await useWikiStore().deleteTemplateById(templateId);
      if (result) {
        showSonnerToast(t('wiki.templates.delete.success'), true);
      } else {
        showSonnerToast(t('wiki.templates.delete.error'), false);
      }
    };

    const alert = await alertController.create({
      header: t('wiki.templates.delete.title'),
      message: t('wiki.templates.delete.confirm', { name: templateName }),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            await deleteTemplate();
            await alertController.dismiss();
          },
        },
      ],
    });

    await alert.present();
  }

  async function _updateExistingWiki(payload: UpdateWikiPayload, isModal: boolean): Promise<number | null> {
    const result = await useWikiStore().update({
      mentionedUserIds: useWikiStore().editForm.mentionedUserIds ?? [],
      ...payload,
    });

    if (!result) {
      showSonnerToast(t('wiki.editMessages.updateError'), false);
      return null;
    }

    showSonnerToast(t('wiki.editMessages.updatedSuccess'), true);

    await _leaveEditPage(payload.wikiId, isModal);
    return payload.wikiId;
  }

  async function _onNewWikiCreate(payload: CreateWikiPayload, isModal: boolean): Promise<number | null> {
    const createdId = await useWikiStore().create(payload);

    if (!createdId) {
      console.warn('[WARN] Error during creating wiki');
      showSonnerToast(t('wiki.editMessages.createError'), false);
      return null;
    }

    showSonnerToast(t('wiki.editMessages.createSuccess'), true);
    await _leaveEditPage(createdId, isModal);
    return createdId;
  }

  function _showParentItem(items: WikiActionsMenuModel[], flag: WikiMenuOptionsFlagEnum): boolean {
    return items.length > 1 && flag === WikiMenuOptionsFlagEnum.All;
  }
  //#endregion

  //#region Getters
  function _getShareMenuItems(
    wiki: WikiModel,
    flag: WikiMenuOptionsFlagEnum,
    versionId?: number
  ): WikiActionsMenuModel[] {
    const shareParentElement = [
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        value: WikiActionEnum.Share,
        disabled: !!versionId,
      },
    ];

    const shareItems: WikiActionsMenuModel[] = [
      {
        title: t('files.menu.share'),
        icon: AppIconsEnum.Share,
        value: WikiShareOptionsEnum.Share,
        disabled: !!versionId || !useAccess().canShareForBKG(wiki.access.includes(ActionAccessEnum.Share)),
      },
      {
        title: t('wiki.menuActions.sendAsArchiveToEmail'),
        icon: AppIconsEnum.Mail,
        value: WikiShareOptionsEnum.SendAsArchiveToEmail,
        disabled: !!versionId || !wiki.access.includes(ActionAccessEnum.ShareAsArchive),
      },
    ].filter(({ disabled }) => !disabled);

    return _showParentItem(shareItems, flag) ? shareParentElement : shareItems;
  }

  function _getDownloadMenuItems(flag: WikiMenuOptionsFlagEnum, versionId?: number): WikiActionsMenuModel[] {
    const currentCompanyId: string = useAuthStore().companyRowId;
    const isBKGNetwork: boolean = useMenu().companiesList['BKG'] === currentCompanyId;

    const downloadParentItem = [
      {
        title: t(`files.menu.download`),
        icon: AppIconsEnum.Download,
        value: WikiActionEnum.Download,
        disabled: !!versionId,
      },
    ];

    const downloadItems: WikiActionsMenuModel[] = [
      {
        title: t(`files.menu.downloadAsPDF`),
        icon: AppIconsEnum.Download,
        value: WikiDownloadOptionsEnum.DownloadAsPDF,
        disabled: !!versionId,
      },
      /**
       * @note Hide possibility to "Download as DOCX" for BKG network
       * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2179
       */
      {
        title: t(`files.menu.downloadAsDOCX`),
        icon: AppIconsEnum.Download,
        value: WikiDownloadOptionsEnum.DownloadAsDOCX,
        disabled: !!versionId || isBKGNetwork,
      },
    ].filter(({ disabled }) => !disabled);

    return _showParentItem(downloadItems, flag) ? downloadParentItem : downloadItems;
  }

  function _getActionsMenuItems(
    wiki: WikiModel,
    versionId?: number,
    flag = WikiMenuOptionsFlagEnum.All
  ): WikiActionsMenuModel[] {
    const isShowFollowers: boolean = useAccess().showFollowListsForBKG(useNetworkStore().showFollowLists);
    const isCurrentRoutePreview = router.currentRoute.value.name === ROUTES_NAME.WIKI_BY_ID;
    /** @note wiki history is visible only if there are several versions of wikis */
    const lengthConditionDocHistory = useWikiStore().getHistory.length > 1;

    const items: WikiActionsMenuModel[] = [
      {
        title: wiki.isOfficial ? t(`wiki.menuActions.unmarkOfficial`) : t(`wiki.menuActions.markOfficial`),
        icon: wiki.isOfficial ? AppIconsEnum.Star : AppIconsEnum.StarOutline,
        value: WikiActionEnum.MarkOfficial,
        disabled: !wiki.access.includes(ActionAccessEnum.MarkAsOfficial) || !!versionId,
      },
      {
        title: wiki.isFollowed ? t(`subscribe.unfollow`) : t(`subscribe.follow`),
        icon: wiki.isFollowed ? AppIconsEnum.NotificationsOff : AppIconsEnum.Notifications,
        value: wiki.isFollowed ? WikiActionEnum.Unfollow : WikiActionEnum.Follow,
        disabled: !!versionId,
      },
      {
        title: t(`wiki.menuActions.edit`),
        icon: AppIconsEnum.PencilSquare,
        value: WikiActionEnum.Edit,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit) || !!versionId,
      },
      ..._getDownloadMenuItems(flag, versionId),
      ..._getShareMenuItems(wiki, flag, versionId),
      {
        title: t(`wiki.menuActions.goToCurrentVersion`),
        icon: AppIconsEnum.GoBack,
        value: WikiActionEnum.ToCurrent,
        disabled: !versionId,
      },
      {
        title: t(`wiki.menuActions.rollback.title`),
        icon: AppIconsEnum.History,
        value: WikiActionEnum.Rollback,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit) || !versionId,
      },
      {
        title: t(`openInNewWindow`),
        icon: AppIconsEnum.ExternalLink,
        value: WikiActionEnum.OpenInNewWindow,
        disabled: isAnyMobile || isCurrentRoutePreview || !!versionId,
      },
      {
        title: t(`wiki.menuActions.showHistory`),
        icon: AppIconsEnum.DocVersions,
        value: WikiActionEnum.ShowHistory,
        disabled: !wiki.access.includes(ActionAccessEnum.Edit) || !isCurrentRoutePreview || !lengthConditionDocHistory,
      },
      {
        title: t(`documentsRelations.showRelations`),
        icon: AppIconsEnum.Link,
        value: WikiActionEnum.ShowRelations,
        disabled: !isCurrentRoutePreview || !!versionId,
      },
      {
        title: t(`wiki.menuActions.showFollowers`),
        icon: AppIconsEnum.Users,
        value: WikiActionEnum.ShowFollowers,
        disabled: !isCurrentRoutePreview || !isShowFollowers || !!versionId,
      },
      {
        title: t(`wiki.menuActions.move.title`),
        icon: AppIconsEnum.Move,
        value: WikiActionEnum.Move,
        disabled: !wiki.access.includes(ActionAccessEnum.Move) || !!versionId,
      },
      {
        title: t(`wiki.menuActions.delete`),
        icon: AppIconsEnum.Remove,
        value: WikiActionEnum.Delete,
        disabled: !wiki.access.includes(ActionAccessEnum.Delete) || !!versionId,
      },
      {
        title: t(`wiki.menuActions.deleteVersion`),
        icon: AppIconsEnum.Remove,
        value: WikiActionEnum.DeleteVersion,
        disabled: !wiki.access.includes(ActionAccessEnum.Delete) || !versionId,
      },
    ].filter(({ disabled }) => !disabled);

    const itemsMap: Record<WikiMenuOptionsFlagEnum, WikiActionsMenuModel[]> = {
      [WikiMenuOptionsFlagEnum.All]: items,
      [WikiMenuOptionsFlagEnum.Share]: _getShareMenuItems(wiki, flag, versionId),
      [WikiMenuOptionsFlagEnum.Download]: _getDownloadMenuItems(flag, versionId),
    };

    return itemsMap[flag];
  }

  function _getTemplatesMenuItems(): TabCategories<WikiEditControlsEnum>[] {
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    return [
      {
        value: WikiEditControlsEnum.ChooseTemplate,
        active: true,
        icon: AppIconsEnum.DocText,
        title: t('wiki.templates.choose.title'),
      },
      {
        value: WikiEditControlsEnum.SaveAsTemplate,
        active: currentUserRoleId >= UserRoleEnum.Administrator,
        icon: AppIconsEnum.Download,
        title: t('wiki.templates.save.title'),
      },
      {
        value: WikiEditControlsEnum.UpdateTemplate,
        active: currentUserRoleId >= UserRoleEnum.Administrator,
        icon: AppIconsEnum.Refresh,
        title: t('wiki.templates.update.title'),
      },
    ].filter((item) => item.active);
  }

  function _getSaveOptions(showMinorSaveOption: boolean): MenuItemModel<WikiSaveModeEnum>[] {
    return [
      {
        value: WikiSaveModeEnum.Major,
        title: t('wiki.editMessages.saveMode.major'),
        icon: AppIconsEnum.None,
        disabled: false,
      },
      {
        value: WikiSaveModeEnum.Minor,
        title: t('wiki.editMessages.saveMode.minor'),
        icon: AppIconsEnum.None,
        disabled: !showMinorSaveOption,
      },
    ].filter(({ disabled }) => !disabled);
  }

  function _getPreparedWikiTemplateData(editForm: WikiEditFormModel): CreateWikiTemplateModel | null {
    const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
    if (isAdvancedWikiesEditor && editForm.wikiContent) {
      return {
        name: '',
        content: pick(editForm.wikiContent, ['body', 'head', 'content', 'settings']),
      };
    } else if (editForm.wikiText) {
      console.warn('[WARN] Simple-wiki templates is not supported');
      return null;
    } else {
      console.warn('[WARN] No data to create template');
      return null;
    }
  }

  function getPreviewMenuItems(
    doc: WikiModel,
    showAllItems: boolean,
    versionId?: number
  ): { menuItems: WikiActionsMenuModel[]; filteredItems: WikiActionsMenuModel[] } {
    const wikiToolbarItems: Array<WikiActionEnum | WikiDownloadOptionsEnum | WikiShareOptionsEnum> = [
      WikiActionEnum.MarkOfficial,
      WikiActionEnum.Follow,
      WikiActionEnum.Unfollow,
      WikiActionEnum.Edit,
      WikiActionEnum.Download,
      WikiDownloadOptionsEnum.DownloadAsPDF,
      WikiDownloadOptionsEnum.DownloadAsDOCX,
      WikiActionEnum.Share,
      WikiShareOptionsEnum.Share,
      WikiShareOptionsEnum.SendAsArchiveToEmail,
    ];

    const historicalToolbarItems: Array<WikiActionEnum | WikiDownloadOptionsEnum | WikiShareOptionsEnum> = [
      WikiActionEnum.ToCurrent,
      WikiActionEnum.Rollback,
      WikiActionEnum.ShowHistory,
      WikiActionEnum.DeleteVersion,
    ];

    const toolbarItems = versionId ? historicalToolbarItems : wikiToolbarItems;

    let maxItems;
    showAllItems ? (maxItems = toolbarItems.length) : (maxItems = 0);
    const splicedItems = toolbarItems.splice(0, maxItems);
    const menuItems = _getActionsMenuItems(doc, versionId);
    const filteredItems = remove(menuItems, ({ value }) => splicedItems.includes(value));
    return { menuItems, filteredItems };
  }

  function getEditOptions(mode: EditModeEnum): EditOptions[] {
    const options = [
      {
        title: t('wiki.editOptions.title'),
        action: WikiEditOptionsEnum.EditTitle,
        disabled: false,
        value: useWikiStore().editForm.name || t('titleChangeModal.placeholder.title'),
      },
      {
        title: t('wiki.editOptions.author'),
        action: WikiEditOptionsEnum.EditAuthor,
        secondaryAction: WikiEditOptionsEnum.ResetAuthor,
        disabled: !(useAccess().isPostOnBehalfAvailable() && mode === EditModeEnum.Create),
        value:
          useWikiStore().editForm.author.id !== 0
            ? useWikiStore().editForm.author.fullName
            : (useUserStore().current?.fullName ?? ''),
      },
      {
        title: t('wiki.editOptions.group'),
        action: WikiEditOptionsEnum.None,
        disabled: false,
        value: useWikiStore().getGroupTitle,
      },
      {
        title: t('wiki.editOptions.folder'),
        action: WikiEditOptionsEnum.None,
        disabled: false,
        /** It's not used anyway because of the filter below. */
        value: useWikiStore().getFolderTitle,
      },
      {
        title: t('wiki.editOptions.author'),
        action: WikiEditOptionsEnum.None,
        disabled: mode !== EditModeEnum.Edit,
        value: useWikiStore().getCreatedBy?.fullName ?? '',
      },
      {
        title: t('wiki.editOptions.createdAt'),
        action: WikiEditOptionsEnum.None,
        disabled: mode !== EditModeEnum.Edit,
        value: formatDateHelper(useWikiStore().getCreationDate, 'long'),
      },
      {
        title: t('wiki.editOptions.lastEditedBy'),
        action: WikiEditOptionsEnum.None,
        disabled: mode !== EditModeEnum.Edit,
        value: useWikiStore().getLastEditor?.fullName ?? '',
      },
      {
        title: t('wiki.editOptions.lastEditedAt'),
        action: WikiEditOptionsEnum.None,
        disabled: mode !== EditModeEnum.Edit,
        value: formatDateHelper(useWikiStore().getLastEditDate, 'long'),
      },
      {
        title: t('wiki.editOptions.outdated'),
        action: WikiEditOptionsEnum.Outdated,
        disabled: false,
        value: '',
      },
    ];

    return options.filter(({ disabled, value }) => !disabled && value !== '');
  }

  function getEditControls(mode: EditModeEnum): EditControls[] {
    const controls = [
      {
        title: t('wiki.editControls.goToCurrentVersion'),
        icon: AppIconsEnum.Eye,
        disabled: true,
        value: WikiActionEnum.ToCurrent,
        isDropdown: false,
      },
      {
        title: t('wiki.editControls.deleteNote'),
        icon: AppIconsEnum.Trash,
        disabled: mode !== EditModeEnum.Edit && !useWikiStore().existingWiki?.id,
        value: WikiEditControlsEnum.Delete,
        isDropdown: false,
      },
      {
        title: t('wiki.templates.title'),
        icon: AppIconsEnum.DocText,
        disabled: !useNetworkStore().isAdvancedWikiesEditor,
        value: WikiEditControlsEnum.TemplatesMenu,
        isDropdown: true,
      },
    ];

    return controls.filter(({ disabled }) => !disabled);
  }

  function getTemplatesTableHeader(): ColumnRegular[] {
    const is2XSWidth = useAppStore().is2XSWidth;
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    const _deleteColumn = {
      prop: 'table-action-button',
      size: 64,
      name: '',
      cellTemplate: VGridVueTemplate(AppTableActionBtn, {
        onAction: (data: WikiTemplateModel) => {
          try {
            _deleteTemplate(data?.id, data?.name);
          } catch (e) {
            console.error('[ERROR] Failed to delete template', e);
          }
        },
        icon: AppIconsEnum.Trash,
      }),
    };

    const _desktopHeader = [
      {
        prop: 'name' as keyof WikiTemplateModel,
        size: is2XSWidth ? 300 : 200,
        name: t('wiki.templates.templateName'),
        cellTemplate: createCellTemplate((props) => props.model.name),
      },
      {
        prop: 'createdBy' as keyof WikiTemplateModel,
        size: is2XSWidth ? 180 : 150,
        name: t('table.author'),
        cellTemplate: createCellTemplate((props) => props.model.createdBy.fullName),
      },
      {
        prop: 'createdAt' as keyof WikiTemplateModel,
        size: is2XSWidth ? 180 : 150,
        name: t('table.date'),
        cellTemplate: createCellTemplate((props) => formatDateHelper(props.model.createdAt, 'short')),
      },
      ...(currentUserRoleId >= UserRoleEnum.Administrator ? [_deleteColumn] : []),
    ];

    return _desktopHeader;
  }

  /**
   * @deprecated
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2331#note_114559
   * @link ...
   */
  function getCreateAccess(groupId: number | null): boolean {
    const allowPostToFeed = useNetworkStore().settings?.allowPostToFeed ?? false;
    const currentUserRoleId = useUserStore().current?.roleId ?? UserRoleEnum.ExternalGroupUserReadLike;

    let result = false;
    if (groupId) {
      const group = useGroupsStore().getGroupById(groupId);
      result = group.accessType >= GroupsAccessEnum.Member;
    } else {
      result = allowPostToFeed && currentUserRoleId >= UserRoleEnum.User;
    }

    return result;
  }

  function getHistoricalButtons(): AppActionButton[] {
    return [
      /** @deprecated  */
      /* {
         title: t('wiki.menuActions.delete'),
         value: WikiActionEnum.DeleteVersion,
         type: 'main',
         icon: AppIconsEnum.Remove,
         showIcon: true,
         showTooltip: true,
         showTitle: false,
       },
       {
         title: t('wiki.menuActions.rollback.title'),
         value: WikiActionEnum.Rollback,
         type: 'main',
         icon: AppIconsEnum.History,
        showIcon: true,
         showTooltip: true,
         showTitle: false,
       }, */
      {
        title: t('wiki.menuActions.compare'),
        value: WikiActionEnum.CompareHistorical,
        type: 'main',
        icon: AppIconsEnum.Compare,
        showIcon: true,
        showTooltip: true,
        showTitle: false,
      },
    ];
  }

  function getHistoricalModifiedByAvatar(wiki: WikiHistoryModel): MediaModel | null {
    if (wiki.modifyUserId) {
      const uId = wiki.modifyUserId;
      const user = useUserStore().getUserProfile(uId);
      return user?.avatar;
    }
    return null;
  }
  //#endregion

  //#region Actions
  async function _create(): Promise<undefined> {
    try {
      await router.push({
        name: ROUTES_NAME.WIKI_CREATE,
      });
    } catch (e) {
      console.error('[ERROR] Failed to go to wiki edit page', e);
    }
  }

  async function _open(id: number, versionId?: number): Promise<void> {
    await router.push({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id, versionId },
    });
  }

  async function _edit(wiki: WikiModel | undefined): Promise<undefined> {
    if (!wiki) {
      console.warn('[WARN] Wiki is not defined');
      return;
    }

    useWikiStore().setPartialEditForm({
      groupId: wiki.group?.id ?? null,
      folderId: wiki.parentFolderId ?? null,
    });

    await router.push({
      name: ROUTES_NAME.WIKI_EDIT,
      params: { id: wiki.id },
    });
  }

  async function _move(id: number): Promise<boolean> {
    try {
      const result = await openDocsMoveFileModal(null);
      if (!result) {
        console.warn('[WARN No folder selected');
        return false;
      }

      await useDocStore().moveWiki(result.folderId, result.groupId, id);

      showSonnerToast(t('wiki.menuActions.move.success'), true);
      await useWikiStore().wikiById(id);
      return true;
    } catch (e) {
      console.error('[ERROR] Failed to move wiki', e);
      showSonnerToast(t('wiki.menuActions.move.error'), false);
      return false;
    }
  }

  async function _markOfficial(id: number): Promise<boolean> {
    try {
      const topModal = await modalController.getTop();
      if (topModal?.id === 'wiki_actions') {
        await modalController.dismiss(null, 'end', 'wiki_actions');
      }
    } catch (e) {
      console.error('[ERROR] Error dismissing modal:', e);
    }

    return await useWikiStore().markOfficial(id);
  }

  async function _showRelations(id: number, canEdit: boolean): Promise<undefined> {
    await openWikiRelationsModal(id, canEdit);
  }

  async function _showFollowers(id: number): Promise<undefined> {
    await useWikiStore().getFollowers(id);
    await openWikiFollowersModal();
  }

  async function _showHistory(id: number, fromComparePage?: boolean): Promise<number | undefined> {
    const existingWiki = useWikiStore().existingWiki;
    if (!existingWiki || existingWiki.id !== id) {
      await useWikiStore().wikiById(id);
    }

    if (!useWikiStore().getHistory.length || !fromComparePage) {
      const result = await useWikiStore().allHistory(id);
      if (!result) {
        console.warn('[WARN] Failed to get history');
        showSonnerToast('Failed to get updated history. Please refresh the page.', false);
        return;
      }
    }

    return await openWikiHistoryModal(id, fromComparePage);
  }

  async function _rollback(id: number, versionId: number | undefined): Promise<undefined> {
    if (!id || !versionId) {
      console.warn('[WARN] Failed to rollback wiki: id or versionId is not defined');
      return;
    }

    try {
      await useWikiStore().rollback(id, versionId);
      await useWikiStore().wikiById(id);
      showSonnerToast(t('wiki.menuActions.rollback.success'), true);
    } catch (e) {
      console.error('[ERROR] Failed to rollback wiki', e);
      showSonnerToast(t('wiki.menuActions.rollback.error'), false);
    }
  }

  async function _compareHistorical(
    sourceId?: number,
    versionIds?: number[],
    fromComparePage = false
  ): Promise<undefined> {
    if (!sourceId && !versionIds) {
      console.warn('[WARN] Failed to compare historical: sourceId or ids are not defined');
      return;
    }

    let tId = 0;
    let sId = 0;

    if (versionIds) {
      tId = versionIds[0];
      sId = versionIds[1];
    } else if (sourceId) {
      tId = useWikiStore().getHistory[0].id;
      sId = sourceId;
    }

    useWikiStore().setOnCompare(
      useWikiStore().getHistoryWikiById(tId),
      useWikiStore().getHistoryWikiById(sId),
      useWikiStore().existingWiki?.name ?? ''
    );

    if (!tId || !sId) {
      console.warn('[WARN] Failed to compare historical: tId or sId is not defined');
      return;
    }

    if (!fromComparePage) {
      await router.push({
        name: ROUTES_NAME.WIKI_COMPARE,
        params: { id: useWikiStore().existingWiki?.id },
        query: {
          targetId: tId,
          sourceId: sId,
        },
      });
    }
  }

  /** @private */
  async function _deleteWiki(wikiId: number, name: string): Promise<boolean> {
    let deleteResult: FileStatusEnum | null = null;
    const loading = useLoading();

    const _confirmDeletionAlert = async (): Promise<boolean> => {
      return new Promise((resolve) => {
        alertController
          .create({
            message: `${t('documents.popup.deleteWiki')} <strong>${name}?</strong>`,
            buttons: [
              { text: t('no'), role: 'cancel', cssClass: 'custom-alert-buttons', handler: () => resolve(false) },
              { text: t('yes'), role: 'confirm', cssClass: 'custom-alert-buttons', handler: () => resolve(true) },
            ],
          })
          .then((alert) => alert.present());
      });
    };

    const _hasRelationsAlert = async (): Promise<FileStatusEnum | null> => {
      return new Promise<FileStatusEnum | null>((resolve) => {
        alertController
          .create({
            message: t('documentsRelations.hasRelations'),
            buttons: [
              {
                text: t('documentsRelations.deleteWithAllRelations'),
                cssClass: 'custom-alert-buttons',
                handler: async () => {
                  resolve(await _handleWikiDeletion());
                },
              },
              {
                text: t('documentsRelations.deleteWithReplace'),
                cssClass: 'custom-alert-buttons',
                handler: async () => {
                  const result = await _handleReplaceFlow();
                  resolve(result);
                },
              },
            ],
          })
          .then((alert) => alert.present());
      });
    };

    const _handleWikiDeletion = async (): Promise<FileStatusEnum | null> => {
      const confirmed = await _confirmDeletionAlert();
      if (!confirmed) return null;

      return (await useWikiStore().delete(wikiId)) ? FileStatusEnum.Success : FileStatusEnum.Error;
    };

    const _handleReplaceFlow = async (): Promise<FileStatusEnum> => {
      try {
        const result = await openDocsAttachmentModal(null, true, false);
        if (!result || result.length === 0) return FileStatusEnum.Error;

        const selectedDoc = result[0];
        const isWiki = selectedDoc.documentType === DocumentTypeEnum.Wiki;
        const isFile = selectedDoc.documentType === DocumentTypeEnum.File;

        if (isWiki) {
          return (await useWikiStore().deleteWithWikiReplace(wikiId, selectedDoc.data.id))
            ? FileStatusEnum.Success
            : FileStatusEnum.Error;
        }

        if (isFile) {
          return (await useWikiStore().deleteWithFileReplace(wikiId, selectedDoc.data.id))
            ? FileStatusEnum.Success
            : FileStatusEnum.Error;
        }

        return FileStatusEnum.Error;
      } catch (error) {
        console.error('[ERROR] Failed to delete with replace:', error);
        return FileStatusEnum.Error;
      }
    };

    const _handlePostDeletion = async (deleteResult: FileStatusEnum) => {
      await goBack();
      showSonnerToast(
        deleteResult === FileStatusEnum.Success ? t('documents.popup.deleteSuccess') : t('documents.popup.deleteError'),
        deleteResult === FileStatusEnum.Success
      );
    };

    try {
      await loading.create(t('loading'), AppLoadingTypeEnum.OtherLoading);
      const hasRelations = (await useWikiStore().getRelations(wikiId)).length > 0;
      await loading.dismiss(AppLoadingTypeEnum.OtherLoading);

      if (hasRelations) {
        deleteResult = await _hasRelationsAlert();
      } else {
        deleteResult = await _handleWikiDeletion();
      }

      if (deleteResult !== null) {
        await _handlePostDeletion(deleteResult);
      }

      return deleteResult === FileStatusEnum.Success;
    } catch (error) {
      console.error('[ERROR] Failed to initiate wiki deletion', error);
      return false;
    }
  }

  /**
   * @private
   * @param versionId Deletion of wiki version
   */
  async function _deleteWikiVersion(wikiId: number, versionId: number | null, name: string): Promise<boolean> {
    if (!versionId) {
      console.warn('[WARN] Failed to delete wiki version: versionId are empty');
      return false;
    }

    try {
      const message = `${t('documents.popup.deleteWiki')} <strong>${name}</strong>?`;
      let deletionSuccess = false;
      const buttons = [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            const result = await useWikiStore().deleteVersion(wikiId, versionId);
            if (result) {
              showSonnerToast(t('documents.popup.deleteSuccess'), true);
              deletionSuccess = true;
            } else {
              showSonnerToast(t('documents.popup.deleteError'), false);
              deletionSuccess = false;
            }
            await alertController.dismiss();
            await goBack();
          },
        },
      ];

      await createConfirmationAlert(message, buttons, t('documents.popup.deleteError'));
      return deletionSuccess;
    } catch (e) {
      console.error('[ERROR] Failed to initiate wiki deletion', e);
      showSonnerToast(t('documents.popup.deleteError'), false);
      return false;
    }
  }

  async function _follow(id: number): Promise<boolean> {
    return await useWikiStore().follow(id);
  }

  async function _unfollow(id: number): Promise<boolean> {
    return await useWikiStore().unfollow(id);
  }

  async function _download(wiki: WikiModel | undefined, documentExtension: DocumentExtensionEnum): Promise<undefined> {
    if (!wiki) {
      console.warn('[WARN] Wiki is not defined');
      return;
    }

    const response = await useWikiStore().download(wiki.id, documentExtension);

    if (isBlob(response)) {
      const result = await useFilesHybrid().downloadWiki(wiki, response);
      showSonnerToast(t('files.successDownloaded'), result === FileStatusEnum.Success);
    } else {
      console.warn(`[WARN] Response is not a Blob: ${JSON.stringify(response)}`);
    }
  }

  /** @private */
  async function _handleRelationRemoval(
    wikiId: number,
    relationWikiId: number | null,
    relationFileId: number | null
  ): Promise<boolean> {
    try {
      const store = useWikiStore();
      const response = relationWikiId
        ? await store.removeRelationWiki(wikiId, relationWikiId)
        : relationFileId
          ? await store.removeRelationFile(wikiId, relationFileId)
          : null;

      if (response) {
        showSonnerToast(t('documentsRelations.delete.success'), true);
        return true;
      }
      return false;
    } catch (error) {
      console.error('[ERROR] Failed to remove relation:', error);
      return false;
    } finally {
      await alertController.dismiss();
    }
  }

  async function removeRelation(
    wikiId: number,
    relationWikiId: number | null,
    relationFileId: number | null
  ): Promise<boolean> {
    if (!wikiId) {
      console.warn('[WARN] Wiki id is not defined');
      return false;
    }

    return new Promise<boolean>((resolve) => {
      const message = t('documentsRelations.delete.confirm');
      const buttons = [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
          handler: () => resolve(false),
        },
        {
          text: t('yes'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            const result = await _handleRelationRemoval(wikiId, relationWikiId, relationFileId);
            resolve(result);
          },
        },
      ];

      createConfirmationAlert(message, buttons, '');
    });
  }

  async function addRelation(wikiId: number): Promise<boolean> {
    if (!wikiId) {
      console.warn('[WARN] Wiki id is not defined');
      return false;
    }

    const result = await openDocsAttachmentModal(null, true, false);

    const _wiki: DocModel | null = result?.[0].documentType === DocumentTypeEnum.Wiki ? result?.[0] : null;
    const _file: DocModel | null = result?.[0].documentType === DocumentTypeEnum.File ? result?.[0] : null;

    if (_wiki) {
      const response = await useWikiStore().addRelationWiki(wikiId, _wiki.data.id);
      return response;
    }

    if (_file) {
      const response = await useWikiStore().addRelationFile(wikiId, _file.data.id);
      return response;
    }

    console.warn('[WARN] Wiki or file is not defined');
    return false;
  }

  async function _openInNewWindow(id: number): Promise<undefined> {
    const resolvedPath = router.resolve({
      name: ROUTES_NAME.WIKI_BY_ID,
      params: { id },
    }).href;
    await openLink(new URL(resolvedPath, window.location.origin).href);
    return undefined;
  }
  //#endregion

  //#region Edit Options
  /** @private */
  async function _editTitle(isModal: boolean): Promise<void> {
    const existingWiki = useWikiStore().existingWiki;
    const editForm = useWikiStore().editForm;

    const result = await openTitleChangeModal(
      null,
      existingWiki?.name ?? editForm.name ?? '',
      undefined,
      undefined,
      undefined,
      isModal
    );
    if (result?.title) {
      useWikiStore().setHasChanged(true);
      useWikiStore().setSimpleWiki('name', result?.title);
    }
  }

  /** @private */
  async function _editAuthor(): Promise<void> {
    const selectedUser = useWikiStore().editForm.author
      ? useUserStore().getUserProfile(useWikiStore().editForm.author.id)
      : null;
    const result = await openChooseUserModal({
      alreadySelectedUsers: selectedUser ? [selectedUser] : [],
      title: t('messenger.chatModal.title'),
      withoutCurrent: false,
      multiSelect: false,
      onBehalf: true,
    });
    if (result === undefined) return;
    const author = result?.length ? result[0] : undefined;
    if (author) {
      useWikiStore().setHasChanged(true);
      useWikiStore().setPartialEditForm({ author: toShortUserModel(author) });
    }
  }

  /** @private */
  async function _resetAuthor(): Promise<void> {
    useWikiStore().setHasChanged(true);
    const currentUser = useUserStore().current;
    /**@note if author is set to defaultUser with id === 0 onBehalfUserId parameter will be set to undefined and won't be passed in request payload */
    useWikiStore().setPartialEditForm({
      author: currentUser ? toShortUserModel(currentUser) : toShortUserModel(cloneDeep(defaultUser)),
    });
  }

  /**
   * @private
   * @todo Implement
   */
  function _showAuthor() {
    console.warn('[WARN] _showAuthor is not implemented yet');
  }

  /**
   * @private
   * @todo Implement
   */
  function _showLastEditor() {
    console.warn('[WARN] _showLastEditor is not implemented yet');
  }
  /**
   * @private
   * @todo Implement
   */
  function _showGroup() {
    console.warn('[WARN] _showGroup is not implemented yet');
  }
  /**
   * @private
   * @todo Implement
   */
  function _showFolder() {
    console.warn('[WARN] _showFolder is not implemented yet');
  }
  /**
   * @private
   * @todo Implement
   */
  function _showOutdated() {
    console.warn('[WARN] _showOutdated is not implemented yet');
  }
  //#endregion

  //#region Edit Controls
  /** @private */
  async function _leaveEditPage(id?: number, isModal?: boolean): Promise<undefined> {
    try {
      useWikiStore().resetFromEditPage();

      if (isModal) {
        await modalController.dismiss(id);
        return;
      }

      if (!id) {
        router.back();
        return;
      }

      await _open(id);
    } catch (e) {
      console.error('[ERROR] Failed to leave edit page:', e);
    }
  }

  /** @private */
  async function _save(
    ev: Event | undefined,
    isModal: boolean,
    showMinorSaveOption: boolean = true
  ): Promise<number | null> {
    const saveOption = await openWikiSavePopover(ev, _getSaveOptions(showMinorSaveOption));
    if (!saveOption) {
      console.warn('[WARN] No save option is selected');
      return null;
    }

    if (saveOption === WikiSaveModeEnum.Minor) {
      useWikiStore().setSaveMode(saveOption);
    }

    useWikiStore().setSaveLoading(true);

    try {
      const check = useWikiStore().checkFields();
      if (!check.ok) {
        showSonnerToast(check.reason, false, undefined, undefined, undefined, undefined, undefined, undefined, true);
        return null;
      }

      const tocHtml = generateTableOfContentsHtml(useWikiStore().editForm.wikiContent);

      const editForm = useWikiStore().editForm;
      const advancedContent = {
        head: editForm.wikiContent.head,
        content: {
          name: t('wiki.editFields.content.title'),
          text: tocHtml,
          settings: {
            isVisible: true,
            visible: true,
            isDeleted: false,
          },
        },
        body: editForm.wikiContent.body,
        participants: {
          name: editForm.wikiContent.participants.name,
          participantsIds: editForm.participantsIds,
          settings: {
            allUsers: false,
            isVisible: true,
            visible: true,
            isDeleted: false,
          },
        },
        settings: { version: WikiVersionEnum.V2 },
      };

      const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
      const selectedGroup = useDocStore().selectedGroup;
      const selectedFolder = useDocStore().selectedFolder;
      const saveMode = useWikiStore().currentSaveMode;
      const currentUserId = useUserStore().current?.id ?? 0;
      const payload: CreateWikiPayload = {
        name: editForm.name,
        groupId: selectedGroup?.id || editForm.groupId,
        folderId: false ? selectedFolder?.id || null : selectedFolder?.id || editForm.folderId,
        onBehalfUserId:
          editForm.author.id !== 0 && editForm.author.id !== currentUserId ? editForm.author.id : undefined,
        isMajor: saveMode !== WikiSaveModeEnum.Minor,
        text: !isAdvancedWikiesEditor ? editForm.wikiText : null,
        content: isAdvancedWikiesEditor ? advancedContent : null,
        participantsIds: editForm.participantsIds,
        accessOnlyForGroupId: null,
        mentionedUserIds: useWikiStore().editForm.mentionedUserIds ?? [],
      };

      const existingId = useWikiStore().existingWiki?.id;
      if (existingId) {
        return await _updateExistingWiki(
          {
            wikiId: existingId,
            ...payload,
          },
          isModal
        );
      }

      return await _onNewWikiCreate(payload, isModal);
    } catch (e) {
      console.error('[ERROR] Failed to save wiki', e);
      return null;
    } finally {
      useWikiStore().setSaveLoading(false);
    }
  }

  /** @private */
  async function _toCurrent(id: number): Promise<undefined> {
    useWikiStore().resetFromHistorical();

    await useWikiStore().wikiById(id);

    await _open(id);
  }

  /** @private */
  async function _toHistorical(id: number, versionId?: number, date?: string, routeName?: string): Promise<undefined> {
    if (!versionId && !date) {
      console.warn('[WARN] Failed to go to historical wiki: versionId and date are empty.');
      return;
    }

    try {
      let historical: WikiHistoricalModel | null = null;
      if (versionId) {
        historical = await useWikiStore().historyById(versionId);
      } else if (date) {
        historical = await useWikiStore().historyByDate(id, date, WikiMajorFilterEnum.All);
      }

      if (!historical) {
        console.warn('[WARN] Failed to get historical wiki: no historical wiki found');
        return;
      }

      await useWikiStore().wikiById(id);

      useWikiStore().setHistoricalWiki(historical);

      useWikiStore().setVersionId(historical.id);

      if (routeName !== ROUTES_NAME.WIKI_BY_ID) await _open(id, historical.id);
    } catch (e) {
      console.error('[ERROR] An error occurred while navigating to historical wiki:', e);
    }
  }

  /** @private */
  async function _saveAsTemplate(): Promise<undefined> {
    const templateData = _getPreparedWikiTemplateData(useWikiStore().editForm);
    if (!templateData) return;

    const data = await openWikiTemplateSaveModal(templateData);

    if (data) {
      showSonnerToast(t('wiki.templates.save.success'), true);
    } else if (data !== undefined) {
      showSonnerToast(t('wiki.templates.save.error'), false);
    }
  }

  /** @private */
  async function _updateTemplate(): Promise<undefined> {
    let selectedTemplate: WikiTemplateModel | undefined = undefined;
    const templateData = _getPreparedWikiTemplateData(useWikiStore().editForm);
    if (!templateData) return;

    const updateTemplate = async () => {
      if (selectedTemplate && templateData) {
        const result = await useWikiStore().updateTemplateById({
          ...selectedTemplate,
          ...templateData,
        });
        if (result) {
          showSonnerToast(t('wiki.templates.update.success'), true);
        } else {
          showSonnerToast(t('wiki.templates.update.error'), false);
        }
      } else {
        console.warn('[WARN] Error while updating template');
      }
    };

    const alert = await alertController.create({
      header: t('wiki.templates.update.title'),
      message: t('wiki.templates.update.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: () => {
            updateTemplate();
          },
        },
      ],
    });

    const data = await openWikiTemplatesModal(t('wiki.templates.update.title'));
    selectedTemplate = data;
    selectedTemplate && (await alert.present());
  }

  /** @private */
  async function _chooseTemplate(): Promise<undefined> {
    let selectedTemplate: WikiTemplateModel | undefined;

    const alert = await alertController.create({
      header: t('wiki.templates.choose.title'),
      message: t('wiki.templates.choose.confirm'),
      buttons: [
        {
          text: t('cancel'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('confirm'),
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            if (!selectedTemplate) {
              console.warn('[WARN] Selected template is not defined');
              return;
            }

            selectedTemplate = await useWikiStore().templateById(selectedTemplate.id);
            if (selectedTemplate?.wikiContent) {
              useWikiStore().setAdvancedWikiContent({
                ...selectedTemplate.wikiContent,
                participants: cloneDeep(defaultAdvancedWikiParticipantsModel),
              });
              showSonnerToast(t('wiki.templates.choose.success'), true);
            } else {
              console.warn('[WARN] Selected template content is not defined');
              showSonnerToast(t('wiki.templates.choose.error'), false);
            }
          },
        },
      ],
    });

    const data = await openWikiTemplatesModal(t('wiki.templates.choose.title'));
    selectedTemplate = data;
    selectedTemplate && (await alert.present());
  }

  /** @private */
  async function _handleTemplatesMenu(ev: Event | undefined): Promise<undefined> {
    const items = _getTemplatesMenuItems();
    const result = items.length > 1 ? await openWikiTemplatesPopover(ev, items) : items[0].value;
    if (!result) return;

    if (result === WikiEditControlsEnum.SaveAsTemplate) return await _saveAsTemplate();

    if (result === WikiEditControlsEnum.ChooseTemplate) return await _chooseTemplate();

    if (result === WikiEditControlsEnum.UpdateTemplate) return await _updateTemplate();
  }
  //#endregion

  //#region Handlers
  async function handleAction(p: WikiActionPayload): Promise<number | boolean | undefined> {
    const actionsMap: Record<
      WikiActionEnum | WikiDownloadOptionsEnum | WikiShareOptionsEnum,
      () => Promise<number | boolean | undefined>
    > = Object.freeze({
      [WikiActionEnum.Create]: _create,
      [WikiActionEnum.Edit]: async () => _edit(p.model),
      [WikiActionEnum.Move]: async () => _move(p.id),
      [WikiActionEnum.MarkOfficial]: async () => _markOfficial(p.id),
      [WikiActionEnum.ShowRelations]: async () =>
        _showRelations(p.id, p.model?.access.includes(ActionAccessEnum.Edit) ?? false),
      [WikiActionEnum.ShowFollowers]: async () => _showFollowers(p.id),
      [WikiActionEnum.ShowHistory]: async () => _showHistory(p.id, p.fromComparePage),
      [WikiActionEnum.Rollback]: async () => _rollback(p.id, p.versionId),
      [WikiActionEnum.CompareHistorical]: async () => _compareHistorical(p.id, p.versionIds, p.fromComparePage),
      /** @note Since deletion of version is not implemented yet => passing it as `undefined` */
      [WikiActionEnum.Delete]: async () => _deleteWiki(p.id, p.model?.name || ''),
      [WikiActionEnum.DeleteVersion]: async () => _deleteWikiVersion(p.id, p.versionId || null, p.model?.name || ''),
      [WikiActionEnum.Follow]: async () => _follow(p.id),
      [WikiActionEnum.Unfollow]: async () => _unfollow(p.id),
      [WikiActionEnum.Share]: async () => {
        return p.model && p.ev
          ? await openActionsMenu({
              ev: p.ev,
              wiki: p.model,
              flag: WikiMenuOptionsFlagEnum.Share,
              versionId: p.versionId,
            })
          : undefined;
      },
      [WikiShareOptionsEnum.SendAsArchiveToEmail]: async () =>
        openShareArchiveLinkModal(p.id, ShareArchiveLinkType.Wiki),
      [WikiShareOptionsEnum.Share]: async () => {
        return await shareEntity(p.model as WikiModel, ShareEntityType.Wiki);
      },
      [WikiActionEnum.ToCurrent]: async () => _toCurrent(p.id),
      [WikiActionEnum.ToHistorical]: async () => await _toHistorical(p.id, p.versionId, p.date, p.routeName),
      [WikiActionEnum.Download]: async () =>
        p.model && p.ev && (await openActionsMenu({ ev: p.ev, wiki: p.model, flag: WikiMenuOptionsFlagEnum.Download })),
      [WikiDownloadOptionsEnum.DownloadAsPDF]: async () => _download(p.model, DocumentExtensionEnum.PDF),
      [WikiDownloadOptionsEnum.DownloadAsDOCX]: async () => _download(p.model, DocumentExtensionEnum.DOCX),
      [WikiActionEnum.OpenInNewWindow]: async () => _openInNewWindow(p.id),
    });

    const handler = actionsMap[p.type];
    if (!handler) {
      await router.push({
        name: ROUTES_NAME.WIKI_EDIT,
        params: { id: p.id },
      });
      console.warn('No handler found for action: ', p.type);
      return false;
    }

    try {
      const result = await handler();
      if (p.type === WikiActionEnum.ShowHistory || p.type === WikiActionEnum.Delete) return result;
    } catch (e) {
      console.error(`[ERROR] Error executing action ${p.type}:`, e);
      showSonnerToast(t('errorResponse'), false);
    }
  }

  async function handleOption(p: WikiOptionPayload): Promise<void> {
    const optionsMap: Record<WikiEditOptionsEnum, () => Promise<void> | void> = Object.freeze({
      [WikiEditOptionsEnum.None]: () => console.warn('[WARN] No handler for editing options'),
      [WikiEditOptionsEnum.EditTitle]: () => _editTitle(p.isModal),
      [WikiEditOptionsEnum.EditAuthor]: _editAuthor,
      [WikiEditOptionsEnum.ResetAuthor]: _resetAuthor,
      [WikiEditOptionsEnum.ShowAuthor]: _showAuthor /** @note Not implemented yet */,
      [WikiEditOptionsEnum.ShowLastEditor]: _showLastEditor /** @note Not implemented yet */,
      [WikiEditOptionsEnum.EditGroup]: _showGroup /** @note Not implemented yet */,
      [WikiEditOptionsEnum.EditFolder]: _showFolder /** @note Not implemented yet */,
      [WikiEditOptionsEnum.Outdated]: _showOutdated /** @note Not implemented yet */,
    });

    const handler = optionsMap[p.type];
    if (!handler) {
      console.warn('No handler found for edit option: ', p.type);
      return;
    }

    try {
      await handler();
    } catch (e) {
      console.error(`[ERROR] Error executing edit option ${p.type}:`, e);
      showSonnerToast(t('errorResponse'), false);
    }
  }

  async function handleControl(p: WikiControlPayload): Promise<void> {
    const controlsMap: Record<WikiEditControlsEnum, () => Promise<number | null | undefined | boolean>> = Object.freeze(
      {
        [WikiEditControlsEnum.Cancel]: async () => await _leaveEditPage(undefined, p.isModal),
        [WikiEditControlsEnum.Save]: async () => await _save(p.ev, p.isModal, p.showMinorSaveOption),
        [WikiEditControlsEnum.Delete]: async () => _deleteWiki(p.id, p.model?.name || ''),
        [WikiEditControlsEnum.SaveAsTemplate]: _saveAsTemplate,
        [WikiEditControlsEnum.UpdateTemplate]: _updateTemplate,
        [WikiEditControlsEnum.ChooseTemplate]: _chooseTemplate,
        [WikiEditControlsEnum.TemplatesMenu]: async () => await _handleTemplatesMenu(p.ev),
      }
    );

    const handler = controlsMap[p.type];
    if (!handler) {
      console.warn('No handler found for edit control:', p.type);
      return;
    }

    try {
      await handler();
    } catch (error) {
      console.error(`[ERROR] Error executing edit control ${p.type}:`, error);
      showSonnerToast(t('errorResponse'), false);
    }
  }
  //#endregion

  //#region Miscellaneous
  /**
   * Leaves edit page and goes to wiki page
   */
  async function goBack() {
    // Get current route name
    const currentRouteName = router.currentRoute.value.name;

    // If on docs page - do nothing
    if (currentRouteName === ROUTES_NAME.DOCS) return;

    // If on group page and in files tab - do nothing
    if (currentRouteName === ROUTES_NAME.GROUP_BY_ID && useGroupsStore().selectedTab === GroupPageTabEnum.Files) return;

    // Check if current route is wiki by ID or wiki edit
    const isWikiRoute = currentRouteName === ROUTES_NAME.WIKI_BY_ID || currentRouteName === ROUTES_NAME.WIKI_EDIT;

    // Check if previous is wiki route
    const history = router.options.history;
    const isPreviousWikiRoute = !!history.state.back?.toString().includes('/wiki');

    // If current route is wiki
    // and previous is also wiki - navigate to docs
    // Otherwise, we can go back
    isWikiRoute && isPreviousWikiRoute ? await router.push({ name: ROUTES_NAME.DOCS }) : router.back();
  }

  async function preventEdit(id: number): Promise<boolean> {
    const { handleError } = useErrors();

    /** @note Failed to lock wiki - not 200 */
    const lockStatus = await useWikiStore().lockEdit(id);
    if (!lockStatus) {
      handleError({
        show: true,
        error: undefined,
        message: `Failed to lock wiki`,
      });
      return true;
    }

    /** @note Failed to lock wiki - somebody is editing it */
    if (!lockStatus.success) {
      const name = { name: lockStatus.name ?? 'Someone' };
      const lockAlert = await alertController.create({
        header: t('wiki.preventAlert.lock.title'),
        message: t('wiki.preventAlert.lock.message', name),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await lockAlert.present();
      return true;
    }

    /** @note Check for isAdvanced on / off */
    let wiki: WikiModel | null = null;
    const existingWiki = useWikiStore().existingWiki;
    if (!existingWiki || existingWiki.id !== id) {
      wiki = await useWikiStore().wikiById(id);
    } else {
      wiki = existingWiki;
    }

    const isAdvancedWikiesEditor = useNetworkStore().isAdvancedWikiesEditor;
    const chosenWikiIsAdvanced = wiki?.version === WikiVersionEnum.V2;
    if (chosenWikiIsAdvanced && !isAdvancedWikiesEditor) {
      const advancedIsOffAlert = await alertController.create({
        header: t('wiki.preventAlert.advanced.title'),
        message: t('wiki.preventAlert.advanced.message'),
        buttons: [
          {
            text: 'Ok',
            role: 'confirm',
            cssClass: 'custom-alert-buttons',
            handler: async () => {
              await alertController.dismiss();
            },
          },
        ],
      });

      await advancedIsOffAlert.present();
      return true;
    }

    return false;
  }

  async function openActionsMenu(payload: {
    ev: Event;
    wiki: WikiModel;
    flag?: WikiMenuOptionsFlagEnum;
    menuItems?: WikiActionsMenuModel[];
    versionId?: number;
  }): Promise<number | boolean | undefined> {
    const { ev, wiki, flag = WikiMenuOptionsFlagEnum.All, menuItems = [], versionId } = payload;

    let items: WikiActionsMenuModel[] = [];
    if (menuItems.length) {
      items = menuItems;
    } else {
      items = _getActionsMenuItems(wiki, versionId, flag);
    }

    const action = isNativeMobile
      ? await openDocBrowserContextSheet(wiki, items)
      : await openDocsActionsPopover(ev, items);

    if (!action) {
      console.warn('No action for wiki', wiki.id);
      return;
    }

    return await handleAction({
      type: action as WikiActionEnum,
      id: wiki.id,
      model: wiki,
      ev,
      ...(versionId ? { versionId } : {}),
    });
  }

  async function showReasonAlert(message: string): Promise<void> {
    const alert = await alertController.create({
      message,
      buttons: [
        {
          text: t('confirm'),
          role: 'confirm',
          cssClass: 'custom-alert-buttons',
        },
      ],
    });

    await alert.present();
  }
  //#endregion

  return {
    getPreviewMenuItems,
    getEditOptions,
    getEditControls,
    getTemplatesTableHeader,
    getCreateAccess,
    getHistoricalButtons,
    getHistoricalModifiedByAvatar,
    handleAction,
    handleOption,
    handleControl,
    preventEdit,
    removeRelation,
    addRelation,
    openActionsMenu,
    showReasonAlert,
    goBack,
  };
}
