import { defineStore } from 'pinia';

import type { SearchUserItemTypes } from '@/enums';
import { SearchType } from '@/enums';
import { serializeQueryParams, useErrors } from '@/helpers';
import { useI18n } from '@/i18n';
import { $api } from '@/services';
import { usePostStore, useDocStore, useGroupsStore, useUserStore, useTopicStore } from '@/store';
import type { EntitiesModel, ResponseSearchModel } from '@/types';

interface SearchStore {
  isLoading: boolean;
  accuracy: number;
  suggestIsLoading: boolean;
  data: EntitiesModel | null;
  suggestions: string[];
}

export const useSearchStore = defineStore({
  id: 'search',

  state: (): SearchStore => ({
    isLoading: false,
    accuracy: 0.01,
    suggestIsLoading: false,
    data: null,
    suggestions: [],
  }),

  getters: {
    getData: (state): EntitiesModel | null => state.data,
  },

  actions: {
    resetSearch(types?: SearchType[]) {
      if (!types) {
        usePostStore().clearSearchData();
        useDocStore().setGlobalSearchDocs([], null);
        useGroupsStore().clearSearchData();
        useUserStore().clearUsersFromAdvancedSearch();
        useTopicStore().clearSearchData();
        this.data = null;
        return;
      }

      for (const type of types) {
        switch (type) {
          case SearchType.Posts:
            usePostStore().clearSearchData();
            break;
          case SearchType.Files:
            useDocStore().setGlobalSearchDocs([], null);
            break;
          case SearchType.Groups:
            useGroupsStore().clearAdvancedSearchData();
            break;
          case SearchType.Users:
            useUserStore().clearChosenOthersData();
            break;
          case SearchType.Wikis:
            this.data = null;
            break;
          case SearchType.Tags:
            useTopicStore().clearSearchData();
            break;
          default:
            this.data = null;
        }
      }
    },
    _updateFromSearch(data: EntitiesModel, types: SearchType[] | SearchType) {
      const postStore = usePostStore();
      const docStore = useDocStore();
      const groupsStore = useGroupsStore();
      const userStore = useUserStore();
      const topicsStore = useTopicStore();

      if (!Array.isArray(types)) {
        types = [types];
      }

      for (const type of types) {
        switch (type) {
          case SearchType.Posts:
            postStore.postsFromSearch(data[type] || []);
            break;
          case SearchType.Files:
            docStore.setGlobalSearchDocs(data[type] || [], null);
            break;
          case SearchType.Groups:
            groupsStore.groupsFromAdvancedSearch(data[type] || []);
            break;
          case SearchType.Users:
            userStore.usersFromAdvancedSearch(data[type] || []);
            break;
          case SearchType.Wikis:
            this.data = data;
            break;
          case SearchType.Tags:
            topicsStore.topicsFromSearch(data[type] || []);
            break;
          default:
            this.data = data;
        }
      }
    },
    async search(payload: {
      types: SearchType[] | SearchType;
      searchText?: string;
      postType?: SearchUserItemTypes;
      authorsIds?: number[];
      start?: string;
      end?: string;
      groupsIds?: number[];
      tagsIds?: number[];
    }): Promise<boolean> {
      const { handleError } = useErrors();

      try {
        this.isLoading = true;
        const { searchText, types, postType, authorsIds, tagsIds, start, end, groupsIds } = payload;

        const queryObj = {
          filters: types,
          AuthorsIds: authorsIds,
          GroupsIds: groupsIds,
          TagsIds: tagsIds,
          searchText,
          postType,
          start,
          end,
        };
        const queryString = serializeQueryParams(queryObj);
        const response = await $api.search.search(queryString);

        if (response.statusCode === 200) {
          const model = response as ResponseSearchModel;
          if (model.data?.entities) {
            this._updateFromSearch(model.data.entities, types);
          }
          return true;
        } else {
          return false;
        }
      } catch (e: any) {
        handleError({
          show: true,
          error: undefined,
          message: `${useI18n().t('search.error')}: ${e.message}`,
        });
        return false;
      } finally {
        this.isLoading = false;
      }
    },
    async suggest(payload: { text?: string; type?: SearchType; postType?: SearchUserItemTypes }): Promise<boolean> {
      const { handleError } = useErrors();

      try {
        this.suggestIsLoading = true;

        const response = await $api.search.suggest({
          filter: payload.type,
          postType: payload.postType,
          query: payload.text,
          accuracy: this.accuracy,
        });

        if (response.statusCode === 200) {
          const model = response as ResponseSearchModel;
          this.suggestions = model.data.suggestions ?? [];
          return true;
        } else {
          return false;
        }
      } catch (e: any) {
        handleError({
          show: true,
          error: undefined,
          message: `${useI18n().t('search.error')}: ${e.message}`,
        });
        return false;
      } finally {
        this.suggestIsLoading = false;
      }
    },
  },

  persist: true,
});
