<template>
  <ion-button
    v-tooltip.bottom="{
      content: $t('feed.feedItemPostMenu.settings'),
      theme: 'info-tooltip',
      disabled: isAnyMobile,
    }"
    mode="md"
    size="default"
    fill="clear"
    class="menu"
    @click.stop="openPostMenu({ ev: $event, postData: post, feedFlag })"
  >
    <icons-provider
      v-if="feedFlag !== FeedFlagEnum.SharedPost"
      slot="icon-only"
      :icon-props="{ width: '22', height: '22' }"
      :name="AppIconsEnum.MenuDots"
    />
  </ion-button>
</template>

<script lang="ts" setup>
import { IonButton } from '@ionic/vue';
import type { PropType } from 'vue';
import 'swiper/css';

import { IconsProvider } from '@/components';
import { AppIconsEnum, FeedFlagEnum } from '@/enums';
import { isAnyMobile, usePostHelper } from '@/helpers';
import type { PostModel } from '@/types';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

defineProps({
  post: {
    type: Object as PropType<PostModel>,
    required: true,
  },
  feedFlag: {
    type: String as PropType<FeedFlagEnum>,
    required: true,
  },
});

const { openPostMenu } = usePostHelper();
</script>

<style scoped lang="scss">
ion-button {
  &.menu {
    @include resetStyleFromIonicButton;
    --color: var(--ion-color-medium);
    --background-hover: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --ripple-color: transparent;
    --padding-end: 0;
    --padding-start: 0;
    margin: 0;
    z-index: 1;

    &:hover {
      ion-icon {
        opacity: 0.7;
      }
    }
  }
}
</style>
