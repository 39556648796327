<template>
  <div class="user">
    <div class="avatar" @click.stop="openProfile(author.id)">
      <user-avatar slot="start" :fio="author.fullName" :url="author.image?.url ?? ''" :image="author.image" />
    </div>
    <ion-label>
      <span class="info">
        <span
          class="author"
          :class="{
            'with-margin': !group?.title || author?.badges,
          }"
          @click.stop="openProfile(author.id)"
        >
          {{ author.fullName }}
        </span>
        <span v-if="author?.badges" class="badges">
          <badges-wrapper
            :type="BadgeDisplayType.UserName"
            :badges="getIds(author.badges)"
            :more-counter="getMoreCounter(author.badges)"
          />
        </span>
        <icons-provider
          v-if="group?.title"
          :icon-props="{ width: '16', height: '16', fill: 'var(--ion-color-medium)' }"
          :name="AppIconsEnum.ChevronRight"
        />
        <span v-if="group?.title" class="group" @click.stop="openGroup(group?.id)">
          {{ group.title }}
        </span>

        <icons-provider
          v-if="isPinned"
          :icon-props="{ width: '16', height: '16', fill: 'var(--ion-color-medium)' }"
          :name="AppIconsEnum.Pin"
        />
      </span>
      <p v-if="initiator && initiator.id !== author.id" class="published" @click.stop="openProfile(initiator.id)">
        {{ t('feed.publishedOnBehalf') }}
        <a class="initiator">
          {{ initiator.fullName }}
        </a>
      </p>
      <p v-if="plannedDate" class="date">
        {{ t('planned') + ': ' + formatDateHelper(plannedDate, 'long') }}
      </p>
      <p v-else-if="createdAt" class="date">
        {{ formatDateHelper(createdAt, 'long') }}
      </p>
    </ion-label>
  </div>
</template>

<script lang="ts" setup>
import { IonLabel } from '@ionic/vue';
import type { PropType } from 'vue';
import 'swiper/css';
import { useRouter } from 'vue-router';
import { UserAvatar, BadgesWrapper, IconsProvider } from '@/components';
import { BadgeDisplayType, AppIconsEnum } from '@/enums';
import type { FeedTypeEnum } from '@/enums';
import { formatDateHelper } from '@/helpers';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import type { UserShortModel, GroupModel, UserBadgesModel, GroupShortModel } from '@/types';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

defineProps({
  group: {
    type: [Object, null] as PropType<GroupModel | GroupShortModel | null>,
    default: null,
  },
  author: {
    type: Object as PropType<UserShortModel>,
    required: true,
  },
  initiator: {
    type: [Object, null] as PropType<UserShortModel | null>,
    default: null,
  },
  createdAt: {
    type: String,
    default: '',
  },
  plannedDate: {
    type: String || undefined,
    default: undefined,
  },
  messageType: {
    type: [String, null] as PropType<FeedTypeEnum | null>,
    default: null,
  },
  isPinned: {
    type: Boolean,
    default: false,
  },
});

//#region Variables
const { t } = useI18n();
const router = useRouter();
//#endregion

//#region Methods
async function openGroup(id: number) {
  await router.push({
    name: ROUTES_NAME.GROUP_BY_ID,
    params: { id: id },
  });
}

async function openProfile(id: number) {
  await router.push({
    name: ROUTES_NAME.USER_BY_ID,
    params: { id: id },
  });
}

function getIds(badges: UserBadgesModel[]): number[] {
  return badges.map((badge) => badge.id);
}

function getMoreCounter(badges: UserBadgesModel[]): number | null {
  return badges.length > 1 ? badges.length - 1 : null;
}
//#endregion
</script>

<style scoped lang="scss">
.user {
  display: inline-flex;
  align-items: center;
  padding-right: 0.5rem;

  &:hover {
    cursor: pointer;
  }

  .avatar {
    flex-shrink: 0;

    ion-avatar {
      width: 40px;
      height: 40px;
    }
  }

  ion-label {
    margin-left: 1rem;
    word-break: break-word;
  }

  .info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .author {
      font-weight: 500;
    }
    .initiator {
      text-decoration: underline;
    }
    .author,
    .initiator {
      font-size: 0.9rem;
      &:hover {
        cursor: pointer;
        color: var(--ion-color-medium);
      }
      &.with-margin {
        margin-right: app-padding(sm);
      }
    }

    .badges {
      display: inline-flex;
      align-items: center;
      justify-content: start;
    }

    .status {
      font-size: 0.8rem;
      color: var(--ion-color-medium);
      margin-right: 0.5rem;
    }

    .date {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .group {
      display: block;
      display: flex;
      align-items: center;
      margin-right: 0.5rem;

      &:hover {
        cursor: pointer;
        color: var(--ion-color-medium);
      }
    }
  }
}

.sc-ion-label-md-s {
  & p.date,
  p.published {
    font-size: 0.9rem;
  }

  & p.published {
    margin-bottom: 0;
  }
}

@include respond-to-min-width(2xl) {
  .user {
    .info {
      .author {
        font-size: 1rem;
      }
    }
  }
  .sc-ion-label-md-s p.date {
    font-size: 1rem;
  }
}
</style>
