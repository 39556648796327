import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/vue';
import { GENERIC_HOSTS } from '@/services/config/genericHosts';
import { ENDPOINTS_WITH_NO_AUTH_CHECK } from '@/services/config/endpointsWithNoAuthCheck';

export function isGenericDomain(): boolean {
  return GENERIC_HOSTS.includes(window.location.hostname);
}

export function isGenericHost(): boolean {
  return GENERIC_HOSTS.includes(import.meta.env.VITE_APP_HOST);
}

export function isLiveHost(): boolean {
  return import.meta.env.VITE_APP_HOST === 'communex.app';
}

export function isLocalhost(): boolean {
  return window.location.hostname === 'localhost';
}

/**
 * Use it to check if session is currently on custom domain
 */
export function isCustomDomain(): boolean {
  if (Capacitor.isNativePlatform() || isPlatform('electron')) {
    return !isGenericHost() && !isLiveHost();
  }
  return !isGenericDomain() && !isLocalhost();
}

/**
 * Helper function to check if requesting a specific endpoint should NOT trigger session validation
 *
 * @param url Currently requested URL
 * @returns true if validation should be skipped, false otherwise
 */
export function skipAuthCheck(url: string): boolean {
  return ENDPOINTS_WITH_NO_AUTH_CHECK.some((e) => url.includes(e));
}
