import { ShareArchiveLinkType, DocBrowserModeEnum, FeedTypeEnum, DocumentExtensionEnum } from '@/enums';
import axios from '@/services/axios';
import type {
  ResponseErrorModel,
  ResponseDocsModel,
  ResponseTreeFolderModel,
  ResponseDocsCreateFolderModel,
  ResponseDocsCreateFileModel,
  ResponseSuccessModel,
  FileModel,
  ResponsePostModel,
  ResponseDocModel,
  ResponseDocsHistoryByIdModel,
  ResponseDocsHistoryModel,
  ResponseDocsFileTagsModel,
  ResponseDocsRelationsModel,
  ResponseDocsFileFollowersModel,
  ResponseFileModel,
  ResponseBlobModel,
} from '@/types';
import { buildAxiosError, buildErrorResponse } from '../builders';
import { useErrors } from '@/helpers';

export class DocApiService {
  async loadMore(url: string): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async getDocById(fileId: number): Promise<ResponseDocModel | ResponseErrorModel> {
    return axios.get(`/files/byId/${fileId}`);
  }
  async getAllDocs(
    mode: DocBrowserModeEnum,
    sort: string,
    filter: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?mode=${filter.includes('search') ? DocBrowserModeEnum.All : mode}&${sort}&${filter}`);
  }
  /** This is an api requirement, in this case we need documents that are in the root of the network (not in groups). For this we need to specify the DocBrowserModeEnum.Groups mode and an empty groupId. */
  async getAllDocsFromNetworkOnly(sort: string, filter: string): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?mode=${DocBrowserModeEnum.Groups}&${sort}&${filter}`);
  }
  async getAllDocsFromGroupId(
    groupId: number,
    sort: string,
    filter: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?groupId=${groupId}&mode=${DocBrowserModeEnum.Groups}&${sort}&${filter}`);
  }
  async getAllDocsFromFolderId(
    folderId: number,
    sort: string,
    filter: string
  ): Promise<ResponseDocsModel | ResponseErrorModel> {
    return axios.get(`/files/all?parentFolderId=${folderId}&${sort}&${filter}`);
  }
  async getFoldersTree(): Promise<ResponseTreeFolderModel | ResponseErrorModel> {
    return axios.get(`/folders/tree`);
  }
  async getFoldersTreeByGroupId(groupId: number): Promise<ResponseTreeFolderModel | ResponseErrorModel> {
    return axios.get(`/folders/tree?groupId=${groupId}`);
  }
  async createFolder(
    folderName: string,
    parentFolderId: number | null = null,
    groupId: number | null = null
  ): Promise<ResponseDocsCreateFolderModel | ResponseErrorModel> {
    return axios.post('/folders/create', {
      folderName,
      parentFolderId,
      groupId,
    });
  }
  async createFiles(
    entities: any,
    folderId: number | null = null,
    groupId: number | null = null,
    onBehalfUserId?: number
  ): Promise<ResponseDocsCreateFileModel | ResponseErrorModel> {
    const files = entities.map(
      (f: any) =>
        ({
          key: f.key,
          name: f.name,
          type: f.type,
          size: f.size,
        }) as any
    );
    return axios.post('/files/filesCreate', { files, folderId, groupId, onBehalfUserId });
  }
  async renameFolder(folderId: number, name: string): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/folders/editInfo/${folderId}`, { name });
  }
  async deleteFolder(folderId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/folders/delete/${folderId}`);
  }
  async renameFile(
    fileId: number,
    name: string,
    description: string
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/editFileInfo/${fileId}`, { name, description });
  }

  //#region History
  async getHistory(id: number): Promise<ResponseDocsHistoryModel | ResponseErrorModel> {
    return axios.get(`/files/history/${id}?page=1`);
  }
  async getHistoryLoadMore(loadMoreUrl: string): Promise<ResponseDocsHistoryModel | ResponseErrorModel> {
    return axios.get(loadMoreUrl);
  }
  async getHistoricalFileById(id: number): Promise<ResponseDocsHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/files/historyById/${id}`);
  }
  async getHistoricalFileByDate(id: number, date: string): Promise<ResponseDocsHistoryByIdModel | ResponseErrorModel> {
    return axios.get(`/files/historyByDate/${id}?date=${date}`);
  }
  async restoreFileVersion(fileId: number, historyId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/rollback/${fileId}?historyId=${historyId}`);
  }
  async uploadNewVersion(fileId: number, fileInfo: FileModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/uploadNewVersion/${fileId}`, {
      key: fileInfo.key,
      name: fileInfo.name,
      type: fileInfo.type,
      size: fileInfo.size,
    });
  }
  //#endregion

  async moveFile(
    toFolderId: number | null,
    toGroupId: number | null,
    fileId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/files/move', { toFolderId, toGroupId, fileId });
  }
  async moveFolder(
    toFolderId: number | null,
    toGroupId: number | null,
    folderId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/folders/move', { toFolderId, toGroupId, folderId });
  }
  async moveWiki(
    toFolderId: number | null,
    toGroupId: number | null,
    wikiId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/wiki/move', { toFolderId, toGroupId, wikiId });
  }
  async getTags(id: number): Promise<ResponseDocsFileTagsModel | ResponseErrorModel> {
    return axios.get(`/files/${id}/tags`);
  }
  async addTag(id: number, tagTexts: string[]): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/files/addTag', { id, tagTexts });
  }
  async removeTag(id: number, tagId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/removeTag/${id}?tagId=${tagId}`);
  }
  async markAsOfficial(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/${id}/official`);
  }
  async getFollowers(id: number): Promise<ResponseDocsFileFollowersModel | ResponseErrorModel> {
    return axios.get(`/files/followers/${id}`);
  }
  async follow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/${id}/follow`);
  }
  async unfollow(id: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/${id}/unfollow`);
  }
  async shareFile(fileId: number, text: string): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/files/shareFile', { id: fileId, text });
  }
  async shareFileToGroup(
    fileId: number,
    text: string,
    groupId: number
  ): Promise<ResponsePostModel | ResponseErrorModel> {
    return axios.post('/files/shareFile', { id: fileId, groupId, text });
  }
  async shareDocumentArchiveLink(
    id: number,
    emails: string[],
    type: ShareArchiveLinkType
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/sendArchive/`, {
      id,
      emails,
      type,
    });
  }
  async getFolderPath(folderId: number): Promise<ResponseTreeFolderModel | ResponseErrorModel> {
    return axios.get(`/folders/path/${folderId}`);
  }
  //#region Relations
  async getRelations(id: number): Promise<ResponseDocsRelationsModel | ResponseErrorModel> {
    return axios.get(`/files/relations/${id}`);
  }
  async addRelationWiki(fileId: number, relationWikiId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/addRelation/${fileId}?relationWikiId=${relationWikiId}`);
  }
  async addRelationFile(fileId: number, relationFileId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/files/addRelation/${fileId}?relationFileId=${relationFileId}`);
  }
  async removeRelationWiki(fileId: number, relationWikiId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/removeRelation/${fileId}?relationWikiId=${relationWikiId}`);
  }
  async removeRelationFile(fileId: number, relationFileId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/removeRelation/${fileId}?relationFileId=${relationFileId}`);
  }
  //#endregion

  //#region Deletion
  async delete(fileId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/delete/${fileId}`);
  }
  async deleteWithFileReplace(
    fileId: number,
    relationFileId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/deleteWithReplace/${fileId}?relationFileId=${relationFileId}`);
  }
  async deleteWithWikiReplace(
    fileId: number,
    relationWikiId: number
  ): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/files/deleteWithReplace/${fileId}?relationWikiId=${relationWikiId}`);
  }
  //#endregion

  async upload(file: File): Promise<ResponseFileModel | ResponseErrorModel> {
    const formData = new FormData();
    formData.append('attach', file);
    return axios.post('/storage/UploadMulti', formData);
  }

  async downloadPost(
    type: FeedTypeEnum,
    documentExtension: DocumentExtensionEnum,
    postId: number
  ): Promise<ResponseBlobModel | ResponseErrorModel> {
    const defaultValue: string = 'posts';
    const map: Record<FeedTypeEnum, string> = {
      [FeedTypeEnum.Text]: defaultValue,
      [FeedTypeEnum.Announcement]: 'announcements',
      [FeedTypeEnum.GroupCreated]: defaultValue,
      [FeedTypeEnum.GroupDeleted]: defaultValue,
      [FeedTypeEnum.Badge]: 'badges',
      [FeedTypeEnum.Event]: 'events',
      [FeedTypeEnum.Poll]: 'polls',
      [FeedTypeEnum.Idea]: 'ideas',
      [FeedTypeEnum.UserCreated]: defaultValue,
      [FeedTypeEnum.Task]: 'tasks',
    };

    return axios.get(`/storage/${map[type]}/${documentExtension}/${postId}`, {
      responseType: 'blob',
    });
  }

  async downloadFolder(folderId: number): Promise<Blob | ResponseErrorModel> {
    return axios.get(`/storage/document/folder/${folderId}`, {
      responseType: 'blob',
    });
  }

  async media(url: string): Promise<Blob | ResponseErrorModel> {
    const response = await fetch(url);

    if (response.status === 200) return await response.blob();

    const error = buildAxiosError(response);

    useErrors().handleError({
      show: false,
      error,
      message: undefined,
    });
    return buildErrorResponse(error);
  }
}
