<template>
  <div class="user-select">
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" key="spinner" class="select-button">
        <ion-button fill="outline">
          <icons-provider
            :icon-props="{ width: '16', height: '16' }"
            :name="AppIconsEnum.DotsAnim"
            fill="var(--ion-color-medium)"
          />
        </ion-button>
      </div>

      <div v-else style="display: flex; height: 100%">
        <div key="text" class="select-button">
          <ion-button fill="outline" @click="openModal">
            <ion-label>{{ selectedUser?.fullName || title }}</ion-label>
          </ion-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { IonButton, IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum, UsersFilterEnum } from '@/enums';
import { openChooseUserModal, toShortUserModel } from '@/helpers';
import { useI18n } from '@/i18n';
import { useUserStore } from '@/store';
import type { UserShortModel } from '@/types';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  author: {
    type: [Object, undefined] as PropType<UserShortModel | undefined>,
    default: undefined,
  },
  groupId: {
    type: Number,
    default: undefined,
  },
});

const emit = defineEmits(['onSelectChange']);

//#region Variables
const { t } = useI18n();
const userStore = useUserStore();

const selectedUser = ref<UserShortModel | undefined>(undefined);
const selectedGroupId = ref<number | undefined>(props.groupId);

const isLoading: ComputedRef<boolean> = computed(() => userStore.isLoading);
const currentUserId: ComputedRef<number> = computed(() => userStore.current?.id ?? 0);
//#endregion

//#region Methods
const openModal = async (): Promise<void> => {
  const currentSelectedUser = selectedUser.value ? useUserStore().getUserProfile(selectedUser.value.id) : null;
  const result = await openChooseUserModal({
    alreadySelectedUsers: currentSelectedUser !== null ? [currentSelectedUser] : [],
    title: t('messenger.chatModal.title'),
    withoutCurrent: true,
    onBehalf: true,
    multiSelect: false,
    groupId: selectedGroupId.value,
    removeUserText: t('removeUser'),
  });
  if (result === undefined) return;
  selectedUser.value = result?.length ? toShortUserModel(result[0]) : undefined;
};

const resetUser = (): void => {
  selectedUser.value = undefined;
};
//#endregion

//#region Watchers
watch(
  () => props.groupId,
  (newValue: number | undefined, oldValue: number | undefined) => {
    selectedGroupId.value = newValue;
    if (newValue !== oldValue) {
      resetUser();
    }
  }
);

watch(selectedUser, () => {
  emit('onSelectChange', selectedUser.value);
});
//#endregion

//#region Lifecycle
onMounted(() => {
  if (props.author) {
    const isCurrentUserId = currentUserId.value === props.author.id;

    if (!isCurrentUserId) {
      selectedUser.value = props.author;
    }

    userStore.resetUsersIdsChosen(UsersFilterEnum.ChosenOthers);
  }
});
//#endregion
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
ion-button {
  margin: 0;
}
.user-select {
  position: relative;
  height: 100%;
  flex-shrink: 1;

  ion-button {
    min-height: 40px;
    position: relative;
    --border-color: var(--ion-color-custom-element-lighter);
    --color: var(--ion-color-medium);
    --background-hover: var(--ion-color-custom-element-lighter);
    --border-radius: #{app-radius(md)};
  }

  .select-button {
    position: relative;
    min-width: 85px;
    height: 100%;
    margin-left: app-padding(md);
    ion-button {
      width: 100%;
      ion-label {
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis;
        font-size: 0.9rem;
      }
    }
  }
}

@include respond-to-min-width(2xl) {
  ion-label {
    font-size: 1rem;
  }
}
</style>
