import { merge } from 'lodash';
import { nextTick } from 'vue';
import type { Composer, I18n, I18nOptions, Locale } from 'vue-i18n';
import { createI18n } from 'vue-i18n';
import { CompanyEnum, EnvVariablesEnum } from '@/enums';
import { useEnvs } from '@/helpers';
import type { CompaniesListType } from '@/types';

export const DEFAULT_LOCALE = import.meta.env.VITE_APP_DEFAULT_LOCALE;
export const SUPPORT_LOCALES = import.meta.env.VITE_APP_SUPPORT_LOCALES.split('|');

//#region I18n Initialization
/**
 * Global i18n instance
 */
let vueI18n: I18n;

/**
 * Sets the locale of the i18n instance to the provided locale
 *
 * @todo Refactor to eliminate double type casting
 */
function _setLocale(i18n: I18n, locale: Locale): void {
  (i18n.global as unknown as Composer).locale.value = locale;
}

/**
 * Sets the language of the i18n instance by calling {@link _setLocale}
 * Sets document html lang attribute to the locale
 */
function _setI18nLanguage(i18n: I18n, locale: Locale): void {
  _setLocale(i18n, locale);
  document.querySelector('html')?.setAttribute('lang', locale);
}

function _setupI18n(options: I18nOptions): I18n {
  const i18n = createI18n(options);
  _setI18nLanguage(i18n, DEFAULT_LOCALE);
  return i18n;
}

/**
 * Initializes the i18n instance if not already initialized.
 * Calls {@link _setI18nLanguage} to set the language with options
 */
export function initI18n(): I18n {
  if (!vueI18n) {
    vueI18n = _setupI18n({
      legacy: false,
      locale: DEFAULT_LOCALE,
      fallbackLocale: DEFAULT_LOCALE,
    });
  }
  return vueI18n;
}
//#endregion

/**
 * Use it to get the global Composer instance
 * If it is not initialized, it will initialize it {@link initI18n}
 *
 * @todo Refactor to eliminate double type casting
 */
export function useI18n(): Composer {
  if (!vueI18n) initI18n();
  return vueI18n.global as unknown as Composer;
}

const _getResourceMessages = (r: any) => r.default || r;

export async function loadLocaleMessages(
  locale: Locale,
  resources: Record<string, string | null> = {},
  currentNetworkId?: string
): Promise<void> {
  const { getEnvValue } = useEnvs();

  // Check if locale is supported, fallback if necessary
  if (!SUPPORT_LOCALES.includes(locale)) {
    console.warn(`Locale '${locale}' is not supported, falling back to default locale '${DEFAULT_LOCALE}'`);
    locale = DEFAULT_LOCALE;
  }

  // Load base locale messages
  const locales = await import(`./locales/${locale}.json`).then(_getResourceMessages);

  let customLocales;
  const companiesList: CompaniesListType = getEnvValue(EnvVariablesEnum.CompaniesListWithCustomLocales);

  const companyLocales: Record<string, () => any> = {
    [companiesList[CompanyEnum.BKG]]: async () => {
      customLocales = await import(`./locales/BKG/${locale}.json`).then(_getResourceMessages);
      return customLocales;
    },
    [companiesList[CompanyEnum.AWO]]: async () => {
      customLocales = await import(`./locales/AWO/${locale}.json`).then(_getResourceMessages);
      return customLocales;
    },
    [companiesList[CompanyEnum.BWV]]: async () => {
      customLocales = await import(`./locales/BWV/${locale}.json`).then(_getResourceMessages);
      return customLocales;
    },
  };

  // Load custom locales
  if (currentNetworkId && companyLocales[currentNetworkId]) {
    const customLocales = await companyLocales[currentNetworkId]();
    resources = merge({}, resources, customLocales);
  }

  // Merge loaded locales with additional resources
  const finalLocales = merge({}, locales, resources);

  // Set locale messages and apply the language
  useI18n().setLocaleMessage(locale, finalLocales);
  _setI18nLanguage(vueI18n, locale);

  await nextTick();
}
