<template>
  <ion-avatar>
    <ion-skeleton-text v-if="isImageAvatar === false && !isTextAvatar" :animated="true" />

    <template v-if="avatar">
      <img :src="avatar" :title="title" :alt="isImageAvatar ? title : ''" />
    </template>

    <template v-else-if="isTextAvatar">
      <div class="u-text-avatar fw-500" :style="{ background: randomColor, color: getTextColor(randomColor) }">
        {{ letters }}
      </div>
    </template>

    <div v-if="avatarIcon.icon" :class="['avatar-icon', statusSize]">
      <icons-provider slot="icon-only" :icon-props="avatarIcon.iconProps" :name="avatarIcon.icon" />
    </div>
  </ion-avatar>
</template>

<script lang="ts" setup>
import { IonAvatar, IonSkeletonText } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';

import { ColorGenerator, getLetters, useGroups, useFilesHybrid, getTextColor } from '@/helpers';
import { GroupStatusModel, IconPropsModel } from '@/types';
import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';

// Props
const props = defineProps({
  groupStatus: {
    type: Object as PropType<GroupStatusModel>,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  statusSize: {
    type: String as PropType<'default' | 'small' | 'medium'>,
    default: 'default',
  },
});

// Refs
const avatar = ref<string>('');
const isImageAvatar = ref<boolean>(false);
const isTextAvatar = ref<boolean>(false);

// Computed
const image: ComputedRef<string | null> = computed(() => props.url);
const letters: ComputedRef<string> = computed(() => getLetters(props.title));
const randomColor: ComputedRef<string> = computed(() => {
  return ColorGenerator.getColor(letters.value);
});
const avatarIcon: ComputedRef<{
  icon: AppIconsEnum | null;
  iconProps: IconPropsModel;
}> = computed(() => useGroups().getGroupStatusIcon(props.groupStatus, props.statusSize));

// Methods
const updateImage = async (): Promise<void> => {
  if (!props.url) {
    avatar.value = '';
    isTextAvatar.value = true;
    return;
  }

  try {
    const file = await useFilesHybrid().readFile(props.url, undefined, false);
    if (!file) {
      avatar.value = '';
      isTextAvatar.value = true;
      console.error(`Failed to get user avatar from url: ${props.url}`);
      return;
    }

    avatar.value = file;
    isImageAvatar.value = true;
  } catch (error) {
    avatar.value = '';
    isTextAvatar.value = true;
    console.error(`Failed to get user avatar from url: ${props.url}`, error);
  }
};

// Watchers
watch(image, () => {
  updateImage();
});
// watchDebounced(
//   image,
//   () => {
//     updateImage();
//   },
//   { debounce: 500, maxWait: 2000 }
// );

// Lifecycle
onMounted(async () => {
  await updateImage();
});
</script>

<style lang="scss" scoped>
.u-text-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
ion-avatar {
  position: relative;
}
.avatar-icon {
  content: '';
  display: flex;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  background-color: rgba(var(--ion-color-light-rgb-custom), 1);
  &.default {
    width: 20px;
    height: 20px;
    right: -6px;
    bottom: -4px;
    padding: 2px;
    border: 2px solid var(--ion-color-light-background-contrast);
  }
  &.medium {
    width: 22px;
    height: 22px;
    right: -4px;
    bottom: -3px;
    padding: 2px;
    border: 2px solid var(--ion-color-light-background-contrast);
  }
  &.small {
    width: 14px;
    height: 14px;
    right: -3px;
    bottom: -2px;
    padding: 1px;
    border: 1px solid var(--ion-color-light-background-contrast);
  }
}
</style>
