import { cloneDeep, filter, find, includes, indexOf, orderBy, remove, unionBy } from 'lodash';
import { defineStore } from 'pinia';

import { useNotifications } from '@/helpers';
import { defaultNotificationsIds } from '@/models';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import { useNetworkStore } from '@/store';
import type {
  ErrorMessageModel,
  ResponseErrorModel,
  NotificationModel,
  ResponseNotificationModel,
  ShortNotificationsModel,
  NotificationsIdsModel,
} from '@/types';

interface NotificationState extends EntityState<NotificationModel> {
  totalUnreadCount: number;
  notificationsIds: NotificationsIdsModel;
}

export const useNotificationsStore = defineStore({
  id: 'notifications',
  state: (): NotificationState => ({
    data: [],
    errors: [],
    isLoading: false,
    totalUnreadCount: 0,
    notificationsIds: cloneDeep(defaultNotificationsIds),
  }),
  getters: {
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    getUnreadNotificationsCount: (state) => {
      return state.totalUnreadCount;
    },
    getNotificationsAll: (state) => (): ShortNotificationsModel => {
      const result = { data: [] } as ShortNotificationsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.notificationsIds.all.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.notificationsIds.all.ids, obj.id));
      return result;
    },
    getNotificationsUnread: (state) => (): ShortNotificationsModel => {
      const result = { data: [] } as ShortNotificationsModel;
      const data = orderBy(state.data, (obj) => indexOf(state.notificationsIds.unread.ids, obj.id));
      result.data = filter(data, (obj) => includes(state.notificationsIds.unread.ids, obj.id));
      return result;
    },
  },
  actions: {
    async notifications(checkBeforeUpdate?: boolean): Promise<void> {
      if (!checkBeforeUpdate) this.isLoading = true;
      const response = await $api.notification.getNotifications();

      if (response.statusCode === 200) {
        const networkStore = useNetworkStore();
        const model = response as ResponseNotificationModel;

        this.notificationsIds.all.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.totalUnreadCount = model.totalUnreadCount;
        networkStore.setNotificationsCount(this.totalUnreadCount);

        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.isLoading = false;
    },
    async unreadNotifications(checkBeforeUpdate?: boolean): Promise<void> {
      if (!checkBeforeUpdate) this.isLoading = true;
      const response = await $api.notification.getUnreadNotifications();

      if (response.statusCode === 200) {
        const networkStore = useNetworkStore();
        const model = response as ResponseNotificationModel;

        this.notificationsIds.unread.ids = model.data.map((n) => n.id);
        this.data = mergeById(this.data, model.data);
        this.totalUnreadCount = model.totalUnreadCount;
        networkStore.setNotificationsCount(this.totalUnreadCount);

        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.isLoading = false;
    },
    async markAs(ids: number[], markAsRead: boolean): Promise<boolean> {
      this.isLoading = true;
      this.errors = [];

      const apiMethod = markAsRead ? $api.notification.markAsRead : $api.notification.markAsUnread;
      const response = await apiMethod(ids);

      if (response.statusCode === 200) {
        const networkStore = useNetworkStore();

        ids.forEach((element) => {
          const index = this.data.findIndex((n) => n.id === element);
          if (~index) {
            if (this.data[index].isRead === markAsRead) return;
            this.data[index].isRead = markAsRead;
          }

          if (markAsRead) {
            remove(this.notificationsIds.unread.ids, (n) => n === element);
            this.totalUnreadCount--;
          } else {
            this.notificationsIds.unread.ids.push(element);
            this.totalUnreadCount++;
          }
        });

        networkStore.setNotificationsCount(this.totalUnreadCount);
        await useNotifications().setBadgeCount();

        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      this.isLoading = false;
      return false;
    },
    async markAllAsRead(): Promise<boolean> {
      this.errors = [];
      if (this.data.length > 0) {
        const response = await $api.notification.markAllAsRead(this.data[0].id);

        if (response.statusCode === 200) {
          const networkStore = useNetworkStore();
          this.data.forEach((element) => {
            element.isRead = true;
          });
          this.notificationsIds.unread.ids = [];
          this.totalUnreadCount = 0;

          networkStore.setNotificationsCount(0);
          await useNotifications().setBadgeCount();
          return true;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
        return false;
      }

      return false;
    },
  },

  persist: true,
});

const mergeById = (a: NotificationModel[], b: NotificationModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};
