import { cloneDeep } from 'lodash';

import { generateUnique } from '@/helpers/hasher';
import type {
  AdvancedWikiContentModel,
  AdvancedWikiBodyModel,
  WikiEditFormModel,
  WikisIdsModel,
  AdvancedWikiParticipantsModel,
} from '@/types';
import { WikiVersionEnum } from '@/enums/wiki';
import { defaultUser } from '@/models/user';
import { toShortUserModel } from '@/helpers';

export const defaultAdvancedWikiBodyModel: AdvancedWikiBodyModel = {
  id: generateUnique(''),
  name: '',
  text: '',
  settings: {
    position: 0,
    isVisible: true,
    visible: true,
    isDeleted: false,
  },
};

export const defaultAdvancedWikiParticipantsModel: AdvancedWikiParticipantsModel = {
  name: '',
  users: [],
  settings: {
    allUsers: false,
    isVisible: false,
    visible: false,
    isDeleted: false,
  },
};

export const defaultAdvancedWikiModel: AdvancedWikiContentModel = {
  head: {
    name: '',
    text: '',
    settings: {
      isVisible: true,
      visible: true,
      isDeleted: false,
    },
  },
  content: {
    name: '',
    text: '',
    settings: {
      isVisible: true,
      visible: true,
      isDeleted: false,
    },
  },
  body: [cloneDeep(defaultAdvancedWikiBodyModel)],
  participants: cloneDeep(defaultAdvancedWikiParticipantsModel),
  settings: { version: WikiVersionEnum.V2 },
};

export const defaultEditFormModel: WikiEditFormModel = {
  name: '',
  groupId: null,
  folderId: null,
  wikiText: '',
  wikiContent: cloneDeep(defaultAdvancedWikiModel),
  participantsIds: [],
  author: toShortUserModel(cloneDeep(defaultUser)),

  accessOnlyForGroupId: null,
  mentionedUserIds: [],
};

export const defaultWikisIds: WikisIdsModel = {
  search: {
    loadMoreUrl: null,
    ids: [],
  },
};
