import { MessageChainModel } from '@/types';
import { ChainTypeEnum, MessageDeliveryStatusEnum } from '@/enums';

export const defaultChain: MessageChainModel = {
  chainUserId: null,
  unreadMessagesCount: 0,
  lastMessage: {
    id: 0,
    chainId: 0,
    createdAt: '',
    type: '',
    text: '',
    authorId: 0,
    authorName: '',
    status: MessageDeliveryStatusEnum.ReadAll,
  },
  muted: false,
  isInChain: true,
  chainId: 0,
  title: '',
  chainAvatar: null,
  chainType: ChainTypeEnum.Active,
  users: [],
  adminsIds: [],
  isGroupChain: true,
  isConference: false,
  userItemId: null,
};
