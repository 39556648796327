<template>
  <div class="group-select" :style="`flex-shrink: ${showResetBtn ? 0 : 1}`">
    <transition name="fade" mode="out-in">
      <div v-if="isLoading" key="spinner" class="select-button">
        <ion-button fill="outline">
          <icons-provider
            :icon-props="{ width: '16', height: '16' }"
            :name="AppIconsEnum.DotsAnim"
            fill="var(--ion-color-medium)"
          />
        </ion-button>
      </div>

      <div v-else style="display: flex; height: 100%">
        <div v-if="showResetBtn" class="reset-button">
          <ion-button mode="md" fill="outline" @click="resetGroup()">
            <icons-provider
              slot="icon-only"
              :icon-props="{
                width: '16',
                height: '16',
                fill: 'var(--ion-color-medium)',
              }"
              :name="AppIconsEnum.Remove"
            />
          </ion-button>
        </div>

        <div v-if="postType === PostTypeActionEnum.Task ? !selectedGroup : true" key="text" class="select-button">
          <ion-button
            v-tooltip.bottom="{
              content: $t('publisher.selectSpace'),
              theme: 'info-tooltip',
              disabled: isNativeMobile,
            }"
            fill="outline"
            :disabled="!accessToRepickGroup"
            @click="openModal()"
          >
            <ion-label>{{ selectText }}</ion-label>
          </ion-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { IonButton, IonLabel } from '@ionic/vue';
import type { ComputedRef, PropType } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { IconsProvider } from '@/components';
import {
  AppIconsEnum,
  GroupAutoSelectModeEnum,
  GroupSelectAccessEnum,
  GroupsFilterEnum,
  PostTypeActionEnum,
  UserRoleEnum,
} from '@/enums';
import { openGroupSelectModal, getGroupsWhereUserIsAdmin, useGroups, isNativeMobile } from '@/helpers';
import { useI18n } from '@/i18n';
import { useGroupsStore, useNetworkStore, useProjectsStore, useUserStore } from '@/store';
import type { GroupModel, GroupShortModel } from '@/types';

const props = defineProps({
  postType: {
    type: String as PropType<PostTypeActionEnum>,
    default: '',
  },
  note: {
    type: String,
    default: '',
  },
  groupData: {
    type: [Object, null] as PropType<GroupShortModel | null>,
    default: null,
  },
  accessToActions: {
    type: Array as PropType<GroupSelectAccessEnum[]>,
    default: () => [
      GroupSelectAccessEnum.RemoveGroup,
      GroupSelectAccessEnum.RepickGroup,
      GroupSelectAccessEnum.PickAllNetwork,
      GroupSelectAccessEnum.RepickProject,
    ],
  },
  autoSelectMode: {
    type: String as PropType<GroupAutoSelectModeEnum>,
    default: GroupAutoSelectModeEnum.None,
  },
  userId: {
    type: Number,
    default: undefined,
  },
});

const emit = defineEmits(['onSelectChange']);

//#region Variables
const route = useRoute();
const userStore = useUserStore();
const groupStore = useGroupsStore();
const projectsStore = useProjectsStore();
const networkStore = useNetworkStore();
const { t } = useI18n();

const selectedGroup = ref<GroupShortModel | null>(props.groupData);
const accessToActionsRef = ref<GroupSelectAccessEnum[]>(props.accessToActions);
const autoSelectModeRef = ref<GroupAutoSelectModeEnum>(GroupAutoSelectModeEnum.None);
const selectedUserId = ref<number | undefined>(props.userId);

const currentUserRoleId: ComputedRef<number> = computed(() => userStore.current?.roleId ?? 0);
const isLoading: ComputedRef<boolean> = computed(() => groupStore.isLoading);
const networkAllowPostToFeed: ComputedRef<boolean> = computed(() => networkStore.settings?.allowPostToFeed ?? false);
const accessToRemoveGroup: ComputedRef<boolean> = computed(() =>
  accessToActionsRef.value.includes(GroupSelectAccessEnum.RemoveGroup)
);
const accessToRepickGroup: ComputedRef<boolean> = computed(() =>
  accessToActionsRef.value.includes(GroupSelectAccessEnum.RepickGroup)
);
const accessToRepickProject: ComputedRef<boolean> = computed(() =>
  accessToActionsRef.value.includes(GroupSelectAccessEnum.RepickProject)
);
const selectText: ComputedRef<string> = computed(() =>
  selectedGroup.value
    ? selectedGroup.value.title
    : !accessToPickAllNetwork.value
      ? t('appMenu.select')
      : t('appMenu.feed')
);

const canPostGroups: ComputedRef<GroupModel[]> = computed(() => {
  if (props.postType === PostTypeActionEnum.Announcement) {
    return currentUserRoleId.value < UserRoleEnum.Administrator
      ? getGroupsWhereUserIsAdmin(groupStore.getCanPostGroups().data)
      : groupStore.getCanPostGroups().data;
  }

  return groupStore.getCanPostGroups().data;
});

const showResetBtn: ComputedRef<boolean> = computed(
  () =>
    selectedGroup.value !== null &&
    props.postType === PostTypeActionEnum.Task &&
    !accessToRepickGroup.value &&
    accessToRepickProject.value
);

//NOTE: if you can post in the feed
const accessToPickAllNetwork: ComputedRef<boolean> = computed(
  () =>
    currentUserRoleId.value > UserRoleEnum.ExternalGroupUser &&
    accessToRemoveGroup.value &&
    networkAllowPostToFeed.value &&
    accessToActionsRef.value.includes(GroupSelectAccessEnum.PickAllNetwork) &&
    props.postType !== PostTypeActionEnum.Task
);
//#endregion

//#region Methods
const shouldSelectFirstAvailableGroup = (): boolean => {
  return (
    autoSelectModeRef.value === GroupAutoSelectModeEnum.FirstAvailableGroup &&
    props.postType === PostTypeActionEnum.Announcement &&
    currentUserRoleId.value < UserRoleEnum.Administrator &&
    canPostGroups.value.length > 0
  );
};

const selectGroupByRouteId = (): void => {
  if (canPostGroups.value.length === 0) {
    selectedGroup.value = null;
    return;
  }

  const { id } = route.params;
  const index = canPostGroups.value.findIndex((n) => n.id === Number(id));
  ~index
    ? (selectedGroup.value = useGroups().toShortGroupModel(canPostGroups.value[index]))
    : (selectedGroup.value = null);
};

const selectGroupFromCurrentProject = (): void => {
  const project = projectsStore.getCurrentProject;
  project ? (selectedGroup.value = project.group) : (selectedGroup.value = null);
};

const autoSelect = (): void => {
  //NOTE: Directly select the group if `groupData` is provided as a prop
  if (props.groupData) {
    selectedGroup.value = props.groupData;
    return;
  }

  //NOTE: Handle cases for announcement posts
  if (shouldSelectFirstAvailableGroup()) {
    selectedGroup.value = canPostGroups.value[0] ? useGroups().toShortGroupModel(canPostGroups.value[0]) : null;
    return;
  }

  //NOTE: Handle specific routes to set the selected group
  if (autoSelectModeRef.value === GroupAutoSelectModeEnum.ByRouteId) {
    selectGroupByRouteId();
    return;
  }

  //NOTE: If in a project route, select the associated group of the current project
  if (autoSelectModeRef.value === GroupAutoSelectModeEnum.FromCurrentProject) {
    selectGroupFromCurrentProject();
    return;
  }

  //NOTE: Default to undefined if no conditions are met
  selectedGroup.value = null;
};

const openModal = async (): Promise<void> => {
  //NOTE: Get the selected group from the selection window

  let filter;
  switch (props.postType) {
    case PostTypeActionEnum.Announcement:
      filter = GroupsFilterEnum.Admin;
      break;
    case PostTypeActionEnum.Task:
      filter = GroupsFilterEnum.AvailableProjects;
      break;
    default:
      filter = selectedUserId.value ? GroupsFilterEnum.ByUser : GroupsFilterEnum.CanPost;
  }
  const currentSelectedGroup = selectedGroup.value ? groupStore.getGroupById(selectedGroup.value.id) : null;
  const result = await openGroupSelectModal({
    mode: filter,
    allowSelectAllNetwork: accessToPickAllNetwork.value,
    alreadySelectedGroups: currentSelectedGroup ? [currentSelectedGroup] : [],
    note: props.note,
    userId: selectedUserId.value,
    multiple: false,
  });
  if (result === undefined) return;
  selectedGroup.value = result?.length ? useGroups().toShortGroupModel(result[0]) : null;
};

const resetGroup = () => {
  autoSelect();
};
//#endregion

//#region Watchers
watch(selectedGroup, () => {
  emit('onSelectChange', selectedGroup.value);
});

watch(
  () => props.accessToActions,
  (value: GroupSelectAccessEnum[]) => {
    accessToActionsRef.value = value;
  }
);

watch(
  () => props.autoSelectMode,
  (value: GroupAutoSelectModeEnum) => {
    autoSelectModeRef.value = value;
    autoSelect();
  },
  { immediate: true }
);

watch(
  () => props.userId,
  (newValue: number | undefined) => {
    selectedUserId.value = newValue;
  }
);
//#endregion

//#region Lifecycle
onMounted(async () => {
  if (canPostGroups.value.length === 0) await groupStore.canPostGroups();
});
//#endregion
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
ion-button {
  margin: 0;
}
.group-select {
  position: relative;
  height: 100%;

  ion-button {
    min-height: 40px;
    position: relative;
    --border-color: var(--ion-color-custom-element-lighter);
    --color: var(--ion-color-medium);
    --background-hover: var(--ion-color-custom-element-lighter);
    --border-radius: #{app-radius(md)};
  }

  .select-button {
    position: relative;
    min-width: 85px;
    height: 100%;
    margin-left: app-padding(md);
    ion-button {
      width: 100%;
      ion-label {
        overflow: hidden;
        white-space: nowrap !important;
        text-overflow: ellipsis;
        font-size: 0.9rem;
      }
    }
  }
}

@include respond-to-min-width(2xl) {
  ion-label {
    font-size: 1rem;
  }
}
</style>
