<template>
  <div class="empty">
    <ion-text v-if="showInitMessage && !hasData && !isLoading">
      {{ initMessage }}
    </ion-text>
    <icons-provider
      v-else-if="isLoading && !showInitMessage && !hasData"
      :name="AppIconsEnum.CircleAnim"
      :icon-props="{
        width: `${loaderWidth}`,
        height: `${loaderHeight}`,
      }"
      fill="var(--ion-color-medium)"
    />
    <ion-text v-else-if="!hasData && !isLoading && !showInitMessage">
      {{ noDataMessage }}
    </ion-text>
  </div>
</template>

<script lang="ts" setup>
import { IonText } from '@ionic/vue';
import { computed, ref, watchEffect } from 'vue';
import type { ComputedRef } from 'vue';

import { IconsProvider } from '@/components';
import { AppIconsEnum } from '@/enums';
import { useI18n } from '@/i18n';
import { useAppStore } from '@/store';

const props = defineProps({
  hasData: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  isInit: {
    type: Boolean,
    default: true,
  },
  initText: {
    type: String || null,
    default: null,
  },
  noDataText: {
    type: String || null,
    default: null,
  },
  loaderWidth: {
    type: Number,
    default: 24,
  },
  loaderHeight: {
    type: Number,
    default: 24,
  },
});

const { t } = useI18n();

const showInitMessage = ref<boolean>(props.isInit);
const isNetworkChanged: ComputedRef<boolean> = computed(() => useAppStore().isNetworkChanged);
const noDataMessage: ComputedRef<string> = computed(() => {
  if (props.noDataText !== null) {
    return props.noDataText;
  }
  return t('noData');
});

const initMessage: ComputedRef<string> = computed(() => {
  if (props.initText !== null) {
    return props.initText;
  }
  return t('loading');
});

watchEffect(() => {
  if (props.isLoading) {
    showInitMessage.value = false;
  } else if (!props.isLoading && isNetworkChanged.value) {
    showInitMessage.value = true;
  }
});
</script>

<style scoped lang="scss">
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);

  ion-text {
    padding: app-padding(lg);
    color: var(--ion-color-medium);
    text-align: center;
  }

  svg {
    margin: app-padding(md);
  }
}
</style>
