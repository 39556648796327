import { AccountApiService } from './api/account.service';
import { AdminApiService } from './api/admin.service';
import { AnnouncementApiService } from './api/announcement.service';
import { AuthApiService } from './api/auth.service';
import { BadgesApiService } from './api/badge.service';
import { DocApiService } from './api/doc.service';
import { EventApiService } from './api/event.service';
import { GroupsApiService } from './api/group.service';
import { MeetApiService } from './api/meet.service';
import { MessengerApiService } from './api/messenger.service';
import { NetworksApiService } from './api/network.service';
import { NotificationApiService } from './api/notifications.service';
import { OfficeApiService } from './api/office.service';
import { CustomPageApiService } from './api/page.service';
import { PostApiService } from './api/posts.service';
import { ProjectsApiService } from './api/projects.service';
import { SearchApiService } from './api/search.service';
import { ToolApiService } from './api/tool.service';
import { TopicApiService } from './api/topic.service';
import { UserApiService } from './api/user.service';
import { WikiApiService } from './api/wiki.service';
import { MenuApiService } from './api/menu.service';

export const $api = {
  auth: new AuthApiService(),
  account: new AccountApiService(),
  user: new UserApiService(),
  meet: new MeetApiService(),
  messenger: new MessengerApiService(),
  network: new NetworksApiService(),
  admin: new AdminApiService(),
  tool: new ToolApiService(),
  post: new PostApiService(),
  group: new GroupsApiService(),
  notification: new NotificationApiService(),
  doc: new DocApiService(),
  announcement: new AnnouncementApiService(),
  page: new CustomPageApiService(),
  event: new EventApiService(),
  topic: new TopicApiService(),
  search: new SearchApiService(),
  badge: new BadgesApiService(),
  projects: new ProjectsApiService(),
  wiki: new WikiApiService(),
  office: new OfficeApiService(),
  menu: new MenuApiService(),
};

export const ResponseErrorCodesEnum = {
  // Client Error
  ClientError: 'ClientError',

  // Old ErrorCodes
  ContextNull: 'ContextNull',
  AppException: 'AppException',
  NotValidModel: 'NotValidModel',
  InvalidClientSecret: 'InvalidClientSecret',
  ArgumentException: 'ArgumentException',
  SolrException: 'SolrException',
  UnknownImageFormat: 'UnknownImageFormat',
  DateInvalid: 'DateInvalid',
  RetriesLimitExceededException: 'RetriesLimitExceededException',
  BadFileFormat: 'BadFileFormat',

  // Axios Codes
  ERR_FR_TOO_MANY_REDIRECTS: 'ERR_FR_TOO_MANY_REDIRECTS',
  ERR_BAD_OPTION_VALUE: 'ERR_BAD_OPTION_VALUE',
  ERR_BAD_OPTION: 'ERR_BAD_OPTION',
  ERR_NETWORK: 'ERR_NETWORK',
  ERR_DEPRECATED: 'ERR_DEPRECATED',
  ERR_BAD_RESPONSE: 'ERR_BAD_RESPONSE',
  ERR_BAD_REQUEST: 'ERR_BAD_REQUEST',
  ERR_NOT_SUPPORT: 'ERR_NOT_SUPPORT',
  ERR_INVALID_URL: 'ERR_INVALID_URL',
  ERR_CANCELED: 'ERR_CANCELED',
  ECONNABORTED: 'ECONNABORTED',
  ETIMEDOUT: 'ETIMEDOUT',
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',

  // HTTP Codes
  /** 100 */ Continue: 'Continue',
  /** 101 */ SwitchingProtocols: 'SwitchingProtocols',
  /** 102 */ Processing: 'Processing',
  /** 103 */ EarlyHints: 'EarlyHints',
  /** 200 */ OK: 'OK',
  /** 201 */ Created: 'Created',
  /** 202 */ Accepted: 'Accepted',
  /** 203 */ NonAuthoritativeInformation: 'NonAuthoritativeInformation',
  /** 204 */ NoContent: 'NoContent',
  /** 205 */ ResetContent: 'ResetContent',
  /** 206 */ PartialContent: 'PartialContent',
  /** 207 */ MultiStatus: 'MultiStatus',
  /** 208 */ AlreadyReported: 'AlreadyReported',
  /** 226 */ IMUsed: 'IMUsed',
  /** 300 */ Ambiguous: 'Ambiguous',
  /** 300 */ MultipleChoices: 'MultipleChoices',
  /** 301 */ Moved: 'Moved',
  /** 301 */ MovedPermanently: 'MovedPermanently',
  /** 302 */ Found: 'Found',
  /** 302 */ Redirect: 'Redirect',
  /** 303 */ RedirectMethod: 'RedirectMethod',
  /** 303 */ SeeOther: 'SeeOther',
  /** 304 */ NotModified: 'NotModified',
  /** 305 */ UseProxy: 'UseProxy',
  /** 306 */ Unused: 'Unused',
  /** 307 */ RedirectKeepVerb: 'RedirectKeepVerb',
  /** 307 */ TemporaryRedirect: 'TemporaryRedirect',
  /** 308 */ PermanentRedirect: 'PermanentRedirect',

  // ErrorCodes for HTTP
  /** 400 */ BadRequest: 'BadRequest',
  /** 401 */ Unauthorized: 'Unauthorized',
  /** 402 */ PaymentRequired: 'PaymentRequired',
  /** 403 */ Forbidden: 'Forbidden',
  /** 404 */ NotFound: 'NotFound',
  /** 405 */ MethodNotAllowed: 'MethodNotAllowed',
  /** 406 */ NotAcceptable: 'NotAcceptable',
  /** 407 */ ProxyAuthenticationRequired: 'ProxyAuthenticationRequired',
  /** 408 */ RequestTimeout: 'RequestTimeout',
  /** 409 */ Conflict: 'Conflict',
  /** 410 */ Gone: 'Gone',
  /** 411 */ LengthRequired: 'LengthRequired',
  /** 412 */ PreconditionFailed: 'PreconditionFailed',
  /** 413 */ RequestEntityTooLarge: 'RequestEntityTooLarge',
  /** 414 */ RequestUriTooLong: 'RequestUriTooLong',
  /** 415 */ UnsupportedMediaType: 'UnsupportedMediaType',
  /** 416 */ RequestedRangeNotSatisfiable: 'RequestedRangeNotSatisfiable',
  /** 417 */ ExpectationFailed: 'ExpectationFailed',
  /** 421 */ MisdirectedRequest: 'MisdirectedRequest',
  /** 422 */ UnprocessableEntity: 'UnprocessableEntity',
  /** 423 */ Locked: 'Locked',
  /** 424 */ FailedDependency: 'FailedDependency',
  /** 426 */ UpgradeRequired: 'UpgradeRequired',
  /** 428 */ PreconditionRequired: 'PreconditionRequired',
  /** 429 */ TooManyRequests: 'TooManyRequests',
  /** 431 */ RequestHeaderFieldsTooLarge: 'RequestHeaderFieldsTooLarge',
  /** 451 */ UnavailableForLegalReasons: 'UnavailableForLegalReasons',
  /** 500 */ InternalServerError: 'InternalServerError',
  /** 501 */ NotImplemented: 'NotImplemented',
  /** 502 */ BadGateway: 'BadGateway',
  /** 503 */ ServiceUnavailable: 'ServiceUnavailable',
  /** 504 */ GatewayTimeout: 'GatewayTimeout',
  /** 505 */ HttpVersionNotSupported: 'HttpVersionNotSupported',
  /** 506 */ VariantAlsoNegotiates: 'VariantAlsoNegotiates',
  /** 507 */ InsufficientStorage: 'InsufficientStorage',
  /** 508 */ LoopDetected: 'LoopDetected',
  /** 510 */ NotExtended: 'NotExtended',
  /** 511 */ NetworkAuthenticationRequired: 'NetworkAuthenticationRequired',

  // ErrorCodes for FeedItems (UserItems)
  Post: 'Post',

  // ErrorCodes for Chain messages
  Message: 'Message',

  // ErrorCodes for Users
  User: 'User',
  User_NotFound: 'User_NotFound',
  User_IsBanned: 'User_IsBanned',
  User_IsDeleted: 'User_IsDeleted',
  User_NotActive: 'User_NotActive',
  User_IsBlocked: 'User_IsBlocked',
  User_AccessDenied: 'User_AccessDenied',
  User_MobileAppBlocked: 'User_MobileAppBlocked',
  User_AccessTemporaryBlocked: 'User_AccessTemporaryBlocked',
  User_LoginPasswordError: 'User_LoginPasswordError',
  User_NotSupportedOAuthGrantType: 'User_NotSupportedOAuthGrantType',
  User_NotFoundInHomeNetwork: 'User_NotFoundInHomeNetwork',
  User_NotFoundByRowId: 'User_NotFoundByRowId',
  User_InvitationEmailIsEmpty: 'User_InvitationEmailIsEmpty',
  User_InvitationEmailIsNotValid: 'User_InvitationEmailIsNotValid',
  User_SendEmailAlreadyRegistered: 'User_SendEmailAlreadyRegistered',
  User_InvitationNotCreated: 'User_InvitationNotCreated',
  User_InviteBadEmailSuffix: 'User_InviteBadEmailSuffix',
  User_InvitationNotExists: 'User_InvitationNotExists',
  User_InvitationAlreadyAccepted: 'User_InvitationAlreadyAccepted',
  User_EmailNotAllowedForRegistration: 'User_EmailNotAllowedForRegistration',
  User_AlreadyRegistered: 'User_AlreadyRegistered',
  User_TokenNotValid: 'User_TokenNotValid',
  User_TokenNotSpecified: 'User_TokenNotSpecified',
  User_InvalidTokenFormat: 'User_InvalidTokenFormat',
  User_ObsoleteToken: 'User_ObsoleteToken',
  User_ClientSslCertificateRequired: 'User_ClientSslCertificateRequired',
  User_NotEnoughRights: 'User_NotEnoughRights',
  User_PasswordNotValid: 'User_PasswordNotValid',
  User_SubDivisionNotCreated: 'User_SubDivisionNotCreated',
  UserItem_NotCreated: 'UserItem_NotCreated',
  UserItem_NotAllowedInGroup: 'UserItem_NotAllowedInGroup',
  UserItem_VoteMultipleAnswersForbidden: 'UserItem_VoteMultipleAnswersForbidden',
  UserAttributes_NameIsEmpty: 'UserAttributes_NameIsEmpty',
  UserAttributes_NameAlreadyExists: 'UserAttributes_NameAlreadyExists',
  UserAttributes_InvalidNameFormat: 'UserAttributes_InvalidNameFormat',
  UserAttributes_UnableEditAttribute: 'UserAttributes_UnableEditAttribute',

  // ErrorCodes for UserGroups
  Group: 'Group',
  Group_NotFound: 'Group_NotFound',
  Group_NotCreated: 'Group_NotCreated',
  Group_OnlyGroupAdminCanGroupUpdate: 'Group_OnlyGroupAdminCanGroupUpdate',
  Group_OnlyAdminsCanInviteToPrivateGroups: 'Group_OnlyAdminsCanInviteToPrivateGroups',

  // ErrorCodes for Wikis
  Wiki: 'Wiki',
  Wiki_RelationsNotSupported: 'Wiki_RelationsNotSupported',

  // ErrorCodes for Files
  File: 'File',
  File_NotUpload: 'File_NotUpload',
  File_OfficeJwtTokenIsNull: 'File_OfficeJwtTokenIsNull',
  File_OfficeCreateOnSave: 'File_OfficeCreateOnSave',
  File_OfficeContentTypeInvalid: 'File_OfficeContentTypeInvalid',
  File_OfficeFileStreamIsNull: 'File_OfficeFileStreamIsNull',

  // ErrorCodes for Folders
  Folder: 'Folder',
  Folder_AccessDeniedForCreateInOfficialGroup: 'Folder_AccessDeniedForCreateInOfficialGroup',

  // ErrorCodes for Tasks
  Task: 'Task',

  // ErrorCodes for Networks
  Network: 'Network',
  Network_CompanyNotActive: 'Network_CompanyNotActive',
  Network_CompanyNotFound: 'Network_CompanyNotFound',
  Network_HomeCompanyNotFound: 'Network_HomeCompanyNotFound',
  Network_NotAllowedForNetworkBox: 'Network_NotAllowedForNetworkBox',
  Network_ExternalAuthorizationNotSupported: 'Network_ExternalAuthorizationNotSupported',
  Network_ApplicationRestrictions: 'Network_ApplicationRestrictions',
  Network_NoUserTokenAvailableWithoutAuthentication: 'Network_NoUserTokenAvailableWithoutAuthentication',
  Network_HomeCodeInvalidUID: 'Network_HomeCodeInvalidUID',
  Network_HomeCodeInvalidCode: 'Network_HomeCodeInvalidCode',
  Network_CompanySettingsRequiredItemType: 'Network_CompanySettingsRequiredItemType',
  Network_ItemTypeRequiredCompanySettings: 'Network_ItemTypeRequiredCompanySettings',
  Network_CoreSecretKeyBroken: 'Network_CoreSecretKeyBroken',
  Network_BoxNetworkIdIsRequired: 'Network_BoxNetworkIdIsRequired',
  Network_FrontDomainIsRequired: 'Network_FrontDomainIsRequired',
  Network_CoreInstanceSelfIsNull: 'Network_CoreInstanceSelfIsNull',
  Network_InviteToGroupsNotAllowed: 'Network_InviteToGroupsNotAllowed',
  Network_CreatingInviteByEmailNotAllowed: 'Network_CreatingInviteByEmailNotAllowed',
  Network_RegistrationOnlyBySmsApi: 'Network_RegistrationOnlyBySmsApi',
  Network_EmailInBlackList: 'Network_EmailInBlackList',
  Network_RegistrationRequestSent: 'Network_RegistrationRequestSent',
  Network_AllowExternalEmailsTypeNotFound: 'Network_AllowExternalEmailsTypeNotFound',
  Network_AccessDeniedForUpdateSettings: 'Network_AccessDeniedForUpdateSettings',
  Network_BadgeLimitExceeded: 'Network_BadgeLimitExceeded',
  Network_OnlySystemCanCreateDefaultContent: 'Network_OnlySystemCanCreateDefaultContent',
} as const;

export type ResponseErrorCodes = (typeof ResponseErrorCodesEnum)[keyof typeof ResponseErrorCodesEnum];
