import { DEFAULT_LOCALE, SUPPORT_LOCALES } from '@/i18n';
import { useAuthStore } from '@/store/auth.pinia';
import { Capacitor } from '@capacitor/core';

/**
 * Returns common headers.
 *
 * @param differ - If true, omit adding the HostApp header (used by axios)
 * @returns Object with common headers (Accept-Language, and conditionally HostApp)
 */
export function getCommonHeaders(differ = false): Record<string, string> {
  const isMobile = Capacitor.isNativePlatform();
  const language = window.navigator.language.substring(0, 2).toLowerCase();
  const common: Record<string, string> = {
    'Accept-Language': SUPPORT_LOCALES.includes(language) ? language : DEFAULT_LOCALE,
  };

  //NOTE: Only add HostApp header if not using the differ mode
  if (isMobile && !differ) {
    common.HostApp = useAuthStore().host;
  }

  return common;
}
