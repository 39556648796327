<template>
  <component :is="component.name" v-bind="component.props" v-on="component.events" />
</template>

<script lang="ts" setup>
import type { Component, ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { DocsItemFolder, DocsItemWiki, DocsItemFile, DocsItemExternalLink } from '@/components';
import { DocumentTypeEnum } from '@/enums';
import type { DataViewMode } from '@/enums';
import type { DocModel, FileModel, WidgetDocModel } from '@/types';

const props = defineProps({
  doc: {
    type: Object as PropType<DocModel | WidgetDocModel>,
    required: true,
  },
  viewMode: {
    type: String as PropType<DataViewMode>,
    required: true,
  },
  searchView: {
    type: Boolean,
    default: false,
  },
  fileMenuIsDisabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onLoading', 'onQuickAction', 'onSecondaryAction']);

const component: ComputedRef<{
  name: Component | null;
  props: object;
  events: object;
}> = computed(() => {
  if (props.searchView) {
    return {
      name: DocsItemFile,
      props: {
        'view-mode': props.viewMode,
        'file-menu-is-disabled': props.fileMenuIsDisabled,
        file: props.doc,
      },
      events: {
        onLoading,
        onQuickAction,
      },
    };
  }

  switch (props.doc.documentType) {
    case DocumentTypeEnum.File:
      return {
        name: DocsItemFile,
        props: {
          'view-mode': props.viewMode,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          file: props.doc.data,
        },
        events: {
          onLoading,
          onQuickAction,
          onSecondaryAction,
        },
      };

    case DocumentTypeEnum.Folder:
      return {
        name: DocsItemFolder,
        props: {
          'view-mode': props.viewMode,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          folder: props.doc.data,
        },
        events: { onQuickAction, onSecondaryAction },
      };

    case DocumentTypeEnum.Wiki:
      return {
        name: DocsItemWiki,
        props: {
          'view-mode': props.viewMode,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          wiki: props.doc.data,
        },
        events: { onQuickAction, onSecondaryAction },
      };

    case DocumentTypeEnum.ExternalLink:
      return {
        name: DocsItemExternalLink,
        props: {
          'view-mode': props.viewMode,
          'file-menu-is-disabled': props.fileMenuIsDisabled,
          link: props.doc.data,
        },
        events: { onQuickAction },
      };

    default:
      return {
        name: null,
        props: {},
        events: {},
      };
  }
});

const onLoading = (file: FileModel) => {
  emit('onLoading', false, file);
};

const onQuickAction = () => {
  emit('onQuickAction', props.doc);
};

const onSecondaryAction = (ev: Event) => {
  emit('onSecondaryAction', props.doc, ev);
};
</script>
