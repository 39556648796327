/**
 * Sets up a PerformanceObserver for 'longtask' events.
 * Logs duration, name, and timestamps of each long task.
 */
export function observeLongTasks() {
  // if (!__DEV__) {
  if (!import.meta.env.DEV) {
    console.warn('[WARN] PerformanceObserver is ONLY available in dev mode');
    return;
  }

  const observer = new PerformanceObserver((list) => {
    list.getEntries().forEach((entry) => {
      console.log('[PERF]:longtask:', entry.toJSON());
    });
  });

  observer.observe({ type: 'longtask', buffered: true });
}

/**
 * Marks the start of a measurement block.
 */
export function markStart(label: string) {
  performance.mark(`${label}-start`);
}

/**
 * Marks the end of a measurement block and logs the duration.
 */
export function markEnd(label: string) {
  const endMark = `${label}-end`;
  performance.mark(endMark);

  const measureName = `${label}-measure`;
  performance.measure(measureName, `${label}-start`, endMark);

  const [measureEntry] = performance.getEntriesByName(measureName);
  if (measureEntry) {
    console.log(`[PERF] "${label}" duration (ms):`, measureEntry.duration);
  }

  performance.clearMarks(`${label}-start`);
  performance.clearMarks(endMark);
  performance.clearMeasures(measureName);
}
