import {
  cloneDeep,
  remove,
  get,
  isEmpty,
  orderBy,
  indexOf,
  filter,
  includes,
  unionBy,
  find,
  some,
  flatMap,
} from 'lodash';
import { defineStore } from 'pinia';
import { v4 as uuid_v4 } from 'uuid';

import { CustomPageWidgetsEnum, WidgetFeedTypeEnum, WidgetResourcesTypeItems } from '@/enums';
import type { CustomPageWidgetsPositionEnum, CustomPageShowTypeEnum } from '@/enums';
import { getImageKeyFromLink, useRichTextEditor } from '@/helpers';
import { defaultDragModel, defaultPage, defaultPagesIds } from '@/models';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import type {
  ResponseErrorModel,
  ResponseCustomPagesModel,
  ResponseCustomPageModel,
  PageModel,
  RequestCustomPageCreateModel,
  ErrorMessageModel,
  RequestUpdatePageModel,
  CustomPagesWidgetModel,
  WidgetPathModel,
  WidgetTextBlockSettingsModel,
  WidgetPeopleSettingsModel,
  WidgetHtmlSettingsModel,
  WidgetGroupsSettingsModel,
  ExternalLinkModel,
  WikiModel,
  FolderModel,
  FileModel,
  GroupModel,
  UserModel,
  CustomPageEditRowModel,
  CustomPageEditWidgetModel,
  WidgetBannerSettingsModel,
  WidgetPostSettingsModel,
  WidgetFilesSettingsModel,
  WidgetFeedSettingsModel,
  PagesIdsModel,
  ShortPagesModel,
  WidgetSliderSettingsModel,
  WidgetSliderImageModel,
  DragModel,
} from '@/types';
import {
  isWidgetCalendarSettingsGuard,
  isWidgetFeedSettingsGuard,
  isWidgetFilesSettingsGuard,
  isWidgetGroupInformationSettingsGuard,
  isWidgetGroupsSettingsGuard,
  isWidgetHtmlSettingsGuard,
  isWidgetPeopleSettingsGuard,
  isWidgetPostSettingsGuard,
  isWidgetSliderSettingsGuard,
  isWidgetTextBlockSettingsGuard,
} from '@/helpers/guards';

interface CustomPageState extends EntityState<PageModel> {
  editablePage: null | PageModel;
  editablePageForReset: null | PageModel;
  pagesIds: PagesIdsModel;
  keepSearchQuery: boolean;

  //NOTE: Helpers for drag'&'drop
  drag: DragModel;
}
export const useCustomPageStore = defineStore({
  id: 'page',
  state: (): CustomPageState => ({
    data: [],
    errors: [],
    isLoading: false,
    editablePage: null,
    editablePageForReset: null,
    pagesIds: cloneDeep(defaultPagesIds),
    drag: cloneDeep(defaultDragModel),
    keepSearchQuery: false,
  }),
  getters: {
    getPages: (state) => {
      return state.data;
    },

    getPageById:
      (state) =>
      (id: number): PageModel => {
        const index = state.data.findIndex((page: PageModel) => page.pageId === id);

        if (~index) {
          return state.data[index];
        }
        return cloneDeep(defaultPage);
      },

    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },

    getPageIdByGroupId:
      (state) =>
      (groupId: number): number | null => {
        const index = state.pagesIds.groupDashboard.findIndex((n) => n.groupId === groupId);
        if (~index) {
          return state.pagesIds.groupDashboard[index].id;
        } else {
          return null;
        }
      },

    getPagesAll: (state) => (): ShortPagesModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPagesModel;
      const data = orderBy(state.data, (obj) => indexOf(state.pagesIds.all.ids, obj.pageId));
      result.data = filter(data, (obj) => includes(state.pagesIds.all.ids, obj.pageId));
      result.loadMoreUrl = state.pagesIds.all.loadMoreUrl;
      return result;
    },

    getPagesAutocomplete: (state) => (): ShortPagesModel => {
      const result = { data: [], loadMoreUrl: null } as ShortPagesModel;
      const data = orderBy(state.data, (obj) => indexOf(state.pagesIds.autocomplete.ids, obj.pageId));
      result.data = filter(data, (obj) => includes(state.pagesIds.autocomplete.ids, obj.pageId));
      result.loadMoreUrl = state.pagesIds.autocomplete.loadMoreUrl;
      return result;
    },

    //NOTE: Checks if there is at least one widget of this type on the page
    pageHasWidget:
      (state) =>
      (widget: CustomPageWidgetsEnum): boolean => {
        if (!state.editablePage) {
          return false;
        }

        //NOTE: Merge all widgets into one array
        const allWidgets = flatMap(state.editablePage.customPageData?.rows, (row) => [
          ...row.leftSidebarWidgets,
          ...row.mainWidgets,
          ...row.rightSidebarWidgets,
        ]);

        return some(allWidgets, { systemName: widget });
      },
  },
  actions: {
    async loadMore(): Promise<void> {
      if (this.pagesIds.all.loadMoreUrl !== null) {
        this.errors = [];
        const response = await $api.page.loadMore(this.pagesIds.all.loadMoreUrl);

        if (response.statusCode === 200) {
          const model = response as ResponseCustomPagesModel;

          this.data = mergeById(this.data, model.data);

          this.pagesIds.all.ids = [...this.pagesIds.all.ids, ...model.data.map((n) => n.pageId)];
          this.pagesIds.all.loadMoreUrl = model.loadMoreUrl;

          return;
        }

        if (response.statusCode !== 200) {
          const error = response as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }

        return;
      }
      return;
    },
    async allPages(): Promise<void> {
      this.errors = [];
      this.data = [];
      this.isLoading = true;

      const response = await $api.page.getAllPages();

      if (response.statusCode === 200) {
        const model = response as ResponseCustomPagesModel;

        this.data = mergeById(this.data, model.data);

        this.pagesIds.all.ids = model.data.map((n) => n.pageId);
        this.pagesIds.all.loadMoreUrl = model.loadMoreUrl;

        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async pageById(id: number): Promise<void> {
      this.errors = [];
      this.isLoading = true;

      const response = await $api.page.getPageById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseCustomPageModel;
        this.upsert(model.data);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async groupDashboard(groupId: number): Promise<void> {
      this.errors = [];
      this.isLoading = true;

      const response = await $api.page.getGroupDashboard(groupId);
      if (response.statusCode === 200) {
        const model = response as ResponseCustomPageModel;
        this.upsert(model.data);

        const index = this.pagesIds.groupDashboard.findIndex((n) => n.groupId === groupId);
        if (~index) {
          this.pagesIds.groupDashboard[index].id = model.data.pageId;
        } else {
          this.pagesIds.groupDashboard.push({ groupId, id: model.data.pageId });
        }

        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async pageToEdit(id: number): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      this.editablePage = null;

      const response = await $api.page.getPageDraftById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseCustomPageModel;
        this.editablePage = this.assignWidgetIds(model.data);
        this.editablePageForReset = cloneDeep(this.editablePage);
        this.upsert(model.data);
        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return;
    },
    async autocomplete(text: string): Promise<void> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.page.autocomplete(text);

      if (response.statusCode === 200) {
        const model = response as ResponseCustomPagesModel;
        this.data = mergeById(this.data, model.data);

        this.pagesIds.autocomplete.ids = model.data.map((n) => n.pageId);
        this.pagesIds.autocomplete.loadMoreUrl = model.loadMoreUrl;

        this.isLoading = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    upsert(page: PageModel) {
      const index = this.data.findIndex(({ pageId }) => pageId === page.pageId);
      if (~index) {
        this.data[index] = cloneDeep(page);
      } else {
        this.data = [...this.data, page];
      }

      this.$patch({
        errors: [],
      });
    },
    async createPage(data: RequestCustomPageCreateModel): Promise<boolean> {
      this.errors = [];

      const response = await $api.page.createPage(data);

      if (response.statusCode === 200) {
        const model = response as ResponseCustomPageModel;
        this.data.unshift(model.data);
        this.pagesIds.all.ids.unshift(model.data.pageId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async updatePage(pageData: PageModel, isDraft: boolean): Promise<boolean> {
      this.errors = [];

      let preparedData;

      try {
        preparedData = this.preparePageData(cloneDeep(pageData), isDraft, false);
      } catch (e) {
        console.error('Failed to perform updatePage', e);
        return false;
      }

      const response = await $api.page.updatePage(preparedData);

      if (response.statusCode === 200) {
        const model = response as ResponseCustomPageModel;
        const index = this.data.findIndex((n) => n.pageId === this.editablePage?.pageId);
        if (~index) {
          this.data[index] = model.data;
        }
        this.editablePage = model.data;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async updateDashboard(pageData: PageModel, groupId: number): Promise<boolean> {
      this.errors = [];

      let preparedData;

      try {
        preparedData = this.preparePageData(cloneDeep(pageData), false, true);
      } catch (e) {
        console.error('Failed to perform updatePage', e);
        return false;
      }

      const response = await $api.page.updateDashboard(preparedData.data, preparedData.title, groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseCustomPageModel;
        const index = this.data.findIndex((n) => n.pageId === this.editablePage?.pageId);
        if (~index) {
          this.data[index] = model.data;
        }
        this.editablePage = model.data;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    async deletePage(pageId: number): Promise<boolean> {
      this.errors = [];

      const response = await $api.page.deletePage(pageId);

      if (response.statusCode === 200) {
        remove(this.data, (n) => n.pageId === pageId);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    updatePageTitle(title: string): void {
      if (this.editablePage) {
        this.editablePage.title = title;
      }
      return;
    },

    updatePageMainAlias(alias: string): void {
      if (this.editablePage) {
        this.editablePage.mainAlias = alias;
      }
      return;
    },

    updatePageShowType(showType: CustomPageShowTypeEnum): void {
      if (this.editablePage) {
        this.editablePage.showType = showType;
      }

      const index = this.data.findIndex((n) => n.pageId === this.editablePage?.pageId);
      if (~index) {
        this.data[index].showType = showType;
      }
    },

    updateWidgetInfo(widgetData: CustomPagesWidgetModel, widgetPath: WidgetPathModel): void {
      const rowIndex = widgetPath.rowIndex;
      const position = widgetPath.position;
      const indexInCol = widgetPath.indexInCol;

      if (this.editablePage) {
        const widget: CustomPagesWidgetModel | undefined =
          this.editablePage?.customPageData?.rows?.[rowIndex]?.[position as CustomPageWidgetsPositionEnum]?.[
            indexInCol
          ] ?? undefined;

        if (!widget || !widget?.settings) return;

        const updaters: Record<
          CustomPageWidgetsEnum,
          (current: CustomPagesWidgetModel['settings'], next: CustomPagesWidgetModel['settings']) => void
        > = {
          [CustomPageWidgetsEnum.WidgetTextBlock]: simpleUpdater(isWidgetTextBlockSettingsGuard, ['text', 'title']),
          [CustomPageWidgetsEnum.WidgetPeople]: simpleUpdater(isWidgetPeopleSettingsGuard, [
            'widgetCompanyResourcesItems',
            'widgetTitle',
          ]),
          [CustomPageWidgetsEnum.WidgetGroups]: simpleUpdater(isWidgetGroupsSettingsGuard, [
            'widgetCompanyResourcesItems',
            'widgetTitle',
          ]),
          [CustomPageWidgetsEnum.WidgetHtml]: simpleUpdater(isWidgetHtmlSettingsGuard, ['html']),
          [CustomPageWidgetsEnum.WidgetCalendar]: simpleUpdater(isWidgetCalendarSettingsGuard, [
            'groupId',
            'period',
            'source',
            'height',
          ]),
          [CustomPageWidgetsEnum.WidgetBanner]: (_, next) => {
            widget.settings = next;
          },
          [CustomPageWidgetsEnum.WidgetSingleUserItem]: updateUserItemID,
          [CustomPageWidgetsEnum.WidgetPoll]: updateUserItemID,
          [CustomPageWidgetsEnum.WidgetFilesWikies]: simpleUpdater(isWidgetFilesSettingsGuard, [
            'widgetCompanyResourcesItems',
            'widgetTitle',
          ]),
          [CustomPageWidgetsEnum.WidgetFeed]: simpleUpdater(isWidgetFeedSettingsGuard, [
            'feedType',
            'groupId',
            'tagId',
            'useItemsCount',
            'hideFilters',
          ]),
          [CustomPageWidgetsEnum.WidgetSlider]: simpleUpdater(isWidgetSliderSettingsGuard, ['images', 'slidesPerView']),
          [CustomPageWidgetsEnum.WidgetGroupInformation]: simpleUpdater(isWidgetGroupInformationSettingsGuard, [
            'groupInfo',
          ]),
          // For widget types that do not require updates.
          [CustomPageWidgetsEnum.WidgetBirthdays]: () => {},
          [CustomPageWidgetsEnum.WidgetInvite]: () => {},
          [CustomPageWidgetsEnum.WidgetGroupMembers]: () => {},
          [CustomPageWidgetsEnum.WidgetGroupAdmins]: () => {},
          [CustomPageWidgetsEnum.WidgetPublisher]: () => {},
        };

        const updater = updaters[widget.systemName];
        if (updater) updater(widget.settings, widgetData.settings);
      }

      return;
    },

    removeAllEmptyRows() {
      const { editablePage } = this;
      if (editablePage?.customPageData) {
        editablePage.customPageData.rows = filter(
          editablePage.customPageData.rows,
          (row) => !isEmpty(row.leftSidebarWidgets) || !isEmpty(row.mainWidgets) || !isEmpty(row.rightSidebarWidgets)
        );
      }
    },

    //NOTE: If the last widget is removed from the main column, but there are widgets in the right column in this row - move them to the main section
    moveRightbarContentToMainbar(): void {
      const { editablePage } = this;
      if (editablePage?.customPageData) {
        editablePage.customPageData.rows.forEach((row) => {
          if (row.mainWidgets.length === 0 && row.rightSidebarWidgets.length > 0) {
            row.mainWidgets = [...row.rightSidebarWidgets];
            row.rightSidebarWidgets = [];
          }
        });
      }
    },

    addNewRow(): void {
      const { editablePage } = this;
      if (editablePage?.customPageData) {
        editablePage.customPageData.rows.unshift({
          leftSidebarWidgets: [],
          mainWidgets: [],
          rightSidebarWidgets: [],
          rowId: uuid_v4(),
        });
      }
    },

    removeRow(rowId: string): void {
      const { editablePage } = this;
      if (editablePage?.customPageData) {
        remove(editablePage.customPageData.rows, (row) => row.rowId === rowId);
      }
    },

    resetEditablePage(): void {
      this.editablePage = null;
      this.editablePageForReset = null;
      return;
    },

    resetDragInfo(): void {
      const dashboard = this.drag.dashboard;
      this.drag = cloneDeep(defaultDragModel);
      this.drag.dashboard = dashboard;
      return;
    },

    // Добавляем к каждому виджету и строке ID, чтобы работать с ними было проще
    assignWidgetIds(pageData: PageModel): PageModel {
      const page = cloneDeep(pageData);
      if (page.customPageData) {
        page.customPageData.rows.forEach((row) => {
          row.rowId = uuid_v4();
          const assignUuid = (widget: CustomPagesWidgetModel) => (widget.widgetId = uuid_v4());

          row.leftSidebarWidgets.forEach(assignUuid);
          row.mainWidgets.forEach(assignUuid);
          row.rightSidebarWidgets.forEach(assignUuid);
        });
      }
      return page;
    },

    // Подготоваваливаем страницу к сохранению
    preparePageData(page: PageModel, isDraft: boolean, isDashboard: boolean): RequestUpdatePageModel {
      const rows = page?.customPageData?.rows;

      const newRows = rows?.map((row) => {
        const cloneRow = {
          ...(row as CustomPageEditRowModel),
        } as CustomPageEditRowModel;

        //TO:DO remove any
        const iterateWidgets = (widgets: CustomPagesWidgetModel[]): CustomPageEditWidgetModel[] | any => {
          return widgets.map((widget) => {
            if (
              widget.systemName === CustomPageWidgetsEnum.WidgetPeople ||
              widget.systemName === CustomPageWidgetsEnum.WidgetGroups
            ) {
              if (
                !(widget.settings as WidgetPeopleSettingsModel).widgetCompanyResourcesItems.length &&
                !(widget.settings as WidgetGroupsSettingsModel).widgetCompanyResourcesItems.length &&
                !(widget.settings as WidgetPeopleSettingsModel | WidgetGroupsSettingsModel).widgetTitle.length
              )
                return null;

              const items = get(widget.settings, 'widgetCompanyResourcesItems', []);
              const newItems = items.map((element) => {
                const itemType =
                  widget.systemName === CustomPageWidgetsEnum.WidgetPeople
                    ? WidgetResourcesTypeItems.User
                    : WidgetResourcesTypeItems.UserGroup;

                return {
                  WidgetCompanyResourcesItemType: itemType,
                  ID: (element as GroupModel | UserModel).id.toString(),
                };
              });

              return {
                ...widget,
                settings: {
                  ...widget.settings,
                  widgetCompanyResourcesItems: newItems,
                },
              };
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetBanner) {
              const isTempImage = !!(widget.settings as WidgetBannerSettingsModel)?.tempImageFilename;
              const settings = {
                [isTempImage ? 'tempImageFilename' : 'imageFilename']: getImageKeyFromLink(
                  isTempImage
                    ? (widget.settings as WidgetBannerSettingsModel).tempImageFilename
                    : (widget.settings as WidgetBannerSettingsModel).imageFilename
                ),
                linkUrl: (widget.settings as WidgetBannerSettingsModel).linkUrl,
              };

              if (!settings.imageFilename?.length && !settings.tempImageFilename?.length) {
                return null;
              }

              return {
                ...widget,
                settings: {
                  ...settings,
                },
              };
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetSlider) {
              /* const newWidget = cloneDeep(widget) */

              (widget.settings as WidgetSliderSettingsModel).images.forEach((element: WidgetSliderImageModel) => {
                if (element?.file && !element?.existImageId && !element?.tempImageId) {
                  element.existImageId = element.file.key;
                } else if (!element?.file) {
                  delete element?.existImageId;
                }
              });

              return widget;
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetSingleUserItem) {
              if (!(widget.settings as WidgetPostSettingsModel).userItemID) return null;
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetPoll) {
              if (!(widget.settings as WidgetPostSettingsModel).userItemID) return null;
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetFilesWikies) {
              if (
                !(widget.settings as WidgetFilesSettingsModel).widgetCompanyResourcesItems.length &&
                !(widget.settings as WidgetFilesSettingsModel).widgetTitle.length
              )
                return null;
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetTextBlock) {
              if (
                !(widget.settings as WidgetTextBlockSettingsModel).text.length &&
                !(widget.settings as WidgetTextBlockSettingsModel).title.length
              )
                return null;

              return {
                ...widget,
                settings: {
                  ...{
                    text: useRichTextEditor().preSubmit((widget.settings as WidgetTextBlockSettingsModel).text),
                    title: (widget.settings as WidgetTextBlockSettingsModel).title,
                  },
                },
              };
            }

            //NOTE: If the widget is on the dashboard, what do we need to force to set the groupId as the dashboard ID
            if (widget.systemName === CustomPageWidgetsEnum.WidgetFeed && isDashboard) {
              return {
                ...widget,
                settings: {
                  ...widget.settings,
                  customFeedType: null,
                  feedType: WidgetFeedTypeEnum.AllPublic,
                  groupId: (widget.settings as WidgetFeedSettingsModel).groupId
                    ? (widget.settings as WidgetFeedSettingsModel).groupId
                    : this.drag.dashboard.id,
                },
              };
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetFilesWikies) {
              widget.systemName = CustomPageWidgetsEnum.WidgetFilesWikies;
              const items = get(widget.settings, 'widgetCompanyResourcesItems', []);
              const newItems = items.map((element) => {
                const itemType = get(element, 'widgetCompanyResourcesItemType', '') as WidgetResourcesTypeItems;
                const data = get(element, 'data', null);

                if (data) {
                  const newItem =
                    itemType === WidgetResourcesTypeItems.ExternalLink
                      ? {
                          WidgetCompanyResourcesItemType: itemType,
                          URL: (data as ExternalLinkModel).url,
                          title: (data as ExternalLinkModel).title,
                        }
                      : {
                          WidgetCompanyResourcesItemType: itemType,
                          ID: (data as WikiModel | FolderModel | FileModel).id.toString(),
                        };

                  return newItem;
                }

                return element;
              });

              return {
                ...widget,
                settings: {
                  ...widget.settings,
                  widgetCompanyResourcesItems: newItems,
                },
              };
            }

            if (widget.systemName === CustomPageWidgetsEnum.WidgetHtml) {
              if (!(widget.settings as WidgetHtmlSettingsModel).html.length) return null;
            }

            return widget;
          });
        };

        const removeNullableWidgets = (widgets: CustomPageEditWidgetModel[]): CustomPageEditWidgetModel[] | any => {
          return widgets.filter((n) => n !== null);
        };

        cloneRow.mainWidgets = removeNullableWidgets(iterateWidgets(row.mainWidgets));
        cloneRow.leftSidebarWidgets = removeNullableWidgets(iterateWidgets(row.leftSidebarWidgets));
        cloneRow.rightSidebarWidgets = removeNullableWidgets(iterateWidgets(row.rightSidebarWidgets));

        return cloneRow;
      });

      const newData = {
        pageId: page.pageId,
        mainAlias: page.mainAlias,
        title: page.title,
        showType: page.showType,
        data: { rows: newRows },
        isPublic: true,
      } as RequestUpdatePageModel;

      if (isDraft) {
        newData.isPublic = false;
      }

      return newData;
    },
  },
  persist: true,
});

const mergeById = (a: PageModel[], b: PageModel[]) => {
  return unionBy(a, b, 'pageId').map((obj) => {
    const match = find(b, { id: obj.pageId });
    return match ? Object.assign({}, obj, match) : obj;
  });
};

const simpleUpdater =
  <T>(guard: (obj: any) => obj is T, keys: (keyof T)[]) =>
  (current: any, next: any): void => {
    if (guard(current) && guard(next)) {
      keys.forEach((key) => (current[key] = next[key]));
    }
  };

const updateUserItemID = (current: any, next: any): void => {
  if (isWidgetPostSettingsGuard(current) && isWidgetPostSettingsGuard(next)) {
    current.userItemID = next.userItemID?.toString() ?? null;
  }
};
