import {
  MessageTypeEnum,
  ChainTypeEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
  MeetStatusEnum,
} from '@/enums';
import {
  isNativeMobile,
  useToasts,
  toLastModel,
  useMeet,
  useNotifications,
  toShortUserModel,
  openMeetRoomModal,
} from '@/helpers';
import { useI18n } from '@/i18n';
import { useAppStore, useAuthStore, useChatStore, useMeetStore, useMessengerStore, useUserStore } from '@/store';
import type { MessageModel, UserModel } from '@/types';
import type { MessageHandler } from '@/ws/types';

async function _onAddParticipant(message: MessageModel, currentUserId: number) {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  if (message.authorId === currentUserId) return;

  const userIds = message.payload ? JSON.parse(message.payload).userIds : undefined;
  if (!userIds) return;

  const meetRoomId = useMeetStore().getRoomIdByChainId(message.chainId);
  const activeCalls = await useMeetStore().getActiveCalls();
  const currentCallSession = activeCalls?.[message.chainId];

  await useChatStore().addParticipants(message.chainId, userIds, currentUserId);

  const existedChain = useMessengerStore().getChainById(message.chainId);
  if (!existedChain) {
    console.error('[WS] Adding participant failed - chain does not exist', message.chainId);
    return;
  }

  if (meetRoomId) {
    for (const id of userIds) {
      let addedUser: UserModel | null = useUserStore().getUserProfile(id);
      if (addedUser.id === 0) addedUser = await useUserStore().userProfileById(id);
      if (addedUser === null) return;
      useMeetStore().addUser(toShortUserModel(addedUser), meetRoomId);
    }
  }

  // if meetRoomId exists it means that current user is participating in a group call and has been added in a group chat already
  if (currentCallSession && !meetRoomId && userIds.includes(currentUserId)) {
    const callModalResult = await useMeet().showCallModal(existedChain);
    if (callModalResult === MeetStatusEnum.Accept) {
      const roomId = await useMeetStore().callUser(existedChain);
      if (!roomId) {
        showSonnerToast(t('meet.meetRoom.tryAgain'), false);
        console.error('[WS] Failed to init call');
        return;
      }
      if (useMeetStore().currentRoomId === '') {
        useMeetStore().$patch({
          currentRoomId: roomId,
        });
      }
      // connect to another incoming call if user is already in a call
      if (useMeetStore().getCurrentRoomId !== roomId) {
        await useMeet().disconnectFromRoom();
        useMeetStore().$patch({
          currentRoomId: roomId,
        });
      }
      await openMeetRoomModal(roomId);
      const requestPermissionsResult = await useMeet().requestPermissions();
      if (!requestPermissionsResult) {
        await useMeet().disconnectFromRoom(roomId);
        return;
      }
      const connectedToRoom = await useMeet().connectToRoom(false, roomId);
      if (!connectedToRoom) {
        await useMeet().disconnectFromRoom(roomId);
      }
    } else if (callModalResult === MeetStatusEnum.Reject) {
      useMeetStore().setRoom(currentCallSession.roomName, existedChain, currentCallSession.participantJwtToken);
    }
  }
}

async function _onRemoveParticipant(message: MessageModel, currentUserId: number) {
  if (message.authorId === currentUserId) {
    return;
  }

  const userIds = message.payload ? JSON.parse(message.payload).userIds : undefined;
  if (!userIds) {
    return;
  }

  const meetRoomId = useMeetStore().getRoomIdByChainId(message.chainId);

  await useChatStore().removeParticipants(message.chainId, userIds, currentUserId);

  if (meetRoomId) {
    useMeetStore().deleteUser(userIds[0], meetRoomId);
  }
}

async function _onRemoveYou(message: MessageModel, currentUserId: number) {
  if (message.authorId === currentUserId) {
    return;
  }

  const meetHelper = useMeet();
  const meetRoomId = useMeetStore().getRoomIdByChainId(message.chainId);

  await useChatStore().removeParticipants(message.chainId, [currentUserId], currentUserId);

  const existedChain = useMessengerStore().getChainById(message.chainId);
  if (!existedChain) {
    console.error('[WS] Remove you failed - chain does not exist', message.chainId);
    return;
  }

  if (meetRoomId) {
    await meetHelper.disconnectFromRoom(meetRoomId, true);
    return;
  }

  const activeCalls = await useMeetStore().getActiveCalls();
  const currentCallSession = activeCalls?.[message.chainId];

  if (currentCallSession && !meetRoomId) {
    useMeetStore().setRoom(currentCallSession.roomName, existedChain, currentCallSession.participantJwtToken);
    await meetHelper.disconnectFromRoom(currentCallSession.roomName, true);
  }
}

async function _onStandardMessage(message: MessageModel, currentUserId: number) {
  const currentChainId = useChatStore().getId;
  const model = useAuthStore().getWebSocketModel;

  if (!model) {
    console.error('[WS] Processing message failed - model is null');
    return;
  }

  message.direction = MessageDirectionTypeEnum.Incoming;

  let existedChain = useMessengerStore().getChainById(message.chainId);
  // If chain is not currently in the local storage - trying to get it
  if (!existedChain) {
    await useMessengerStore().chainById(message.chainId);
  }
  // Trying to get chain again
  existedChain = useMessengerStore().getChainById(message.chainId);
  if (!existedChain) {
    console.error('[WS] Processing message failed - chain does not exist', message.chainId);
    return;
  }
  // If message is in archive chain, change chain type to active
  if (existedChain && existedChain.chainType === ChainTypeEnum.Archive) {
    existedChain.chainType = ChainTypeEnum.Active;
  }

  const chainIsMuted = existedChain?.muted;
  const notificationsIsActive = useAppStore().localNotifications;

  // If message is incoming, chain is not current, chain is not muted and notifications are active - playing sound
  const shouldPlaySound =
    message.direction === MessageDirectionTypeEnum.Incoming &&
    (message.chainId !== currentChainId || message.chainId === null) &&
    !chainIsMuted &&
    notificationsIsActive;

  if (shouldPlaySound) {
    if (!isNativeMobile) {
      const audioFile = new Audio(useAppStore().getAppNotificationSoundPath);
      await audioFile.play();
    } else {
      await useNotifications().scheduleLocalNotification(message);
    }
  }

  await useChatStore().updateChain(existedChain, false);

  useMessengerStore().setLastMessage(toLastModel(message), model.userId !== message.authorId);

  // If the message is in the current chain and the author is not the current user - reading the message
  if (message.chainId === currentChainId && message.authorId !== currentUserId) {
    useChatStore().read(
      {
        authorId: message.authorId,
        chainId: message.chainId,
        messageId: message.id,
        status: MessageDeliveryStatusEnum.ReadAll,
        uniqueId: message.uniqueId,
      },
      false
    );
    await useChatStore().markAsRead([message.id]);
  }
}

async function _onPlaceHolder(): Promise<void> {
  console.warn('[WS] Received placeholder message');
  return;
}

export const messageHandlers: Record<MessageTypeEnum, MessageHandler> = Object.freeze({
  [MessageTypeEnum.AddParticipant]: _onAddParticipant,
  [MessageTypeEnum.RemoveParticipant]: _onRemoveParticipant,
  [MessageTypeEnum.RemoveYou]: _onRemoveYou,
  [MessageTypeEnum.Message]: _onStandardMessage,
  [MessageTypeEnum.FileVideo]: _onStandardMessage,
  [MessageTypeEnum.FileImage]: _onStandardMessage,
  [MessageTypeEnum.FileDocument]: _onStandardMessage,
  [MessageTypeEnum.Quote]: _onStandardMessage,
  [MessageTypeEnum.Sticker]: _onStandardMessage,
  [MessageTypeEnum.Forward]: _onStandardMessage,
  [MessageTypeEnum.ForwardOld]: _onStandardMessage,
  [MessageTypeEnum.CreateGroup]: _onPlaceHolder,
  [MessageTypeEnum.YouNewAdmin]: _onPlaceHolder,
  [MessageTypeEnum.SelfRemove]: _onPlaceHolder,
  [MessageTypeEnum.Init]: _onPlaceHolder,
  [MessageTypeEnum.InitVideoCall]: _onPlaceHolder,
  [MessageTypeEnum.EndVideoCall]: _onPlaceHolder,
});
