import { assign, chain, cloneDeep, find } from 'lodash';
import { defineStore } from 'pinia';

import {
  ChainTypeEnum,
  MessageDeleteEnum,
  MessageDeliveryStatusEnum,
  MessageDirectionTypeEnum,
  MessageTypeEnum,
} from '@/enums';
import { toEditModel, toLastModel, toShortUserModel } from '@/helpers';
import { $api } from '@/services';
import { useMessengerStore, useUserStore } from '@/store';
import type {
  DraftMessageModel,
  EditMessageModel,
  ErrorMessageModel,
  MediaModel,
  MessageChainModel,
  MessageModel,
  MessagesModel,
  MessagesReadModel,
  RequestChainModel,
  RequestMessageModel,
  ResponseChainModel,
  ResponseChatAvatarModel,
  ResponseErrorModel,
  ResponseMessageModel,
  ResponseMessagesModel,
  UserModel,
  UserShortModel,
} from '@/types';
import { useNotifications } from '@/helpers/useNotificationsHelper';

interface ChatState {
  chain: MessageChainModel | null;
  quote: MessageModel | null;
  isEdit: boolean;
  inAction: boolean;
  updated: number;
  callToken: string | null;
  disableLoadMore: boolean;
  errors: ErrorMessageModel[];
  isLoading: boolean;
  chats: {
    [chainId: number]: {
      data: MessageModel[];
      loadMoreUrl: string | null;
    };
  };
}

export const useChatStore = defineStore({
  id: 'chat',
  state: (): ChatState => ({
    errors: [],
    isLoading: false,
    chain: null,
    quote: null,
    isEdit: false,
    inAction: false,
    updated: 0,
    callToken: null,
    disableLoadMore: false,
    chats: {},
  }),
  getters: {
    getId: (state): number => state.chain?.chainId ?? 0,
    getMessages:
      (state) =>
      (chainId: number): MessagesModel[] | null => {
        if (!state.chats[chainId]) {
          return null;
        }
        return chain(state.chats[chainId].data)
          .groupBy((message: MessageModel) => message.createdAt.substring(0, 10))
          .map(
            (messages: MessageModel[]) =>
              ({
                created: messages[0].createdAt,
                data: messages,
              }) as MessagesModel
          )
          .value();
      },
    getLoadMoreUrl: (state) => (chainId: number) => state.chats[chainId]?.loadMoreUrl,
    getUsers(state): (text: string, userId: number) => UserShortModel[] {
      return (text: string, userId: number) => {
        text = text.toLowerCase();
        const users = state.chain?.users ?? [];
        return text.length > 0
          ? users.filter(
              (n: UserShortModel) =>
                (n.mainAlias.toLowerCase().includes(text) || n.fullName.toLowerCase().includes(text)) && n.id !== userId
            )
          : users.filter((n: UserShortModel) => n.id !== userId);
      };
    },
    getUsersCnt(state): number {
      return state.chain?.users.length ?? 0;
    },
    getType(state): ChainTypeEnum {
      return state.chain?.chainType ?? ChainTypeEnum.Active;
    },
    autocomplete: (state) => (text: string) => {
      text = text.toLowerCase();
      if (state.chain !== null) {
        return text.length > 0
          ? state.chain.users.filter((n: UserShortModel) => n.fullName.toLowerCase().includes(text))
          : state.chain.users;
      } else {
        return [];
      }
    },
    autocompleteToAdmins: (state) => (text: string) => {
      text = text.toLowerCase();
      if (state.chain !== null) {
        return text.length > 0
          ? state.chain.users.filter(
              (n: UserShortModel) =>
                n.fullName.toLowerCase().includes(text) && !state.chain?.adminsIds.some((m: number) => m == n.id)
            )
          : state.chain.users;
      } else {
        return [];
      }
    },
    getChainAdmins(state): UserShortModel[] {
      const chainAdmins = [] as UserShortModel[];
      if (state.chain !== null) {
        state.chain.adminsIds.forEach((element) => {
          const admin = find(state.chain?.users, { id: element });
          if (admin !== undefined) {
            chainAdmins.push(admin);
          }
        });
      }
      return chainAdmins;
    },
  },
  actions: {
    async getChainById(chainId: number): Promise<boolean> {
      const messengerStore = useMessengerStore();

      const response = await $api.messenger.getChainById(chainId);
      if (response.statusCode === 200) {
        const model = response as ResponseChainModel;
        const chainEntity = cloneDeep(model.data);
        this.$patch({
          chain: chainEntity,
        });
        messengerStore.setMessengerData(messengerStore.data, [chainEntity]);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async getMessagesByChainId(chainId: number): Promise<boolean> {
      this.$patch({
        isLoading: true,
        errors: [],
        quote: null,
        isEdit: false,
      });

      const response = await $api.messenger.getMessagesByChainId(chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;

        this.$patch({
          isLoading: false,
          chain: this.chain,
          chats: {
            [chainId]: {
              data: model.data,
              loadMoreUrl: model.loadMoreUrl,
            },
          },
          quote: null,
        });

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async getChainByUserId(userId: number): Promise<boolean> {
      this.$patch({
        isLoading: true,
        errors: [],
        chain: null,
        quote: null,
        isEdit: false,
      });
      const messagesResponse = await $api.messenger.getByUserId(userId);

      if (messagesResponse.statusCode === 200) {
        const messagesModel = messagesResponse as ResponseMessagesModel;
        const chainId = messagesModel.data[0].chainId;
        let chainEntity: MessageChainModel | undefined;

        if (chainId === this.chain?.chainId) {
          this.$patch({
            isLoading: false,
            chats: {
              [chainId]: {
                data: messagesModel.data,
                loadMoreUrl: messagesModel.loadMoreUrl,
              },
            },
            quote: null,
            errors: [],
          });
          return true;
        }

        const chainResponse = await $api.messenger.getChainById(chainId);
        if (chainResponse.statusCode === 200) {
          const chainModel = chainResponse as ResponseChainModel;
          chainEntity = cloneDeep(chainModel.data);
          this.$patch({
            isLoading: false,
            chain: chainEntity,
            chats: {
              [chainId]: {
                data: messagesModel.data,
                loadMoreUrl: messagesModel.loadMoreUrl,
              },
            },
            quote: null,
            errors: [],
          });
          return true;
        }

        if (chainResponse.statusCode !== 200) {
          const error = chainResponse as ResponseErrorModel;
          this.errors = cloneDeep(error.errorMessages);
        }
        this.isLoading = false;
        return false;
      }

      if (messagesResponse.statusCode !== 200) {
        const error = messagesResponse as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    setDisableLoadMore(state: boolean) {
      this.disableLoadMore = state;
    },
    setChain(chain: MessageChainModel | undefined) {
      if (!chain) {
        return;
      }
      this.$patch({
        chain,
      });
    },
    async updateChain(entity: MessageChainModel, setAsCurrent: boolean): Promise<void> {
      this.$patch((state) => {
        state.isLoading = setAsCurrent ? true : state.isLoading;
        state.errors = [];
        state.chain = setAsCurrent ? entity : this.chain;
        state.quote = null;
        state.isEdit = false;
      });

      const response = await $api.messenger.getMessagesByChainId(entity.chainId);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;

        const lastMessage = model.data.at(-1);
        if (lastMessage) {
          this.upsert(lastMessage);
        }

        this.$patch((state) => {
          state.isLoading = false;
          state.chain = this.chain;
          state.chats = {
            [entity.chainId]: {
              data: model.data,
              loadMoreUrl: model.loadMoreUrl,
            },
          };
          state.quote = null;
        });

        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
    },
    async markAsRead(ids: number[]) {
      const response = await $api.messenger.markAsRead(ids);
      if (response.statusCode === 200) {
        if (this.chain) {
          const messengerStore = useMessengerStore();

          // We need to mark all notificationsrelated to this chain as read
          // so user will not see unread notifications for this chain
          // in notifications list
          await useNotifications().markNotificationsForChain(this.chain.chainId);

          messengerStore.resetUnreadMessagesCount(this.chain.chainId);
          this.chain.unreadMessagesCount = 0;
        }
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
    },
    async loadMore(url: string, chainId: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.getByUrl(url);

      if (response.statusCode === 200) {
        const model = response as ResponseMessagesModel;

        const currentChat = this.chats[chainId];
        currentChat.data = [...model.data, ...currentChat.data];
        currentChat.loadMoreUrl = model.loadMoreUrl;
        this.errors = [];

        this.inAction = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
      return false;
    },
    async createChain(data: RequestChainModel): Promise<number> {
      this.inAction = true;
      const response = await $api.messenger.createChain(data);

      if (response.statusCode === 200) {
        const model = response as ResponseMessageModel;
        this.upsert(model.data);

        this.inAction = false;
        return model.data.chainId;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
      return 0;
    },
    async reply(data: RequestMessageModel): Promise<MessageModel | undefined> {
      this.inAction = true;
      const response = await $api.messenger.reply(data);

      if (response.statusCode === 200) {
        const model = response as ResponseMessageModel;

        this.upsert(model.data);

        this.inAction = false;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        if (data.payload !== null) {
          const index = this.chats[data.chainId].data.findIndex(({ payload }) => payload === data.payload);

          if (~index) {
            this.chats[data.chainId].data[index].status = MessageDeliveryStatusEnum.Error;
          }
        }
        this.errors = error.errorMessages;
      }

      this.inAction = false;
      return undefined;
    },
    async edit(data: EditMessageModel): Promise<void> {
      this.inAction = true;
      const response = await $api.messenger.edit(toEditModel(data));

      if (response.statusCode === 200) {
        this.redact(data);
        this.inAction = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
    },
    setUpdate() {
      this.$patch({
        updated: new Date().getTime(),
      });
    },
    async draft(data: DraftMessageModel): Promise<void> {
      const message: MessageModel = {
        attachedFiles: {
          count: data.files.length,
          data: data.files,
        },
        authorAlias: data.authorAlias,
        authorAvatarUrl: data.authorAvatarUrl,
        authorFullName: data.authorFullName,
        authorId: data.authorId,
        bodyHtml: data.text,
        chainId: data.chainId,
        createdAt: new Date().toISOString(),
        direction: MessageDirectionTypeEnum.Outgoing,
        editable: false,
        edited: false,
        forwardedAuthorAlias: null,
        forwardedAuthorFullName: null,
        forwardedAttachedFiles: {
          count: data.files.length,
          data: data.files,
        },
        id: 0,
        messageType: MessageTypeEnum.Message,
        originalMessage: data.quoteItem,
        payload: data.payload,
        sharedUserItems: [],
        status: MessageDeliveryStatusEnum.Draft,
        sticker: null,
        uniqueId: '',
      };
      this.upsert(message);
      this.setUpdate();
    },
    async deleteRequest(messageId: number, type: MessageDeleteEnum, chainId: number) {
      this.inAction = true;
      const response =
        type === MessageDeleteEnum.ForMe
          ? await $api.messenger.deleteForMe(messageId)
          : await $api.messenger.deleteForAll(messageId);

      if (response.statusCode === 200) {
        this.delete(messageId, chainId);
        this.inAction = false;
        return;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.inAction = false;
    },
    init() {
      this.$patch({
        isLoading: false,
        errors: [],
        chain: null,
        quote: null,
        isEdit: false,
        chats: {},
      });
    },
    updateQuote(quote: MessageModel | null, isEdit: boolean) {
      this.$patch(
        cloneDeep({
          quote,
          isEdit,
        })
      );
    },
    upsert(message: MessageModel) {
      if (!this.chats[message.chainId]) {
        this.chats[message.chainId] = {
          data: [],
          loadMoreUrl: null,
        };
      }

      const chat = this.chats[message.chainId];
      const existingMessage = find(chat.data, { payload: message.payload });

      if (existingMessage) {
        assign(existingMessage, message);
      } else {
        chat.data.push(message);
      }

      this.$patch({
        errors: [],
        quote: null,
        isEdit: false,
      });
    },
    redact(data: EditMessageModel): void {
      const index = this.chats[data.chainId].data.findIndex(({ uniqueId }) => uniqueId === data.uniqueId);

      if (~index) {
        const currentMessage = this.chats[data.chainId].data[index];

        const model = cloneDeep(data);

        currentMessage.bodyHtml = model.message.text;
        currentMessage.attachedFiles.data = model.fileInfos;
        currentMessage.attachedFiles.count = model.fileInfos.length;
        currentMessage.edited = true;
      }

      this.$patch({
        errors: [],
        quote: null,
        isEdit: false,
      });
    },
    read(data: MessagesReadModel, all: boolean): void {
      const messengerStore = useMessengerStore();

      //TODO: waiting for improvement - https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1465
      if (data.status === MessageDeliveryStatusEnum.Delivered) {
        console.warn('No need to update status to Delivered');
        return;
      }

      const messagesInThisChain = this.chats[data.chainId].data;
      let messagesToRead = messagesInThisChain.filter(
        (m: MessageModel) => m.chainId === data.chainId && m.authorId === data.authorId && m.status !== data.status
      );

      if (!all) {
        messagesToRead = messagesInThisChain.filter((m: MessageModel) => m.uniqueId === data.uniqueId);
      }

      messagesToRead.forEach((message) => {
        message.status = data.status;
      });

      const lastMessage = messagesToRead.at(-1);
      if (!lastMessage) {
        console.warn('Cannot update last message with null/undefined', lastMessage);
        return;
      }
      messengerStore.setLastMessage(toLastModel(lastMessage), false);
    },
    delete(messageId: number, chainId: number): MessageModel {
      this.chats[chainId].data = this.chats[chainId].data.filter(({ id }: MessageModel) => id !== messageId);

      this.$patch({
        errors: [],
        quote: null,
        isEdit: false,
      });

      return this.chats[chainId].data.at(-1) as MessageModel;
    },
    async updateAvatar(chainId: number, file: File): Promise<boolean> {
      this.errors = [];
      const response = await $api.messenger.updateAvatar(chainId, file);
      if (response.statusCode === 200 && this.chain !== null) {
        const model = response as ResponseChatAvatarModel;
        this.chain.chainAvatar = {
          url: model.data.url ?? null,
          width: model.data.width ?? null,
          height: model.data.height ?? null,
        } as MediaModel;
        return true;
      }
      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async deleteAvatar(chainId: number): Promise<boolean> {
      this.errors = [];
      const response = await $api.messenger.deleteAvatar(chainId);
      if (response.statusCode === 200 && this.chain !== null) {
        this.chain.chainAvatar = {
          url: null,
          width: null,
          height: null,
        } as MediaModel;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },
    async addParticipants(chainId: number, usersIds: number[], currentUserId?: number): Promise<UserShortModel[]> {
      const messengerStore = useMessengerStore();
      const addedParticipants: UserShortModel[] = [];
      let shortedUser: UserShortModel | undefined;
      let chainToUpdate = messengerStore.getChainById(chainId);
      if (!chainToUpdate) {
        await messengerStore.chainById(chainId);
        chainToUpdate = messengerStore.getChainById(chainId);
      }

      if (!chainToUpdate) {
        return [];
      }

      for (const id of usersIds) {
        let addedUser: UserModel | null = useUserStore().getUserProfile(id);
        if (addedUser.id === 0) {
          addedUser = await useUserStore().userProfileById(id);
        }
        if (addedUser === null) {
          continue;
        }
        shortedUser = toShortUserModel(addedUser);
        if (chainToUpdate.users.findIndex((n) => n.id === id) === -1) {
          chainToUpdate.users.push(shortedUser);
          addedParticipants.push(shortedUser);
        }
      }
      if (currentUserId && chainToUpdate.users.findIndex((n) => n.id === currentUserId)) {
        chainToUpdate.isInChain = true;
      }
      if (this.chain?.chainId === chainId) {
        this.setChain(chainToUpdate);
      }
      return addedParticipants;
    },
    async chatAddParticipant(chainId: number, usersIds: number[], currentUserId?: number): Promise<UserShortModel[]> {
      this.inAction = true;
      const response = await $api.messenger.chatAddParticipant(chainId, usersIds);

      if (response.statusCode === 200) {
        return await this.addParticipants(chainId, usersIds, currentUserId);
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return [];
    },
    async removeParticipants(chainId: number, usersIds: number[], currentUserId?: number): Promise<boolean> {
      const messengerStore = useMessengerStore();
      let chainToUpdate = messengerStore.getChainById(chainId);
      if (!chainToUpdate) {
        await messengerStore.chainById(chainId);
        chainToUpdate = messengerStore.getChainById(chainId);
      }

      if (!chainToUpdate) {
        return false;
      }

      const index = chainToUpdate.users.findIndex((n) => n.id === usersIds[0]);
      if (~index) {
        chainToUpdate.users.splice(index, 1);
      }
      if (currentUserId && chainToUpdate.users.findIndex((n) => n.id === currentUserId) === -1) {
        chainToUpdate.isInChain = false;
      }
      if (this.chain?.chainId === chainId) {
        this.setChain(chainToUpdate);
      }
      return true;
    },
    async chatRemoveParticipant(chainId: number, usersIds: number[], currentUserId?: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.chatRemoveParticipant(chainId, usersIds);

      if (response.statusCode === 200) {
        return await this.removeParticipants(chainId, usersIds, currentUserId);
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async chatAddAdministrator(chainId: number, user: UserShortModel): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.chatAddAdministrator(chainId, user.id);

      if (response.statusCode === 200) {
        this.chain?.adminsIds.push(user.id);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async chatRemoveAdministrator(chainId: number, userId: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.chatRemoveAdministrator(chainId, userId);

      if (response.statusCode === 200) {
        if (this.chain?.adminsIds) {
          const index = this.chain.adminsIds.findIndex((n) => n === userId);
          this.chain?.adminsIds.splice(index, 1);
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async exitChain(chainId: number, userId: number): Promise<void> {
      if (this.chain !== null && this.chain.chainId === chainId) {
        this.chain.isInChain = false;

        const usersIndex = this.chain.users.findIndex((n) => n.id === userId);
        if (~usersIndex) {
          this.chain?.users.splice(usersIndex, 1);
        }

        const adminsIndex = this.chain.adminsIds.findIndex((n: number) => n === userId);
        if (~adminsIndex) {
          this.chain?.adminsIds.splice(adminsIndex, 1);
        }
      }
    },
    async forwardToChain(messageId: number, chainId: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.forwardToChain(messageId, chainId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    async forwardToUser(messageId: number, userId: number): Promise<boolean> {
      this.inAction = true;
      const response = await $api.messenger.forwardToUser(messageId, userId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
  },
  persist: true,
});
