import { useGroups } from './useGroupsHelper';

import { AccessByRoleEnum, ActionAccessEnum, GroupsAccessEnum, UserRoleEnum } from '@/enums';
import { useUserStore, useNetworkStore, useAuthStore } from '@/store';
import type { FileModel, GroupModel, WikiModel } from '@/types';
import { useMenu } from '@/helpers/useMenuHelper';

type IUseAccess = {
  /**
   * Determines whether the current user has access to create posts on behalf or docs on behalf
   *
   * @returns true if the current user has access, otherwise false.
   */
  isPostOnBehalfAvailable: () => boolean;

  /**
   * Determines whether the current user has access to view the list of subscribers and subscriptions in the user's profile.
   *
   * @param userId The ID of the user from whom we want to view the list of subscribers and subscriptions
   * @returns true if the current user has access, otherwise false.
   */
  showFollowersList: (userId: number) => boolean;

  /**
   * Checks whether the current user has access to do specific action based on his role and value of network setting.
   *
   * @param {AccessByRoleEnum} networkSettingValue - Access value of network setting.
   * @returns {boolean} Returns `true` if the current user meets the required access level, otherwise `false`.
   *
   * The access is determined based on the following rules:
   * `AccessByRoleEnum.Off`: Access is disabled for all users.
   * `AccessByRoleEnum.AllUsers`: Access is granted to all users with a role equal to or higher than `UserRoleEnum.User`.
   * `AccessByRoleEnum.ModeratorOrHigher`: Access is granted to users with a role equal to or higher than `UserRoleEnum.Moderator`.
   * `AccessByRoleEnum.AdminOrHigher`: Access is granted to users with a role equal to or higher than `UserRoleEnum.Administrator`.
   * `AccessByRoleEnum.SuperAdminOrHigher`: Access is granted to users with a role equal to or higher than `UserRoleEnum.SuperAdministrator`.
   *
   * If no matching access level is found, the function defaults to returning `false`.
   */
  checkNetworkSettingAccess: (networkSettingValue: AccessByRoleEnum) => boolean;

  /**
   * Determines whether the current user has access to view the user requests in the group.
   *
   * @param groupData - The group data
   * @returns true if the current user has access, otherwise false.
   */
  showUserRequestsInGroup: (groupData: GroupModel) => boolean;

  /**
   * Check to show the create new document button
   *
   * @param groupData - The group data
   * @param allowPostToFeed  - The parameter in the network settings responsible for allowing you to post to the feed
   * @returns true if Is it possible to create a documents
   */
  canCreateDocument: (groupData: GroupModel | null, allowPostToFeed: boolean) => boolean;

  /**
   * Check to show the edit file tags button
   *
   * @param file - The file data
   * @returns true if Is it possible to edit file tags
   */
  canEditFileTags: (file: FileModel | null) => boolean;

  /**
   * Check to show the edit wiki tags button
   *
   * @param wiki - The wiki data
   * @returns true if Is it possible to edit wiki tags
   */
  canEditWikiTags: (wiki: WikiModel | null) => boolean;

  /**
   * Custom share access for BKG Network, temporary implementation until backend fix is ready
   * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/2298
   * @param shareAccessFromEnum indicates whether access enum from back includes 3
   * */
  canShareForBKG: (shareAccessFromEnum: boolean) => boolean;

  /**
   * Custom rule of showing follow lists BKG Network
   * @param defaultSetting indicates showFollowLists setting from current network settings
   * */
  showFollowListsForBKG: (defaultSetting: boolean) => boolean;
};

export const useAccess = (): IUseAccess => {
  //#region Store
  const userStore = useUserStore();
  const networkStore = useNetworkStore();
  //#endregion

  //#region Methods

  const isPostOnBehalfAvailable = (): boolean => {
    const currentUserRoleId = userStore.current?.roleId ?? 0;
    const networkAllowPostOnBehalf = networkStore.settings?.allowPostOnBehalf ?? false;

    return networkAllowPostOnBehalf && currentUserRoleId >= UserRoleEnum.Moderator;
  };

  const showFollowersList = (userId: number): boolean => {
    const currentUserId = userStore.getId ?? null;
    const currentUserRoleId = userStore.current?.roleId ?? 0;
    const networkShowIFollow = networkStore.settings?.showIFollow ?? false;
    const networkShowFollowLists = networkStore.settings?.showFollowLists ?? false;

    //NOTE: If the user is viewing their profile - grant access
    if (currentUserId === userId) return true;

    //NOTE: If the user is an administrator - grant access
    if (currentUserRoleId >= UserRoleEnum.Administrator) return true;

    //NOTE: If the user is an external user - deny access
    if (currentUserRoleId <= UserRoleEnum.ExternalGroupUser) return false;

    //NOTE: If the user has access to the network settings - grant access
    if (networkShowFollowLists) return networkShowIFollow;

    return false;
  };

  const checkNetworkSettingAccess = (networkSettingValue: AccessByRoleEnum): boolean => {
    const currentUserRoleId: UserRoleEnum = userStore.current?.roleId ?? 0;

    const accessLevels = {
      [AccessByRoleEnum.Off]: false,
      [AccessByRoleEnum.AllUsers]: currentUserRoleId >= UserRoleEnum.User,
      [AccessByRoleEnum.ModeratorOrHigher]: currentUserRoleId >= UserRoleEnum.Moderator,
      [AccessByRoleEnum.AdminOrHigher]: currentUserRoleId >= UserRoleEnum.Administrator,
      [AccessByRoleEnum.SuperAdminOrHigher]: currentUserRoleId >= UserRoleEnum.SuperAdministrator,
    };
    return accessLevels[networkSettingValue] ?? false;
  };

  const showUserRequestsInGroup = (groupData: GroupModel): boolean => {
    const groupsHelper = useGroups();

    //NOTE: Check if the user is joined to the group
    const currentUserIsJoined = groupsHelper.isUserJoined(groupData);

    //NOTE: Check if the user is a group admin
    const currentUserIsGroupAdmin = groupsHelper.isGroupAdmin(Number(groupData.id));

    //NOTE: Check if the group has invite modes
    const inviteModesCount = groupsHelper.getInviteModesToGroup(groupData).length;

    return currentUserIsJoined && currentUserIsGroupAdmin && inviteModesCount > 0;
  };

  const canCreateDocument = (groupData: GroupModel | null, allowPostToFeed: boolean): boolean => {
    const accessToCreateInGroup = groupData ? groupData.accessType >= GroupsAccessEnum.Member : false;

    /** If the group is not specified, then we only check the network settings */
    return groupData ? accessToCreateInGroup : allowPostToFeed;
  };

  const canEditFileTags = (file: FileModel | null): boolean => {
    if (!file) return false;
    if (file.mimeType.startsWith('image/') || file.mimeType.startsWith('video/'))
      return file?.access.includes(ActionAccessEnum.NewVersion);
    return file?.access.includes(ActionAccessEnum.Edit);
  };

  const canEditWikiTags = (wiki: WikiModel | null): boolean => {
    if (!wiki) return false;
    return wiki?.access.includes(ActionAccessEnum.Edit);
  };

  const canShareForBKG = (shareAccessFromEnum: boolean) => {
    const currentCompanyId: string = useAuthStore().companyRowId;
    const isBKGNetwork: boolean = useMenu().companiesList['BKG'] === currentCompanyId;
    const currentUserRoleId: number = useUserStore().current?.roleId ?? UserRoleEnum.User;
    return isBKGNetwork ? currentUserRoleId >= UserRoleEnum.Moderator : shareAccessFromEnum;
  };

  const showFollowListsForBKG = (defaultSetting: boolean) => {
    const currentCompanyId: string = useAuthStore().companyRowId;
    const isBKGNetwork: boolean = useMenu().companiesList['BKG'] === currentCompanyId;
    const currentUserRoleId: number = useUserStore().current?.roleId ?? UserRoleEnum.User;
    return isBKGNetwork ? currentUserRoleId >= UserRoleEnum.Moderator : defaultSetting;
  };

  //#endregion

  return {
    isPostOnBehalfAvailable,
    showFollowersList,
    showUserRequestsInGroup,
    checkNetworkSettingAccess,
    canCreateDocument,
    canEditFileTags,
    canEditWikiTags,
    canShareForBKG,
    showFollowListsForBKG,
  };
};
