import { format, addMinutes, parse, parseISO, addDays, isSameYear, isSameDay, fromUnixTime, Locale } from 'date-fns';
import { de, enUS, cs, es, fr, ru } from 'date-fns/locale';
import type { Date11, Date16, DateISO, DateISOUTC, Date23UTC, UnionDateType } from '@/types';
import { isDate11, isDate16, isDateISO, isDateISOUTC, isDate23UTC } from './guards';
import { toZonedTime } from 'date-fns-tz';
import { DateFormatEnum } from '@/enums';
import { useI18n } from '@/i18n';
import { useToasts } from '@/helpers/useToastsHelper';
import { useAppStore } from '@/store';

/**
 * @link https://github.com/date-fns/date-fns/blob/6c70ac6d073ebe869e42795f5e71dfecf5abbea0/src/format/index.ts#L37
 *
 * For parsing
 * @use {@link parseDate16}, {@link parseDate23}, {@link parseDateIsoUtc}.
 *
 * For formatting
 * @use {@link formatDate16}, {@link formatDate23}, {@link formatDateIso}.
 *
 * For converting
 * @use
 * - {@link convertStringToIsoUtc}
 * - {@link convertDate16ToDate23}
 * - {@link convertDate16ToIso}
 * - {@link convertDate23ToDate16}
 * - {@link convertDate23ToIsoUtc}
 * - {@link convertIsoToDate16}
 * - {@link convertIsoUtcToDate23}.
 *
 * For adding @use
 * - {@link addMinutesToDate}
 * - {@link addDaysToDate}
 */

/**
 * @private
 * @readonly
 */
const _DATE11 = 'dd MMM yyyy';

/**
 * @private
 * @readonly
 */
const _DATE16 = 'yyyy-MM-dd HH:mm';

/**
 * @private
 * @readonly
 */
const _DATE23 = 'yyyy-MM-dd HH:mm:ss zzz';

/**
 * @private
 * @readonly
 */
const _DATE_ISO_UTC = "yyyy-MM-dd'T'HH:mm:ss'Z'";

/**
 * @private
 * @readonly
 */
const _DATE_ISO = "yyyy-MM-dd'T'HH:mm:ss";

//#region Parsers - String => Date
export function parseDate11(value: UnionDateType): Date {
  if (!isDate11(value)) {
    throw new Error('String is not in the format dd MMM yyyy');
  }
  return parse(value, _DATE11, new Date());
}

export function parseDate16(value: UnionDateType): Date {
  if (!isDate16(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return parse(value, _DATE16, new Date());
}

export function parseDate23(value: UnionDateType): Date {
  if (!isDate23UTC(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return parse(value, _DATE23, new Date());
}

export function parseDateIsoUtc(value: UnionDateType): Date {
  if (!isDateISOUTC(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return parse(value, _DATE_ISO_UTC, new Date());
}

export function parseDateIso(value: UnionDateType): Date {
  if (!isDateISO(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ss');
  }
  return parse(value, _DATE_ISO, new Date());
}
//#endregion

//#region Formatters - Date => String
export function formatDate11(value: Date): Date11 {
  const formatted = format(value, _DATE11);
  if (!isDate11(formatted)) {
    throw new Error('String is not in the format dd MMM yyyy');
  }
  return formatted as Date11;
}

export function formatDate16(value: Date): Date16 {
  const formatted = format(value, _DATE16);
  if (!isDate16(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return formatted as Date16;
}

export function formatDateIso(value: Date): DateISO {
  const formatted = format(value, _DATE_ISO);
  if (!isDateISO(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ss');
  }
  return formatted as DateISO;
}

export function formatDateIsoUtc(value: Date): DateISOUTC {
  /**
   * otherwise function just formats local time and adds Z
   * we are sum this values because value.getTimezoneOffset() could be negative
   *
   * https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/Date/getTimezoneOffset
   * Возвращает смещение часового пояса, являющееся разностью в минутах между временем UTC и местным временем.
   * Обратите внимание, что это значит, что смещение будет положительным для местного часового пояса, находящегося западнее часового пояса UTC и отрицательным — восточнее.
   * Например, если ваш часовой пояс равен UTC+10 (австралийское восточное поясное время), будет возвращено значение -600.
   * Наличие летнего и зимнего времени не даёт этому смещению быть постоянным, даже в пределах одного часового пояса.
   * */
  const utcDate = new Date(value.getTime() + value.getTimezoneOffset() * 60000);
  const formatted = format(utcDate, "yyyy-MM-dd'T'HH:mm:ss'Z'");
  if (!isDateISOUTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return formatted as DateISOUTC;
}

export function formatDate23(value: Date): Date23UTC {
  const formatted = format(value, _DATE23);
  if (!isDate23UTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return formatted as Date23UTC;
}
//#endregion

//#region Converters - String => String
/**
 * Takes any string and returns a Date24ISO (ISO 8601) string.
 * 1) Checks if it's already Date24ISO => returns as-is.
 * 2) If Date16 or Date23 => parse => reformat as ISO.
 * 3) Otherwise, tries parseISO(...). If invalid => throw Error.
 */
export function convertStringToIsoUtc(value: string): DateISOUTC {
  // 1) Already ISO?
  if (isDateISOUTC(value)) {
    return value; // no conversion needed
  }

  // 2) Known 16-char or 23-char format?
  if (isDate16(value)) {
    return formatDateIsoUtc(parseDate16(value));
  }

  // 3) Known 23-char format?
  if (isDate23UTC(value)) {
    return formatDateIsoUtc(parseDate23(value));
  }

  // 4) Fall back to parseISO(...) for unknown cases
  const parsed = parseISO(value);
  if (isNaN(parsed.getTime())) {
    throw new Error(`Unsupported date format: "${value}"`);
  }

  return formatDateIsoUtc(parsed);
}

/**
 * Takes any string and returns a DateISO (ISO 8601) string in local timezone.
 * 1) Checks if it's already DateISO => returns as-is.
 * 2) If Date16 or Date23 => parse => reformat as ISO.
 * 3) Otherwise, tries parseISO(...). If invalid => throw Error.
 */
export function convertStringToIso(value: string): DateISO {
  // 1) Already ISO?
  if (isDateISO(value)) {
    return value; // no conversion needed
  }

  // 2) Known 16-char or 23-char format?
  if (isDate16(value)) {
    return formatDateIso(parseDate16(value));
  }

  // 3) Known 23-char format?
  if (isDate23UTC(value)) {
    return formatDateIso(parseDate23(value));
  }

  // 4) Fall back to parseISO(...) for unknown cases
  const parsed = parseISO(value);
  if (isNaN(parsed.getTime())) {
    throw new Error(`Unsupported date format: "${value}"`);
  }

  return formatDateIso(parsed);
}

export function convertLocalTimezoneToIsoUtc(value: UnionDateType): DateISOUTC {
  if (!isDateISO(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ss');
  }
  const formatted = formatDateIsoUtc(parseDateIso(value));
  if (!isDateISOUTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return formatted as DateISOUTC;
}

export function convertIsoUtcToLocalTimezone(value: UnionDateType): DateISO {
  if (!isDateISOUTC(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const zonedDate = toZonedTime(value, userTimeZone);
  return formatDateIso(zonedDate);
}

export function convertDate16ToIsoUtc(value: UnionDateType): DateISOUTC {
  if (!isDate16(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  const formatted = formatDateIsoUtc(parseDate16(value));
  if (!isDateISOUTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return formatted as DateISOUTC;
}

export function convertDate16ToIso(value: UnionDateType): DateISO {
  if (!isDate16(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  const formatted = format(parseDate16(value), _DATE_ISO);
  if (!isDateISO(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ss');
  }
  return formatted as DateISO;
}

export function convertDate16ToDate23(value: UnionDateType): Date23UTC {
  if (!isDate16(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  const formatted = format(parseDate16(value), _DATE23);
  if (!isDate23UTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return formatted as Date23UTC;
}

export function convertDate23ToDate16(value: UnionDateType): Date16 {
  if (!isDate23UTC(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  const formatted = format(parseDate23(value), _DATE16);
  if (!isDate16(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return formatted as Date16;
}

export function convertDate23ToIsoUtc(value: UnionDateType): DateISOUTC {
  if (!isDate23UTC(value)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  const formatted = formatDateIsoUtc(parseDate23(value));
  if (!isDateISOUTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  return formatted as DateISOUTC;
}

export function convertIsoUtcToDate11(value: UnionDateType): Date11 {
  if (!isDateISOUTC(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  const formatted = format(parseDateIsoUtc(value), _DATE11);
  if (!isDate11(formatted)) {
    throw new Error('String is not in the format dd MMM yyyy');
  }
  return formatted as Date11;
}

export function convertIsoToDate11(value: UnionDateType): Date11 {
  if (!isDateISO(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ss');
  }
  const formatted = format(parseDateIso(value), _DATE11);
  if (!isDate11(formatted)) {
    throw new Error('String is not in the format dd MMM yyyy');
  }
  return formatted as Date11;
}

export function convertIsoToDate16(value: UnionDateType): Date16 {
  if (!isDateISO(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ss');
  }
  const formatted = format(parseDateIso(value), _DATE16);
  if (!isDate16(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm');
  }
  return formatted as Date16;
}

export function convertIsoUtcToDate23(value: UnionDateType): Date23UTC {
  if (!isDateISOUTC(value)) {
    throw new Error('String is not in the format yyyy-MM-ddTHH:mm:ssZ');
  }
  const formatted = format(parseDateIsoUtc(value), _DATE23);
  if (!isDate23UTC(formatted)) {
    throw new Error('String is not in the format yyyy-MM-dd HH:mm:ss zzz');
  }
  return formatted as Date23UTC;
}
//#endregion

export function _getDateFormat<T extends UnionDateType>(value: T): DateFormatEnum {
  switch (true) {
    case isDate11(value):
      return DateFormatEnum.Date11;
    case isDate16(value):
      return DateFormatEnum.Date16;
    case isDate23UTC(value):
      return DateFormatEnum.Date23;
    case isDateISO(value):
      return DateFormatEnum.DateIso;
    case isDateISOUTC(value):
      return DateFormatEnum.DateIsoUtc;
    default:
      throw new Error(`Unsupported date format: "${value}"`);
  }
}

/**
 * Use this function as entry point to parse date
 *
 * @param value   expects date value which type is one from described in UnionDateType
 */
export function parser(value: UnionDateType): Date | undefined {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  const dateParsers: Record<DateFormatEnum, (date: UnionDateType) => Date | undefined> = {
    [DateFormatEnum.Date11]: () => undefined,
    [DateFormatEnum.Date16]: parseDate16,
    [DateFormatEnum.Date23]: parseDate23,
    [DateFormatEnum.DateIso]: parseDateIso,
    [DateFormatEnum.DateIsoUtc]: parseDateIsoUtc,
  };

  const handler = dateParsers[_getDateFormat(value)];

  if (!handler) {
    console.warn('No parser found for date: ', value);
    return undefined;
  }

  try {
    return handler(value);
  } catch (error) {
    console.error(`[ERROR] Error executing parsing ${value}:`, error);
    showSonnerToast(t('errorResponse'), false);
  }
}

/**
 * Use this function to format Date object
 *
 * @param value   expects js Date object
 * @param format  expects one of DateFormatEnum formats
 * @returns       formatted date which type is one from described in UnionDateType or undefined if formatter doesn't exist
 */
export function formatter(value: Date, format: DateFormatEnum): UnionDateType | undefined {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  const dateFormatters: Record<DateFormatEnum, (date: Date) => UnionDateType | undefined> = {
    [DateFormatEnum.Date11]: formatDate11,
    [DateFormatEnum.Date16]: formatDate16,
    [DateFormatEnum.Date23]: formatDate23,
    [DateFormatEnum.DateIso]: formatDateIso,
    [DateFormatEnum.DateIsoUtc]: formatDateIsoUtc,
  };

  const handler = dateFormatters[format];

  if (!handler) {
    console.warn('No formatter found for date: ', value);
    return undefined;
  }

  try {
    return handler(value);
  } catch (error) {
    console.error(`[ERROR] Error executing formatting ${value}:`, error);
    showSonnerToast(t('errorResponse'), false);
  }
}

/** @todo Add other variations of converters to dateConverters*/
/**
 * Use this function as entry point to convert date from one format to another
 *
 * @param inputType   expects date value which type is one from described in UnionDateType
 * @param outputType  optional parameter of desired format to convert date, without it function by default converts to IsoUtc
 * @returns       converted date which type is one from described in UnionDateType or undefined if converter doesn't exist
 */
export function converter(inputType: UnionDateType, outputType?: DateFormatEnum): UnionDateType | undefined {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  const dateConverters: Record<
    DateFormatEnum,
    (date: UnionDateType, format?: DateFormatEnum) => UnionDateType | undefined
  > = {
    [DateFormatEnum.Date11]: () => undefined,
    [DateFormatEnum.Date16]: (date, format) => {
      switch (format) {
        case DateFormatEnum.DateIso:
          return convertDate16ToIso(date);
        case DateFormatEnum.DateIsoUtc:
          return convertDate16ToIsoUtc(date);
        case DateFormatEnum.Date23:
          return convertDate16ToDate23(date);
        default:
          return convertDate16ToIsoUtc(date);
      }
    },
    [DateFormatEnum.Date23]: (date, format) => {
      switch (format) {
        case DateFormatEnum.Date16:
          return convertDate23ToDate16(date);
        case DateFormatEnum.DateIsoUtc:
          return convertDate23ToIsoUtc(date);
        default:
          return convertDate23ToIsoUtc(date);
      }
    },
    [DateFormatEnum.DateIso]: (date, format) => {
      switch (format) {
        case DateFormatEnum.Date16:
          return convertIsoToDate16(date);
        case DateFormatEnum.DateIsoUtc:
          return convertLocalTimezoneToIsoUtc(date);
        default:
          return convertLocalTimezoneToIsoUtc(date);
      }
    },
    [DateFormatEnum.DateIsoUtc]: (date, format) => {
      switch (format) {
        case DateFormatEnum.Date11:
          return convertIsoUtcToDate11(date);
        case DateFormatEnum.Date23:
          return convertIsoUtcToDate23(date);
        case DateFormatEnum.DateIso:
          return convertIsoUtcToLocalTimezone(date);
        default:
          return convertIsoUtcToLocalTimezone(date);
      }
    },
  };

  const handler = dateConverters[_getDateFormat(inputType)];

  if (!handler) {
    console.warn('No parser found for date: ', inputType);
    return undefined;
  }

  try {
    return handler(inputType, outputType);
  } catch (error) {
    console.error(`[ERROR] Error executing parsing ${inputType}:`, error);
    showSonnerToast(t('errorResponse'), false);
  }
}

/**
 * Accepts one of three date-string formats, adds minutes,
 * then returns the same date type and format.
 *
 * @param value   The date in one of three formats
 * @param minutes How many minutes to add
 * @returns       The same date format with minutes added
 */
export function addMinutesToDate<T extends UnionDateType>(value: T, minutes: number): T {
  if (isDate11(value)) {
    const date = parseDate11(value);
    const newDate = addMinutes(date, minutes);
    return formatDate11(newDate) as T;
  }

  if (isDate16(value)) {
    const date = parseDate16(value);
    const newDate = addMinutes(date, minutes);
    return formatDate16(newDate) as T;
  }

  if (isDateISO(value)) {
    const date = parseDateIso(value);
    const newDate = addMinutes(date, minutes);
    return formatDateIso(newDate) as T;
  }

  if (isDateISOUTC(value)) {
    const date = parseDateIsoUtc(value);
    const newDate = addMinutes(date, minutes);
    return formatDateIsoUtc(newDate) as T;
  }

  if (isDate23UTC(value)) {
    const date = parseDate23(value);
    const newDate = addMinutes(date, minutes);
    return formatDate23(newDate) as T;
  }

  throw new Error('Invalid date format');
}

/**
 * Convert from timestamp to desired format string
 *
 * @param timestamp - Timestamp in milliseconds
 * @param formatStr - Desired format string
 * {@link https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table}
 * @returns Formatted timestamp
 *
 */
export function convertTimestamp(timestamp: number, formatStr: string): string {
  let date: Date;
  const asString = String(timestamp);

  // If it looks like seconds (10 digits), use fromUnixTime
  // Otherwise, use new Date(timestamp)
  if (asString.length === 10) {
    date = fromUnixTime(timestamp);
  } else {
    date = new Date(timestamp);
  }

  //NOTE: VITE_APP_SUPPORT_LOCALES = de | en | cs | es | fr;
  const map: Record<string, Locale> = {
    de: de,
    en: enUS,
    cs: cs,
    es: es,
    fr: fr,
    ru: ru,
  };
  const options = { locale: map[useAppStore().locale] };

  return format(date, formatStr, options);
}

/**
 * Accepts one of three date-string formats, adds days,
 * then returns the same date type and format.
 *
 * @param value The date in one of three formats
 * @param days  How many days to add
 * @returns     The same date format with minutes added
 */
export function addDaysDate<T extends UnionDateType>(value: T, days: number): T {
  if (isDate11(value)) {
    const date = parseDate11(value);
    const newDate = addDays(date, days);
    return formatDate11(newDate) as T;
  }

  if (isDate16(value)) {
    const date = parseDate16(value);
    const newDate = addDays(date, days);
    return formatDate16(newDate) as T;
  }

  if (isDateISOUTC(value)) {
    const date = parseDateIsoUtc(value);
    const newDate = addDays(date, days);
    return formatDateIsoUtc(newDate) as T;
  }

  if (isDateISO(value)) {
    const date = parseDateIso(value);
    const newDate = addDays(date, days);
    return formatDateIso(newDate) as T;
  }

  if (isDate23UTC(value)) {
    const date = parseDate23(value);
    const newDate = addDays(date, days);
    return formatDate23(newDate) as T;
  }

  throw new Error('Invalid date format');
}

/** NOTE: Checking that the year of the two dates match */
export function yearsIsEqual(firstDate: DateISOUTC, secondDate: DateISOUTC): boolean {
  return isSameYear(parseDateIsoUtc(firstDate), parseDateIsoUtc(secondDate));
}

/** NOTE: Checking that the day of the two dates match */
export function daysIsEqual(firstDate: DateISO, secondDate: DateISO): boolean {
  const firstDateObject = new Date(firstDate);
  const secondDateObject = new Date(secondDate);
  return isSameDay(firstDateObject, secondDateObject);
}
