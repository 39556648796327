import type { ComponentPublicInstance } from 'vue';
import { WikiJsonTextEnum } from '@/enums/wiki';
import type { AdvancedWikiContentModel } from '@/types';
import { useRouter } from 'vue-router';
import slugifyLib from 'slugify';

interface IUseWikiNavigationProps {
  navigateToSection: (sectionId: string, shouldScroll?: boolean) => Promise<void>;
}

type IUseWikiNavigation = {
  /**
   * Sets up event listeners for navigation links in the table of contents
   * Handles click events on links to properly navigate to the target section
   * @param el Optional element to set up if different from the one passed during initialization
   */
  parseTOC: (el: ComponentPublicInstance) => void;

  /**
   * Handles navigation to the section specified in the URL fragment
   * Called on page load to automatically navigate to the section in the URL
   */
  handleFragmentNavigation: () => Promise<void>;
};

export function useWikiNavigation(props: IUseWikiNavigationProps): IUseWikiNavigation {
  const { navigateToSection } = props;
  const router = useRouter();

  function parseTOC(el: ComponentPublicInstance): void {
    if (!el.$el) {
      console.error('Failed to get navigation: no element found');
      return;
    }

    try {
      const navDomElement = el.$el;

      // Find <ul> element with class wiki-toc
      const tocElement = navDomElement.querySelector('.wiki-toc');
      if (!tocElement) {
        console.error('Failed to get navigation: no wiki-toc element found');
        return;
      }

      // Find all <a> elements inside <li> elements inside <ul>
      const links = tocElement.querySelectorAll('li a');
      links.forEach((element: HTMLLinkElement) => {
        const href = element.getAttribute('href');
        console.log('[TOC] Removing event listener from link:', href);

        // Clone to remove existing listeners
        const oldElement = element.cloneNode(true);
        if (element.parentNode) {
          element.parentNode.replaceChild(oldElement, element);
        }
      });

      // Add fresh event listeners
      navDomElement.querySelectorAll('.wiki-toc li a').forEach((element: HTMLLinkElement) => {
        const href = element.getAttribute('href');
        if (!href || !href.startsWith('#')) {
          console.warn('[TOC] No href found for link');
          return;
        }
        console.log('[TOC] Added event listener to link:', href);

        element.addEventListener('click', (event) => {
          event.preventDefault();
          event.stopPropagation();

          // Use the full href (which already includes the #)
          // Appending hash to the current route
          const currentRoute = router.currentRoute.value;
          router.replace({
            path: currentRoute.path,
            query: currentRoute.query,
            hash: href, // Use the original href which already includes the # symbol
          });
        });
      });
    } catch (e) {
      console.error('Error setting up navigation:', e);
    }
  }

  async function handleFragmentNavigation(): Promise<void> {
    const fragment = window.location.hash;
    if (!fragment) return;

    // Decode the URL-encoded fragment to handle non-ASCII characters properly
    const sectionId = decodeURIComponent(fragment.substring(1));

    // Give the browser a moment to render the content before navigating
    setTimeout(async () => {
      try {
        await navigateToSection(sectionId, true);
      } catch (error) {
        console.error('Error in fragment navigation:', error);
      }
    }, 100);
  }

  return {
    parseTOC,
    handleFragmentNavigation,
  };
}

/**
 * Convert a string to a URL-friendly slug
 * Uses the slugify library to handle special characters across multiple languages
 */
export function slugify(text: string): string {
  const original = text;

  const options = {
    replacement: '-', // replace spaces with hyphens
    lower: true, // convert to lowercase
    strict: true, // strip special characters except replacement
    trim: true, // trim leading and trailing replacement chars
  };

  const slug = slugifyLib(text, options);

  // Testing if the slug is empty or only contains dashes
  // If so, create a hash from the original text
  // Using charCodeAt to ensure uniqueness
  if (!slug || /^-*$/.test(slug)) {
    const hash = original.split('').reduce((acc, char) => {
      return acc + char.charCodeAt(0);
    }, 0);
    return `section-${hash}`;
  }

  return slug;
}

/**
 * Generates an HTML string for the table of contents based on wiki sections
 * Creates links to all available sections in the wiki
 */
export function generateTableOfContentsHtml(wikiContent: AdvancedWikiContentModel): string {
  let tocHtml = '<ul class="wiki-toc">';

  const headSlug = slugify(wikiContent.head.name);
  tocHtml += `<li><a [data-hash]="section-${WikiJsonTextEnum.Head}" href="#${headSlug}" [data-original]="${wikiContent.head.name}">${wikiContent.head.name}</a></li>`;

  const contentSlug = slugify(wikiContent.content.name);
  tocHtml += `<li><a [data-hash]="section-${WikiJsonTextEnum.Content}" href="#${contentSlug}" [data-original]="${wikiContent.content.name}">${wikiContent.content.name}</a></li>`;

  wikiContent.body.forEach((section, index) => {
    const sectionSlug = slugify(section.name);
    tocHtml += `<li><a [data-hash]="section-${WikiJsonTextEnum.Body}-${index}" href="#${sectionSlug}" [data-original]="${section.name}">${section.name}</a></li>`;
  });

  const participantsSlug = slugify(wikiContent.participants.name);
  tocHtml += `<li><a [data-hash]="section-${WikiJsonTextEnum.Participants}" href="#${participantsSlug}" [data-original]="${wikiContent.participants.name}">${wikiContent.participants.name}</a></li>`;

  tocHtml += '</ul>';

  return tocHtml;
}
