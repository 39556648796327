import { Capacitor } from '@capacitor/core';
import { isGenericDomain, isGenericHost, isLiveHost, isLocalhost } from '@/services/checkers';

/**
 * Returns the appropriate service host for the current environment.
 * @returns The host name used for API requests.
 */
export function getServiceHost(): string {
  const { VITE_APP_HOST } = import.meta.env;
  const { hostname, port } = window.location;
  const isNative = Capacitor.isNativePlatform();

  if (isNative) {
    return isGenericHost() || isLiveHost() ? `api.${VITE_APP_HOST}` : VITE_APP_HOST;
  }

  /**
   * If running locally with custom company host
   * we override default localhost behavior
   * to be able to run with custom company host
   * @example if (isLocalhost() && port === '8210') return 'hamstersnet.dev.communex.app';
   */
  if (isLocalhost() && port === '8210') return 'dev.dev.communex.app';

  return isGenericDomain() || isLocalhost() ? `api.${VITE_APP_HOST}` : hostname;
}
