<template>
  <transition name="slide" mode="out-in" :appear="useAppear">
    <ion-router-outlet id="main-content" :key="renderFlag"></ion-router-outlet>
  </transition>
</template>

<script lang="ts" setup>
import { IonRouterOutlet } from '@ionic/vue';
import type { ComputedRef } from 'vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

import { ROUTES_NAME } from '@/router';

const route = useRoute();
const useAppear: ComputedRef<boolean> = computed(() => route.matched.length === 1);

const renderFlag: ComputedRef<string> = computed(() =>
  route.name ===
  // ROUTES_NAME.WIKI_CREATE ||
  // ROUTES_NAME.WIKI_EDIT ||
  // ROUTES_NAME.WIKI_COMPARE ||
  (ROUTES_NAME.LOADING || ROUTES_NAME.NOT_FOUND || ROUTES_NAME.DOCS)
    ? Date.now().toString()
    : ''
);
</script>
