import { isPlatform } from '@ionic/vue';
import { computed, type ComputedRef, type Directive } from 'vue';

import { useAppStore } from '@/store';

const anyLongPressIsActive: ComputedRef<boolean> = computed(() => useAppStore().anyLongPressIsActive);

const isMobile = isPlatform('android') || isPlatform('ios');

type CallBack = (event: TouchEvent) => void;

export interface Input extends HTMLElement {
  _longPressTimer: number | null;
  _longPressDelay: number;
  _longPressIsStart: boolean;
  _longPressCallBack: CallBack;
  _longPressStart: CallBack;
  _longPressStop: CallBack;
  _longPressMove: CallBack;
  _longPressDestroy: () => void;
}

class VanillaLongPress {
  private readonly element: Input;

  constructor(element: HTMLElement, cb: CallBack, delay = 1300) {
    this.element = element as Input;
    this.element._longPressCallBack = cb;
    this.element._longPressStart = this.start;
    this.element._longPressStop = this.stop;
    this.element._longPressMove = this.move;
    this.element._longPressDelay = delay;
    this.element._longPressDestroy = this.destroy(this.element);
    this.element._longPressIsStart = false;
    this.element._longPressTimer = null;

    this.element.addEventListener('touchstart', this.element._longPressStart);
    this.element.addEventListener('touchend', this.element._longPressStop);
    this.element.addEventListener('touchmove', this.element._longPressMove);
  }

  private start(event: TouchEvent): void {
    const element = this as unknown as Input;

    if (element._longPressTimer === null && !element._longPressIsStart && !anyLongPressIsActive.value) {
      element._longPressIsStart = true;
      useAppStore().$patch({
        anyLongPressIsActive: true,
      });
      element._longPressTimer = window.setTimeout(() => {
        element._longPressCallBack(event);
        element._longPressStop(event);
      }, element._longPressDelay);
    }
  }

  private stop(): void {
    const element = this as unknown as Input;

    if (element._longPressTimer) {
      clearTimeout(element._longPressTimer);
    }
    element._longPressTimer = null;
    element._longPressIsStart = false;
    useAppStore().$patch({
      anyLongPressIsActive: false,
    });
  }

  private move(event: TouchEvent): void {
    const element = this as unknown as Input;

    if (element._longPressIsStart) {
      if (element._longPressTimer) {
        clearTimeout(element._longPressTimer);
      }
      element._longPressTimer = window.setTimeout(() => {
        element._longPressCallBack(event);
        element._longPressStop(event);
      }, element._longPressDelay);
    }
  }

  private destroy(element: HTMLElement): () => void {
    return () => {
      element.removeEventListener('touchstart', this.start);
      element.removeEventListener('touchend', this.stop);
      element.removeEventListener('touchmove', this.move);
    };
  }
}

function mounted(el: HTMLElement, binding: { value: () => void }): void {
  if (isMobile) {
    new VanillaLongPress(el, binding.value, 500);
  }
}
function beforeUnmount(el: Input): void {
  if (isMobile) {
    el._longPressDestroy();
  }
}

export const longPress: Directive = {
  mounted,
  beforeUnmount,
};

export default longPress;
