import { Browser } from '@capacitor/browser';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import type { OverlayEventDetail } from '@ionic/core';
import { getPlatforms } from '@ionic/core';
import { actionSheetController, alertController, isPlatform, menuController } from '@ionic/vue';
import useVuelidate from '@vuelidate/core';
import { useDateFormat, useFavicon } from '@vueuse/core';
import type { CapacitorVideoPlayerPlugin } from 'capacitor-video-player';
import { CapacitorVideoPlayer } from 'capacitor-video-player';
import type { RouteLocationNormalizedLoaded, RouteRecordName } from 'vue-router';
import { openPostCreateContextSheet } from './actionSheetComponents';
import { openEntityShareModal, openPostCreateMobileModal, openPostCreateContextMenuModal } from './modalComponents';
import { WidgetFeedTypeEnum } from '@/enums';
import { WikiActionEnum } from '@/enums/wiki';
import {
  AccessByRoleEnum,
  AppAlertTypeEnum,
  AppCardsActionEnum,
  AppIconsEnum,
  CalendarViewModeEnum,
  CommentActionEnum,
  CountriesEnum,
  CountryCodesEnum,
  DefaultUserItemTypesEnum,
  DocumentTypeEnum,
  FeedContextMenuActionEnum,
  FeedFilterTypeEnum,
  FeedTypeEnum,
  GroupInvitationStatusEnum,
  GroupsAccessEnum,
  GroupsTypeEnum,
  IdeaTypeEnum,
  MessageActionEnum,
  PageToPostCreateEnum,
  PostMenuActionEnum,
  PostsFilterEnum,
  PostShareToEnum,
  PostTypeActionEnum,
  SendKeyEnum,
  ShareEntityType,
  ThemeAppEnum,
  UserAdminAccessLevel,
  UserRoleEnum,
} from '@/enums';
import {
  DateHelper,
  LightOrDark,
  useCustomPages,
  useGroups,
  useNetworks,
  useTaskManagement,
  useToasts,
  useSession,
  useUsers,
  extentedUrl,
} from '@/helpers';
import { isFileGuard, isGroupShortGuard, isPostGuard, isWikiGuard } from '@/helpers/guards';
import { useI18n } from '@/i18n';
import router, { ROUTES_NAME } from '@/router';
import {
  resetAllStores,
  useAppStore,
  useAuthStore,
  useChatStore,
  useGroupsStore,
  useMessengerStore,
  useNetworkStore,
  usePostStore,
  useProjectsStore,
  useUserStore,
} from '@/store';
import type {
  CommentMenuModel,
  FileModel,
  GroupModel,
  GroupShortModel,
  LinkApiViewModel,
  MessageMenuModel,
  MessageModel,
  PageModel,
  PostModel,
  PostModelWithLink,
  RequestAnnouncementCreateModel,
  RequestBadgeCreateModel,
  RequestEventCreateModel,
  RequestIdeaCreateModel,
  RequestMessageModel,
  RequestPollCreateModel,
  RequestStandardPostCreateModel,
  TabCategories,
  TaskManagementCreateTaskRequestModel,
  TopicColorModel,
  UserShortModel,
  WikiModel,
} from '@/types';
import { CellTemplateProp, HyperFunc, VNode } from '@revolist/vue3-datagrid';
import { Guid } from 'guid-typescript';
import { difference, isEmpty } from 'lodash';

export const appTimeout = 25;
export const appScrollTop = 250;
export const appDebounce = 250;

export const isDesktop =
  !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) &&
  !getPlatforms().includes('ipad');
export const isDesktopOrTablet =
  !/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent) ||
  isPlatform('ipad') ||
  isPlatform('tablet');
export const isNativeMobile = Capacitor.isNativePlatform();
export const isNativeAndroid = Capacitor.isNativePlatform() && isPlatform('android');
export const isNativeIos = Capacitor.isNativePlatform() && isPlatform('ios');
export const isWebMobile = isPlatform('mobileweb');
export const isAnyMobile = (isNativeMobile || isWebMobile) && !isDesktop;

export function getUtcNow(minus?: number): Date {
  const date = new Date();
  const nowUtc = Date.UTC(
    minus ? date.getUTCFullYear() - minus : date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(nowUtc);
}

export function getIsoNow(): string {
  return getUtcNow().toISOString();
}

export function getLetters(text: string | null): string {
  if (text === null) {
    return '';
  }

  const name = text.trim().split(' ');
  let letters;
  if (name[1]) {
    letters = name[0][0] + name[1][0];
  } else {
    letters = name[0][0];
  }

  return letters;
}

export function stringToBlob(b64Data: string, fileName: string, contentType: string, sliceSize = 512): File {
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new File(byteArrays, fileName, { type: contentType });
}

/**
 * Converts a Blob to a Base64 string.
 *
 * @param blob - The Blob to convert.
 * @returns A promise resolving to a Base64-encoded string.
 */
export const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    // Handle any errors during file reading
    reader.onerror = () => {
      reject(new Error(`Failed to read Blob. Error: ${reader.error?.message || 'Unknown error'}`));
    };

    // Resolve the promise when the read operation completes
    reader.onload = () => {
      if (reader.result) {
        resolve(reader.result as string);
      } else {
        reject(new Error('Reader result is null or undefined.'));
      }
    };

    // Begin reading the Blob as a Base64-encoded string
    reader.readAsDataURL(blob);
  });
};

export const getUniqueKey = (url?: string | null): string => {
  if (!url) {
    console.warn('[WARN] URL is empty');
    return '';
  }

  /*
   * Regex breakdown:
   * / - start of the regex,
   * \. - dot, [^/.] - any character except dot and slash,
   * + - one or more times,
   * $ - end of the string.
   * Removes dot (.) and characters after it, if no other dots (.) or slashes (/) follow.
   * Specifically targets file extensions at the string's end.
   */

  const path = new URL(url).pathname.replace(/\.[^/.]+$/, '');
  const key = path.substring(path.lastIndexOf('/') + 1);

  if (!key || key.trim() === '') {
    console.warn('[WARN] Key is empty');
    return '';
  }

  return key;
};

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Converts an array of bytes to a base64 string.
 *
 * @deprecated - since currently clientSecret can be defined beforehand
 * @todo - will in use when clientSecret will be dynamically generated
 */
export function uint8ToBase64(buffer: number[]): string {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function getExtension(nameFile: string): string | undefined {
  const re = /(?:\.([^.]+))?$/;

  const exec = re.exec(nameFile);

  if (exec !== null) return exec[1];

  return undefined;
}

export function getExtensionFromType(mimeType: string): string {
  const exec = mimeType.split('/');

  if (exec.length === 2) return exec[1];

  return '';
}

export const setVideoPlayer = async (): Promise<CapacitorVideoPlayerPlugin> => {
  return CapacitorVideoPlayer;
};

export const keyUpSend = (event: KeyboardEvent | MouseEvent): boolean => {
  const appStore = useAppStore();

  if (isNativeMobile) {
    return false;
  }

  const sendKey = appStore.getLocalSendKey;

  switch (sendKey) {
    case SendKeyEnum.Enter:
      if (event.ctrlKey || event.shiftKey) {
        return false;
      }
      event.preventDefault();
      return true;

    case SendKeyEnum.CtrlEnter:
      if (event.ctrlKey) {
        event.preventDefault();
        return true;
      }
      break;

    case SendKeyEnum.CmdEnter:
      if ((event as KeyboardEvent).metaKey) {
        event.preventDefault();
        return true;
      }
      break;

    default:
      return false;
  }

  return false;
};

export const feedTypeHelper = async (
  route: undefined | null | RouteRecordName,
  updateOnlyType?: boolean
): Promise<void> => {
  if (!useAuthStore().isAuth()) return;

  const currentUserId = useUserStore().current?.id ?? 0;
  const feedType = useAppStore().feedType;
  const feedFilter = useAppStore().feedFilter;

  if (route === ROUTES_NAME.FEED) {
    if (!updateOnlyType) {
      await usePostStore().announcementsWithoutRead();
    }

    switch (feedType) {
      case FeedFilterTypeEnum.Recommended:
        await usePostStore().postsRecommended();
        break;
      case FeedFilterTypeEnum.AllPublic:
        await usePostStore().postsAll(feedFilter);
        break;
      case FeedFilterTypeEnum.Announcement:
        await usePostStore().announcementsWithoutRead();
        break;
      case FeedFilterTypeEnum.Following:
        await usePostStore().postsFollowing(feedFilter);
        break;
      case FeedFilterTypeEnum.MyBookmarks:
        await usePostStore().postsBookmarks();
        break;
      case FeedFilterTypeEnum.AllMy:
        await usePostStore().postsFromUserId(currentUserId);
        break;
      case FeedFilterTypeEnum.Planned:
        await usePostStore().postsPlannedAll();
        break;

      default:
        await usePostStore().postsAll();
        break;
    }
  }
};

export const ideaTypeHelper = async (route: undefined | null | RouteRecordName): Promise<void> => {
  if (!useAuthStore().isAuth()) return;

  const ideaType = useAppStore().ideaType;

  if (route === ROUTES_NAME.IDEAS) {
    switch (ideaType) {
      case IdeaTypeEnum.New:
        await usePostStore().postsNewIdeas();
        break;
      case IdeaTypeEnum.InProgress:
        await usePostStore().postsInProgressIdeas();
        break;
      case IdeaTypeEnum.Realized:
        await usePostStore().postsRealizedIdeas();
        break;
      case IdeaTypeEnum.Archived:
        await usePostStore().postsArchivedIdeas();
        break;
      case IdeaTypeEnum.MostLiked:
        await usePostStore().postsMostLikedIdeas();
        break;
      case IdeaTypeEnum.My:
        await usePostStore().postsMyIdeas();
        break;

      default:
        await usePostStore().postsNewIdeas();
        break;
    }
  }
};

export const invitationStatusHelper = (status: GroupInvitationStatusEnum): string => {
  const { t } = useI18n();

  switch (status) {
    case GroupInvitationStatusEnum.Join:
      return t('invites.status.successJoined');
    case GroupInvitationStatusEnum.JoinedInGroup:
      return t('invites.status.alreadyJoined');
    case GroupInvitationStatusEnum.NotAllowed:
      return t('invites.status.notAllowed');
    case GroupInvitationStatusEnum.ReceiveInvitation:
      return t('invites.status.receivedToGroup');
    case GroupInvitationStatusEnum.SendError:
      return t('invites.status.error');
    case GroupInvitationStatusEnum.SendInvite:
      return t('invites.status.sent');
  }
};

export const htmlToText = (html: string | null | undefined): string => {
  if (!html) return '';

  const tempDivElement = document.createElement('div');
  tempDivElement.innerHTML = html;

  const links = tempDivElement.getElementsByTagName('a');
  for (const element of links) {
    if (element.href.startsWith('platform://')) {
      element.innerHTML = element.innerHTML.replace(/ /g, '_');
    }

    if (element.href.startsWith('platform://users') || element.href.startsWith('platform://groups')) {
      element.innerHTML = element.innerHTML.toLowerCase();
    } else if (!element.href.startsWith('platform://tags')) {
      element.innerHTML = element.href;
    }
  }

  return tempDivElement.textContent || tempDivElement.innerText || '';
};

export const convertHexToRgb = (str: string): string => {
  if (str !== null) {
    if (/^#([0-9a-f]{3}|[0-9a-f]{6})$/gi.test(str)) {
      let hex = str.slice(1);
      hex = hex.length == 3 ? hex.replace(/(.)/g, '$1$1') : hex;
      const rgb = parseInt(hex, 16);
      return [(rgb >> 16) & 255, (rgb >> 8) & 255, rgb & 255].join(',');
    }
  }
  return '0, 77, 96';
};

export const convertObjectToRGB = (obj: TopicColorModel | null): string | null => {
  /** Check if all keys exist and contain numeric values */
  const isValid = obj && typeof obj.red === 'number' && typeof obj.green === 'number' && typeof obj.blue === 'number';
  if (isValid) {
    /** Check if all numbers are in the valid range (0-255) */
    if (
      !isNaN(obj.red) &&
      !isNaN(obj.green) &&
      !isNaN(obj.blue) &&
      obj.red >= 0 &&
      obj.red <= 255 &&
      obj.green >= 0 &&
      obj.green <= 255 &&
      obj.blue >= 0 &&
      obj.blue <= 255
    ) {
      /** Format strings RGB */
      return `rgb(${obj.red}, ${obj.green}, ${obj.blue})`;
    }
  }
  /** If the object does not match */
  return null;
};

export const convertRGBToObject = (rgbString: string): TopicColorModel | null => {
  const regex = /(\d+), (\d+), (\d+)/;
  const matches = regex.exec(rgbString);

  if (matches) {
    const [, red, green, blue] = matches;
    const rgbObject = {
      red: parseInt(red, 10),
      green: parseInt(green, 10),
      blue: parseInt(blue, 10),
    };
    return rgbObject;
  }

  /** If the string RGB does not match the expected format */
  return null;
};

export const clearStorage = async (): Promise<void> => {
  /** Closing the menu if it exists */
  await menuController?.close();

  /** Clearing custom menu interval before stores reset */
  useSession().clearCustomMenuTimer();

  /** Resetting all stores except: authStore, appStore, userStore, networkStore */
  resetAllStores(false);

  /** If mobile platform - clear preferences storage */
  if (isNativeMobile) {
    await Preferences.clear();
  }

  /** If user is authenticated - try to setup app */
  if (useAuthStore().isAuth()) {
    await useSession().setupApp({
      newWebSocket: false,
      newPush: false,
    });
  }
};

export const updateApp = async (): Promise<void> => {
  let checkUpdate = true;

  const ret = await Preferences.get({ key: 'updateApp' });
  if (ret.value !== null) {
    try {
      const lastUpdateApp = new Date(ret.value);
      const now = new Date();
      now.setTime(now.getTime() - 8 * 60 * 60 * 1000);
      checkUpdate = lastUpdateApp < now;
    } catch (e) {
      console.error('[ERROR] Error while checking for updates', e);
    }
  }

  if (checkUpdate) {
    try {
      const result = await AppUpdate.getAppUpdateInfo();
      if (result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE) {
        const { t } = useI18n();
        const alert = await alertController.create({
          message: t('updateIsAvailable', {
            app: import.meta.env.VITE_APP_NAME,
          }),
          buttons: [
            {
              text: t('cancel'),
              role: 'cancel',
            },
            {
              text: t('confirm'),
              role: 'confirm',
            },
          ],
          id: AppAlertTypeEnum.UpdateApp,
        });
        await alert.present();

        alert.onDidDismiss().then(async (event: OverlayEventDetail) => {
          if (event.role !== undefined) {
            await Preferences.set({
              key: 'updateApp',
              value: new Date().toISOString(),
            });
          }
          if (event.role === 'confirm') {
            await AppUpdate.openAppStore();
          }
        });
      }
    } catch (e) {
      console.error('[ERROR] Error while checking for updates', e);
    }
  }
};

/**
 * Use this function to get post data from link to post
 * @returns {PostModelWithLink | undefined} - modified PostModel with link property or undefined
 */
export const getPostDataFromLink = async (url: string): Promise<PostModelWithLink | undefined> => {
  let post;
  const urlParts = url.split('/');
  const postId = +urlParts[urlParts.indexOf('post') + 1];
  post = usePostStore().getPostById(postId);
  if (!post.id) {
    post = await usePostStore().getPostFromId(postId);
  }
  return post ? { ...post, url } : undefined;
};

/**
 * Use this function to check if arrays with string values are different
 * @returns {boolean} - true if arrays are different
 */
export const compareStringArrays = (array1: string[], array2: string[]): boolean => {
  const diff1 = difference(array1, array2);
  const diff2 = difference(array2, array1);

  return !isEmpty(diff1) || !isEmpty(diff2);
};

/**
 * Use this function to find urls in input text
 */
export const findUrls = (text: string): string[] => {
  if (!text) {
    return [];
  }

  const pattern =
    '(https?:\\/\\/)?' + // optional protocol (http or https)
    '(localhost|' + // match localhost
    '(([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])\\.)+[a-zA-Z]{2,13}))' + // domain or sub-domain
    '(\\:\\d{1,5})?' + // optional port
    '(\\/[-a-zA-Z\\d%_.~+@:]*)*' + // path
    '(\\?[;&a-zA-Z\\d%_.~+=-@]*)?' + // query string
    '(\\#[-a-zA-Z\\d_]*)?'; // fragment locator

  const regex = new RegExp(pattern, 'g');

  const words = text.split(/\s+/);
  const matches: string[] = [];

  for (const word of words) {
    const match = word.match(regex);
    if (match) {
      matches.push(...match);
    }
  }

  return matches;
};

/**
 * Use this function to find urls to posts in input text
 */
export const findInternalPostUrls = (text: string): string[] => {
  if (!text) {
    return [];
  }

  const pattern =
    '(https?:\\/\\/)?' + // optional protocol (http or https)
    '(' + // begin group for domain or localhost
    'localhost(\\:\\d{1,5})?' + // match localhost with optional port
    '|' + // or
    '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*)\\.)+' + // sub-domain
    '(communex\\.app|baykonet\\.de|kiwi-bkg-online\\.de))' + // match domain communex.app, baykonet.de, or kiwi-bkg-online.de
    ')' + // end group for domain or localhost
    '\\/post\\/(\\d+)' + // mandatory /post/{id} with numeric ID
    '(?=\\s|$)'; // ensure the URL ends with a space or is at the end of the string

  const regex = new RegExp(pattern, 'g');

  const words = text.split(/\s+/);
  const matches: string[] = [];

  for (const word of words) {
    const match = word.match(regex);
    if (match) {
      matches.push(...match);
    }
  }

  return matches;
};

/**
 * Checks if provided url is a link to post
 * @param {string} url - provided url
 * @returns {boolean}
 */
export const isInternalPostURL = (url: string): boolean => {
  const pattern =
    '(https?:\\/\\/)?' + // optional protocol (http or https)
    '([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])\\.)?' + // mandatory sub-domain
    '(communex\\.app|localhost)' + // match domain communex.app or localhost
    '(\\:\\d{1,5})?' + // optional port
    '\\/post\\/(\\d+)' + // mandatory /post/{id} with numeric ID
    '(?:[\\/?#].*)?$'; // optional extra path, query string, or fragment
  const regex = new RegExp(pattern);
  return regex.test(url);
};

export const isValidURL = (str: string): boolean => {
  const pattern =
    '^(https?:\\/\\/)?' + // protocol
    '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
    '[a-zA-Z]{2,13})' + // extension
    '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
    '|localhost)' + // OR localhost
    '(\\:\\d{1,5})?' + // port
    '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
    '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
    '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
  const regex = new RegExp(pattern);
  return regex.test(str);
};

export const validateCustomLink = async (href: string): Promise<boolean> => {
  const rules = {
    href: {
      extentedUrl,
    },
  };
  const state = {
    href: href,
  };
  const v$ = useVuelidate(rules, state);
  return await v$.value.$validate();
};

export const getGroupsWhereUserIsAdmin = (groups: GroupModel[]): GroupModel[] => {
  const newArr: GroupModel[] = [];
  groups.forEach((element) => {
    if (element.accessType === GroupsAccessEnum.Admin) {
      newArr.push(element);
    }
  });
  return newArr;
};

export const getAnnouncementAccess = (groups: GroupModel[], route: RouteLocationNormalizedLoaded): boolean => {
  const userStore = useUserStore();
  const currentUserRoleId = userStore.current?.roleId ?? 0;
  if (route.name === ROUTES_NAME.GROUP_BY_ID) {
    const { id } = route.params;
    return groups.some(
      (n) =>
        (n.accessType === GroupsAccessEnum.Admin && n.id === Number(id)) ||
        currentUserRoleId >= UserRoleEnum.Administrator
    );
  } else {
    return groups.some(
      (n) => n.accessType === GroupsAccessEnum.Admin || currentUserRoleId >= UserRoleEnum.Administrator
    );
  }
};

export const openPostCreateMenu = async (
  categories: TabCategories<PostTypeActionEnum>[] | undefined = undefined
): Promise<void> => {
  let data: PostTypeActionEnum | undefined;
  if (categories?.length === 1) {
    data = categories[0].value;
  } else if (isAnyMobile && categories) {
    data = await openPostCreateContextSheet(categories);
  } else {
    data = await openPostCreateContextMenuModal();
  }

  if (!data) return;

  await openPostCreateMobileModal(data);
};

export const canCreateIdeas = (): boolean => {
  const networkStore = useNetworkStore();
  const userStore = useUserStore();
  const externalCanCreateIdeas = networkStore.settings?.externalCanCreateIdeas ?? false;

  const currentUserRoleId = userStore.current?.roleId ?? 0;

  return (
    (networkStore.settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Idea) &&
      !(currentUserRoleId <= UserRoleEnum.ExternalGroupUser && !externalCanCreateIdeas)) ??
    false
  );
};

export const canCreateBadges = (): boolean => {
  const onlyForAdminsBadgesManaging = !!useNetworkStore().settings?.onlyForAdminsBadgesManaging;
  const canCreateBadges = !!useNetworkStore().settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Badge);
  const currentUserRoleId = useUserStore().current?.roleId ?? 0;
  const accessToCreateBadges = onlyForAdminsBadgesManaging
    ? currentUserRoleId >= UserRoleEnum.Administrator
    : currentUserRoleId >= UserRoleEnum.User;

  return canCreateBadges && accessToCreateBadges;
};

export const getPostCreateCategories = (
  hasAnnouncementAccess: boolean,
  route: RouteLocationNormalizedLoaded
): TabCategories<PostTypeActionEnum>[] => {
  const { t } = useI18n();

  if (route.name === ROUTES_NAME.IDEAS) {
    return [
      {
        value: PostTypeActionEnum.Idea,
        active: canCreateIdeas(),
        icon: AppIconsEnum.Bulb,
        title: t('feed.idea.title'),
      },
    ];
  }

  return [
    {
      value: PostTypeActionEnum.Text,
      active: useNetworkStore().settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Text) ?? false,
      icon: AppIconsEnum.Comment,
      title: t('feed.post.title'),
    },
    {
      value: PostTypeActionEnum.Announcement,
      active:
        (hasAnnouncementAccess &&
          useNetworkStore().settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Announcement)) ??
        false,
      icon: AppIconsEnum.Bullhorn,
      title: t('feed.announcement.title'),
    },
    {
      value: PostTypeActionEnum.Event,
      active: useNetworkStore().settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Event) ?? false,
      icon: AppIconsEnum.Calendar,
      title: t('feed.event.title'),
    },
    {
      value: PostTypeActionEnum.Poll,
      active: useNetworkStore().settings?.defaultUserItemTypes?.includes(DefaultUserItemTypesEnum.Poll) ?? false,
      icon: AppIconsEnum.Chart,
      title: t('feed.poll.poll'),
    },
    {
      value: PostTypeActionEnum.Idea,
      active: canCreateIdeas(),
      icon: AppIconsEnum.Bulb,
      title: t('feed.idea.title'),
    },
    {
      value: PostTypeActionEnum.Badge,
      active: canCreateBadges(),
      icon: AppIconsEnum.License,
      title: t('feed.badge.title'),
    },
    {
      value: PostTypeActionEnum.Task,
      active: useTaskManagement().getAccessToCreateTask(),
      icon: AppIconsEnum.Projects,
      title: t('taskManagement.tasks.title'),
    },
  ];
};

/**
 * Use this function to remove link HTML tags from input text
 */
export const removeLinkTagsFromText = (inputText: string): string => {
  // Creating temporary DOM element for parsing links
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = inputText;

  const links = tempDiv.querySelectorAll('a');
  links.forEach((link) => {
    const linkText = link.textContent;
    link.replaceWith(linkText ?? '');
  });

  return tempDiv.textContent || tempDiv.innerHTML;
};

/**
 * Use this function to create new post from existing one with the same content
 */
export const createPostFromPostData = async (postData: PostModel): Promise<boolean> => {
  const postStore = usePostStore();
  const projectsStore = useProjectsStore();
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  const postTypeHandlers: Record<FeedTypeEnum, (() => Promise<boolean>) | undefined> = {
    [FeedTypeEnum.GroupCreated]: undefined,
    [FeedTypeEnum.GroupDeleted]: undefined,
    [FeedTypeEnum.UserCreated]: undefined,
    [FeedTypeEnum.Text]: async () => {
      const sendData: RequestStandardPostCreateModel = {
        text: postData.attachedLinks.count ? removeLinkTagsFromText(postData.bodyHtml) : postData.bodyHtml,
        userIds: postData.ccUsers.map((n: UserShortModel) => n.id),
        toGroupId: postData.group?.id || null,
        fileExistIds: postData.attachedFiles.data.map((f: FileModel) => f.key),
        wikiIds: postData.attachedWikis.data.map((f: WikiModel) => f.id),
        linksIds: postData.attachedLinks.data.map((n: LinkApiViewModel) => n.id),
        fileTempIds: [],
        fileIds: [],
      };
      if (postData.initiator && postData.author.id !== postData.initiator.id) {
        sendData.onBehalfUserId = postData.author.id;
      }
      return await postStore.postCreate(sendData);
    },
    [FeedTypeEnum.Idea]: async () => {
      if (!postData.ideaData) return false;
      const idea: RequestIdeaCreateModel = {
        title: postData.ideaData.title,
        problem: postData.ideaData.problem,
        solution: postData.ideaData.solution,
        results: postData.ideaData.results,
        toGroupID: postData.group?.id || null,
        fileExistIds: postData.attachedFiles.data.map((f: FileModel) => f.key),
        wikiIds: postData.attachedWikis.data.map((f: WikiModel) => f.id),
        fileTempIds: [],
        fileIds: [],
      };
      if (postData.initiator && postData.author.id !== postData.initiator.id) {
        idea.onBehalfUserId = postData.author.id;
      }
      return await postStore.ideaCreate(idea);
    },
    [FeedTypeEnum.Badge]: async () => {
      if (!postData.attachedBadge) return false;
      const badge: RequestBadgeCreateModel = {
        text: postData.bodyHtml,
        badgeId: postData.attachedBadge.id,
        userIds: postData.attachedBadge.assignedTo.map((n) => n.id),
      };
      return await postStore.badgeCreate(badge);
    },
    [FeedTypeEnum.Event]: async () => {
      if (!postData.eventData) return false;
      const event: RequestEventCreateModel = {
        title: postData.eventData.title,
        text: postData.bodyHtml,
        date: postData.eventData.datetime,
        duration: postData.eventData.durationMinutes,
        place: postData.eventData.location,
        userIds: postData.ccUsers.map((n: UserShortModel) => n.id),
        toGroupId: postData.group?.id || null,
        endDate: postData.eventData
          ? DateHelper.addMinutesToDateInISO(postData.eventData.datetime, postData.eventData.durationMinutes)
          : '',
        isBlocker: postData.eventData.isBlocker,
        groupIds: [],
        userEmails: [],
      };
      if (postData.initiator && postData.author.id !== postData.initiator.id) {
        event.onBehalfUserId = postData.author.id;
      }
      return await postStore.eventCreate(event);
    },
    [FeedTypeEnum.Announcement]: async () => {
      const announcement: RequestAnnouncementCreateModel = {
        title: postData.title,
        text: postData.bodyHtml,
        isRequiredToRead: postData.isRequiredToRead,
        imageId: getUniqueKey(postData.image?.url) ?? '',
        groupId: postData.group?.id || null,
        fileExistIds: postData.attachedFiles.data.map((f: FileModel) => f.key),
        wikiIds: postData.attachedWikis.data.map((f: WikiModel) => f.id),
        fileTempIds: [],
      };
      if (postData.initiator && postData.author.id !== postData.initiator.id) {
        announcement.onBehalfUserId = postData.author.id;
      }
      return await postStore.announcementCreate(announcement);
    },
    [FeedTypeEnum.Poll]: async () => {
      if (!postData.pollData) return false;
      const poll: RequestPollCreateModel = {
        text: postData.pollData.mainHtml,
        allowUserAddOptions: postData.pollData.allowAddOptions,
        allowMultipleAnswers: postData.pollData.allowSeveralAnswers,
        isAnonymousVoting: postData.pollData.anonymousMode,
        limitDate: postData.pollData.timeLimit || '',
        questionOptions: postData.pollData.options.map((n) => n.text),
        toGroupId: postData.group?.id || null,
        fileTempIds: postData.attachedFiles.data.map((f: FileModel) => f.key),
      };
      if (postData.initiator && postData.author.id !== postData.initiator.id) {
        poll.onBehalfUserId = postData.author.id;
      }
      return await postStore.pollCreate(poll);
    },
    [FeedTypeEnum.Task]: async () => {
      if (!postData.taskData) return false;
      const task: TaskManagementCreateTaskRequestModel = {
        title: postData.taskData.title,
        tagsIds: postData.taskData.tags.data.map((n) => n.id),
        dateDue: postData.taskData.dateDue,
        assigneeId: postData.taskData.assignee?.id,
        milestoneId: postData.taskData.milestone?.id || null,
        description: postData.taskData.description || '',
        projectId: postData.taskData.projectId,
        fileExistIds: postData.attachedFiles.data.map((f: FileModel) => f.key),
        wikiIds: postData.attachedWikis.data.map((f: WikiModel) => f.id),
        participantsIds: postData.taskData.participants.data.map((n) => n.id),
      };
      return await projectsStore.taskCreate(task);
    },
  };

  const handler = postTypeHandlers[postData.messageType];

  if (!handler) {
    console.warn('No handler found for post type: ', postData.messageType);
    return false;
  }

  try {
    return await handler();
  } catch (error) {
    console.error(`[ERROR] Error executing creating ${postData.messageType} post:`, error);
    showSonnerToast(t('errorResponse'), false);
  }

  return false;
};

export const shareFile = async (
  updateData: { text: string; group?: GroupModel; userIds?: number[]; shareTo: PostShareToEnum },
  file: FileModel
): Promise<void> => {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  if (!isFileGuard(file)) {
    showSonnerToast(t('files.failedShared'), false);
    return;
  }

  if (updateData.shareTo === PostShareToEnum.PrivateMessage) {
    const promises =
      updateData.userIds?.map(async (id) => {
        const chainId = await useMessengerStore().chainByUserId(id);
        if (chainId) {
          const requestData: RequestMessageModel = {
            chainId: chainId,
            text: updateData.text.trim(),
            fileTempIds: [],
            fileExistIds: isFileGuard(file) ? [file.key] : [],
            messageId: null,
            payload: Guid.create().toString(),
          };
          return await useChatStore().reply(requestData);
        }
        return false;
      }) ?? [];
    if (await Promise.all(promises)) {
      showSonnerToast(t('files.successShared'), true);
    } else {
      showSonnerToast(t('files.failedShared'), false);
    }
  }

  if (updateData.shareTo === PostShareToEnum.Group) {
    const postData = {
      text: updateData.text.trim(),
      toGroupId: updateData.group?.id,
      fileExistIds: isFileGuard(file) ? [file.key] : [],
      fileTempIds: [],
      groupIds: [],
      userEmails: [],
      userIds: [],
      wikiIds: [],
    };
    if (await usePostStore().postCreate(postData)) {
      showSonnerToast(t('files.successShared'), true);
    } else {
      showSonnerToast(t('files.failedShared'), false);
    }
  }
};

export const shareWiki = async (
  updateData: { text: string; group?: GroupModel; userIds?: number[]; shareTo: PostShareToEnum },
  wiki: WikiModel
): Promise<void> => {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  if (!isWikiGuard(wiki)) {
    showSonnerToast(t('wiki.menuActions.share.error'), false);
    return;
  }

  const postData = {
    text: updateData.text.trim(),
    toGroupId: updateData.group?.id || null,
    fileExistIds: [],
    fileTempIds: [],
    groupIds: [],
    userEmails: [],
    userIds: [],
    wikiIds: [wiki.id],
  };
  if (await usePostStore().postCreate(postData)) {
    showSonnerToast(t('wiki.menuActions.share.success'), true);
  } else {
    showSonnerToast(t('wiki.menuActions.share.error'), false);
  }
};

/**
 * - 2 types of sharing a post: Share or Repost
 *  - Share: Post can be shared to a group/feed or sent as private message to a user
 *  - Repost: New post is created with the same content but from current user
 */
export const sharePost = async (
  updateData: { text: string; group?: GroupModel; userIds?: number[]; shareTo: PostShareToEnum },
  post: PostModel,
  action = PostMenuActionEnum.Share
): Promise<void> => {
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();

  if (!isPostGuard(post)) {
    showSonnerToast(t('feed.feedItemPostMenu.share.failedShared'), false);
    return;
  }

  if (action === PostMenuActionEnum.Share) {
    let result;
    switch (updateData.shareTo) {
      case PostShareToEnum.Group: {
        const shareData = {
          text: updateData.text.trim(),
          sharedUserItemId: post.id,
          toGroupId: updateData.group?.id,
          userIds: [],
        };
        result = await usePostStore().postShare(shareData, PostShareToEnum.Group);
        break;
      }
      case PostShareToEnum.PrivateMessage: {
        const shareData = {
          text: updateData.text.trim(),
          sharedUserItemId: post.id,
          userIds: updateData.userIds || [],
        };
        result = await usePostStore().postShare(shareData, PostShareToEnum.PrivateMessage);
        break;
      }
    }

    if (result) {
      showSonnerToast(t('feed.feedItemPostMenu.share.successShared'), true);
    } else {
      showSonnerToast(t('feed.feedItemPostMenu.share.failedShared'), false);
    }
    return;
  }

  if (action === PostMenuActionEnum.Repost) {
    post.group = updateData.group ? useGroups().toShortGroupModel(updateData.group) : null;
    if (await createPostFromPostData(post)) {
      showSonnerToast(t('feed.feedItemPostMenu.repost.successRepost'), true);
    } else {
      showSonnerToast(t('feed.feedItemPostMenu.repost.failedRepost'), false);
    }
  }
};

/**
 * Use this function to share File, Wiki or Post from EntityShareModal
 *
 * @param entity - Object to share
 * @param type - Type of shared object, could be 'File', 'Wiki or 'Post'
 * @param action - Optional parameter used when sharing posts. Represents kind of action to perform - Share or Repost
 */
export const shareEntity = async (
  entity: FileModel | WikiModel | PostModel,
  type: ShareEntityType,
  action?: PostMenuActionEnum
): Promise<undefined> => {
  const result = await openEntityShareModal(entity, type, action);

  if (result === undefined) {
    return;
  }

  switch (type) {
    case ShareEntityType.File: {
      await shareFile(result, entity as FileModel);
      break;
    }
    case ShareEntityType.Wiki: {
      await shareWiki(result, entity as WikiModel);
      break;
    }
    case ShareEntityType.Post: {
      await sharePost(result, entity as PostModel, action);
      break;
    }
  }
};

export const createPostAccessHelper = (
  page: PageToPostCreateEnum,
  isFab: boolean,
  isLoading: boolean,
  isGroupAdmin?: boolean,
  groupData?: GroupModel,
  pageData?: PageModel
): boolean => {
  const groupStore = useGroupsStore();
  const userStore = useUserStore();
  const networkStore = useNetworkStore();

  const currentUserRoleId = userStore.current?.roleId ?? 0;
  const canPostGroups = groupStore.getCanPostGroups().data;
  const networkAllowPostToFeed = networkStore.settings?.allowPostToFeed;
  const defaultUserItemTypes = networkStore.settings?.defaultUserItemTypes !== undefined;

  // If the role is 12 or less - you can not create any posts
  if (currentUserRoleId <= UserRoleEnum.ExternalGroupUserLikeUpsert) return false;

  const emptyAndOff = !canPostGroups.length && !networkAllowPostToFeed;
  const emptyAndOn = !canPostGroups.length && networkAllowPostToFeed;

  // Display according the network settings + canPostGroups
  switch (true) {
    case !defaultUserItemTypes:
      return false;
    case emptyAndOff:
      return false;
    case emptyAndOn:
      return !isFab ? true : !isLoading;
  }

  // Display according page
  switch (page) {
    case PageToPostCreateEnum.Feed: {
      return !isFab ? true : !isLoading;
    }

    case PageToPostCreateEnum.Group: {
      const groupAccessToCreate = () => {
        if (!groupData) return false;

        if (groupData?.isOfficial && isGroupAdmin) return true;

        if (!groupData?.isOfficial && canPostGroups.includes(groupData)) return true;

        return false;
      };

      if (!groupAccessToCreate()) return false;

      return !isFab ? true : !isLoading;
    }

    case PageToPostCreateEnum.Ideas:
      return !isFab ? true : !isLoading;

    case PageToPostCreateEnum.Tag:
      return !isLoading;

    case PageToPostCreateEnum.CustomPage:
    case PageToPostCreateEnum.GroupDashboard: {
      if (!pageData) return false;

      const customPagesHelper = useCustomPages();

      return !isLoading && customPagesHelper.pageHavePublisher(pageData) ? true : false;
    }

    default:
      return false;
  }
};

/** Use this function to get menu items for post context menu */

export const getPostsFilterTitle = (label: PostsFilterEnum): string => {
  const { t } = useI18n();
  switch (label) {
    case PostsFilterEnum.All:
      return t('read.all');
    case PostsFilterEnum.Read:
      return t('read.reads');
    case PostsFilterEnum.Unread:
      return t('read.unread');
    default:
      return t('read.all');
  }
};

export const getFeedFilterContextMenuItems = (): {
  title: string;
  value: FeedFilterTypeEnum | null;
  subTitle: string;
  action: FeedContextMenuActionEnum;
  enabled: boolean;
}[] => {
  const { t } = useI18n();
  const selectedFeedFilter = useAppStore().feedFilter;
  const selectedFeedType = useAppStore().feedType;
  const filterSelectIsShow =
    selectedFeedType === FeedFilterTypeEnum.AllPublic || selectedFeedType === FeedFilterTypeEnum.Following;

  const mainItems: {
    title: string;
    value: FeedFilterTypeEnum | null;
    subTitle: string;
    action: FeedContextMenuActionEnum;
    enabled: boolean;
  }[] = [
    {
      title: t('viewType'),
      value: null,
      subTitle: getPostsFilterTitle(selectedFeedFilter),
      action: FeedContextMenuActionEnum.SelectFilter,
      enabled: filterSelectIsShow,
    },
    {
      title: t('read.markAllAsRead'),
      value: null,
      subTitle: t('read.allReadDescription'),
      action: FeedContextMenuActionEnum.MarkAllAsRead,
      enabled: filterSelectIsShow,
    },
  ].filter((i) => i.enabled);

  return mainItems;
};

export const getFeedTypeContextMenuItems = (): {
  title: string;
  value: FeedFilterTypeEnum | null;
  subTitle: string;
  action: FeedContextMenuActionEnum;
  enabled: boolean;
}[] => {
  const { t } = useI18n();

  const mainItems: {
    title: string;
    value: FeedFilterTypeEnum | null;
    subTitle: string;
    action: FeedContextMenuActionEnum;
    enabled: boolean;
  }[] = [
    {
      title: t('feedPage.all'),
      value: FeedFilterTypeEnum.AllPublic,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.recommended'),
      value: FeedFilterTypeEnum.Recommended,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('subscribe.subscriptions'),
      value: FeedFilterTypeEnum.Following,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.bookmarks'),
      value: FeedFilterTypeEnum.MyBookmarks,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.myPosts'),
      value: FeedFilterTypeEnum.AllMy,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    {
      title: t('feedPage.planned'),
      value: FeedFilterTypeEnum.Planned,
      subTitle: '',
      action: FeedContextMenuActionEnum.SelectType,
      enabled: true,
    },
    ...getFeedFilterContextMenuItems(),
    {
      title: t('files.resetFilter'),
      value: null,
      subTitle: '',
      action: FeedContextMenuActionEnum.Reset,
      enabled:
        router.currentRoute.value.name === ROUTES_NAME.GROUP_BY_ID ||
        router.currentRoute.value.name === ROUTES_NAME.PAGE_BY_ID,
    },
  ].filter((i) => i.enabled);

  return mainItems;
};

export const getMessengerContextMenuItems = (
  message: MessageModel,
  currentId: number | undefined,
  deleteFlag = false
): MessageMenuModel[] => {
  const { t } = useI18n();

  const mainItems: MessageMenuModel[] = [
    {
      title: t('appPopoverMenu.messengerMenu.reply'),
      icon: AppIconsEnum.ArrowLeft,
      value: MessageActionEnum.Reply,
      disabled: false,
    },
    {
      title: t('appPopoverMenu.edit.title'),
      icon: AppIconsEnum.Pencil,
      value: MessageActionEnum.Edit,
      disabled: !message.editable || message.authorId !== currentId,
    },
    {
      title: t('appPopoverMenu.copy.title'),
      icon: AppIconsEnum.CopyToClipboard,
      value: MessageActionEnum.Copy,
      disabled: !message.bodyHtml,
    },
    {
      title: t('appPopoverMenu.messengerMenu.forward'),
      icon: AppIconsEnum.ArrowRight,
      value: MessageActionEnum.Forward,
      disabled: false,
    },
    {
      title: t('appPopoverMenu.messengerMenu.deleteForMe'),
      icon: AppIconsEnum.Trash,
      value: MessageActionEnum.DeleteForMe,
      disabled: message.authorId === currentId,
    },
    {
      title: t('appPopoverMenu.messengerMenu.deleteMessage'),
      icon: AppIconsEnum.Trash,
      value: MessageActionEnum.Delete,
      disabled: message.authorId !== currentId,
    },
  ].filter(({ disabled }) => !disabled);

  const deleteItems: MessageMenuModel[] = [
    {
      title: t('appPopoverMenu.messengerMenu.deleteForMe'),
      icon: AppIconsEnum.Trash,
      value: MessageActionEnum.DeleteForMe,
      disabled: message.authorId === currentId,
    },
    {
      title: t('appPopoverMenu.messengerMenu.deleteForAll'),
      icon: AppIconsEnum.Trash,
      value: MessageActionEnum.DeleteForAll,
      disabled: !message.editable || message.authorId !== currentId,
    },
  ].filter(({ disabled }) => !disabled);

  return deleteFlag ? deleteItems : mainItems;
};

export const getCommentContextMenuItems = (isEditable: boolean): CommentMenuModel[] => {
  const { t } = useI18n();

  return [
    {
      title: t('appPopoverMenu.copy.title'),
      value: CommentActionEnum.CopyText,
      icon: AppIconsEnum.CopyToClipboard,
      disabled: false,
    },
    {
      value: CommentActionEnum.Delete,
      title: t('appPopoverMenu.commentMenu.deleteComment'),
      icon: AppIconsEnum.Trash,
      disabled: !isEditable,
      handler: async () => {
        const alert = await alertController.create({
          message: t('appPopoverMenu.commentMenu.deleteConfirm'),
          buttons: [
            {
              text: t('no'),
              role: 'cancel',
              cssClass: 'custom-alert-buttons',
            },
            {
              text: t('yes'),
              cssClass: 'custom-alert-buttons',
              handler: async () => {
                await actionSheetController.dismiss(2);
              },
            },
          ],
        });

        await alert.present();
        return false;
      },
    },
  ].filter(({ disabled }) => !disabled);
};

export const changeFavicon = (): void => {
  if (isNativeMobile) return;

  const icon = useFavicon();

  icon.value = useNetworks().getCurrentNetworkFaviconUrl();
};

export const getPhoneCodeByCountryName = (name: CountriesEnum): string | null => {
  const countryPhoneCodes: Record<CountriesEnum, string> = {
    [CountriesEnum.Armenia]: CountryCodesEnum.Armenia,
    [CountriesEnum.Austria]: CountryCodesEnum.Austria,
    [CountriesEnum.Belarus]: CountryCodesEnum.Belarus,
    [CountriesEnum.Belgium]: CountryCodesEnum.Belgium,
    [CountriesEnum.Canada]: CountryCodesEnum.Canada,
    [CountriesEnum.Czech]: CountryCodesEnum.Czech,
    [CountriesEnum.Uk]: CountryCodesEnum.Uk,
    [CountriesEnum.Germany]: CountryCodesEnum.Germany,
    [CountriesEnum.Kazakhstan]: CountryCodesEnum.Kazakhstan,
    [CountriesEnum.Russia]: CountryCodesEnum.Russia,
    [CountriesEnum.Usa]: CountryCodesEnum.Usa,
    [CountriesEnum.Italy]: CountryCodesEnum.Italy,
    [CountriesEnum.Spain]: CountryCodesEnum.Spain,
  };

  return countryPhoneCodes[name] || null;
};

export const getPhoneMaskByCountryCode = (code: CountryCodesEnum, length = 0): string | null => {
  const phoneMasks: Record<string, string | ((length: number) => string | null)> = {
    [CountryCodesEnum.Usa]: '(###) ###-####',
    [CountryCodesEnum.Russia]: '(###) ###-##-##',
    [CountryCodesEnum.Armenia]: '## ### ###',
    [CountryCodesEnum.Austria]: '##########',
    [CountryCodesEnum.Belarus]: '## ###-##-##',
    [CountryCodesEnum.Belgium]: '## ### ###',
    [CountryCodesEnum.Czech]: '### ### ###',
    [CountryCodesEnum.Uk]: '#### ### ####',
    [CountryCodesEnum.Italy]: '### ### ####',
    [CountryCodesEnum.Spain]: '### ### ###',
    [CountryCodesEnum.Germany]: (length: number) => {
      switch (length) {
        case 13:
          return '### #### ####';
        case 12:
          return '#### #######';
        case 11:
          return '## ### ####';
        default:
          return '### #### ####';
      }
    },
  };

  const mask = phoneMasks[code];
  return typeof mask === 'function' ? mask(length) : mask || null;
};

/**
 * @deprecated
 * @see `src/helpers/useDateHelper.ts`
 */
export const formatDateHelper = (
  str: string | number,
  style: 'short' | 'day' | 'month' | 'year' | 'yearNumeric' | 'long' | 'week' | 'monthName'
): string => {
  try {
    if (!str) return '';

    const appStore = useAppStore();
    const language = appStore.locale;

    const options = { locales: language };

    const formatDate = (format: string): string => {
      return useDateFormat(str, format, options).value;
    };

    switch (style) {
      case 'day':
        return formatDate('HH:mm');
      case 'month':
        return formatDate('DD MMM');
      case 'year':
        return formatDate('DD MMM YYYY');
      case 'yearNumeric':
        return formatDate('DD.MM.YYYY');
      case 'long':
        return formatDate('ddd, DD MMM YYYY, HH:mm');
      case 'short':
        return formatDate('DD.MM.YYYY HH:mm');
      case 'week':
        return formatDate('ddd');
      case 'monthName':
        return formatDate('MMMM');
      default:
        return '';
    }
  } catch (e) {
    console.error('[ERROR] Error while formatting date:', e);
    return '';
  }
};

export const appActionButtonAction = async (
  id: number | string,
  action: AppCardsActionEnum | WikiActionEnum
): Promise<void> => {
  const groupsHelper = useGroups();
  const usersHelper = useUsers();
  switch (action) {
    case AppCardsActionEnum.GroupJoin:
      await groupsHelper.onJoinGroup(Number(id));
      break;

    case AppCardsActionEnum.GroupLeave:
      await groupsHelper.onLeaveGroup(Number(id));
      break;

    case AppCardsActionEnum.GroupSendRequest:
      await groupsHelper.onSendRequest(Number(id));
      break;

    case AppCardsActionEnum.UserFollow:
      await usersHelper.onFollow(Number(id));
      break;

    case AppCardsActionEnum.UserUnfollow:
      await usersHelper.onUnfollow(Number(id));
      break;

    case AppCardsActionEnum.ChangeNetwork:
      {
        const network = useNetworkStore().getNetworkById(id.toString());
        if (network) {
          useAppStore().setIsNetworkChanged(true);
          await useSession().setNetwork(network, true);
        }
      }
      break;

    default:
      break;
  }
};

/** @note This code looks for http/https links in a line of text and replaces them with tags */
export const replaceLinksToTags = (text: string): string => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  return text.replace(urlRegex, function (match, url) {
    const domain = new URL(url).hostname;
    return '<a target="_blank" href="' + url + '">' + domain + '</a>';
  });
};

export const getImageKeyFromLink = (url: string): string => {
  const regex = /\/media\/public\/(.*?)(?:\.\w+)?$/;
  const match = regex.exec(url);
  if (match) return match[1];
  return url;
};

/** Share access rules
 * https://docs.google.com/spreadsheets/d/1rk7rn5qt17fJNCBLTGVlhYucCFYS2u9RZu-Qe18nSSc/edit?gid=0#gid=0
 * */
export const canShareFromGroup = (group: GroupModel | GroupShortModel | null): boolean => {
  if (!group) return true;

  const activeGroup: GroupModel = isGroupShortGuard(group) ? useGroupsStore().getGroupById(group.id) : group;
  const currentUserId = useUserStore().current?.id ?? 0;

  return activeGroup.type === GroupsTypeEnum.PrivateHidden ? activeGroup.adminIds.includes(currentUserId) : true;
};

export const canSendArchivedLink = (): boolean => {
  const userStore = useUserStore();
  const networkStore = useNetworkStore();

  const currentUserRoleId: UserRoleEnum = userStore.current?.roleId ?? 0;
  const sendArchivedPost = networkStore.settings?.sendArchivedPost ?? UserAdminAccessLevel.None;

  const accessLevels = {
    [UserAdminAccessLevel.None]: false,
    [UserAdminAccessLevel.All]: true,
    [UserAdminAccessLevel.Administrator]: currentUserRoleId >= UserRoleEnum.Administrator,
  };

  return accessLevels[sendArchivedPost] ?? false;
};

export const calendarShowEvents = (isSmall: boolean, activeView: CalendarViewModeEnum): boolean =>
  isSmall && activeView === CalendarViewModeEnum.Month;

export const calendarShowBackBtn = (isSmall: boolean, activeView: CalendarViewModeEnum): boolean =>
  isSmall ? activeView === CalendarViewModeEnum.Day : activeView !== CalendarViewModeEnum.Year;

export const getAccessToDownloadPost = (postType: FeedTypeEnum): boolean => {
  const allowedTypes = [FeedTypeEnum.Text, FeedTypeEnum.Announcement, FeedTypeEnum.Event, FeedTypeEnum.Poll];
  return allowedTypes.includes(postType);
};

export const getFeedTypeTitle = (
  selectedFeedFilter: PostsFilterEnum,
  selectedFeedType: FeedFilterTypeEnum | WidgetFeedTypeEnum
): string => {
  const { t } = useI18n();
  const filter = getPostsFilterTitle(selectedFeedFilter);
  let text = '';
  switch (selectedFeedType) {
    case FeedFilterTypeEnum.Recommended:
      text = t('feedPage.recommended');
      break;
    case FeedFilterTypeEnum.Following:
      text = t('subscribe.subscriptions');
      break;
    case FeedFilterTypeEnum.AllPublic:
      text = t('feedPage.all');
      break;
    case FeedFilterTypeEnum.MyBookmarks:
      text = t('feedPage.bookmarks');
      break;
    case FeedFilterTypeEnum.AllMy:
      text = t('feedPage.myPosts');
      break;
    case FeedFilterTypeEnum.Planned:
      text = t('feedPage.planned');
      break;

    case FeedFilterTypeEnum.ByGroup:
      text = t('group');
      break;

    case FeedFilterTypeEnum.ByTag:
      text = t('appMenu.topics');
      break;

    case FeedFilterTypeEnum.Polls:
      text = t('search.searchView.postTypes.polls');
      break;

    case FeedFilterTypeEnum.Ideas:
      text = t('search.searchView.postTypes.ideas');
      break;

    default:
      text = t('feedPage.all');
      break;
  }

  if (
    selectedFeedFilter !== PostsFilterEnum.All &&
    (selectedFeedType === FeedFilterTypeEnum.AllPublic || selectedFeedType === FeedFilterTypeEnum.Following)
  ) {
    text = `${text} (${filter})`;
  }

  return text;
};

export const searchSuggestionsEnabled = (): boolean => {
  const currentUserRoleId = useUserStore().current?.roleId ?? 0;

  const searchSuggestions = useNetworkStore().settings?.searchSuggestions ?? AccessByRoleEnum.AdminOrHigher;

  switch (searchSuggestions) {
    case AccessByRoleEnum.Off:
      return false;
    case AccessByRoleEnum.AllUsers:
      return currentUserRoleId >= UserRoleEnum.User;
    case AccessByRoleEnum.ModeratorOrHigher:
      return currentUserRoleId >= UserRoleEnum.Moderator;
    case AccessByRoleEnum.AdminOrHigher:
      return currentUserRoleId >= UserRoleEnum.Administrator;
  }

  return false;
};

export const getDocumentIcon = (documentType: DocumentTypeEnum, name: string): string => {
  switch (documentType) {
    case DocumentTypeEnum.Wiki:
      return AppIconsEnum.Wiki;
    case DocumentTypeEnum.Folder:
      return AppIconsEnum.Folder;
    case DocumentTypeEnum.ExternalLink:
      return AppIconsEnum.Symlink;
    default:
      return name.split('.').pop() ?? '';
  }
};

export const reloadAndResetCache = async (): Promise<void> => {
  if (caches) {
    const cacheNames = await caches.keys();
    for (const name of cacheNames) {
      await caches.delete(name);
    }
  }
  window.location.reload();
};

export const serializeQueryParams = (
  queryObj: Record<string, string | number | (string | number)[] | undefined> | null
): string => {
  if (queryObj === null) {
    return '';
  }
  const params = [];
  for (const key in queryObj) {
    if (Array.isArray(queryObj[key])) {
      queryObj[key].forEach((value) => {
        params.push(`${key}=${value}`);
      });
    } else if (queryObj[key] !== undefined) {
      params.push(`${key}=${queryObj[key]}`);
    }
  }
  return params.join('&');
};

export const splitStringToArr = <T extends string>(inputString: string, delimiter: string): T[] => {
  return inputString.includes(delimiter) ? (inputString.split(delimiter) as T[]) : ([inputString] as T[]);
};

export const splitStringToNumberArr = (inputString: string, delimiter: string): number[] => {
  return inputString.includes(delimiter) ? inputString.split(delimiter).map((item) => +item) : [+inputString];
};

export const getTextColor = (color: string | null): string => {
  if (color) {
    return LightOrDark.getColor(color) === ThemeAppEnum.Light ? '#000000' : '#ffffff';
  }
  return '#ffffff';
};

/**
 * Use , to get the in-platform path if the link is internal - to a user's profile, group or tag page
 *
 * @param link - link to parser
 */
export const internalLinkParser = (link: string | null): string | null => {
  if (!link) {
    console.log('[INFO] Link for parser is empty');
    return null;
  }

  const pattern = /^[ugt]:\d+$/;
  if (pattern.test(link)) {
    const [identifier, id] = link.split(':');

    const routerNameMapper = {
      u: ROUTES_NAME.USER_BY_ID,
      g: ROUTES_NAME.GROUP_BY_ID,
      t: ROUTES_NAME.TOPIC_BY_ID,
    };

    return router.resolve({
      name: routerNameMapper[identifier as 'g' | 't' | 'u'],
      params: { id },
    }).href;
  }

  return null;
};

/**
 * Use this if you want to open a link.
 *
 * Any platform;
 *
 * @param link - The link to open
 */
export const openLink = async (link: string | null | undefined): Promise<void> => {
  if (!link) return console.warn('[WARN] Link is empty');

  const internalLink = internalLinkParser(link);
  const finalLink = internalLink || link;
  const logMessage = `[INFO] Opening link: ${finalLink} ${isNativeMobile ? 'on mobile' : ''}`;

  if (!isNativeMobile) window.open(finalLink, '_blank');

  if (internalLink) {
    router.push(internalLink);
  } else {
    await Browser.open({ url: link });
  }

  console.log(logMessage);
};

/**
 * Use this if you want to copy the text to the clipboard;
 *
 * Any platform;
 *
 * @param text - The text to copy to the clipboard
 */
export const copyToClipboard = async (text: string): Promise<void> => {
  try {
    if (isNativeMobile) {
      await Clipboard.write({
        string: text,
      });
    } else {
      await navigator.clipboard.writeText(text);
    }
  } catch (e) {
    console.error('[ERROR] Failed to copy text to clipboard', e);
  }
};

/**
 * Use it if you want to create a cell template for the table.
 *
 * @param renderer A function that takes cell properties and returns an HTML string.
 * @param className Optional CSS class to apply to the cell's container. Defaults to 'overflow-el'.
 * @returns A function compatible with Vue's render function to generate the cell's virtual DOM.
 */
export const createCellTemplate = (
  renderer: (props: CellTemplateProp) => string,
  className: string = 'overflow-el'
): ((createElement: HyperFunc<VNode>, props: CellTemplateProp) => VNode) => {
  return (createElement, props) => {
    const htmlString = renderer(props);
    return createElement('div', {
      class: className,
      innerHTML: htmlString,
    });
  };
};

/** Use it if you want to capitalize the first letter in the string. */
export const capitalizeFirstLetter = (val: string): string => {
  if (!val) return '';
  return val.charAt(0).toUpperCase() + val.slice(1);
};

/** Use it if you want to create confirmation alert */
export const createConfirmationAlert = async (message: string, buttons: any[], errorMessage: string): Promise<void> => {
  const { showSonnerToast } = useToasts();

  try {
    const alert = await alertController.create({
      message,
      buttons,
    });
    await alert.present();
  } catch (e) {
    console.error('[ERROR] Failed to create confirmation alert', e);
    if (errorMessage) showSonnerToast(errorMessage, false);
  }
};
