import { useRouter } from 'vue-router';
import {
  ActionAccessEnum,
  AppIconsEnum,
  DocumentExtensionEnum,
  FeedFlagEnum,
  FeedTypeEnum,
  FileStatusEnum,
  PostMenuActionEnum,
  PostMenuActionTypeEnum,
  ShareArchiveLinkType,
  ShareEntityType,
} from '@/enums';
import {
  copyToClipboard,
  DateHelper,
  getAccessToDownloadPost,
  htmlToText,
  isAnyMobile,
  openAppDatePickerPopover,
  openPostComplainModal,
  openPostContextMenuModal,
  openPostContextSheet,
  openPostEditModal,
  openPostSendToExternalEmailModal,
  openShareArchiveLinkModal,
  shareEntity,
  useAccess,
  useFilesHybrid,
  useToasts,
} from '@/helpers';
import { isBlob } from '@/helpers/guards';
import { useI18n } from '@/i18n';
import { ROUTES_NAME } from '@/router';
import { useLinksStore, usePostStore } from '@/store';
import type { PostActionPayload, PostMenuItemModel, PostMenuPayload, PostModel } from '@/types';
import { actionSheetController, alertController } from '@ionic/vue';

type IUsePostHelper = {
  openPostMenu: (payload: PostMenuPayload) => Promise<boolean>;
  handleAction: (payload: PostActionPayload) => Promise<boolean | number>;
};

export function usePostHelper(): IUsePostHelper {
  //#region Variables
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();
  const postStore = usePostStore();
  const linksStore = useLinksStore();
  const router = useRouter();
  //#endregion

  //#Getters
  function _getSendMenuItems(postData: PostModel, actionType: PostMenuActionTypeEnum): PostMenuItemModel[] {
    const sendParentItem = [
      {
        title: t('feed.feedItemPostMenu.send.title'),
        icon: AppIconsEnum.Mail,
        value: PostMenuActionEnum.Send,
        disabled: !postData.access.includes(ActionAccessEnum.ShareAsArchive),
      },
    ];

    const sendItems: PostMenuItemModel[] = [
      {
        title: t('feed.feedItemPostMenu.send.sendToMyEmail'),
        icon: AppIconsEnum.Mail,
        value: PostMenuActionEnum.SendToEmail,
        disabled: !postData.access.includes(ActionAccessEnum.ShareAsArchive),
      },
      {
        title: t('feed.feedItemPostMenu.send.sendToExternalEMail'),
        icon: AppIconsEnum.Mail /** @todo - Add mailUnread icon */,
        value: PostMenuActionEnum.SendToExternalEmail,
        disabled: !postData.access.includes(ActionAccessEnum.ShareAsArchive),
      },
      {
        title: t('feed.feedItemPostMenu.send.sendAsArchiveToEmail'),
        icon: AppIconsEnum.Mail,
        value: PostMenuActionEnum.SendAsArchiveToEmail,
        disabled: !postData.access.includes(ActionAccessEnum.ShareAsArchive),
      },
    ].filter(({ disabled }) => !disabled);

    return _showParentItem(sendItems, actionType) ? sendParentItem : sendItems;
  }

  function _getShareMenuItems(postData: PostModel, actionType: PostMenuActionTypeEnum): PostMenuItemModel[] {
    const shareParentItem = [
      {
        title: t('feed.feedItemPostMenu.share.title'),
        icon: AppIconsEnum.Share,
        value: PostMenuActionEnum.ShareGeneral,
        disabled: !useAccess().canShareForBKG(postData.access.includes(ActionAccessEnum.Share)),
      },
    ];

    const shareItems: PostMenuItemModel[] = [
      {
        title: t('feed.feedItemPostMenu.share.title'),
        icon: AppIconsEnum.Share,
        value: PostMenuActionEnum.Share,
        disabled: !useAccess().canShareForBKG(postData.access.includes(ActionAccessEnum.Share)),
      },
      {
        title: t('feed.feedItemPostMenu.repost.title'),
        icon: AppIconsEnum.Symlink,
        value: PostMenuActionEnum.Repost,
        disabled: !useAccess().canShareForBKG(postData.access.includes(ActionAccessEnum.Share)),
      },
    ].filter(({ disabled }) => !disabled);

    return _showParentItem(shareItems, actionType) ? shareParentItem : shareItems;
  }

  function _getDownloadMenuItems(postData: PostModel, actionType: PostMenuActionTypeEnum): PostMenuItemModel[] {
    const downloadParentItem = [
      {
        title: t('files.menu.download'),
        icon: AppIconsEnum.Download,
        value: PostMenuActionEnum.Download,
        disabled: !getAccessToDownloadPost(postData.messageType),
      },
    ];

    const downloadItems: PostMenuItemModel[] = [
      {
        title: t('files.menu.downloadAsPDF'),
        icon: AppIconsEnum.Document,
        value: PostMenuActionEnum.DownloadAsPDF,
        disabled: !getAccessToDownloadPost(postData.messageType),
      },
      {
        title: t('files.menu.downloadAsZIP'),
        icon: AppIconsEnum.FolderYellowAdd,
        value: PostMenuActionEnum.DownloadAsZIP,
        disabled: !getAccessToDownloadPost(postData.messageType),
      },
    ].filter(({ disabled }) => !disabled);

    return _showParentItem(downloadItems, actionType) ? downloadParentItem : downloadItems;
  }

  function _getActionMenuItems(p: PostMenuPayload): PostMenuItemModel[] {
    const { postData, feedFlag, actionType = PostMenuActionTypeEnum.All } = p;

    const items: PostMenuItemModel[] = [
      {
        title: t('feed.feedItemPostMenu.sendNow.title'),
        icon: AppIconsEnum.ArrowUp,
        value: PostMenuActionEnum.SendNow,
        disabled: !postData.plannedPostData,
      },
      {
        title: t('feed.feedItemPostMenu.reSchedule.title'),
        icon: AppIconsEnum.Timer,
        value: PostMenuActionEnum.ReSchedule,
        disabled: !postData.plannedPostData,
      },
      {
        title: t('feed.feedItemPostMenu.viewPost'),
        icon: AppIconsEnum.ExternalLink,
        value: PostMenuActionEnum.Open,
        disabled: feedFlag === FeedFlagEnum.PostPage,
      },
      {
        title: t('appPopoverMenu.edit.title'),
        icon: AppIconsEnum.Pencil,
        value: PostMenuActionEnum.Edit,
        disabled: !postData.access.includes(ActionAccessEnum.Edit) || postData.messageType === FeedTypeEnum.Task,
      },
      {
        title: t('feed.feedItemPostMenu.bookmark.addToBookmarks'),
        icon: AppIconsEnum.Bookmark,
        value: PostMenuActionEnum.AddToBookmarks,
        disabled: postData.isBookmarked,
      },
      {
        title: t('feed.feedItemPostMenu.bookmark.removeFromBookmarks'),
        icon: AppIconsEnum.Close,
        value: PostMenuActionEnum.RemoveFromBookmarks,
        disabled: !postData.isBookmarked,
      },
      {
        title: t('feed.feedItemPostMenu.attach.pin'),
        icon: AppIconsEnum.Pin,
        value: PostMenuActionEnum.Pin,
        disabled: !postData.access.includes(ActionAccessEnum.Pin) || postData.isPinned,
      },
      {
        title: t('feed.feedItemPostMenu.attach.unpin'),
        icon: AppIconsEnum.Close,
        value: PostMenuActionEnum.UnPin,
        disabled: !postData.access.includes(ActionAccessEnum.Pin) || !postData.isPinned,
      },
      {
        title: t('feed.feedItemPostMenu.following.followToPost'),
        icon: AppIconsEnum.Notifications,
        value: PostMenuActionEnum.Follow,
        disabled: postData.isFollowed,
      },
      {
        title: t('feed.feedItemPostMenu.following.unfollowFromPost'),
        icon: AppIconsEnum.NotificationsOff,
        value: PostMenuActionEnum.UnFollow,
        disabled: !postData.isFollowed,
      },
      {
        title: t('feed.feedItemPostMenu.violation.complain'),
        icon: AppIconsEnum.StatusAlert,
        value: PostMenuActionEnum.Complain,
        disabled: !postData.isComplainable,
      },
      {
        title: t('appPopoverMenu.copy.title'),
        icon: AppIconsEnum.CopyToClipboard,
        value: PostMenuActionEnum.CopyText,
        disabled: postData.messageType === FeedTypeEnum.Task,
      },
      {
        title: t('read.list'),
        icon: AppIconsEnum.Eye,
        value: PostMenuActionEnum.ShowViewers,
        disabled: !postData.access.includes(ActionAccessEnum.ObserveViewers),
      },
      ..._getShareMenuItems(postData, actionType),
      ..._getSendMenuItems(postData, actionType),
      ..._getDownloadMenuItems(postData, actionType),
      {
        title: t('appPopoverMenu.delete.title'),
        icon: AppIconsEnum.Trash,
        value: PostMenuActionEnum.Delete,
        disabled: !postData.access.includes(ActionAccessEnum.Delete),
        handler: async () => {
          const alert = await alertController.create({
            message: t('feed.feedItemPostMenu.delete.deleteConfirm'),
            buttons: [
              {
                text: t('no'),
                role: 'cancel',
                cssClass: 'custom-alert-buttons',
              },
              {
                text: t('yes'),
                cssClass: 'custom-alert-buttons',
                handler: async () => {
                  await actionSheetController.dismiss(2);
                },
              },
            ],
          });

          await alert.present();
          return false;
        },
      },
    ].filter(({ disabled }) => !disabled);

    const itemsMap: Record<PostMenuActionTypeEnum, PostMenuItemModel[]> = {
      [PostMenuActionTypeEnum.All]: items,
      [PostMenuActionTypeEnum.Send]: _getSendMenuItems(postData, actionType),
      [PostMenuActionTypeEnum.Share]: _getShareMenuItems(postData, actionType),
      [PostMenuActionTypeEnum.Download]: _getDownloadMenuItems(postData, actionType),
    };

    return itemsMap[actionType];
  }
  //#endregion

  //#region Helpers
  function _showParentItem(items: PostMenuItemModel[], actionType: PostMenuActionTypeEnum): boolean {
    return items.length > 1 && actionType === PostMenuActionTypeEnum.All;
  }

  async function _executeWithToast<T>(
    action: () => Promise<T>,
    successMsg: string,
    errorMsg: string
  ): Promise<T | false> {
    try {
      const result = await action();
      showSonnerToast(result ? successMsg : errorMsg, !!result);
      return result;
    } catch (err) {
      console.error(`[ERROR] ${errorMsg}:`, err);
      showSonnerToast(errorMsg, false);
      return false;
    }
  }

  function _cleanupLinksStore(): void {
    linksStore.$patch({
      currentInternalPostLinks: [],
      currentInternalPostLinksPreviewData: [],
      currentLinksPreviewData: [],
    });
  }

  async function _openPost(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    await router.push({
      name: ROUTES_NAME.POST_BY_ID,
      params: { id },
    });
    return true;
  }

  function _createPostTextContent(post: PostModel): string {
    if (post.messageType === FeedTypeEnum.Idea && post.ideaData) {
      return htmlToText(
        post.ideaData.title +
          ` ${t('feed.idea.problem')}: ` +
          post.ideaData.problem +
          ` ${t('feed.idea.solution')}: ` +
          post.ideaData.solution
      );
    }
    return htmlToText(post.bodyHtml);
  }

  async function _copyPostContent(post: PostModel): Promise<boolean> {
    const text = _createPostTextContent(post);
    await copyToClipboard(text);
    showSonnerToast(t('appPopoverMenu.copy.textCopied'), true);
    return true;
  }

  async function _downloadPostAsFormat(post: PostModel, extension: DocumentExtensionEnum): Promise<boolean> {
    const response = await postStore.postDownload(post.messageType, extension, post.id);
    if (response && isBlob(response.blob)) {
      const status = await useFilesHybrid().downloadPost(response.blob, response.filename);
      showSonnerToast(t('files.successDownloaded'), status === FileStatusEnum.Success);
      return true;
    }
    showSonnerToast(t('files.failedDownloaded'), false);
    return false;
  }
  //#endregion

  //#region Bookmarks
  async function _addToBookmarks(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    return await _executeWithToast(
      () => postStore.addBookmark(id),
      t('feed.feedItemPostMenu.bookmark.bookmarkAdded'),
      t('feed.feedItemPostMenu.bookmark.bookmarkNotAdd')
    );
  }

  async function _removeFromBookmarks(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    return await _executeWithToast(
      () => postStore.removeBookmark(id),
      t('feed.feedItemPostMenu.bookmark.bookmarkRemoved'),
      t('feed.feedItemPostMenu.bookmark.bookmarkNotRemoved')
    );
  }
  //#endregion

  //#region Pin
  async function _pinPost(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    return await _executeWithToast(
      () => postStore.pinPost(id),
      t('feed.feedItemPostMenu.attach.postPinned'),
      t('feed.feedItemPostMenu.attach.postNotPinned')
    );
  }

  async function _unpinPost(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    return await _executeWithToast(
      () => postStore.unPinPost(id),
      t('feed.feedItemPostMenu.attach.postUnpinned'),
      t('feed.feedItemPostMenu.attach.postNotUnpinned')
    );
  }
  //#endregion

  //#region Follow
  async function _followPost(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    return await _executeWithToast(
      () => postStore.followToPost(id),
      t('feed.feedItemPostMenu.following.followed'),
      t('feed.feedItemPostMenu.following.notFollowed')
    );
  }

  async function _unfollowPost(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    return await _executeWithToast(
      () => postStore.unFollowFromPost(id),
      t('feed.feedItemPostMenu.following.unfollowed'),
      t('feed.feedItemPostMenu.following.notUnfollowed')
    );
  }
  //#endregion

  //#region Share
  async function _sharePost(post: PostModel): Promise<boolean> {
    if (!post.id) return false;
    const result = await shareEntity(post, ShareEntityType.Post, PostMenuActionEnum.Share);
    return !!result;
  }

  async function _repostPost(post: PostModel): Promise<boolean> {
    if (!post.id) return false;
    const result = await shareEntity(post, ShareEntityType.Post, PostMenuActionEnum.Repost);
    return !!result;
  }
  //#endregion

  //#region Send
  async function _sendPostToEmail(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    return await _executeWithToast(
      () => postStore.sendPostToEmail(id),
      t('feed.feedItemPostMenu.send.mailSend'),
      t('feed.feedItemPostMenu.send.mailNotSend')
    );
  }

  async function _sendPostToExternalEmail(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    await openPostSendToExternalEmailModal(id);
    return true;
  }

  async function _sendPostAsArchiveToEmail(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    await openShareArchiveLinkModal(id, ShareArchiveLinkType.UserItem);
    return true;
  }

  async function _sendPostNow(post: PostModel): Promise<boolean> {
    if (!post.id) return false;
    return await _executeWithToast(
      () => postStore.switchPlannedForce(post),
      t('feed.feedItemPostMenu.sendNow.success'),
      t('feed.feedItemPostMenu.sendNow.failed')
    );
  }
  //#endregion

  async function _deletePost(post: PostModel, redirectToFeed = false): Promise<boolean> {
    if (!post.id) return false;

    const deleteAction = post.plannedPostData
      ? () => postStore.switchPlannedDelete(post)
      : () => postStore.postDelete(post.id as number);

    const result = await _executeWithToast(
      deleteAction,
      t('feed.feedItemPostMenu.delete.postDeleted'),
      t('feed.feedItemPostMenu.delete.postNotDeleted')
    );

    if (result && redirectToFeed) {
      await router.push({ name: ROUTES_NAME.FEED });
    }

    return result;
  }

  async function _reschedulePost(p: PostActionPayload): Promise<boolean> {
    if (!p.ev || !p.postData) return false;

    const selectedDate = await openAppDatePickerPopover(
      p.ev,
      DateHelper.addMinutesToDateInISO(new Date().toISOString(), 15),
      true,
      true,
      5,
      DateHelper.getDateInUtc(new Date())
    );

    if (!selectedDate) return false;

    return await _executeWithToast(
      () => postStore.updatePlannedPost(p.postData, selectedDate),
      t('feed.feedItemPostMenu.reSchedule.successReSchedule'),
      t('feed.feedItemPostMenu.reSchedule.failedReSchedule')
    );
  }

  async function _editPost(post: PostModel): Promise<boolean> {
    if (!post.id) return false;
    await openPostEditModal(post);
    _cleanupLinksStore();
    return true;
  }

  async function _complainAboutPost(id: number | undefined): Promise<boolean> {
    if (!id) return false;
    await openPostComplainModal(id);
    return true;
  }

  /**
   * @todo Show viewers functionality is not fully implemented
   */
  async function _showViewers(post: PostModel): Promise<boolean> {
    if (!post.id) return false;
    console.warn('Show viewers action is not fully implemented');
    return true;
  }
  //#endregion

  //#region Action Mapping
  const _actionsMap: Record<PostMenuActionEnum, (p: PostActionPayload) => Promise<boolean>> = Object.freeze({
    [PostMenuActionEnum.Open]: async (p) => await _openPost(p.id),
    [PostMenuActionEnum.Delete]: async (p) => _deletePost(p.postData, p.redirectToFeed),
    [PostMenuActionEnum.Edit]: async (p) => _editPost(p.postData),
    [PostMenuActionEnum.AddToBookmarks]: async (p) => _addToBookmarks(p.id),
    [PostMenuActionEnum.RemoveFromBookmarks]: async (p) => _removeFromBookmarks(p.id),
    [PostMenuActionEnum.Follow]: async (p) => _followPost(p.id),
    [PostMenuActionEnum.UnFollow]: async (p) => _unfollowPost(p.id),
    [PostMenuActionEnum.Complain]: async (p) => _complainAboutPost(p.id),
    [PostMenuActionEnum.SendToEmail]: async (p) => _sendPostToEmail(p.id),
    [PostMenuActionEnum.SendToExternalEmail]: async (p) => _sendPostToExternalEmail(p.id),
    [PostMenuActionEnum.SendAsArchiveToEmail]: async (p) => _sendPostAsArchiveToEmail(p.id),
    [PostMenuActionEnum.Share]: async (p) => _sharePost(p.postData),
    [PostMenuActionEnum.Repost]: async (p) => _repostPost(p.postData),
    [PostMenuActionEnum.SendNow]: async (p) => _sendPostNow(p.postData),
    [PostMenuActionEnum.CopyText]: async (p) => _copyPostContent(p.postData),
    [PostMenuActionEnum.DownloadAsPDF]: async (p) => _downloadPostAsFormat(p.postData, DocumentExtensionEnum.PDF),
    [PostMenuActionEnum.DownloadAsZIP]: async (p) => _downloadPostAsFormat(p.postData, DocumentExtensionEnum.ZIP),
    [PostMenuActionEnum.ShareGeneral]: async (p) => _handleMenuAction(p, PostMenuActionTypeEnum.Share),
    [PostMenuActionEnum.Send]: async (p) => _handleMenuAction(p, PostMenuActionTypeEnum.Send),
    [PostMenuActionEnum.Download]: async (p) => _handleMenuAction(p, PostMenuActionTypeEnum.Download),
    [PostMenuActionEnum.ReSchedule]: async (p) => _reschedulePost(p),
    [PostMenuActionEnum.ShowViewers]: async (p) => _showViewers(p.postData),
    [PostMenuActionEnum.Pin]: async (p) => _pinPost(p.id),
    [PostMenuActionEnum.UnPin]: async (p) => _unpinPost(p.id),
  });

  async function _handleMenuAction(p: PostMenuPayload, actionType: PostMenuActionTypeEnum): Promise<boolean> {
    if (!p.postData || !p.ev) return false;
    await openPostMenu({ ...p, actionType });
    return true;
  }
  //#endregion

  //#region Public API
  async function openPostMenu(p: PostMenuPayload): Promise<boolean> {
    const { postData, feedFlag, actionType = PostMenuActionTypeEnum.All } = p;

    if (feedFlag === FeedFlagEnum.SharedPost) {
      await _openPost(postData.id);
      return true;
    }

    const menuItems = _getActionMenuItems({
      ...p,
      feedFlag: isAnyMobile ? FeedFlagEnum.PostPage : feedFlag,
      actionType,
    });

    const action = isAnyMobile ? await openPostContextSheet(menuItems) : await openPostContextMenuModal(menuItems);
    if (!action) return false;

    return await handleAction({ ...p, type: action, id: postData.id });
  }

  async function handleAction(p: PostActionPayload): Promise<boolean> {
    const handler = _actionsMap[p.type];
    if (!handler) {
      console.warn(`No handler found for action: ${p.type}`);
      return false;
    }

    try {
      return await handler(p);
    } catch (e) {
      console.error(`[ERROR] Error executing action ${p.type}:`, e);
      showSonnerToast(t('errorResponse'), false);
      return false;
    }
  }
  //#endregion

  return { openPostMenu, handleAction };
}
