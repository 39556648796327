import { defineStore } from 'pinia';
import {
  type ErrorMessageModel,
  LinkApiViewModel,
  PostModelWithLink,
  type ResponseErrorModel,
  type ResponseLinksPreviewModel,
} from '@/types';
import { $api } from '@/services';
import { cloneDeep } from 'lodash';
import { defaultPost } from '@/models';
import { compareStringArrays, getPostDataFromLink } from '@/helpers';

interface LinksStore {
  errors: ErrorMessageModel[];
  currentLinksPreviewData: LinkApiViewModel[];
  currentInternalPostLinksPreviewData: PostModelWithLink[];
  currentInternalPostLinks: string[];
}

export const useLinksStore = defineStore({
  id: 'links',
  state: (): LinksStore => ({
    errors: [],
    currentLinksPreviewData: [],
    currentInternalPostLinksPreviewData: [],
    currentInternalPostLinks: [],
  }),
  getters: {},
  actions: {
    setCurrentLinksPreviewData(data: LinkApiViewModel[]): void {
      this.currentLinksPreviewData = data;
    },
    setCurrentInternalPostLinksPreviewData(data: PostModelWithLink[]): void {
      this.currentInternalPostLinksPreviewData = data;
    },
    setCurrentInternalPostLinks(data: string[]): void {
      this.currentInternalPostLinks = data;
    },
    /**
     * Use this function to get data for links preview
     */
    async getLinksPreviewData(text: string): Promise<string[]> {
      const pattern = /https?:\/\/[^\s]+/g;
      const regex = new RegExp(pattern, 'g');
      const previewLinks = text.match(regex) || [];

      if (
        previewLinks.length &&
        compareStringArrays(
          previewLinks,
          this.currentLinksPreviewData.map((obj) => obj.url)
        )
      ) {
        const linksPreviewData = await this.linksPreviewCreate(previewLinks);
        this.setCurrentLinksPreviewData(linksPreviewData);
      }

      return previewLinks;
    },
    /**
     * Use this function to get posts data from links
     */
    async getInternalLinksPreviewData(): Promise<string[]> {
      const currentLinks = this.currentLinksPreviewData.map((obj) => obj.url);
      const allowedDomains = ['.communex.app/post', 'baykonet.de/post', 'kiwi-bkg-online.de/post'];
      const internalPostLinks = currentLinks.filter(
        (link) => allowedDomains.some((domain) => link.includes(domain)) || /localhost:\d+\/post/.test(link)
      );

      if (internalPostLinks.length && compareStringArrays(internalPostLinks, this.currentInternalPostLinks)) {
        this.setCurrentInternalPostLinksPreviewData([]);
        this.setCurrentInternalPostLinks(internalPostLinks);

        for (const url of internalPostLinks) {
          let post = await getPostDataFromLink(url);
          if (!post) {
            post = { ...cloneDeep(defaultPost), url } as PostModelWithLink;
          }
          this.setCurrentInternalPostLinksPreviewData([...this.currentInternalPostLinksPreviewData, post]);
        }
      }

      return internalPostLinks;
    },
    resetLinksPreview(): void {
      this.currentLinksPreviewData = [];
      this.currentInternalPostLinksPreviewData = [];
      this.currentInternalPostLinks = [];
    },
    filterCurrentLinksPreviewData(url: string): void {
      this.currentLinksPreviewData = this.currentLinksPreviewData.filter((n) => n.url !== url);
    },
    filterCurrentInternalPostLinksPreviewData(id: number): void {
      this.currentInternalPostLinksPreviewData = this.currentInternalPostLinksPreviewData.filter((n) => n.id !== id);
    },
    filterCurrentInternalPostLinks(url: string): void {
      this.currentInternalPostLinks = this.currentInternalPostLinks.filter((n) => n !== url);
    },
    async linksPreviewCreate(urls: string[]): Promise<LinkApiViewModel[]> {
      this.errors = [];
      const response = await $api.post.linksPreviewCreate(urls);

      if (response.statusCode === 200) {
        const model = response as ResponseLinksPreviewModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return [];
    },
  },
});
