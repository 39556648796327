import { isPlatform } from '@ionic/vue';
import type { NetworkSettingsTypeEnum } from '@/enums';
import type {
  ResponseErrorModel,
  ResponseNetworkModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkResourcesModel,
  NetworkLocateModel,
  ResponseNetworkLocateModel,
  NetworkUploadLogoResponseModel,
  NetworkSettingsModel,
  NetworkMobileAppsModel,
  NetworkBrandingModel,
  ResponseNetworkMobileAppsModel,
  ResponseNetworkBrandingModel,
  ResponseNetworkDesignModel,
  NetworkDesignModel,
  NetworkUploadFaviconResponseModel,
  ResponseAllowedEmailSuffixesModel,
} from '@/types';
import { useToasts } from '@/helpers';
import axios from '@/services/axios';
import { getAdditionalHeaders } from '@/services/getters/getAdditionalHeaders';
import { getCommonHeaders } from '@/services/getters/getCommonHeaders';

export class NetworksApiService {
  private clientSecret: string = isPlatform('ios')
    ? import.meta.env.VITE_AXIOS_CLIENT_SECRET_IOS
    : import.meta.env.VITE_AXIOS_CLIENT_SECRET_WEB;

  private api = import.meta.env.VITE_API_URL;
  private env = import.meta.env.VITE_APP_ENV; // qa / beta / prod

  /** @todo - add version to the request */
  // private version = import.meta.env.VITE_API_VERSION;

  //#region Settings
  async settings(): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/?clientSecret=${this.clientSecret}`);
  }
  async saveNetworkSettings(
    data: NetworkSettingsModel | NetworkBrandingModel | NetworkMobileAppsModel | NetworkDesignModel,
    type: NetworkSettingsTypeEnum
  ): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.post(`/networks/settings/${type}`, data);
  }
  async networkSettings(): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/all`);
  }
  async mobileAppsSettings(): Promise<ResponseNetworkMobileAppsModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/mobileapps`);
  }
  async brandingSettings(): Promise<ResponseNetworkBrandingModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/branding`);
  }
  async designSettings(): Promise<ResponseNetworkDesignModel | ResponseErrorModel> {
    return axios.get(`/networks/settings/style`);
  }
  async allowedEmailSuffixes(): Promise<ResponseAllowedEmailSuffixesModel | ResponseErrorModel> {
    return axios.get(`/networks/allowedEmailSuffixes`);
  }
  /**
   * @note Not in use
   */
  // async currentNetworkSettings(): Promise<ResponseNetworkFullSettingsModel | ResponseErrorModel> {
  //   return axios.get(`/networks/settings/all`);
  // }
  //#endregion

  //#region Miscellaneous
  async current(): Promise<ResponseNetworkModel | ResponseErrorModel> {
    return axios.get(`/networks/current/?clientSecret=${this.clientSecret}`);
  }
  async getResources(): Promise<ResponseNetworkResourcesModel | ResponseErrorModel> {
    return axios.get(`/networks/resources?clientSecret=${this.clientSecret}&mobile=true`);
  }
  async uploadLogo(key: string, type: string): Promise<NetworkUploadLogoResponseModel | ResponseErrorModel> {
    return axios.post('/networks/logo', {
      key: key,
      type: type,
    });
  }
  async uploadFavicon(key: string, type: string): Promise<NetworkUploadFaviconResponseModel | ResponseErrorModel> {
    return axios.post('/networks/favicon', {
      key: key,
      type: type,
    });
  }
  async getLocate(uri: string): Promise<NetworkLocateModel | undefined> {
    const { showSonnerToast } = useToasts();

    /** @todo - add version to the request */
    // const url = `${uri}${this.api}/networks/locate/?clientSecret=${this.clientSecret}&env=${this.env}&version=${version}`;
    const url = `${uri}${this.api}/networks/locate/?clientSecret=${this.clientSecret}&env=${this.env}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        redirect: 'follow',
        headers: {
          'Content-Type': 'application/json',
          ...getCommonHeaders(),
          ...getAdditionalHeaders(),
        },
      });

      if (response.status === 302) {
        const locationHeader = response.headers.get('Location');
        if (!locationHeader) {
          showSonnerToast('Failed to get network locate', false); // TODO: i18n
          throw new Error('No location header found in redirect response');
        }
        window.location.href = locationHeader;
        return;
      }

      if (response.ok) {
        const responseToken: ResponseNetworkLocateModel = await response.json();
        return responseToken.data;
      }

      throw new Error(`Failed to get network locate: ${response.status} ${response.statusText}`);
    } catch (e) {
      console.error('[Error] NetworksApiService - {getLocate}:', e);
      return undefined;
    }
  }
  //#endregion
}
