import { isPlatform } from '@ionic/vue';

export function useCustomScroll(): any {
  const init = async (element: any, isBoard?: boolean) => {
    const isMobile = isPlatform('android') || isPlatform('ios');
    if (!isMobile) {
      const contents = isBoard ? element.querySelectorAll('.board') : element.querySelectorAll('ion-content');
      contents.forEach((element: any) => {
        const scrollbarHidden = element?.className.includes('scrollbar-hidden') ?? false;
        const isGutterBothEdges = element?.className.includes('both-edges-gutter');
        const styles = document.createElement('style');
        styles.textContent = scrollbarHidden
          ? `
        /* firefox */
        *{scrollbar-width: none;}
          ::-webkit-scrollbar {height: 0; width:0;}`
          : isBoard
            ? `
      /* firefox */
      *{scrollbar-color: var(--ion-color-custom-element-lighter) var(--ion-color-light-background-contrast);scrollbar-height: 1rem;}
      /* chrome */
      ::-webkit-scrollbar {height: 1rem; }
      ::-webkit-scrollbar-track {background-color: var(--ion-color-light-background-contrast);border-radius: 3px;}
      ::-webkit-scrollbar-thumb {border-radius: 5px;background-color: var(--ion-color-custom-element-lighter);border: 3px solid var(--ion-color-light-background-contrast);}
    `
            : `
    /* firefox */
    *{scrollbar-color: var(--ion-color-medium) var(--ion-color-light);scrollbar-width: thin;}
    /* chrome */
    *{scrollbar-gutter: ${isGutterBothEdges ? 'stable both-edges' : 'auto'}  !important;}
    ::-webkit-scrollbar {width: 6px;}
    ::-webkit-scrollbar-track {background-color: var(--ion-color-custom-element-lighter);}
    ::-webkit-scrollbar-thumb {border-radius: 3px;background-color: var(--ion-color-custom-element-darker);}
  `;

        isBoard ? element.appendChild(styles) : element.shadowRoot.appendChild(styles);
      });
    }
  };
  return { init };
}
