import { App } from '@capacitor/app';
import { isPlatform } from '@ionic/vue';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import type {
  RouteRecordName,
  RouteLocationNormalized,
  RouteLocationRaw,
  RouteRecordRaw,
  RouteLocationNormalizedLoaded,
} from 'vue-router';
import { UserRoleEnum, RouteWithSearchEnum, RequirementsEnum } from '@/enums';
import { useTaskManagement, useAiAssistant, useRequirements, useWiki, useErrors, useSession } from '@/helpers';
import { useI18n } from '@/i18n';
import {
  useUserStore,
  useAppStore,
  useGroupsStore,
  useCustomPageStore,
  useTopicStore,
  useWikiStore,
  useAuthStore,
} from '@/store';
import { OAuthGrantTypeEnum, TokenModeEnum } from '@/enums/auth';
import { createRouteConfig, ROUTES_NAME, NO_AUTH_ROUTES, isValidRoute, type RouteName } from './routes';

//#region Inline Composables Specific to this module
const useSearchQuery = () => {
  const _routeHandlers: Record<RouteWithSearchEnum, () => void> = Object.freeze({
    [RouteWithSearchEnum.GROUPS]: (): void => {
      useGroupsStore().$patch({
        keepSearchQuery: router.currentRoute.value.name === ROUTES_NAME.GROUP_BY_ID,
      });
    },
    [RouteWithSearchEnum.USERS]: (): void => {
      useUserStore().$patch({
        keepSearchQuery: router.currentRoute.value.name === ROUTES_NAME.USER_BY_ID,
      });
    },
    [RouteWithSearchEnum.PAGES]: (): void => {
      useCustomPageStore().$patch({
        keepSearchQuery: router.currentRoute.value.name === ROUTES_NAME.PAGE_BY_ID,
      });
    },
    [RouteWithSearchEnum.TOPICS]: (): void => {
      useTopicStore().$patch({
        keepSearchQuery: router.currentRoute.value.name === ROUTES_NAME.TOPIC_BY_ID,
      });
    },
  });

  /**
   * Updates the keepSearchQuery state based on the route transition
   * to prevent search query state from being lost when navigating between routes
   */
  function updateState(toRouteName: RouteRecordName): void {
    if (typeof toRouteName !== 'string') return;

    const handler = _routeHandlers[toRouteName as RouteWithSearchEnum];
    if (handler) handler();
  }

  return { updateState };
};

const useWikiEditing = () => {
  /**
   * Handle entry into wiki edit mode
   * to prevent editing of wiki pages that are locked
   */
  async function handleWikiEditEntry(routeName: RouteRecordName, id: number): Promise<boolean> {
    if (routeName !== ROUTES_NAME.WIKI_EDIT) return true;

    const preventRoute = await useWiki().preventEdit(id);
    if (preventRoute) return false;

    useWikiStore().setEditMode(true);
    return true;
  }

  /**
   * Handle exit from wiki edit mode
   * to unlock the wiki page when leaving the edit mode
   */
  async function handleWikiEditExit(routeName: RouteRecordName, id: number): Promise<void> {
    if (routeName !== ROUTES_NAME.WIKI_EDIT) return;

    await useWikiStore().unlockEdit(id);
    useWikiStore().setEditMode(false);
  }

  return { handleWikiEditEntry, handleWikiEditExit };
};

const useNavigation = () => {
  /**
   * Handle middle-click navigation
   * to open a new tab with the url
   * when the user is on the home page and clicks the middle button
   */
  async function handleMiddleClick(url: string): Promise<boolean> {
    if (!useAppStore().isMiddleButtonClicked) return true;

    useAppStore().setMiddleButtonClicked(false);
    window.open(url, '_blank');
    return false;
  }

  /**
   * Handle Android back button behavior
   * to exit the app or navigate to the home page
   * when the user is on the home page and clicks the back button
   */
  async function handleAndroidBackButton(toName: RouteRecordName, fromName: RouteRecordName): Promise<boolean> {
    const isAndroid = isPlatform('android') && !isPlatform('mobileweb');

    if (toName === ROUTES_NAME.HOME && fromName === useAppStore().homePage.name) {
      if (isAndroid) {
        await App.exitApp();
        return false;
      }
      await router.push(useAppStore().homePage);
    }

    return true;
  }

  return { handleMiddleClick, handleAndroidBackButton };
};

const useRequirementsCheck = () => {
  /**
   * Check if requirements checking is currently in progress
   * to prevent navigation to a route that requires requirements to be met
   */
  async function isRequirementsCheckInProgress(): Promise<boolean> {
    const inProgress = await useRequirements().requirementsCheckInProcess();

    if (inProgress) {
      const { t } = useI18n();
      const { handleError } = useErrors();

      handleError({
        show: true,
        error: undefined,
        message: t('read.required'),
      });
    }

    return inProgress;
  }

  return { isRequirementsCheckInProgress };
};

const useAuthGuard = () => {
  function _isPublicRoute(routeName: RouteName): boolean {
    return NO_AUTH_ROUTES.includes(routeName);
  }

  /**
   * Handles OAuth callback parameters
   * when app is initialized
   * from OAuth authentication flow
   */
  async function _handleOAuthCallback(route: RouteLocationNormalizedLoaded): Promise<boolean | RouteLocationRaw> {
    try {
      await useAuthStore().tokenByRedirect(
        String(route.query.code),
        String(route.query.grantType) as OAuthGrantTypeEnum
      );

      if (!useAuthStore().isAuth()) {
        console.warn('[WARN] Failed to authenticate via OAuth callback');
        return false;
      }

      const targetUrl = route.query.redirect ? String(route.query.redirect) : null;
      await useSession().setupApp({
        newWebSocket: true,
        newPush: true,
        skipRequirementsCheck: true,
        skipRedirect: !!targetUrl, // We need to skip redirect if targetUrl is present, so we can handle it in `_handleTargetLocation` in `setupApp`
      });

      const user = await useUserStore().currentUser();
      if (!user) {
        console.warn('[WARN] Failed to load user data after authentication');
        return false;
      }

      const requirementsMet = await useRequirements().check(RequirementsEnum.All);

      if (requirementsMet && targetUrl && isValidRoute(targetUrl)) {
        return targetUrl;
      }

      return requirementsMet ?? false;
    } catch (error) {
      console.error('[ERROR] Error in handleOAuthCallback:', error);
      return false;
    }
  }

  async function _tryRefreshToken(): Promise<boolean> {
    await useAuthStore().token(TokenModeEnum.Code, {
      silent: true,
      force: true,
    });

    return useAuthStore().isAuth();
  }

  /**
   * Redirects to the login page, preserving the intended destination URL
   */
  async function _redirectToLogin(targetUrl: string): Promise<void> {
    const validTargetUrl = targetUrl && isValidRoute(targetUrl) ? targetUrl : null;
    const query = validTargetUrl ? { redirect: validTargetUrl } : {};

    let isHomeRoute = false;
    try {
      if (targetUrl) {
        const targetLocation = router.resolve(targetUrl);
        isHomeRoute = targetLocation.name === ROUTES_NAME.HOME;
      }
    } catch (error) {
      console.warn('[WARN] Error resolving target URL:', error);
    }

    if (isHomeRoute) {
      await router.push({ name: ROUTES_NAME.LOGIN });
      return;
    }

    await router.push({ name: ROUTES_NAME.LOGIN, query });
  }

  /**
   * Main route guard function that enforces authentication and permissions
   */
  async function guardRoute(to: RouteLocationNormalizedLoaded): Promise<boolean | RouteLocationRaw> {
    if (_isPublicRoute(to.name as RouteName)) return true;

    if (useAuthStore().isAuth() && to.name !== ROUTES_NAME.AUTH) return true;

    if (to.name === ROUTES_NAME.AUTH) {
      return await _handleOAuthCallback(to);
    }

    const isAuthenticated = await _tryRefreshToken();
    if (isAuthenticated) return true;

    await _redirectToLogin(to.fullPath);
    return false;
  }

  /**
   * Checks if the user has required permissions for a route
   */
  function hasRequiredPermissions(to: RouteLocationNormalized): boolean {
    const { getAccessToTaskManagement } = useTaskManagement();
    const { getAccessToAi } = useAiAssistant();
    const userRole = useUserStore().current?.roleId ?? 0;

    const permissionChecks = [
      to.meta.requiresModeratorRights && userRole < UserRoleEnum.Moderator,
      to.meta.requiresAdminRights && userRole < UserRoleEnum.Administrator,
      to.meta.requiresStandardUserRights && userRole < UserRoleEnum.User,
      to.meta.requiresProjectsPermission && !getAccessToTaskManagement(),
      to.meta.requiresAiAssistantPermission && !getAccessToAi(),
    ];

    return !permissionChecks.some(Boolean);
  }

  return { guardRoute, hasRequiredPermissions };
};

const { updateState } = useSearchQuery();
const { handleWikiEditEntry, handleWikiEditExit } = useWikiEditing();
const { handleMiddleClick, handleAndroidBackButton } = useNavigation();
const { isRequirementsCheckInProgress } = useRequirementsCheck();
const { guardRoute, hasRequiredPermissions } = useAuthGuard();
//#endregion

const routes: RouteRecordRaw[] = createRouteConfig();

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

//#region Route Hooks
/**
 * Handles the route permissions before resolving the route
 */
router.beforeResolve(async (to): Promise<boolean> => {
  try {
    // Check if requirements check is in progress
    if (await isRequirementsCheckInProgress()) {
      return false;
    }

    // Check permissions for the route
    if (!hasRequiredPermissions(to)) {
      await router.push({ name: ROUTES_NAME.FEED });
      return false;
    }

    return true;
  } catch (error) {
    console.error('[ERROR] Error in beforeResolve guard:', error);
    return false;
  }
});

/**
 * Handles the navigation before each route change
 */
router.beforeEach(async (to, from): Promise<boolean | RouteLocationRaw> => {
  try {
    const guardResult = await guardRoute(to);
    // Check if the result is a route redirect object rather than a boolean
    if (guardResult !== true && guardResult !== false) {
      // It's a route location, return it for redirection
      return guardResult;
    }
    // If guardResult is false, deny navigation
    if (!guardResult) return false;

    await handleWikiEditExit(from.name, Number(from.params.id));
    const canContinueToWiki = await handleWikiEditEntry(to.name, Number(to.params.id));
    if (!canContinueToWiki) return false;

    updateState(to.name);

    const shouldContinueAfterMiddleClick = await handleMiddleClick(to.fullPath);
    if (!shouldContinueAfterMiddleClick) return false;

    return await handleAndroidBackButton(to.name, from.name);
  } catch (error) {
    const { handleError } = useErrors();

    const defaultMessage = `Error during navigation from ${String(from.name)} to ${String(to.name)}`;
    const errorMessage = error instanceof Error ? error.message : defaultMessage;

    handleError({
      show: false,
      error: undefined,
      message: errorMessage,
    });

    await router.push({ name: ROUTES_NAME.LOGIN });
    return false;
  }
});

/**
 * Handles the error after each route change
 */
router.afterEach((to, from, failure) => {
  if (failure) {
    const { handleError } = useErrors();
    handleError({
      show: false,
      error: undefined,
      message: String(failure),
    });
  }
});
//#endregion

declare module 'vue-router' {
  interface RouteMeta {
    isActive?: boolean;
  }
}

export * from './routes';

export default router;
