import { Preferences } from '@capacitor/preferences';
import { toastController, isPlatform } from '@ionic/vue';
import { informationCircle } from 'ionicons/icons';
import { useAppStore } from '@/store';
import { useI18n } from '@/i18n';
import { isNativeMobile, clearStorage } from '@/helpers';
import { useErrors, useToasts } from '@/helpers';
import { $api } from '@/services';
import { ROUTES_NAME } from '@/router';
import router from '@/router';

export const VERSION_STORAGE_KEYS = {
  CLEAR_CACHE: 'clearCacheAfterReload',
  NEW_VERSION: 'newVersion',
  NEW_BUILD: 'newBuild',
} as const;

type IUseVersionChange = {
  check: () => Promise<void>;
};

export function useVersionChange(): IUseVersionChange {
  //#region Variables
  const appStore = useAppStore();
  const { t } = useI18n();
  const { handleError } = useErrors();
  const { showSonnerToast } = useToasts();
  //#endregion

  //#region Helpers
  function _clearSessionFlags(): void {
    Object.values(VERSION_STORAGE_KEYS).forEach((key) => {
      sessionStorage.removeItem(key);
    });
  }

  /**
   * @note Not in use
   * @deprecated
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function _getCurrentRoute(): { path: string; params: Record<string, any> } {
    const route = router.currentRoute.value;
    return {
      path: route.path,
      params: {
        ...route.params,
        ...route.query,
        hash: route.hash,
      },
    };
  }

  /**
   * @note Not in use
   * @deprecated
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function _restoreUrl(savedUrl: string | null, savedParams: string | null): void {
    if (!savedUrl) return;

    try {
      const currentRoute = router.currentRoute.value;
      if ([ROUTES_NAME.LOGIN, ROUTES_NAME.HOME].includes(currentRoute.name as any)) {
        return;
      }

      const params = savedParams ? JSON.parse(savedParams) : {};
      console.log('[INFO] Restoring route:', {
        from: { path: currentRoute.path, params: currentRoute.params },
        to: { path: savedUrl, params },
      });

      router.push({
        path: '/login',
        query: { redirect: savedUrl },
      });
    } catch (e) {
      console.error('[ERROR] Failed to restore route:', e);
    }
  }

  async function _handleClearStorage(version: string): Promise<void> {
    try {
      await clearStorage();
      if (__DEV__) showSonnerToast(t('successfulAppVersionUpdate', { version }), true);
    } catch (error: any) {
      handleError({
        show: true,
        error,
        message: t('failedAppVersionUpdate', { version }),
      });
    }
  }
  //#endregion

  //#region Mobile
  async function _handleNativeVersionChange(): Promise<void> {
    const currentVersion = appStore.appVersion;
    console.log('[VERSION_CHECK] Current app version:', currentVersion);

    const localVersion = await Preferences.get({ key: 'appVersion' });
    console.log('[VERSION_CHECK] Stored local version:', localVersion.value);

    if (!localVersion.value) {
      console.log('[VERSION_CHECK] No stored version found, setting initial version');
      await Preferences.set({ key: 'appVersion', value: currentVersion });
      return;
    }

    if (localVersion.value !== currentVersion) {
      console.log('[VERSION_CHECK] Version change detected:', {
        from: localVersion.value,
        to: currentVersion,
      });

      // Updating stored version
      await Preferences.set({ key: 'appVersion', value: currentVersion });

      // Clearing storage
      await _handleClearStorage(currentVersion);
    }
  }
  //#endregion

  //#region Electron
  /**
   * @todo Implement later
   */
  async function _handleElectronVersionChange(): Promise<void> {
    const urlNewVersion = await $api.tool.getAppVersion();
    if (urlNewVersion.length > 0 && confirm(urlNewVersion)) {
      const toast = await toastController.create({
        duration: 0,
        message: `<a href="${urlNewVersion}" target="_blank">Download</a>`,
        icon: informationCircle,
      });
      await toast.present();
    }
  }
  //#endregion

  //#region Web
  async function _preReloadPhase(): Promise<void> {
    console.log('[VERSION_CHECK] Pre-reload phase detected');

    // Remote info
    const meta = await $api.tool.getMeta();
    if (!meta) throw new Error('Failed to fetch meta.json');
    const { version, build } = meta;

    // Local info
    const cachedVersion = appStore.appVersion;
    const cachedBuild = appStore.appBuild;

    console.log('[VERSION_CHECK] Version comparison:', {
      cached: { version: cachedVersion, build: cachedBuild },
      fetched: { version, build },
    });

    if (version !== cachedVersion || build !== cachedBuild) {
      // Storring new info
      // to use later in post-reload phase
      sessionStorage.setItem(VERSION_STORAGE_KEYS.CLEAR_CACHE, 'true');
      sessionStorage.setItem(VERSION_STORAGE_KEYS.NEW_VERSION, version);
      sessionStorage.setItem(VERSION_STORAGE_KEYS.NEW_BUILD, build);

      // Now we need to reload the page
      // to revalidate in-memory cache
      window.location.reload();
    }
  }

  async function _postReloadPhase(): Promise<void> {
    console.log('[VERSION_CHECK] Post-reload phase detected.');

    // Retrieving new info
    // from pre-reload phase
    const newVersion = sessionStorage.getItem(VERSION_STORAGE_KEYS.NEW_VERSION);
    const newBuild = sessionStorage.getItem(VERSION_STORAGE_KEYS.NEW_BUILD);

    console.log('[VERSION_CHECK] Retrieved session data:', { newVersion, newBuild });

    // We need to clear all session flags
    // immediately after the reload
    // to prevent any loops
    _clearSessionFlags();

    if (newVersion && newBuild) {
      // Updating app info
      appStore.setAppInfo(newVersion, newBuild);

      // Clearing storage
      await _handleClearStorage(newVersion);
    }
  }

  async function _handleWebVersionChange(): Promise<void> {
    const isPostReloadPhase = sessionStorage.getItem(VERSION_STORAGE_KEYS.CLEAR_CACHE) === 'true';
    isPostReloadPhase ? await _postReloadPhase() : await _preReloadPhase();
  }
  //#endregion

  //#region Public
  async function check(): Promise<void> {
    try {
      console.log('[VERSION_CHECK] Starting version check...');

      if (isNativeMobile) {
        console.log('[VERSION_CHECK] Handling native mobile version check');
        await _handleNativeVersionChange();
        return;
      }

      if (isPlatform('electron')) {
        console.log('[VERSION_CHECK] Handling electron version check');
        await _handleElectronVersionChange();
        return;
      }

      console.log('[VERSION_CHECK] Handling web version check');
      await _handleWebVersionChange();
    } catch (error: any) {
      handleError({
        show: true,
        error,
        message: `${t('failedAppVersionUpdate')}: ${error.message}`,
      });
    }
  }
  //#endregion

  return { check };
}
