import { Capacitor } from '@capacitor/core';
import { getPlatforms } from '@ionic/core';

/**
 * Use it to get additional headers for the request
 *
 * @returns ApiVersion, BrowserPlatforms, CapacitorPlatform, IsNativePlatform
 */
export function getAdditionalHeaders(): Record<string, string | string[]> {
  const additional: Record<string, string | string[]> = {
    ApiVersion: import.meta.env.VITE_API_VERSION,
    BrowserPlatforms: getPlatforms(),
    CapacitorPlatform: Capacitor.getPlatform(),
    IsNativePlatform: Capacitor.isNativePlatform() ? 'True' : 'False',
  };

  return additional;
}
