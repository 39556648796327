import { alertController } from '@ionic/vue';
import { filter } from 'lodash';

import { ActionAccessEnum, AppIconsEnum, FileActionEnum, FileStatusEnum, ShareArchiveLinkType } from '@/enums';
import {
  canSendArchivedLink,
  openDocsMoveFileModal,
  openTitleChangeModal,
  openShareArchiveLinkModal,
  useToasts,
  useFilesHybrid,
} from '@/helpers';
import { useI18n } from '@/i18n';

import { useDocStore } from '@/store';
import type { DocsMenuItemModel, FolderModel } from '@/types';
import { $api } from '@/services';
import { isBlob } from './guards';

type IUseFolderActions = {
  /** Use it to rename a folder */
  renameFolder: (folder: FolderModel) => Promise<boolean>;

  /** Use it to move a folder */
  moveFolder: (folder: FolderModel) => Promise<boolean>;

  /** Use it to delete a folder */
  deleteFolder: (folder: FolderModel) => Promise<boolean>;

  /** Use it to share an archive link of a folder*/
  shareArchiveLink: (folder: FolderModel) => Promise<undefined>;

  /** Use it to get actions menu items */
  getActionsMenuItems: (doc: FolderModel) => DocsMenuItemModel[];

  /** Use it to make an action */
  whichActionToMake: (action: FileActionEnum, folder: FolderModel) => Promise<boolean | undefined>;
};

export const useFolderActions = (): IUseFolderActions => {
  //#region Variables
  const { t } = useI18n();
  const { showSonnerToast } = useToasts();
  const docStore = useDocStore();
  //#endregion

  //#region Public methods
  const renameFolder = async (folder: FolderModel): Promise<boolean> => {
    const result = await openTitleChangeModal(null, folder.name);

    if (!result) {
      console.error('Failed to rename file');
      return false;
    }

    const renameFileResult = await docStore.renameFolder(folder.id, result.title);
    showSonnerToast(renameFileResult ? t('files.fileSuccessRenamed') : t('files.fileFailedRenamed'), renameFileResult);

    return renameFileResult;
  };

  const moveFolder = async (folder: FolderModel): Promise<boolean> => {
    const result = await openDocsMoveFileModal(folder.id);
    if (!result) {
      console.error('Failed to move file');
      return false;
    }

    const moveFileResult = await docStore.moveFolder(result.folderId, result.groupId, folder.id);
    showSonnerToast(moveFileResult ? t('files.folderSuccessMoved') : t('files.folderFailedMoved'), moveFileResult);
    return moveFileResult;
  };

  const deleteFolder = async (folder: FolderModel): Promise<boolean> => {
    let deleteFolderResult: boolean = false;

    const alert = await alertController.create({
      message: `${t('documents.popup.deleteFolder')} <strong>${folder.name}?</strong>`,
      buttons: [
        {
          text: t('no'),
          role: 'cancel',
          cssClass: 'custom-alert-buttons',
        },
        {
          text: t('yes'),
          role: 'confirm',
          cssClass: 'custom-alert-buttons',
          handler: async () => {
            deleteFolderResult = await docStore.deleteFolder(folder.id);
          },
        },
      ],
    });

    await alert.present();

    const dismissed = await alert.onDidDismiss();
    if (dismissed.role === 'confirm') {
      showSonnerToast(
        deleteFolderResult ? t('files.fileSuccessDeleted') : t('files.fileFailedDeleted'),
        deleteFolderResult
      );
    }

    return deleteFolderResult;
  };

  const shareArchiveLink = async (folder: FolderModel): Promise<undefined> => {
    await openShareArchiveLinkModal(folder.id, ShareArchiveLinkType.Folder);
    return undefined;
  };

  const downloadFolder = async (folder: FolderModel): Promise<undefined> => {
    const response = await $api.doc.downloadFolder(folder.id);
    if (isBlob(response)) {
      const status = await useFilesHybrid().downloadFolder(folder.id, response);
      showSonnerToast(t('files.folderSuccessDownloaded'), status === FileStatusEnum.Success);
    } else {
      showSonnerToast(t('files.folderFailedDownloaded'), false);
      console.warn(`[WARN] Response is not a Blob: ${JSON.stringify(response)}`);
    }
  };

  const getActionsMenuItems = (folder: FolderModel): DocsMenuItemModel[] => {
    const { t } = useI18n();

    const menuItems: DocsMenuItemModel[] = [
      {
        value: FileActionEnum.SendAsArchiveToEmail,
        title: t('files.menu.sendAsArchiveToEmail'),
        icon: AppIconsEnum.Mail,
        disabled: !(folder.access.includes(ActionAccessEnum.Share) && canSendArchivedLink()),
      },
      {
        value: FileActionEnum.Rename,
        title: t('files.menu.rename'),
        icon: AppIconsEnum.Letters,
        disabled: !folder.access.includes(ActionAccessEnum.Rename),
      },

      {
        value: FileActionEnum.Move,
        title: t('files.menu.move'),
        icon: AppIconsEnum.Move,
        disabled: !folder.access.includes(ActionAccessEnum.Move),
      },

      {
        value: FileActionEnum.Download,
        title: t('files.menu.download'),
        icon: AppIconsEnum.Download,
        disabled: false,
      },

      {
        value: FileActionEnum.Delete,
        title: t('appPopoverMenu.delete.title'),
        icon: AppIconsEnum.Remove,
        disabled: !folder.access.includes(ActionAccessEnum.Delete),
      },
    ];

    return filter(menuItems, ({ disabled }) => !disabled);
  };

  const whichActionToMake = async (action: FileActionEnum, folder: FolderModel): Promise<boolean | undefined> => {
    const actionHandlers: Partial<Record<FileActionEnum, () => Promise<boolean | undefined>>> = {
      [FileActionEnum.Rename]: () => renameFolder(folder),
      [FileActionEnum.Move]: () => moveFolder(folder),
      [FileActionEnum.Delete]: () => deleteFolder(folder),
      [FileActionEnum.SendAsArchiveToEmail]: () => shareArchiveLink(folder),
      [FileActionEnum.Download]: () => downloadFolder(folder),
    };

    const handler = actionHandlers[action];
    if (handler) {
      return await handler();
    }

    return undefined;
  };
  //#endregion

  return {
    deleteFolder,
    renameFolder,
    moveFolder,
    shareArchiveLink,
    whichActionToMake,
    getActionsMenuItems,
  };
};
