import type { OpenAI } from 'openai';
import type { Thread, Assistant } from 'openai/src/resources/beta';
import { defineStore } from 'pinia';

import { AiModeEnum } from '@/enums';
import { useAiAssistant, useErrors } from '@/helpers';
import { useAppStore } from '@/store';
import type { ChatGPTMessage } from '@/types';
import { useI18n } from '@/i18n';

type AiAssistantState = {
  assistant: Assistant | null;
  isLoading: boolean;
  isFailed: boolean;
  mode: AiModeEnum;
  threadId: string;
  threads: {
    id: string;
    obj: Thread | null;
    files: OpenAI.Beta.Threads.Message.Attachment[];
    images: OpenAI.Images.Image[];
    messages: ChatGPTMessage[];
  }[];
  formText: string;
  showDeleteMessageAlert: boolean;
};

export const useAiAssistantStore = defineStore('ai', {
  state: (): AiAssistantState => ({
    assistant: null,
    isLoading: false,
    isFailed: false,
    mode: AiModeEnum.Default,
    threadId: '',
    threads: [],
    formText: '',
    showDeleteMessageAlert: true,
  }),
  getters: {
    getAssistant: (state) => state.assistant,
    getMode: (state) => state.mode,
    getThread: (state) => state.threads.find((t) => t.id === state.threadId),
    getThreadFiles: (state) => state.threads.find((t) => t.id === state.threadId)?.files,
    getThreadImages: (state) => state.threads.find((t) => t.id === state.threadId)?.images,
    getThreadMessages: (state) => state.threads.find((t) => t.id === state.threadId)?.messages,
    getMessagesById: (state) => (threadId: string) => state.threads.find((t) => t.id === threadId)?.messages,
    getMessageById: (state) => (id: string) =>
      state.threads.find((t) => t.id === state.threadId)?.messages.find((m) => m.id === id),
  },
  actions: {
    //#region Setters
    setAssistant(assistant: Assistant) {
      this.assistant = assistant;
    },
    setLoading(value: boolean) {
      this.isLoading = value;
    },
    setFailed(value: boolean) {
      this.isFailed = value;
    },
    setMode(mode: AiModeEnum) {
      this.mode = mode;
    },
    setThread(thread: Thread) {
      this.threads.push({
        id: thread.id,
        obj: thread,
        files: [],
        images: [],
        messages: [],
      });
      this.threadId = thread.id;
    },
    setFormText(text: string) {
      this.formText = text;
    },
    setShowDeleteMessageConfirmation(show: boolean) {
      this.showDeleteMessageAlert = show;
    },
    setMessage(threadId: string, message: ChatGPTMessage): boolean {
      return this.setItem(threadId, message, 'messages');
    },
    setFile(threadId: string, file: OpenAI.Beta.Threads.Message.Attachment): boolean {
      return this.setItem(threadId, file, 'files');
    },
    setImage(threadId: string, image: { id?: string; url?: string }): boolean {
      return this.setItem(threadId, image, 'images');
    },
    setItem<T>(threadId: string, item: T, key: 'files' | 'images' | 'messages'): boolean {
      if (!item) {
        console.error(`[ERROR] ${key} is empty`);
        return false;
      }
      const thread = this.threads.find((t) => t.id === threadId);
      if (thread) {
        (thread[key] as T[]).push(item);
        return true;
      }
      return false;
    },
    updateMessage(id: string, propObj: Partial<ChatGPTMessage>): boolean {
      const message = this.getMessageById(id);
      if (!message) {
        console.error('[ERROR] Message not found');
        return false;
      }
      Object.assign(message, propObj);
      return true;
    },
    clearMessages(threadId: string): void {
      const thread = this.threads.find((t) => t.id === threadId);
      if (thread) thread.messages.splice(0);
    },
    deleteThread(threadId: string): boolean {
      this.clearMessages(threadId);
      const index = this.threads.findIndex((t) => t.id === threadId);
      if (~index) {
        this.threads.splice(index, 1);
        return true;
      }
      return false;
    },
    //#endregion

    //#region API
    async sendMessage(sendObj: {
      threadId: string;
      text?: string;
      files?: OpenAI.FileObject[];
      id?: string;
    }): Promise<boolean> {
      const { t } = useI18n();
      const { threadId, text, files, id } = sendObj;

      this.setFailed(false);
      this.setLoading(true);

      if (!this.assistant) {
        console.error('[ERROR] No assistant exists');
        return false;
      }

      if (!text?.trim() && !id) {
        console.error('[ERROR] Text or id is required');
        return false;
      }

      this.setFormText('');

      const appStore = useAppStore();
      const aiAssistantHelper = useAiAssistant();

      let message: ChatGPTMessage | undefined;
      if (id) {
        message = this.getMessageById(id);
      } else {
        if (!text?.trim().length) {
          console.error('Failed to send message: Text is empty');
          return false;
        }

        message = {
          id: Date.now().toString(),
          role: 'user',
          content: text,
          attachments: { files: [], images: [] },
          created: Date.now(),
          language: appStore.locale,
          isProcessing: false,
        };
      }

      if (!message) {
        console.error('[ERROR] Message not found');
        return false;
      }
      this.setMessage(threadId, message);

      try {
        const result = await aiAssistantHelper.getAnswer({
          assistantId: this.assistant.id,
          threadId,
          message: message.content,
          files,
        });

        if (result?.error) {
          switch (result.error.code) {
            case 'server_error':
              throw new Error(t('aiAssistant.answer.error.serverError'));

            case 'rate_limit_exceeded':
              throw new Error(t('aiAssistant.answer.error.rateLimitExceeded'));

            case 'invalid_prompt':
              throw new Error(t('aiAssistant.answer.error.invalidPrompt'));

            default:
              throw new Error('Failed to get answer: unknown error');
          }
        }

        if (!result?.userMessage || !result?.assistantMessage) {
          throw new Error('Failed to get answer');
        }

        this.updateMessage(message.id, {
          id: result.userMessage.id,
          attachments: {
            files:
              files?.map((f) => {
                return { file_id: f.id };
              }) ?? [],
            images: [],
          },
        });

        const assistantMessage: ChatGPTMessage = {
          id: result.assistantMessage.id,
          role: 'assistant',
          content: '',
          created: result.assistantMessage.created_at,
          language: appStore.locale,
          isProcessing: true,
          attachments: {
            files: result.assistantMessage.attachments ?? [],
            images: [],
          },
        };
        this.setMessage(threadId, assistantMessage);

        const answerType = result.assistantMessage.content[0].type;
        switch (answerType) {
          case 'text':
            this.updateMessage(assistantMessage.id, {
              content: result.assistantMessage.content[0].text.value,
              isProcessing: false,
            });
            break;

          case 'image_file':
            this.setImage(threadId, {
              url: '',
              id: result.assistantMessage.content[0].image_file.file_id,
            });
            break;

          case 'image_url':
            this.setImage(threadId, {
              url: result.assistantMessage.content[0].image_url.url,
              id: '',
            });
            break;

          default:
            break;
        }

        return true;
      } catch (error: any) {
        console.error('[ERROR]', error);

        this.setFailed(true);

        useErrors().handleError({
          show: true,
          error,
          message: error.message,
        });
        return false;
      } finally {
        this.setLoading(false);
      }
    },
    async deleteMessage(threadId: string, id: string): Promise<boolean> {
      const messages = this.getMessagesById(threadId);
      if (!messages?.length) {
        console.error('[ERROR] Messages is empty');
        return false;
      }
      const index = messages.findIndex((m) => m.id === id);
      if (~index) {
        messages.splice(index, 1);
        return true;
      }
      return false;
    },
  },
  persist: true,
});
