import { useAppStore } from '@/store';
import type { SupportModel, ResponseErrorModel, ResponseSuccessModel, ResponseVersionModel } from '@/types';
import axios from '@/services/axios';
import { getAdditionalHeaders } from '@/services/getters/getAdditionalHeaders';
import { getBaseUrl } from '@/services/getters/getBaseUrl';
import { getCommonHeaders } from '@/services/getters/getCommonHeaders';

export class ToolApiService {
  async sendToSupport(params: SupportModel): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/tools/sendToSupport', params);
  }
  async getAppVersion(): Promise<string> {
    let urlApp = '';

    const url = `${getBaseUrl()}/tools/appVersion`;
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...getCommonHeaders(),
        ...getAdditionalHeaders(),
      },
      body: JSON.stringify({
        version: useAppStore().appVersion,
      }),
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response.json().then((responseToken: ResponseVersionModel) => {
            urlApp = responseToken.data;
          });
        } else {
          console.error('Error in getAppVersion: ', response.status);
        }
      })
      .catch((e: any) => {
        console.error('Error in getAppVersion: ', e);
      });

    return urlApp;
  }
  async getMeta(): Promise<{ version: string; build: string } | null> {
    let meta: { version: string; build: string } | null = null;

    await fetch('/meta.json', {
      method: 'GET',
      cache: 'no-store',
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response.json().then((data: { version: string; build: string }) => {
            meta = data;
          });
        } else {
          console.error('Error in getMeta: ', response.status);
        }
      })
      .catch((e: any) => {
        console.error('Error in getMeta: ', e);
      });

    return meta;
  }
  async getIPInfo(): Promise<string | null> {
    let ip: string | null = null;

    await fetch('https://api.ipify.org?format=json', {
      method: 'GET',
      cache: 'no-store',
    })
      .then(async (response: Response) => {
        if (response.ok) {
          await response.json().then((data: { ip: string }) => {
            ip = data.ip;
          });
        } else {
          console.error('Error in getIPInfo: ', response.status);
        }
      })
      .catch((e: any) => {
        console.error('Error in getIPInfo: ', e);
      });

    return ip;
  }
}
