import { useCssVar } from '@vueuse/core';
import { ref } from 'vue';
import type { Ref } from 'vue';

import { BreakpointsEnum } from '@/enums';
import { useAppStore } from '@/store';

type IUseLayout = {
  appGridPadding: Ref<string>;
  isXSWidth(): boolean;
  is2XSWidth(): boolean;
  isSMWidth(): boolean;
  isMDWidth(): boolean;
  isLGWidth(): boolean;
  isXLWidth(): boolean;
  is2XLWidth(): boolean;
  is3XLWidth(): boolean;
  is4XLWidth(): boolean;
  updateInnerWidth(width: number): void;
};

const paddingRatios = {
  [BreakpointsEnum.XS]: 1,
  [BreakpointsEnum['2XS']]: 1,
  [BreakpointsEnum.SM]: 3,
  [BreakpointsEnum.MD]: 1.875,
  [BreakpointsEnum.LG]: 1.125,
  [BreakpointsEnum.XL]: 1,
  [BreakpointsEnum['2XL']]: 1,
  [BreakpointsEnum['3XL']]: 1,
  [BreakpointsEnum['4XL']]: 1,
};

export const useLayout = (): IUseLayout => {
  const innerWidth = ref<number>(window.innerWidth);

  const appGridPadding = useCssVar('--app-grid-padding') as Ref<string, string>;
  const app2XSSize = Number(useCssVar('--app-2xs-size').value?.slice(0, -2));
  const appSMSize = Number(useCssVar('--app-sm-size').value?.slice(0, -2));
  const appMDSize = Number(useCssVar('--app-md-size').value?.slice(0, -2));
  const appLGSize = Number(useCssVar('--app-lg-size').value?.slice(0, -2));
  const appXLSize = Number(useCssVar('--app-xl-size').value?.slice(0, -2));
  const app2XLSize = Number(useCssVar('--app-2xl-size').value?.slice(0, -2));
  const app3XLSize = Number(useCssVar('--app-3xl-size').value?.slice(0, -2));
  const app4XLSize = Number(useCssVar('--app-4xl-size').value?.slice(0, -2));

  const isXS = innerWidth.value < appSMSize;
  const is2XS = innerWidth.value >= app2XSSize;
  const isSM = innerWidth.value >= appSMSize;
  const isMD = innerWidth.value >= appMDSize;
  const isLG = innerWidth.value >= appLGSize;
  const isXL = innerWidth.value >= appXLSize;
  const is2XL = innerWidth.value >= app2XLSize;
  const is3XL = innerWidth.value >= app3XLSize;
  const is4XL = innerWidth.value >= app4XLSize;

  const isXSWidth = (): boolean => {
    return isXS;
  };

  const is2XSWidth = (): boolean => {
    return is2XS;
  };

  const isSMWidth = (): boolean => {
    return isSM;
  };

  const isMDWidth = (): boolean => {
    return isMD;
  };

  const isLGWidth = (): boolean => {
    return isLG;
  };

  const isXLWidth = (): boolean => {
    return isXL;
  };

  const is2XLWidth = (): boolean => {
    return is2XL;
  };

  const is3XLWidth = (): boolean => {
    return is3XL;
  };

  const is4XLWidth = (): boolean => {
    return is4XL;
  };

  /** @note Changes the dynamic parameter depending on the screen width */
  const setGridPaddingVar = () => {
    const appXSPadding = useCssVar('--app-xs-padding').value?.slice(0, -2);
    const app2XSPadding = useCssVar('--app-2xs-padding').value?.slice(0, -2);
    const appSMPadding = useCssVar('--app-sm-padding').value?.slice(0, -2);
    const appMDPadding = useCssVar('--app-md-padding').value?.slice(0, -2);
    const appLGPadding = useCssVar('--app-lg-padding').value?.slice(0, -2);
    const appXLPadding = useCssVar('--app-xl-padding').value?.slice(0, -2);
    const app2XLPadding = useCssVar('--app-2xl-padding').value?.slice(0, -2);
    const app3XLPadding = useCssVar('--app-3xl-padding').value?.slice(0, -2);
    const app4XLPadding = useCssVar('--app-4xl-padding').value?.slice(0, -2);

    if (is4XL) {
      appGridPadding.value = `${+(app4XLPadding ?? '1') * paddingRatios[BreakpointsEnum['4XL']]}px`;
    } else if (is3XL) {
      appGridPadding.value = `${+(app3XLPadding ?? '1') * paddingRatios[BreakpointsEnum['3XL']]}px`;
    } else if (is2XL) {
      appGridPadding.value = `${+(app2XLPadding ?? '1') * paddingRatios[BreakpointsEnum['2XL']]}px`;
    } else if (isXL) {
      appGridPadding.value = `${+(appXLPadding ?? '1') * paddingRatios[BreakpointsEnum.XL]}px`;
    } else if (isLG) {
      appGridPadding.value = `${+(appLGPadding ?? '1') * paddingRatios[BreakpointsEnum.LG]}px`;
    } else if (isMD) {
      appGridPadding.value = `${+(appMDPadding ?? '1') * paddingRatios[BreakpointsEnum.MD]}px`;
    } else if (isSM) {
      appGridPadding.value = `${+(appSMPadding ?? '1') * paddingRatios[BreakpointsEnum.SM]}px`;
    } else if (is2XS) {
      appGridPadding.value = `${+(app2XSPadding ?? '1') * paddingRatios[BreakpointsEnum['2XS']]}px`;
    } else if (isXS) {
      appGridPadding.value = `${appXSPadding}px`;
    }
  };
  setGridPaddingVar();

  const getMaxItemsInHeaderMenu = () => {
    switch (true) {
      case is4XLWidth():
        return 11;
      case is3XLWidth():
        return 11;
      case is2XLWidth():
        return 10;
      case isXLWidth():
        return 9;
      case isLGWidth():
        return 8;
      default:
        return 8;
    }
  };

  const updateInnerWidth = (width: number) => {
    const appStore = useAppStore();
    innerWidth.value = width;
    appStore.$patch((state) => {
      state.isXSWidth = isXSWidth();
      state.is2XSWidth = is2XSWidth();
      state.isSMWidth = isSMWidth();
      state.isMDWidth = isMDWidth();
      state.isLGWidth = isLGWidth();
      state.isXLWidth = isXLWidth();
      state.is2XLWidth = is2XLWidth();
      state.is3XLWidth = is3XLWidth();
      state.is4XLWidth = is4XLWidth();
      state.appGridPadding = appGridPadding.value as string;
      state.maxItemsInHeaderMenu = getMaxItemsInHeaderMenu();
    });
  };

  return {
    appGridPadding,
    isXSWidth,
    is2XSWidth,
    isSMWidth,
    isMDWidth,
    isLGWidth,
    isXLWidth,
    is2XLWidth,
    is3XLWidth,
    is4XLWidth,
    updateInnerWidth,
  };
};
