import { cloneDeep, orderBy, sample } from 'lodash';
import { defineStore } from 'pinia';

import { OrgChartTypeEnum } from '@/enums';
import { defaultNetwork } from '@/models';
import { $api } from '@/services';
import type { EntityState } from '@/store';
import type {
  AuthFormModel,
  ErrorMessageModel,
  NetworkModel,
  NetworkLocateModel,
  NetworkFullSettingsModel,
  ResponseErrorModel,
  ResponseNetworksModel,
  ResponseNetworkFullSettingsModel,
  ResponseNetworkLinkModel,
  ResponseNetworkModel,
  ResponseAllowedEmailSuffixesModel,
} from '@/types';

interface NetworkState extends EntityState<NetworkModel> {
  settings: NetworkFullSettingsModel | null;
  network: NetworkModel;
  settingNetwork: boolean;
  isNetworkAvailable: boolean;
}

export const useNetworkStore = defineStore({
  id: 'network',
  state: (): NetworkState => ({
    data: [],
    errors: [],
    isLoading: false,
    settings: null,
    network: cloneDeep(defaultNetwork),
    settingNetwork: false,
    isNetworkAvailable: true,
  }),
  getters: {
    localAutocomplete:
      (state) =>
      (text: string): NetworkModel[] => {
        text = text.toLowerCase();
        return text.length > 0 ? state.data.filter((n) => n.title.toLowerCase().includes(text)) : state.data;
      },
    isAdvancedWikiesEditor: (state): boolean => !!state.settings?.isAdvancedWikiesEditor,
    showFollowLists: (state): boolean => state.settings?.showFollowLists ?? false,
    getErrors:
      (state) =>
      (type: string): string[] => {
        let _errors: string[] = [];
        state.errors
          .filter((f: ErrorMessageModel) => f.key === type)
          .forEach(function (m: ErrorMessageModel) {
            _errors = [..._errors, ...m.errors];
          });
        return _errors;
      },
    orgChartType: (state): OrgChartTypeEnum => state.settings?.orgChartType ?? OrgChartTypeEnum.Off,
    getNetwork: (state): NetworkModel => state.network,
    getCurrentSettings: (state): NetworkFullSettingsModel | null => state.settings,
    getNetworks: (state) => {
      return state.data;
    },
    getRandomNetwork: (state): NetworkModel | undefined => {
      return sample(state.data);
    },
    getNetworkById:
      (state) =>
      (id: string): NetworkModel | undefined => {
        const index = state.data.findIndex((network: NetworkModel) => network.id === id);

        if (~index) {
          return state.data[index];
        }
        return undefined;
      },
    getLeftMenuNetworks:
      (state) =>
      (slice: number): NetworkModel[] => {
        const { network, data } = state;
        const remainingNetworks = data.filter((n) => n.id !== network.id).slice(0, slice);
        return [network, ...remainingNetworks];
      },
    /**
     * @link https://gitlab.united-grid.com/intra/intra-ionic/-/issues/1402
     */
    getLeftMenuNetworksSaarland:
      (state) =>
      (slice: number): NetworkModel[] => {
        const { network, data } = state;
        const saarlandNetworkId = import.meta.env.VITE_SAARLAND_NETWORK_ID;
        const saarlandNetwork = data.find((n) => n.id === saarlandNetworkId && n.id !== network.id);
        const networks = saarlandNetwork ? [network, saarlandNetwork] : [network];
        const remainingNetworks = data.filter((n) => n.id !== network.id && n.id !== saarlandNetworkId).slice(0, slice);
        return [...networks, ...remainingNetworks];
      },
  },
  actions: {
    //#region Setters
    setNetwork(network: NetworkModel) {
      this.network = network;
    },
    setSettingNetwork(state: boolean) {
      this.settingNetwork = state;
    },
    setNotificationsCount(count: number) {
      this.network.notificationCount = count;
      const index = this.data.findIndex((n) => n.id === this.network.id);
      if (~index) {
        this.data[index].notificationCount = count;
      }
    },
    //#endregion

    //#region API
    async networksByUri(): Promise<string | undefined> {
      const response = await $api.auth.oauth();

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkLinkModel;

        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return undefined;
    },
    async networksByForm(data: AuthFormModel): Promise<NetworkModel[] | undefined> {
      this.$patch({
        data: [],
        isLoading: true,
        errors: [],
      });
      const response = await $api.auth.networks(data);

      if (response.statusCode === 200) {
        const model = response as ResponseNetworksModel;

        const networks = orderBy(model.data, ['isOfficial', 'isHome'], ['desc', 'desc']);
        this.data = networks;

        this.isLoading = false;
        return networks;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return undefined;
    },
    async networksByUser(): Promise<NetworkModel[] | undefined> {
      this.$patch({
        isLoading: true,
        errors: [],
      });
      const response = await $api.account.networks();

      if (response.statusCode === 200) {
        const model = response as ResponseNetworksModel;

        const networks = orderBy(model.data, ['isOfficial', 'isHome'], ['desc', 'desc']);
        this.data = networks;

        this.isLoading = false;
        return networks;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return undefined;
    },
    async networkForUser(): Promise<boolean> {
      this.$patch({
        network: cloneDeep(defaultNetwork),
        isLoading: true,
        errors: [],
      });

      const response = await $api.network.current();

      if (response.statusCode === 200) {
        const model = response as ResponseNetworkModel;

        this.network = cloneDeep(model.data);
        this.isLoading = false;
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return false;
    },
    async getLocate(uri: string | undefined): Promise<NetworkLocateModel | undefined> {
      if (!uri) {
        console.warn('[WARN] Failed to get network settings: uri is required');
        return undefined;
      }

      const settings = await $api.network.getLocate(uri);

      if (!settings) {
        console.warn('[WARN] Failed to get network settings');
        return undefined;
      }

      this.errors = [];
      return settings;
    },
    async getSettings(): Promise<NetworkFullSettingsModel | undefined> {
      this.isLoading = true;

      const response = await $api.network.settings();
      if (response.statusCode === 200) {
        const model = response as ResponseNetworkFullSettingsModel;

        this.settings = cloneDeep(model.data);

        this.isNetworkAvailable = true;
        this.isLoading = false;
        return this.settings;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return undefined;
    },
    async uploadLogo(key: string, type: string): Promise<string | null> {
      const response = await $api.network.uploadLogo(key, type);
      if (response.statusCode === 200) {
        //const model = response as NetworkUploadLogoResponseModel;
        return `${key}.${type}`;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return null;
    },
    async uploadFavicon(key: string, type: string): Promise<string | null> {
      const response = await $api.network.uploadFavicon(key, type);
      if (response.statusCode === 200) {
        //const model = response as NetworkUploadFaviconResponseModel;
        return `${key}.${type}`;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return null;
    },
    async getAllowedEmailSuffixes(): Promise<string[] | undefined> {
      this.isLoading = true;
      const response = await $api.network.allowedEmailSuffixes();
      if (response.statusCode === 200) {
        const model = response as ResponseAllowedEmailSuffixesModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return undefined;
    },
    //#endregion
  },
  persist: true,
});
