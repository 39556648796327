import { FeedTypeEnum, GroupsTypeEnum } from '@/enums';
import { getIsoNow } from '@/helpers';
import type {
  PostModel,
  UserShortModel,
  PostAttachedWikisModel,
  PostAttachedFilesModel,
  PostCommentsModel,
  PostLikesModel,
  DraftPostsModel,
  PostsIdsModel,
  PostsIdsFeedModel,
  PostsIdsDataModel,
  PostsIdsGroupModel,
  PostsIdsUserModel,
  PostsIdsWikiModel,
  PostsIdsTagModel,
  PostPollModel,
  PollOptionsModel,
  PostAttachedFoldersModel,
  PostsIdsIdeasModel,
  PostsIdsFileModel,
  PostAttachedLinksModel,
  GroupShortModel,
} from '@/types';

export const defaultPost: PostModel = {
  image: null,
  attachedBadge: null,
  attachedFiles: { count: 0, data: [] } as PostAttachedFilesModel,
  attachedFolders: { count: 0, data: [] } as PostAttachedFoldersModel,
  attachedLinks: { count: 0, data: [] } as PostAttachedLinksModel,
  attachedWikis: { count: 0, data: [] } as PostAttachedWikisModel,
  author: {} as UserShortModel,
  initiator: {} as UserShortModel,
  bodyHtml: '',
  ccUsers: [],
  comments: {
    count: 0,
    countRemains: 0,
    data: [],
    loadMoreUrl: null,
  } as PostCommentsModel,
  createdAt: getIsoNow(),
  lastEditedAt: getIsoNow(),
  eventData: null,
  taskData: null,
  group: {
    id: 0,
    mainAlias: '',
    title: '',
    type: GroupsTypeEnum.Public,
    image: {
      url: '',
      width: 0,
      height: 0,
    },
  } as GroupShortModel,
  id: 0,
  ideaData: null,
  isAnnouncementRead: false,
  isBookmarked: false,
  isComplainable: false,
  isFollowed: true,
  isManual: false,
  isPinned: false,
  isRead: false,
  likes: { count: 0, data: [], isLiked: false } as PostLikesModel,
  messageType: FeedTypeEnum.Text,
  plannedPostData: null,
  pollData: null,
  sharedMessages: { count: 0, data: [] },
  title: '',
  topics: { count: 0, data: [] },
  viewCount: 0,
  access: [],
  usersReadCount: 0,
  isRequiredToRead: false,
};

export const defaultDraftPosts: DraftPostsModel = {
  standardPost: null,
  event: null,
  idea: null,
  announcement: null,
  poll: null,
  badge: null,
  tempFiles: [],
  existFiles: [],
  wikis: [],
  task: null,
};

export const defaultPostsIds: PostsIdsModel = {
  feed: {
    all: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    recommended: {
      loadMoreUrl: null,
      ids: [],
    } as PostsIdsDataModel,
    following: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    bookmarks: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    planned: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
  } as PostsIdsFeedModel,
  groups: [] as PostsIdsGroupModel[],
  users: [] as PostsIdsUserModel[],
  wikis: [] as PostsIdsWikiModel[],
  files: [] as PostsIdsFileModel[],
  tags: [] as PostsIdsTagModel[],
  pages: null,
  search: {
    loadMoreUrl: null,
    ids: [],
  },
  announcements: {
    loadMoreUrl: null,
    ids: [],
  } as PostsIdsDataModel,
  polls: {
    loadMoreUrl: null,
    ids: [],
  } as PostsIdsDataModel,
  ideas: {
    all: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    new: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    inProgress: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    realized: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    archived: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    mostLiked: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
    my: { loadMoreUrl: null, ids: [] } as PostsIdsDataModel,
  } as PostsIdsIdeasModel,
  forceToRead: {
    loadMoreUrl: null,
    ids: [],
  } as PostsIdsDataModel,
};

export const defaultPostsPoll: PostPollModel = {
  allowAddOptions: false,
  allowSeveralAnswers: false,
  anonymousMode: false,
  votes: 0,
  voters: 0,
  isAnswered: false,
  mainHtml: '',
  options: [] as PollOptionsModel[],
  timeLimit: '',
};
