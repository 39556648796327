<template>
  <div class="card-modal-content">
    <div class="card-header">
      <task-management-task-modal-title :task-data="taskData" @click.stop />
      <div class="info">
        <span class="card-id">{{ '#' + taskData.identifier }}</span>
        <span v-if="taskData.isClosed" class="card-status">
          <task-management-status :is-closed="taskData.isClosed" colored-closed />
        </span>
        <task-management-card-due-date v-if="taskData.dateDue !== null" :with-header="false" :task-data="taskData" />
        <span
          v-if="taskData.dateCreated"
          v-tooltip.bottom="{
            content: $t('dates.createdAt') + ': ' + formatDateHelper(taskData.dateCreated, 'long'),
            theme: 'info-tooltip',
            disabled: isAnyMobile,
          }"
          class="card-date"
        >
          {{ formatDateHelper(taskData.dateCreated, 'year') }}
        </span>
        <span v-if="taskData?.author" class="card-author">
          {{ taskData.author?.fullName }}
        </span>
      </div>
    </div>
    <div v-if="taskData.assignee || taskData.milestone || taskData.participants" class="details">
      <div v-if="taskData.assignee" class="details--assignee">
        <ion-title mode="md">{{ $t('appPopoverMenu.taskManagementMenu.assignee') }}</ion-title>
        <task-management-card-participants :users="[taskData.assignee]" />
      </div>

      <div v-if="taskData.participants.data.length > 0" class="details--participants">
        <ion-title mode="md">{{ $t('taskManagement.participants') }}</ion-title>
        <task-management-card-participants :users="taskData.participants.data" />
      </div>

      <div v-if="taskData.milestone" class="details--milestone">
        <ion-title mode="md">{{ $t('taskManagement.milestones.title') }}</ion-title>
        <task-management-card-milestone :milestone-id="taskData.milestone.id" with-dates :task-data="taskData" />
      </div>

      <div v-if="taskData.dateDue !== null" class="details--due-date">
        <ion-title mode="md">{{ $t('appPopoverMenu.taskManagementMenu.dueDate') }}</ion-title>
        <task-management-card-due-date :with-header="true" :task-data="taskData" />
      </div>
    </div>

    <!-- Описание -->
    <div :class="['description', { 'is-modal': !isPost }]">
      <task-management-card-description :task-id="taskData.id" :description="taskData.description" :is-post="isPost" />
    </div>

    <!-- Вложения -->
    <div v-if="showAttachments" class="attachments">
      <ion-title mode="md">{{ $t('appPopoverMenu.taskManagementMenu.attachments') }}</ion-title>
      <task-management-card-attachments :task-id="taskData.id" :with-controls="false" />
    </div>
    <!-- Тэги -->
    <div v-if="taskData.tags.count > 0" class="tags">
      <ion-title mode="md">{{ $t('taskManagement.tags.title') }}</ion-title>
      <!-- NOTE: isPost - теги будут квадратными и без фона -->
      <task-management-card-tags
        :tags-ids="taskData.tags.data.map((n) => n.id)"
        is-active
        size="medium"
        :is-post="false"
      />
    </div>

    <!-- История -->
    <div v-if="taskData.history && !isPost" class="history">
      <task-management-card-history :task-data="taskData" />
    </div>

    <!-- Комментарии -->
    <div v-if="!isPost" :class="['comments', isPost ? 'post' : 'task']">
      <ion-title mode="md">{{ $t('taskManagement.comments', commentsCount) }}</ion-title>

      <!-- Подгрузка комментариев -->
      <div v-if="isPost && commentsCount > 0 && commentsCount > commentsToShow" class="more-comments">
        <ion-button mode="md" fill="clear" @click.stop="loadMore()">
          {{
            $t(
              'feed.comments.loadMore',
              commentsCount - commentsToShow <= commentsIterator ? commentsCount - commentsToShow : commentsIterator
            )
          }}
        </ion-button>
      </div>

      <feed-comments-item
        v-for="(comment, comment_index) in !isPost
          ? taskData.comments.data
          : takeRight(taskData.comments.data, commentsToShow)"
        :key="`task_comment_${comment_index}`"
        :task-comment-data="comment"
        :task-id="taskData.id"
        :current-user-id="currentUserId"
        @click.stop
      />
    </div>
    <div v-if="!isPost" class="task-comment-field">
      <feed-message-send
        ref="sendRef"
        :task-id="taskData.id"
        :post-type="PostTypeActionEnum.TaskComment"
        @on-send-successful="sendSuccessful"
      />
    </div>

    <!-- <div v-if="isPost" class="post-comment-field">
      <feed-message-send
        ref="sendRef"
        :task-id="taskData.id"
        :post-type="PostTypeActionEnum.TaskComment"
        @onSendSuccessful="sendSuccessful"
      />
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { IonTitle, IonButton } from '@ionic/vue';
import { takeRight } from 'lodash';
import { computed, ref } from 'vue';
import type { ComputedRef, PropType } from 'vue';

import {
  TaskManagementCardParticipants,
  TaskManagementCardTags,
  TaskManagementCardMilestone,
  TaskManagementCardDescription,
  TaskManagementCardDueDate,
  TaskManagementCardAttachments,
  TaskManagementTaskModalTitle,
  TaskManagementStatus,
  TaskManagementCardHistory,
  FeedMessageSend,
  FeedCommentsItem,
} from '@/components';
import { PostTypeActionEnum } from '@/enums';
import { formatDateHelper, isAnyMobile } from '@/helpers';
import { useUserStore } from '@/store';
import type { TaskManagementTaskModel } from '@/types';

const props = defineProps({
  taskData: {
    type: Object as PropType<TaskManagementTaskModel>,
    required: true,
  },
  isPost: {
    type: Boolean,
    default: false,
  },
  previewMode: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onCommentCreate']);

const userStore = useUserStore();
const currentUserId: ComputedRef<number> = computed(() => userStore.current?.id ?? 0);
const sendRef = ref<any | null>(null);

const commentsCount: ComputedRef<number> = computed(() => props.taskData.comments.data.length);

const showAttachments: ComputedRef<boolean> = computed(() => {
  const secondCondition = props.previewMode || !props.isPost;

  if (!props.taskData?.files) {
    if (!props.taskData?.wikis) {
      return false;
    }

    return props.taskData.wikis.data.length > 0 && secondCondition;
  }

  return props.taskData.files.data.length > 0 && secondCondition;
});

const commentsToShow = ref<number>(2);
const commentsIterator = ref<number>(5);

// Комментарии задачи отдаются сразу все, поэтому просто обрезаем их локально
const loadMore = () => {
  commentsToShow.value = commentsToShow.value + commentsIterator.value;
};

const sendSuccessful = () => {
  emit('onCommentCreate');
};
</script>

<style scoped lang="scss">
.card-modal-content {
  color: var(--ion-color-dark);
  ion-title {
    padding-inline: 0;
  }

  .card-header {
    padding-right: 1rem;
    div.info {
      font-size: 0.9rem;
      display: flex;
      flex-wrap: wrap;
      .card-id,
      .card-status,
      .card-date,
      .card-author {
        margin-right: app-padding(md);
        color: var(--ion-color-medium);
      }
    }
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .details {
    margin-top: calc(app-padding(lg) * 2);
    display: flex;
    flex-wrap: wrap;

    ion-title {
      margin-bottom: app-padding(md);
      font-size: 1rem;
    }

    & .details--assignee,
    & .details--milestone,
    & .details--participants,
    & .details--due-date {
      margin-right: calc(app-padding(lg) * 2);
    }
  }

  .description,
  .attachments,
  .tags,
  .comments {
    margin-top: calc(app-padding(lg) * 2);
    ion-title {
      margin-bottom: app-padding(md);
      font-size: 1rem;
    }
  }
  .history {
    margin-top: calc(app-padding(lg));
  }
  .comments {
    margin-top: calc(app-padding(md));
  }
  .description.is-modal {
    margin-right: app-padding(md);
  }
  .history,
  .attachments {
    margin-right: app-padding(md);
  }

  .comments {
    position: relative;
    margin-right: app-padding(md);
    &.post {
      margin-right: unset;
      .more-comments {
        margin-bottom: 0.5rem;
        display: flex;
        justify-content: center;
      }
      .more-comments ion-button {
        --color: var(--ion-color-medium);
        font-size: 0.7em;
      }
    }
  }

  .task-comment-field {
    padding-top: 2px;
    margin-right: app-padding(md);
    position: sticky;
    bottom: -1px;
    padding-bottom: app-padding(md);
    background: var(--ion-background-color, #fff);
    z-index: 1;
  }

  .post-comment-field {
    padding-bottom: app-padding(md);
    background: transparent;
  }
}

@include respond-to-max-width(md) {
  .card-header {
    padding-right: 70px !important;
  }
}
</style>
