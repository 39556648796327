import { DeletionUserKindEnum } from '@/enums';
import type { FeedFilterTypeEnum, IdeaTypeEnum, UserRoleEnum } from '@/enums';
import { $api } from '@/services';
import axios from '@/services/axios';
import type {
  ResponseUserModel,
  ResponseErrorModel,
  ResponseUsersModel,
  UserProfileUpdateModel,
  PhoneMobileVisibleUpdateModel,
  ResponseUserAvatarModel,
  ResponseSuccessModel,
  ResponseFileModel,
  ResponseInviteUserModel,
  ResponseShortUsersModel,
  ResponsePasswordPolicyModel,
  RequestSendInvitationsModel,
  AdminRegisterModel,
} from '@/types';

export class UserApiService {
  async list(): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users?usersSort=Name&sortDirection=asc`);
  }
  async loadMore(url: string): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(url);
  }
  async autocomplete(text: string, canPostOnBehalf = false): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.post('/users/autocomplete', {
      searchText: text,
      canPostOnBehalf,
    });
  }
  async canPostOnBehalf(payload: {
    searchText?: string;
    groupId?: number;
  }): Promise<ResponseUsersModel | ResponseErrorModel> {
    const { searchText, groupId } = payload;
    const params = new URLSearchParams();
    if (searchText) params.append('SearchText', searchText);
    if (groupId) params.append('GroupId', groupId.toString());
    const queryString = params.toString();
    return axios.get(`/users/canPostOnBehalf${queryString ? `?${queryString}` : ''}`);
  }
  async search(text: string): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/all?search=${text}`);
  }
  async all(): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/all`);
  }
  async current(): Promise<ResponseUserModel | ResponseErrorModel> {
    return axios.get('/users/current');
  }
  async follow(userId: number): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.post(`/users/follow/${userId}`);
  }
  async unFollow(userId: number): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.post(`/users/unfollow/${userId}`);
  }
  async followers(userId: number, search?: string): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/followers/${userId}${search ? `?search=${search}` : ''}`);
  }
  async following(userId: number, search?: string): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/following/${userId}${search ? `?search=${search}` : ''}`);
  }
  async getUserById(userId: number): Promise<ResponseUserModel | ResponseErrorModel> {
    return axios.get(`/users/byId/${userId}`);
  }
  async userProfileUpdate(
    data: UserProfileUpdateModel | PhoneMobileVisibleUpdateModel
  ): Promise<ResponseUserModel | ResponseErrorModel> {
    return axios.post('/users/update', data);
  }
  async updateAvatar(image: File): Promise<ResponseUserAvatarModel | ResponseErrorModel> {
    const response = await $api.doc.upload(image);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post('/users/uploadAvatar', {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }
  async deleteAvatar(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete('/users/deleteAvatar');
  }
  async deleteCover(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete('/users/deleteCover');
  }
  async emailChange(email: string): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/emailChange', { email: email });
  }
  async emailConfirm(hash: string): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/emailConfirm', {
      hash: hash,
    });
  }
  async usersByGroup(id: number): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/byGroup/${id}`);
  }
  async usersByGroupAutocomplete(id: number, text: string): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(`/users/byGroup/${id}?query=${text}`);
  }
  async updateCover(image: File): Promise<ResponseUserAvatarModel | ResponseErrorModel> {
    const response = await $api.doc.upload(image);
    if (response.statusCode === 200) {
      const model = response as ResponseFileModel;
      return axios.post('/users/uploadCover', {
        key: model.data.key,
        type: model.data.type,
      });
    }

    return response as ResponseErrorModel;
  }
  async accountDelete(): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete('/users/selfDelete');
  }
  async updateDefaultFeedType(feedType: FeedFilterTypeEnum): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/updateDefaultFeedType', { feedType });
  }
  async inviteUser(data: RequestSendInvitationsModel): Promise<ResponseInviteUserModel | ResponseErrorModel> {
    return axios.post(`/users/invite`, {
      emails: data.emails,
      notificationSubject: data.notificationSubject,
      notificationTemplate: data.notificationTemplate,
    });
  }
  async updateDefaultIdeaType(ideaType: IdeaTypeEnum): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post('/users/updateDefaultIdeaType', { ideaType });
  }
  async getPostViewers(postId: number): Promise<ResponseShortUsersModel | ResponseErrorModel> {
    return axios.get(`/users/showPostViewers/${postId}`);
  }
  async passwordSettings(userRowId: string): Promise<ResponsePasswordPolicyModel | ResponseErrorModel> {
    return axios.get(`/users/${userRowId}/passwordSettings`);
  }
  async setPassword(userRowId: string, password: string): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.post(`/users/${userRowId}/password`, {
      password,
    });
  }
  async updateUserRole(userId: number, roleId: UserRoleEnum): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.put(`/users/${userId}/role`, { targetRoleId: roleId });
  }
  async deactivateUser(userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.put(`/users/${userId}/deactivate/${userId}`);
  }
  async activateUser(userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.put(`/users/${userId}/activate/${userId}`);
  }
  async blockUser(userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.put(`/users/${userId}/block`);
  }
  async unblockUser(userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.put(`/users/${userId}/unblock`);
  }
  async deleteUser(userId: number): Promise<ResponseSuccessModel | ResponseErrorModel> {
    return axios.delete(`/users/${userId}/${DeletionUserKindEnum.Anonymize}`);
  }
  async allForAdmin(IncludeInactive: boolean, search: string): Promise<ResponseUsersModel | ResponseErrorModel> {
    return axios.get(
      `/users/extended?includeInactive=${IncludeInactive}&search=${search}&sortDirection=Asc&usersSort=Name&pageSize=50`
    );
  }
  async registerUser(data: AdminRegisterModel): Promise<ResponseInviteUserModel | ResponseErrorModel> {
    return axios.post(`/users/register`, data);
  }
}
