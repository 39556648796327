<template>
  <ion-app ref="pageRef" translate="no">
    <app-toast />
    <keep-alive v-if="!isLoadingI18n">
      <component :is="layout" />
    </keep-alive>
    <app-loading v-else></app-loading>
  </ion-app>
</template>

<script lang="ts" setup>
import { App } from '@capacitor/app';
import { Keyboard } from '@capacitor/keyboard';
import { IonApp, isPlatform, modalController, popoverController, alertController } from '@ionic/vue';
import { HubConnectionState } from '@microsoft/signalr';
import { useIntervalFn, useWindowSize, watchDebounced } from '@vueuse/core';
import { isEqual } from 'lodash';
import type { ComponentPublicInstance, ShallowRef, ComputedRef } from 'vue';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { AppToast, AppLoading } from '@/components';
import { AppAlertTypeEnum, AppLoadingTypeEnum, PostsModeEnum, RequirementsEnum } from '@/enums';
import {
  isNativeMobile,
  useTheme,
  useSession,
  updateApp,
  feedTypeHelper,
  isWebMobile,
  openTaskManagementTaskModal,
  changeFavicon,
  useLayout,
  useLoading,
  useRequirements,
  useAiAssistant,
  useNotifications,
  useVersionChange,
} from '@/helpers';
import { useI18n, loadLocaleMessages } from '@/i18n';
import { AppLayoutDefault, AppLayoutMenu } from '@/layouts';
import { NO_AUTH_ROUTES, type RouteName, ROUTES_NAME } from '@/router';

import {
  useAppStore,
  useFileStore,
  useMeetStore,
  useMessengerStore,
  useNetworkStore,
  usePostStore,
  useMenuStore,
  useAiAssistantStore,
  useAuthStore,
} from '@/store';
import { useEventListener } from '@vueuse/core';
import { TokenModeEnum } from '@/enums/auth';
import { useWebSockets } from '@/ws';

//#region Variables
const authStore = useAuthStore();
const appStore = useAppStore();
const fileStore = useFileStore();
const networkStore = useNetworkStore();
const meetStore = useMeetStore();
const messengerStore = useMessengerStore();
const postStore = usePostStore();
const menuStore = useMenuStore();
const aiAssistantStore = useAiAssistantStore();

const route = useRoute();
const router = useRouter();

const { t } = useI18n();

const aiAssistantHelper = useAiAssistant();
const { width: innerWidth } = useWindowSize();

const pageRef = ref<ComponentPublicInstance | null>(null);
const isLoadingI18n = ref(!isNativeMobile);
const keyboardIsShow = ref<boolean>(false);

const keyboard: ComputedRef<boolean> = computed(() => keyboardIsShow.value);
const postsToMarkAsRead: ComputedRef<number[]> = computed(() => postStore.postsToMarkAsRead);
const postsMode: ComputedRef<PostsModeEnum | null> = computed(() => postStore.postsMode);
const layout: ShallowRef = computed(() => {
  switch (route.meta.layout) {
    case 'auth':
      return AppLayoutDefault;
    default:
      return AppLayoutMenu;
  }
});
const isLoading: ComputedRef<boolean> = computed(
  () =>
    appStore.isLoading ||
    networkStore.isLoading ||
    messengerStore.isLoading ||
    (isPlatform('desktop') && menuStore.isLoading)
);
const localLanguage: ComputedRef<string> = computed(() => appStore.locale);
const isWaitingForCompleteLogin: ComputedRef<boolean> = computed(() => appStore.isWaitingForCompleteLogin);
const isSettingNetwork: ComputedRef<boolean> = computed(() => networkStore.settingNetwork);
//#endregion

//#region Handlers
const handleAuth = async (): Promise<boolean> => {
  const currentRouteName = String(route.name);
  if (!NO_AUTH_ROUTES.includes(currentRouteName as RouteName)) {
    await authStore.token(TokenModeEnum.Code, { force: true });
    if (!authStore.isAuth()) return false;
  }
  return true;
};

const handleWheelClick = (e: MouseEvent): void => {
  if (e.button === 1) {
    appStore.setMiddleButtonClicked(true);
    e.preventDefault();
    e.stopPropagation();
    const leftClickEvent = new MouseEvent('click', {
      bubbles: true,
      button: 0,
      buttons: 1,
      ctrlKey: false,
      altKey: false,
      shiftKey: false,
      metaKey: false,
      clientX: e.clientX,
      clientY: e.clientY,
      relatedTarget: e.relatedTarget,
    });

    if (!e.target) {
      console.error('Error during wheel click handling: no target found.', e);
      return;
    }

    e.target.dispatchEvent(leftClickEvent);
  }
};

//#region Init Functions
const initTheme = async (): Promise<void> => {
  const userTheme = appStore.getLocalTheme;
  useTheme().initTheme(userTheme);

  const currentSettings = networkStore.settings;
  const color = useTheme().getAppColor(currentSettings?.headBackgroundColor);
  await useTheme().setTheme(color);

  if (authStore.isAuth()) {
    const settings = await networkStore.getSettings();
    if (settings !== undefined) {
      if (!isEqual(currentSettings, settings)) {
        const color = useTheme().getAppColor(settings?.headBackgroundColor);
        await useTheme().setTheme(color);
      }
      changeFavicon();
    }
  }
};

const initLocales = async (): Promise<void> => {
  await loadLocaleMessages(localLanguage.value);

  if (authStore.isAuth()) {
    const companyResources = await appStore.getCompanyResources();
    const currentNetworkId = useNetworkStore().getNetwork.id;
    await loadLocaleMessages(localLanguage.value, companyResources || {}, currentNetworkId);
  }
};

const initNativeMobileInterface = async (): Promise<void> => {
  const localInterface = appStore.getLocalInterfaceSize;
  await useTheme().initNativeMobileInterface(localInterface);
};

const initNativeMobileKeyboardListeners = async (): Promise<void> => {
  if (Keyboard) {
    Keyboard?.addListener('keyboardDidShow', () => {
      keyboardIsShow.value = true;
      appStore.setKeyboardShown(true);
    });

    Keyboard?.addListener('keyboardDidHide', () => {
      keyboardIsShow.value = false;
      appStore.setKeyboardShown(false);
    });
  }
};
//#endregion

//#region Global Listeners & Watchers
App.addListener('appStateChange', async ({ isActive }) => {
  console.log(`[INFO] App state changed. Is active?' ${isActive}`);
  console.log(`[INFO] SignalR status: ${authStore.signalRConnectionStatus}`);
  try {
    if (!isActive) {
      if (isNativeMobile) await useWebSockets().stopWebSockets();
      return;
    }

    if (!authStore.isAuth()) {
      if (!(await handleAuth())) {
        throw new Error('Failed to get a new token during app mount');
      }
    }

    if (authStore.isAuth()) {
      if (authStore.signalRConnectionStatus == HubConnectionState.Disconnected) {
        await useWebSockets().startWebSockets();
      }

      if (isNativeMobile) {
        await useNotifications().setBadgeCount();
        await updateApp();
      }

      await messengerStore.updateUnreadCounter();
    }
  } catch (e) {
    console.error(e);
  }
});

watch(keyboard, () => {
  appStore.setAppBottomMenu((isWebMobile || isNativeMobile) && !keyboardIsShow.value);
});

/** @note Watching the innerWidth, if the innerWidth changes, then we need to update the innerWidth in the layout helper */
watch(
  innerWidth,
  () => {
    useLayout().updateInnerWidth(innerWidth.value);
  },
  { immediate: true }
);

/** @note Watching the route, if the route changes, then we need to close all the windows */
watch(route, async () => {
  modalController.getTop().then((modal) => {
    return modal && modal?.id !== 'groupManage' ? modalController.dismiss() : null;
  });
  popoverController.getTop().then((popover) => {
    return popover ? popoverController.dismiss() : null;
  });
  alertController.getTop().then((alert) => {
    return alert && alert?.id !== AppAlertTypeEnum.UpdateApp ? alertController.dismiss() : null;
  });
});

/** @note Watching the loading state, if the loading state changes, then we need to show or hide the loading */
watch(isLoading, async () => {
  if (isWaitingForCompleteLogin.value) return;

  if (!isLoading.value) {
    await useLoading().dismiss(AppLoadingTypeEnum.OtherLoading);
  } else {
    if (route.name === ROUTES_NAME.LOGIN) {
      await useLoading().create(t('loading'), AppLoadingTypeEnum.OtherLoading);
    }
  }
});

/** @note Watching the network settings, if not login/registration/activation page, then we need to dismiss (false) / create (true) the loader */
watch(isSettingNetwork, async () => {
  if (
    route.name === ROUTES_NAME.LOGIN ||
    route.name === ROUTES_NAME.REGISTRATION ||
    route.name === ROUTES_NAME.ACTIVATION
  )
    return;

  if (!isSettingNetwork.value) {
    await useLoading().dismiss(AppLoadingTypeEnum.NetworkSetting);
  } else {
    await useLoading().create(t('network.loading'), AppLoadingTypeEnum.NetworkSetting);
  }
});

/** @note Watching the change of the posts mode, if the mode changes, then we need to update the feed */
watch(postsMode, async (currentMode, lastMode) => {
  if (lastMode !== currentMode) {
    if (route.name === ROUTES_NAME.FEED) {
      if (postsMode.value === PostsModeEnum.Feed) {
        //if (posts.value?.length === 0) await feedTypeHelper(route.name);
        await feedTypeHelper(route.name);
      }
    }
  }
});

/** @note Watching the query parameters, if the showTaskId and projectId parameters are present, then we need to open the task modal */
const queryParams = computed(() => route.query);
watch(
  queryParams,
  async (newValue) => {
    const { showTaskId, projectId } = newValue;
    if (showTaskId && projectId) {
      const modal = await openTaskManagementTaskModal(Number(showTaskId), Number(projectId));

      await modal.onDidDismiss();

      await router.replace({ query: undefined });
    }
  },
  { deep: true, immediate: true }
);

/** @note Watching the array of posts to mark as read, if the array is not empty, then we need to send a request to the server to mark the posts as read */
watchDebounced(
  postsToMarkAsRead,
  async () => {
    if (postsToMarkAsRead.value.length > 0 && authStore.isAuth()) {
      await postStore.markAsRead(false, postsToMarkAsRead.value);
    }
  },
  { debounce: 5000, maxWait: 120000 }
);

/** @note Watching the array of posts to mark as read, if the array is not empty, then we need to mark the posts as read locally */
watchDebounced(
  postsToMarkAsRead,
  async () => {
    if (postsToMarkAsRead.value.length > 0) {
      postStore.markAsReadLocally(postsToMarkAsRead.value);
    }
  },
  { debounce: 1500, maxWait: 2000 }
);

/** @note Listening to the page-print event */
useEventListener('beforeprint', () => {
  appStore.setIsPrinting(true);
});

/** @note Listening to the page-print event */
useEventListener('afterprint', () => {
  appStore.setIsPrinting(false);
});
//#endregion

//#region Global Timers
useIntervalFn(async () => {
  await useRequirements().check(RequirementsEnum.All);
}, 900000); /** @note once every 15 minutest */
//#endregion

//#region App Mount Functions (Lifecycle)
const authorizedMount = async (): Promise<void> => {
  try {
    if (pageRef.value !== null && route.path === '/') {
      await useNotifications().initPushNotifications();
      await useSession().setupApp({ newWebSocket: true, newPush: true, skipRequirementsCheck: false });
    } else {
      await Promise.all([
        messengerStore.updateUnreadCounter(),
        useWebSockets().initWebSockets(authStore.getWebSocketModel),
      ]);
    }

    await useNotifications().initLocalNotifications();
    await useRequirements().check(RequirementsEnum.All);

    useSession().clearCustomMenuTimer();
    useSession().setCustomMenuTimer();
  } catch (e) {
    console.error('Error on handleAuthorizedMount', e);
  }
};

const webMount = async (): Promise<void> => {
  try {
    messengerStore.setIsActive(true);

    isLoadingI18n.value = false;
  } catch (e) {
    console.error('Error on handleWebMount', e);
  }
};

const nativeMobileMount = async (): Promise<void> => {
  try {
    await fileStore.init();
    await initNativeMobileInterface();
    await initNativeMobileKeyboardListeners();

    messengerStore.setIsActive(true);
    await updateApp();
  } catch (e) {
    console.error('Error on handleNativeMobileMount', e);
  }
};

/** @todo const electronMount = async (): Promise<void> => {}; */

const mount = async (): Promise<void> => {
  try {
    useLayout().updateInnerWidth(innerWidth.value);
    await initLocales();
    await initTheme();

    if (isNativeMobile) {
      await nativeMobileMount();
    } else {
      await webMount();
    }

    /** @note If user is NOT authenticated AND current route needs auth => try to reauthenticate */
    if (!authStore.isAuth()) {
      if (!(await handleAuth())) {
        throw new Error('Failed to reauthenticate during app mount');
      }
    }

    if (authStore.isAuth()) {
      await authorizedMount();
    }

    if (isWebMobile || isNativeMobile) {
      appStore.setAppBottomMenu(!keyboardIsShow.value);
    }
  } catch (e) {
    console.error('Error on handleMount', e);
  }
};

const unmount = async (): Promise<void> => {
  try {
    const currentAssistant = aiAssistantStore.assistant;
    if (currentAssistant) {
      await aiAssistantHelper.deleteAssistant(currentAssistant);
    }

    await useWebSockets().stopWebSockets();

    App.removeAllListeners();
    window.removeEventListener('mouseup', handleWheelClick);
  } catch (e) {
    console.error('Error on handleUnmount', e);
  }
};

meetStore.$reset();

let updateCheckInterval: number | undefined;
onMounted(async () => {
  try {
    const { check } = useVersionChange();
    await check();

    if (!isNativeMobile) {
      window.addEventListener('mouseup', handleWheelClick);
      updateCheckInterval = window.setInterval(check, 60000);
    }

    await mount();
  } catch (e) {
    console.error('Error on onMountedApp', e);
  }
});

onUnmounted(async () => {
  try {
    if (!isNativeMobile && updateCheckInterval) {
      window.clearInterval(updateCheckInterval);
    }

    await unmount();
  } catch (e) {
    console.error('Error on onUnmountedApp', e);
  }
});
//#endregion
</script>
