import { EnvVariablesEnum } from '@/enums';
import type { CompaniesListType, CompaniesListType2, CompaniesListType3 } from '@/types';

type EnvValueMap = {
  [EnvVariablesEnum.CompaniesListWithDisabledMenuItems]: CompaniesListType;
  [EnvVariablesEnum.CompaniesListWithCustomLocales]: CompaniesListType;
  [EnvVariablesEnum.CompaniesListWithDisabledCampus]: CompaniesListType;
  [EnvVariablesEnum.CompaniesListCampusLink]: CompaniesListType3;
  [EnvVariablesEnum.CompaniesListWithDisabledQuickSearch]: CompaniesListType;
  [EnvVariablesEnum.CompaniesListWithPermanentlyPinnedGroups]: CompaniesListType2;
  [EnvVariablesEnum.CompaniesListWithDocsInNewWindow]: CompaniesListType;
  [EnvVariablesEnum.SaarlandSecondaryNetworks]: CompaniesListType;
  [EnvVariablesEnum.CompaniesListWithDisabledUsersFilterInDocsBrowser]: CompaniesListType;
};

type IUseEnvs = {
  /**
   * Returns value of environment variable
   * The return type depends on the specific environment variable.
   */
  getEnvValue: <T extends EnvVariablesEnum>(env: T) => EnvValueMap[T];
};

let instance: IUseEnvs | null = null;

export function useEnvs(): IUseEnvs {
  if (instance) return instance;

  //#region Private methods
  const _getCompaniesListWithDisabledMenuItems = (): CompaniesListType => {
    return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_MENU_ITEMS);
  };
  const _getCompaniesListWithCustomLocales = (): CompaniesListType => {
    return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_CUSTOM_LOCALES);
  };
  const _getCompaniesListWithDisabledCampus = (): CompaniesListType => {
    return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_CAMPUS);
  };
  const _getCampusLinkCompaniesList = (): CompaniesListType3 => {
    return JSON.parse(import.meta.env.VITE_CAMPUS_LINK_COMPANIES_LIST);
  };
  const _getCompaniesListWithDisabledQuickSearch = (): CompaniesListType => {
    return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_QUICK_SEARCH);
  };
  const _getCompaniesListWithPermamentlyPinnedGroups = (): CompaniesListType2 => {
    return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_PERMANENTLY_PINNED_GROUPS);
  };
  const _getCompaniesListWithDocsInNewWindow = (): CompaniesListType => {
    return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DOCS_IN_NEW_WINDOW);
  };
  const _getSaarlandSecondaryNetworks = (): CompaniesListType => {
    return JSON.parse(import.meta.env.VITE_SAARLAND_SECONDARY_NETWORKS);
  };
  const _getCompaniesListWithDisabledUsersFilterInDocsBrowser = (): CompaniesListType => {
    return JSON.parse(import.meta.env.VITE_COMPANIES_LIST_WITH_DISABLED_USERS_FILTER_IN_DOCS_BROWSER);
  };
  //#endregion

  //#region Variables
  const _envs: {
    [key in keyof EnvValueMap]: () => EnvValueMap[key];
  } = Object.freeze({
    [EnvVariablesEnum.CompaniesListWithDisabledMenuItems]: _getCompaniesListWithDisabledMenuItems,
    [EnvVariablesEnum.CompaniesListWithCustomLocales]: _getCompaniesListWithCustomLocales,
    [EnvVariablesEnum.CompaniesListWithDisabledCampus]: _getCompaniesListWithDisabledCampus,
    [EnvVariablesEnum.CompaniesListCampusLink]: _getCampusLinkCompaniesList,
    [EnvVariablesEnum.CompaniesListWithDisabledQuickSearch]: _getCompaniesListWithDisabledQuickSearch,
    [EnvVariablesEnum.CompaniesListWithPermanentlyPinnedGroups]: _getCompaniesListWithPermamentlyPinnedGroups,
    [EnvVariablesEnum.CompaniesListWithDocsInNewWindow]: _getCompaniesListWithDocsInNewWindow,
    [EnvVariablesEnum.SaarlandSecondaryNetworks]: _getSaarlandSecondaryNetworks,
    [EnvVariablesEnum.CompaniesListWithDisabledUsersFilterInDocsBrowser]:
      _getCompaniesListWithDisabledUsersFilterInDocsBrowser,
  });
  //#endregion

  //#region Public methods
  const getEnvValue = <T extends EnvVariablesEnum>(env: T): EnvValueMap[T] => {
    const handler = _envs[env];
    return handler();
  };
  //#endregion

  instance = {
    getEnvValue,
  };

  return instance;
}
