import { AppIconsEnum, TopicsSortTypeEnum } from '@/enums';
import { useI18n } from '@/i18n';
import type { TopicsSortingItemModel } from '@/types';

type IUseTopics = {
  /**
   * Returns sorting modes for topics
   */
  getTopicsSortingModes(): TopicsSortingItemModel[];
};

export function useTopics(): IUseTopics {
  //#region Getters
  function getTopicsSortingModes(): TopicsSortingItemModel[] {
    const { t } = useI18n();
    return [
      {
        value: TopicsSortTypeEnum.ByPopularity,
        title: t('sorting.byPopularity'),
        disabled: false,
        icon: AppIconsEnum.Check,
      },
      {
        value: TopicsSortTypeEnum.ByTitle,
        title: t('sorting.byTitle'),
        disabled: false,
        icon: AppIconsEnum.Check,
      },
    ];
  }
  //#endregion

  return {
    getTopicsSortingModes,
  };
}
