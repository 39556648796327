export const enum WikiActionEnum {
  Create = 'create',
  Edit = 'edit',
  Move = 'move',
  MarkOfficial = 'markOfficial',
  ShowRelations = 'showRelations',
  ShowFollowers = 'showFollowers',
  ShowHistory = 'showHistory',
  Rollback = 'rollback',
  CompareHistorical = 'compareHistorical',
  Delete = 'delete',
  Follow = 'follow',
  Unfollow = 'unfollow',
  Share = 'share',
  ToCurrent = 'toCurrent',
  ToHistorical = 'toHistorical',
  OpenInNewWindow = 'openInNewWindow',
  Download = 'download',
  DeleteVersion = 'deleteVersion',
}

export const enum WikiPreviewManagerEnum {
  ExpandAll = 'expand-all',
  CollapseAll = 'collapse-all',
}

export const enum WikiMenuOptionsFlagEnum {
  All = 'all',
  Download = 'download',
  Share = 'share',
}

export const enum WikiDownloadOptionsEnum {
  DownloadAsPDF = 'pdf',
  DownloadAsDOCX = 'docx',
}

export const enum WikiShareOptionsEnum {
  Share = 1,
  SendAsArchiveToEmail = 2,
}

export const enum WikiDeleteOptionsEnum {
  ReplaceFromDocBrowser = 'replaceFromDocBrowser',
  ReplaceWithNewWiki = 'replaceWithNewWiki',
  ReplaceWithNewFile = 'replaceWithNewFile',
}

export const enum WikiSaveModeEnum {
  Major = 'major',
  Minor = 'minor',
}

export const enum WikiMajorFilterEnum {
  All = 'all',
  OnlyMajor = 'onlyMajor',
  OnlyMinor = 'onlyMinor',
}

export const enum WikiEditOptionsEnum {
  None = 0,
  EditTitle = 1,
  ShowAuthor = 3,
  ShowLastEditor = 4,
  EditGroup = 5,
  EditFolder = 6,
  Outdated = 7,
  EditAuthor = 8,
  ResetAuthor = 9,
}

export const enum WikiEditControlsEnum {
  Cancel = 'cancel',
  Save = 'save',
  Delete = 'delete',
  TemplatesMenu = 'templatesMenu',
  SaveAsTemplate = 'saveAsTemplate',
  UpdateTemplate = 'updateTemplate',
  ChooseTemplate = 'chooseTemplate',
}

export const enum WikiVersionEnum {
  V1 = 1,
  V2 = 2,
}

export const enum WikiJsonTextEnum {
  Head = 'head',
  Content = 'content',
  Body = 'body',
  Participants = 'participants',
}

export const enum WikiSectionMoveTypeEnum {
  Top = 'top',
  Up = 'up',
  Down = 'down',
  Bottom = 'bottom',
}

export const enum WikiLinkIdentifierEnum {
  User = 'u',
  Tag = 't',
  Group = 'g',
}

export const enum WikiCompareModeEnum {
  SideBySide = 'side-by-side',
  LineByLine = 'line-by-line',
}

export const enum WikiLockStatusEnum {
  /** [OK] Блокировка принадлежит текущему пользователю */
  NoLock = 0,
  /** [OK] Пользователь, владеющий блокировкой - не в сети */
  Offline = 1,
  /** [OK] Пользователь владеющий блокировкой обновил ее */
  Updated = 2,
  /** [ERR] Время владения блоккировкой вышло */
  Timeout = 3,
  /** [ERR] Блокировка доступна для установки */
  Unlocked = 4,
}
