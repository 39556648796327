import { AppFabButtonEnum, PostTypeActionEnum } from '@/enums';
import {
  openAdminRightMenuModal,
  openCustomPageCreateModal,
  openCustomPageInfoModal,
  openPostCreateMobileModal,
  openPostCreateMenu,
  useTaskManagement,
  getAnnouncementAccess,
  getPostCreateCategories,
} from '@/helpers';
import router, { ROUTES_NAME } from '@/router';
import { useCustomPageStore, useGroupsStore, useProjectsStore } from '@/store';
import type { MenuItemModel } from '@/types';

export type FabData = MenuItemModel<AppFabButtonEnum | null>;

type IUseFabButton = {
  /**
   * Use it to handle fab button click by {@link AppFabButtonEnum}
   */
  handleFabClick(ev: Event, fab: AppFabButtonEnum): Promise<void>;
};

export const useFabButton = (): IUseFabButton => {
  //#region Public methods
  const handleFabClick = async (ev: Event, fab: AppFabButtonEnum): Promise<void> => {
    const route = router.currentRoute.value;
    const { id } = route.params;

    const pageStore = useCustomPageStore();

    const { taskManagementProjectSwitchHelper, taskManagementMilestonesHelper } = useTaskManagement();

    const currentProject = useProjectsStore().getCurrentProject;
    const groupData = useGroupsStore().getGroupById(Number(id));
    const hasAnnouncementAccess = getAnnouncementAccess(useGroupsStore().getCanPostGroups().data, route);
    const categories = getPostCreateCategories(hasAnnouncementAccess, route).filter((n) => n.active);

    switch (fab) {
      case AppFabButtonEnum.CreatePost:
        await openPostCreateMenu(categories);
        break;

      case AppFabButtonEnum.CreateTask:
        await openPostCreateMobileModal(PostTypeActionEnum.Task);
        break;

      case AppFabButtonEnum.CreateProject:
        await taskManagementProjectSwitchHelper(ev, false, false, true);
        break;

      case AppFabButtonEnum.CreateMilestone:
        await taskManagementMilestonesHelper(ev, null, null, currentProject.id, true);
        break;

      case AppFabButtonEnum.CreatePage:
        await openCustomPageCreateModal();
        break;

      case AppFabButtonEnum.DashboardInfo: {
        if (pageStore.editablePage) {
          await openCustomPageInfoModal(pageStore.editablePage?.pageId, true, Number(id));
        }
        break;
      }

      case AppFabButtonEnum.EditDashboard:
        await router.push({
          name: ROUTES_NAME.GROUP_DASHBOARD,
          params: { id: groupData.id },
        });
        break;

      case AppFabButtonEnum.EditPage:
        await router.push({ name: ROUTES_NAME.PAGE_EDIT });
        break;

      case AppFabButtonEnum.PageInfo: {
        if (pageStore.editablePage) {
          await openCustomPageInfoModal(pageStore.editablePage?.pageId, false);
        }
        break;
      }

      case AppFabButtonEnum.CreateEvent:
        await openPostCreateMobileModal(PostTypeActionEnum.CalendarEvent);
        break;

      case AppFabButtonEnum.OpenAdminMenu:
        await openAdminRightMenuModal();
        break;

      default:
        break;
    }
  };
  //#endregion

  return {
    handleFabClick,
  };
};
