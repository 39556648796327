import cloneDeep from 'lodash/cloneDeep';
import { defineStore } from 'pinia';

import { DocsTableHeaderPropEnum, ShareArchiveLinkType } from '@/enums';
import { DocBrowserModeEnum, DocumentTypeEnum, SortingDirectionEnum } from '@/enums';
import { DateHelper, toShortUserModel } from '@/helpers';
import { isFileGuard } from '@/helpers/guards';

import { $api } from '@/services';
import { useGroupsStore, usePostStore, useUserStore } from '@/store';
import type {
  ResponseErrorModel,
  ResponseDocsModel,
  DocModel,
  ResponseTreeFolderModel,
  TreeFolderModel,
  GroupModel,
  FileModel,
  FolderModel,
  ResponseDocsCreateFileModel,
  ResponseDocsCreateFolderModel,
  ResponsePostModel,
  DocsDataModeModel,
  ErrorMessageModel,
  ResponseDocModel,
  ResponseDocsHistoryByIdModel,
  ResponseDocsHistoryModel,
  UserModel,
  TopicModel,
  RelationModel,
  ResponseDocsRelationsModel,
  ResponseDocsFileTagsModel,
  ResponseDocsFileFollowersModel,
  DocBrowserFilterModel,
} from '@/types';
import { Order } from '@revolist/vue3-datagrid';
import { find, unionBy } from 'lodash';

interface DocState {
  errors: ErrorMessageModel[];
  isLoading: boolean;

  selectedFolder: TreeFolderModel | null;
  selectedGroup: GroupModel | null;
  selectedDocs: DocModel[];

  activeFolder: FolderModel | null;
  activeGroup: GroupModel | null;

  /** Filter */
  filter: DocBrowserFilterModel;
  usersFilterEnabled: boolean;

  /** Sorting */
  sorting: {
    direction: Order;
    prop: DocsTableHeaderPropEnum;
  };

  /** Current document */
  current: {
    data: DocModel | null;
    version: FileModel | null;
    isOutdated: boolean;
    tags: TopicModel[];
    history: { data: FileModel[]; loadMoreUrl: string | null };
  };

  /** Global search documents data */
  globalSearch: DocsDataModeModel;
}
export const useDocStore = defineStore({
  id: 'docs',
  state: (): DocState => ({
    errors: [],
    isLoading: false,

    selectedFolder: null,
    selectedGroup: null,
    selectedDocs: [],

    activeFolder: null,
    activeGroup: null,

    current: {
      data: null,
      version: null,
      isOutdated: false,
      tags: [],
      history: { data: [], loadMoreUrl: null },
    },

    filter: {
      users: [],
      groups: [],
      searchEverywhere: true,
    },
    usersFilterEnabled: true,

    sorting: {
      direction: SortingDirectionEnum.Desc,
      prop: DocsTableHeaderPropEnum.Official,
    },

    globalSearch: {
      data: [],
      loadMoreUrl: null,
    },
  }),
  getters: {
    getSorting: (state: DocState) => {
      return state.sorting;
    },
    getGlobalSearchDocs: (state: DocState) => {
      return state.globalSearch;
    },
    getHistory: (state): FileModel[] => state.current.history.data,
    getHistoryLoadMoreUrl: (state): string | null => state.current.history.loadMoreUrl,
  },
  actions: {
    /** Use it to set the sorting */
    setSort(sort: { direction: Order; prop: DocsTableHeaderPropEnum }): void {
      this.sorting = sort;
    },

    /** Use it to set the selected documents */
    setSelectedDocs(docs: DocModel[]): void {
      this.selectedDocs = docs;
    },

    /** Use it to set the selected folder */
    setSelectedFolder(folder: TreeFolderModel | null): void {
      this.selectedFolder = folder;
    },

    /** Use it to set the selected group */
    setSelectedGroup(group: GroupModel | null): void {
      this.selectedGroup = group;
    },

    /** Use it to set the current file version */
    setCurrentFileVersion(file: FileModel | null): void {
      this.current.version = file;
    },

    /** Use it to set the tags of the current file */
    setCurrentFileTags(tags: TopicModel[]): void {
      this.current.tags = tags;
    },

    /** Use it to reset the tags of the current file */
    resetCurrentFileTags(): void {
      this.current.tags = [];
    },

    /** Use it to set the outdated status of the file */
    setIsOutdated(isOutdated: boolean): void {
      this.current.isOutdated = isOutdated;
    },

    /** Use it to set the current document */
    setCurrentFile(file: DocModel | null): void {
      this.current.data = file;
    },

    /** Use it to set an active group */
    setActiveGroup(group: GroupModel | null): void {
      this.activeGroup = group;
    },

    /** Use it to set an active folder */
    setActiveFolder(folder: FolderModel | null): void {
      this.activeFolder = folder;
    },

    /** Use it to set the documents of global search */
    setGlobalSearchDocs(docs: FileModel[], loadMoreUrl: string | null = null): void {
      this.globalSearch.data = docs.map((file) => ({
        documentType: DocumentTypeEnum.File,
        data: file,
      }));
      this.globalSearch.loadMoreUrl = loadMoreUrl;
    },

    /** Use it to enable/disable search everywhere */
    setSearchEverywhere(isEnabled: boolean): void {
      this.filter.searchEverywhere = isEnabled;
    },

    /** Use it to set the users of the filter */
    setFilterUsers(users: UserModel[]): void {
      this.filter.users = users;
    },

    /** Use it to set the groups of the filter */
    setFilterGroups(groups: GroupModel[]): void {
      this.filter.groups = groups;
    },

    /** Use it to set the groups of the filter */
    setUsersFilterEnabled(enable: boolean): void {
      this.usersFilterEnabled = enable;
    },

    /** Use it to get documents from group */
    async allDocsFromGroupId(groupId: number, sort: string, filter: string): Promise<DocsDataModeModel> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.doc.getAllDocsFromGroupId(groupId, sort, filter);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        this.isLoading = false;
        return { data: model.data, loadMoreUrl: model.loadMoreUrl };
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return { data: [], loadMoreUrl: '' };
    },

    /** Use it to get documents from folder */
    async allDocsFromFolderId(folderId: number, sort: string, filter: string): Promise<DocsDataModeModel> {
      this.errors = [];
      this.isLoading = true;
      const response = await $api.doc.getAllDocsFromFolderId(folderId, sort, filter);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        this.isLoading = false;
        return { data: model.data, loadMoreUrl: model.loadMoreUrl };
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return { data: [], loadMoreUrl: '' };
    },

    /** Use it to upload more documents */
    async loadMore(url: string): Promise<DocsDataModeModel> {
      this.errors = [];
      const response = await $api.doc.loadMore(url);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        return { data: model.data, loadMoreUrl: model.loadMoreUrl };
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return { data: [], loadMoreUrl: '' };
    },

    /** Use it to get documents from the network */
    async allDocs(mode: DocBrowserModeEnum, sort: string, filter: string): Promise<DocsDataModeModel> {
      this.isLoading = true;
      const response = await $api.doc.getAllDocs(mode, sort, filter);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        this.isLoading = false;
        return { data: model.data, loadMoreUrl: model.loadMoreUrl };
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return { data: [], loadMoreUrl: '' };
    },

    /** Use it to get documents only from the network (which do not have a group) */
    async allDocsFromNetworkOnly(sort: string, filter: string): Promise<DocsDataModeModel> {
      this.isLoading = true;
      const response = await $api.doc.getAllDocsFromNetworkOnly(sort, filter);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsModel;
        this.isLoading = false;
        return { data: model.data, loadMoreUrl: model.loadMoreUrl };
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      this.isLoading = false;
      return { data: [], loadMoreUrl: '' };
    },

    /** Use it to get the tree of folders from the network */
    async getFoldersTree(): Promise<TreeFolderModel[] | undefined> {
      const response = await $api.doc.getFoldersTree();

      if (response.statusCode === 200) {
        const model = response as ResponseTreeFolderModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },

    /** Use it to get the tree of folders from the network */
    async getFoldersTreeByGroupId(groupId: number): Promise<TreeFolderModel[] | undefined> {
      const response = await $api.doc.getFoldersTreeByGroupId(groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseTreeFolderModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },

    /** Use it to get the path to the specified folder */
    async getFolderPath(folderId: number): Promise<TreeFolderModel[] | false> {
      const response = await $api.doc.getFolderPath(folderId);

      if (response.statusCode === 200) {
        const model = response as ResponseTreeFolderModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to create a new folder */
    async createFolder(
      folderName: string,
      parentFolderId: number | null,
      groupId: number | null
    ): Promise<DocModel | null> {
      const response = await $api.doc.createFolder(folderName, parentFolderId, groupId);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsCreateFolderModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return null;
    },

    /** Use it to rename a folder */
    async renameFolder(folderId: number, name: string): Promise<boolean> {
      const response = await $api.doc.renameFolder(folderId, name);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to create new documents */
    async createFiles(
      files: FileModel[],
      folderId: number | null,
      groupId: number | null,
      onBehalfUserId?: number
    ): Promise<DocModel[] | []> {
      const response = await $api.doc.createFiles(files, folderId, groupId, onBehalfUserId);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsCreateFileModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return [];
    },

    /** Use it to rename a document */
    async renameFile(fileId: number, name: string, description: string): Promise<boolean> {
      const response = await $api.doc.renameFile(fileId, name, description);

      if (response.statusCode === 200) {
        if (this.current.data) {
          const currentFile = this.current.data;
          currentFile.data.name = name;
          currentFile.data.description = description;
          this.current.data = Object.assign({}, currentFile);
          await this.updateCurrentFile();
        }

        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to get the current document */
    async getCurrentFile(id: number): Promise<DocModel | undefined> {
      const response = await $api.doc.getDocById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseDocModel;
        this.current.data = model.data as DocModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },

    //#region History
    resetHistory(): void {
      this.current.history = { data: [], loadMoreUrl: null };
    },
    /** Use it to get the history of the document */
    async getFileHistory(id: number): Promise<FileModel[]> {
      try {
        const response = await $api.doc.getHistory(id);

        if (response.statusCode === 200) {
          const model = response as ResponseDocsHistoryModel;
          this.$patch((state) => {
            state.current.history.data = model.data;
            state.current.history.loadMoreUrl = model.loadMoreUrl;
          });

          return model.data;
        }
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
        this.resetHistory();
        return [];
      } catch (e) {
        console.error('Failed to get available file versions', e);
        this.resetHistory();
        return [];
      }
    },

    async historyLoadMore(loadMoreUrl: string): Promise<boolean> {
      const response = await $api.doc.getHistoryLoadMore(loadMoreUrl);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsHistoryModel;
        this.current.history.data = cloneDeep(mergeHistoryById(this.current.history.data, model.data));
        this.current.history.loadMoreUrl = model.loadMoreUrl;
        return true;
      }

      const error = response as ResponseErrorModel;
      console.error(error.errorMessages);
      return false;
    },

    /** Use it to get the document history by id */
    async getHistoricalFileById(id: number): Promise<FileModel | undefined> {
      const response = await $api.doc.getHistoricalFileById(id);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsHistoryByIdModel;
        this.current.version = model.data as FileModel;
        return model.data;
      }

      if (response.statusCode !== 200) {
        this.current.version = null;
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },

    /** Use it to get the document history by date */
    async getHistoricalFileByDate(id: number, date: string): Promise<FileModel | undefined> {
      const response = await $api.doc.getHistoricalFileByDate(id, date);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsHistoryByIdModel;
        return model.data as FileModel;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return undefined;
    },

    /** Use it to restore a document version */
    async restoreFileVersion(fileId: number, historyId: number): Promise<boolean> {
      const response = await $api.doc.restoreFileVersion(fileId, historyId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to upload a new version of a document */
    async uploadNewVersion(file: FileModel, fileInfo: FileModel): Promise<boolean> {
      const response = await $api.doc.uploadNewVersion(file.id, fileInfo);

      if (response.statusCode === 200) {
        await this.updateCurrentFile(true);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },
    //#endregion

    /** Use it to move the document to another folder */
    async moveFile(toFolderId: number | null, toGroupId: number | null, fileId: number): Promise<boolean> {
      const response = await $api.doc.moveFile(toFolderId, toGroupId, fileId);

      if (response.statusCode === 200) {
        const toGroup = toGroupId ? useGroupsStore().getGroupById(toGroupId) : null;
        if (this.current.data && toGroup) {
          const currentFile = this.current.data;
          const { id, mainAlias, title, type, avatar } = toGroup;
          currentFile.data.group = {
            id,
            mainAlias,
            title,
            type,
            avatar,
            urlPhoto: null,
            urlPhotoX2: null,
            urlPhotoMax: null,
          };
          this.current.data = Object.assign({}, currentFile);
          await this.updateCurrentFile();
        }
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to move the folder to another folder. */
    async moveFolder(toFolderId: number | null, toGroupId: number | null, folderId: number): Promise<boolean> {
      const response = await $api.doc.moveFolder(toFolderId, toGroupId, folderId);
      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    //#region Relations
    /** Use it to get the related documents by id */
    async getRelations(id: number): Promise<RelationModel[]> {
      const response = await $api.doc.getRelations(id);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsRelationsModel;
        return model.data;
      }

      return [];
    },
    async addRelationWiki(fileId: number, relationWikiId: number): Promise<boolean> {
      try {
        const response = await $api.doc.addRelationWiki(fileId, relationWikiId);
        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to add relation', e);
        return false;
      }
    },
    async addRelationFile(fileId: number, relationFileId: number): Promise<boolean> {
      try {
        const response = await $api.doc.addRelationFile(fileId, relationFileId);
        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to add relation', e);
        return false;
      }
    },
    async removeRelationWiki(fileId: number, relationWikiId: number): Promise<boolean> {
      try {
        const response = await $api.doc.removeRelationWiki(fileId, relationWikiId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to remove relation', e);
        return false;
      }
    },
    async removeRelationFile(fileId: number, relationFileId: number): Promise<boolean> {
      try {
        const response = await $api.doc.removeRelationFile(fileId, relationFileId);

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to remove relation', e);
        return false;
      }
    },
    //#endregion

    /** Use it to get the tags of a document */
    async getTags(id: number): Promise<TopicModel[]> {
      const response = await $api.doc.getTags(id);
      if (response.statusCode === 200) {
        const model = response as ResponseDocsFileTagsModel;
        return model.data;
      }

      return [];
    },

    /** Use it to add tags to a document */
    async addTag(id: number, tags: TopicModel[]): Promise<boolean> {
      try {
        const response = await $api.doc.addTag(
          id,
          tags.map(({ title }) => title)
        );

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    /** Use it to remove a tag from a document */
    async removeTag(id: number, tagId: number): Promise<boolean> {
      try {
        const response = await $api.doc.removeTag(id, tagId);

        return response.statusCode === 200;
      } catch (e) {
        console.error(e);

        return false;
      }
    },

    /** Use it to mark a document as official */
    async markOfficial(id: number): Promise<boolean> {
      try {
        const response = await $api.doc.markAsOfficial(id);
        if (response.statusCode === 200) {
          if (this.current.data && isFileGuard(this.current.data.data)) {
            this.current.data.data.isOfficial = !this.current.data.data.isOfficial;
          }
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to make file official', e);
        return false;
      }
    },

    /**Use it to get subscribers to the document. */
    async getFollowers(id: number): Promise<UserModel[]> {
      const response = await $api.doc.getFollowers(id);

      if (response.statusCode === 200) {
        const model = response as ResponseDocsFileFollowersModel;
        return model.data;
      }

      return [];
    },

    /** Use it to subscribe to a document */
    async follow(id: number): Promise<boolean> {
      try {
        const response = await $api.doc.follow(id);

        if (response.statusCode === 200) {
          if (this.current.data && isFileGuard(this.current.data.data)) {
            this.current.data.data.isFollowed = !this.current.data.data.isFollowed;
          }
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to follow file', e);
        return false;
      }
    },

    /** Use it to unsubscribe from a document */
    async unfollow(id: number): Promise<boolean> {
      try {
        const response = await $api.doc.unfollow(id);

        if (response.statusCode === 200) {
          if (this.current.data && isFileGuard(this.current.data.data)) {
            this.current.data.data.isFollowed = !this.current.data.data.isFollowed;
          }
        }

        return response.statusCode === 200;
      } catch (e) {
        console.error('Failed to unfollow file', e);
        return false;
      }
    },

    /** Use it to delete a document */
    async deleteFile(fileId: number): Promise<boolean> {
      const response = await $api.doc.delete(fileId);

      if (response.statusCode === 200) {
        return true;
      }

      return false;
    },

    /** Use it to delete a document with replace */
    async deleteWithFileReplace(fileId: number, relationFileId: number): Promise<boolean> {
      const response = await $api.doc.deleteWithFileReplace(fileId, relationFileId);

      if (response.statusCode === 200) {
        return true;
      }

      return false;
    },

    /** Use it to delete a document with replace */
    async deleteWithWikiReplace(fileId: number, relationWikiId: number): Promise<boolean> {
      const response = await $api.doc.deleteWithWikiReplace(fileId, relationWikiId);

      if (response.statusCode === 200) {
        return true;
      }

      return false;
    },

    /** Use it to delete a wiki */
    async deleteWiki(wikiId: number): Promise<boolean> {
      const response = await $api.wiki.delete(wikiId);

      if (response.statusCode === 200) {
        return true;
      }

      return false;
    },

    /** Use it to delete a folder */
    async deleteFolder(folderId: number): Promise<boolean> {
      const response = await $api.doc.deleteFolder(folderId);

      if (response.statusCode === 200) {
        return true;
      }

      return false;
    },

    /** Use it to move the wiki to another folder */
    async moveWiki(toFolderId: number | null, toGroupId: number | null, wikiId: number): Promise<boolean> {
      const response = await $api.doc.moveWiki(toFolderId, toGroupId, wikiId);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to share a document */
    async shareFile(fileId: number, text: string): Promise<boolean> {
      const response = await $api.doc.shareFile(fileId, text);

      if (response.statusCode === 200) {
        const postStore = usePostStore();
        const model = response as ResponsePostModel;
        postStore.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to share a document to a group */
    async shareFileToGroup(fileId: number, text: string, groupId: number): Promise<boolean> {
      const response = await $api.doc.shareFileToGroup(fileId, text, groupId);

      if (response.statusCode === 200) {
        const postStore = usePostStore();
        const model = response as ResponsePostModel;
        postStore.addNewPost(model.data);
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }
      return false;
    },

    /** Use it to share a document in an archive */
    async shareDocumentArchiveLink(id: number, emails: string[], type: ShareArchiveLinkType): Promise<boolean> {
      this.errors = [];
      const response = await $api.doc.shareDocumentArchiveLink(id, emails, type);

      if (response.statusCode === 200) {
        return true;
      }

      if (response.statusCode !== 200) {
        const error = response as ResponseErrorModel;
        this.errors = cloneDeep(error.errorMessages);
      }

      return false;
    },

    /** Use it to update the current document */
    async updateCurrentFile(contentChanged = false): Promise<void> {
      const currentUser = useUserStore().current;
      const currentFile = this.current.data;
      const currentFileData = currentFile?.data as FileModel;
      if (currentFile && currentFileData) {
        currentFileData.editedAt = DateHelper.getIsoNow();
        if (currentUser) {
          currentFileData.editor = toShortUserModel(currentUser as UserModel);
        }
        if (contentChanged) {
          currentFileData.pdfUrl = '';
          currentFileData.apiUrl = '';
        }
        currentFile.data = currentFileData;
        this.current.data = Object.assign({}, currentFile);
      }
    },
  },
  persist: true,
});

const mergeHistoryById = (a: FileModel[], b: FileModel[]) => {
  return unionBy(a, b, 'id').map((obj) => {
    const match = find(b, { id: obj.id });
    return match ? Object.assign({}, obj, match) : obj;
  });
};
