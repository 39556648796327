/**
 * This configuration file contains the list of generic hosts.
 * The app uses this list to determine whether it is running on a standard (generic) domain.
 * This helps distinguish between custom domains and the predefined generic ones.
 * Update this list if additional generic hostnames need to be recognized.
 */
export const GENERIC_HOSTS: string[] = [
  'dev.communex.app',
  'dev2.communex.app',
  'dev3.communex.app',
  'dev4.communex.app',
  'dev5.communex.app',
  'dev6.communex.app',
  'beta.communex.app',
  'live.communex.app',
];
