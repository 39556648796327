/**
 * This configuration file defines API endpoints that do not require user authentication.
 * When the app makes requests to any of these endpoints, session validation is skipped.
 * Adjust this list to control which endpoints should be accessible without authentication.
 */
export const ENDPOINTS_WITH_NO_AUTH_CHECK: string[] = [
  'oauth/link',
  'oauth/networks',
  'oauth/homeCode',
  'oauth/token',
  'tools/sendToSupport',
  'tools/appVersion',
  'networks/locate',
  'networks/allowedEmailSuffixes',
  'account/passwordSettings',
  'account/passwordPolicy',
  'account/passwordRestore',
  'account/passwordSave',
  'account/passwordUpdate',
  'account/invite',
  'account/registration/byForm',
  'account/registration/byNetwork',
  'account/registerDevice',
  'account/unRegisterDevice',
];
