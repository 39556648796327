<template>
  <template v-if="type === BadgeDisplayType.Post && badgeData">
    <div class="post-badge">
      <media-image v-if="badgeData.largeIcon !== null" :url="badgeData.largeIcon" />
    </div>
  </template>

  <template v-if="type === BadgeDisplayType.UserName && badgeData">
    <div
      v-tooltip.bottom="{
        content: badgeData.title,
        theme: 'info-tooltip',
        disabled: isAnyMobile,
      }"
      class="user-name-badge"
    >
      <media-image
        v-if="badgeData.largeIcon !== null"
        :url="badgeData.largeIcon"
        @on-load="onLoad"
        @on-error="onError"
        @on-will-load="onWillLoad"
      />
    </div>
  </template>

  <template v-if="type === BadgeDisplayType.Profile && badgeData">
    <div
      v-tooltip.bottom="{
        content: `${badgeData.title}: ${count}`,
        theme: 'info-tooltip',
        disabled: isAnyMobile,
      }"
      class="profile-badge"
    >
      <media-image v-if="badgeData.largeIcon !== null" :url="badgeData.largeIcon" />
      <ion-badge mode="md" class="counter">{{ count }}</ion-badge>
    </div>
  </template>

  <template v-if="type === BadgeDisplayType.ListItem && badgeData">
    <div slot="start" class="item-image">
      <media-image
        v-if="badgeData.largeIcon !== null"
        :url="badgeData.largeIcon"
        @on-load="onLoad"
        @on-error="onError"
        @on-will-load="onWillLoad"
      />
    </div>
    <ion-label :class="['ion-text-wrap', 'small']">
      {{ badgeData.title }}
      <p class="subtitle">{{ badgeData.description }}</p>
    </ion-label>
  </template>
</template>

<script lang="ts" setup>
import { IonBadge, IonLabel } from '@ionic/vue';
import { computed, type ComputedRef, type PropType } from 'vue';

import { MediaImage } from '@/components';
import { BadgeDisplayType } from '@/enums';
import { isAnyMobile } from '@/helpers';
import { useBadgesStore } from '@/store';
import type { BadgeModel } from '@/types';

const props = defineProps({
  badgeId: {
    type: Number,
    required: true,
  },
  type: {
    type: Number as PropType<BadgeDisplayType>,
    required: true,
  },
  count: {
    type: Number,
    default: 1,
  },
});

const emit = defineEmits(['onWillLoad', 'onLoad', 'onError']);

//#region Variables
const badgeData: ComputedRef<BadgeModel | undefined> = computed(() => useBadgesStore().getBadgeById(props.badgeId));
//#endregion

//#region Methods
const onWillLoad = () => {
  emit('onWillLoad');
};

const onLoad = () => {
  emit('onLoad');
};

const onError = () => {
  emit('onError');
};
//#endregion
</script>

<style scoped lang="scss">
.post-badge {
  width: 100%;
  height: 100%;
}

.user-name-badge {
  margin-right: app-padding(sm);
  width: 1.2rem;
  height: 1.2rem;
}

.profile-badge {
  position: relative;
  margin-right: app-padding(md);
  width: 32px;
  height: 32px;
  .counter {
    position: absolute;
    bottom: -(app-padding(sm));
    right: -(app-padding(sm));
    color: var(--ion-color-medium);
    --background: var(--ion-color-light-custom);
    font-size: 0.7rem;
    border-radius: app-radius(lg);
    min-width: 1.3rem;
    z-index: 2;
  }
}

ion-label {
  p {
    word-break: normal;
    &.subtitle {
      line-height: 1.2;
    }
  }
}

.item-image {
  width: 40px;
}
</style>
