import { alertController } from '@ionic/vue';
import {
  newspaperOutline,
  imageOutline,
  calendarOutline,
  textOutline,
  chatbubbleEllipsesOutline,
  chatbubblesOutline,
  documentTextOutline,
  peopleOutline,
  personOutline,
  codeWorkingOutline,
  pizzaOutline,
  personAddOutline,
  eyeOutline,
  trashOutline,
  createOutline,
  sendOutline,
  alertCircleOutline,
  accessibilityOutline,
  imagesOutline,
  closeCircleOutline,
} from 'ionicons/icons';
import { cloneDeep, isEqual, remove } from 'lodash';
import { v4 as uuid_v4 } from 'uuid';
import type { Router } from 'vue-router';

import { formatDateHelper } from './helper';
import { openTitleChangeModal } from './modalComponents';
import { openCustomPageAccessEditPopover } from './popoverComponents';

import {
  CustomPageWidgetsEnum,
  CustomPageWidgetsPositionEnum,
  WidgetFeedTypeEnum,
  CustomPageInfoEditActionsEnum,
  CustomPageInfoEditControlsEnum,
  CustomPageShowTypeEnum,
} from '@/enums';
import { useErrors, useToasts } from '@/helpers';
import { useI18n } from '@/i18n';
import {
  defaultWidgetBanner,
  defaultWidgetBirthdays,
  defaultWidgetCalendar,
  defaultWidgetFeed,
  defaultWidgetFilesWikies,
  defaultWidgetGroupAdmins,
  defaultWidgetGroupInformation,
  defaultWidgetGroupMembers,
  defaultWidgetGroups,
  defaultWidgetHtml,
  defaultWidgetPeople,
  defaultWidgetPoll,
  defaultWidgetSingleUserItem,
  defaultWidgetTextBlock,
  defaultWidgetSlider,
  defaultWidgetPublisher,
} from '@/models';
import { ROUTES_NAME } from '@/router';
import { useCustomPageStore, useGroupsStore } from '@/store';
import type {
  CustomPagesDataJsonRowModel,
  CustomPagesWidgetModel,
  PageAccessEditItems,
  PageModel,
  PageInfoControls,
  PageInfoOptions,
} from '@/types';

type IUseCustomPages = {
  getWidgetsSelectorItems(isGroupDashboard: boolean, groupId?: number): CustomPagesWidgetModel[];
  getPageInfoOptions(page: PageModel, isGroupDashboard: boolean, groupId?: number): PageInfoOptions[];
  getPageInfoControls(page: PageModel, isGroupDashboard: boolean): PageInfoControls[];
  getAccessToPageEdit(page: PageModel | null, isGroupDashboard: boolean): boolean;
  pageInfoOptionsAction(event: Event, action: CustomPageInfoEditActionsEnum, router: Router): Promise<void>;
  pageInfoControlsAction(
    event: Event,
    action: CustomPageInfoEditControlsEnum,
    router: Router,
    groupId?: undefined | number
  ): Promise<void>;
  unsavedVersion(): boolean;
  getAccessEditItems(): PageAccessEditItems[];
  removeWidgetById(widgetId: string): void;
  getWidgetsListForModalStyling(): CustomPageWidgetsEnum[];
  getWidgetIcon(widget: CustomPageWidgetsEnum): string;
  getWidgetTitle(widget: CustomPageWidgetsEnum): string;
  pageHavePublisher(page: PageModel | null): boolean;
};

export const useCustomPages = (): IUseCustomPages => {
  const icons = {
    feed: newspaperOutline,
    banner: imageOutline,
    calendar: calendarOutline,
    text: textOutline,
    post: chatbubbleEllipsesOutline,
    poll: chatbubblesOutline,
    documents: documentTextOutline,
    groups: peopleOutline,
    people: personOutline,
    html: codeWorkingOutline,
    birthdays: pizzaOutline,
    invite: personAddOutline,
    trash: trashOutline,
    create: createOutline,
    eye: eyeOutline,
    send: sendOutline,
    info: alertCircleOutline,
    groupMembers: accessibilityOutline,
    slider: imagesOutline,
    close: closeCircleOutline,
  };

  //NOTE: List of widgets available for creation
  const getWidgetsSelectorItems = (isGroupDashboard: boolean, groupId?: number): CustomPagesWidgetModel[] => {
    const pageStore = useCustomPageStore();

    const addWidgetIfGroupDashboard = (widget: any) =>
      isGroupDashboard ? [cloneDeep({ ...widget, widgetId: uuid_v4() })] : [];

    const addWidgetIfNotExists = (widget: any, widgetEnum: CustomPageWidgetsEnum) =>
      isGroupDashboard && !pageStore.pageHasWidget(widgetEnum) ? [cloneDeep({ ...widget, widgetId: uuid_v4() })] : [];

    const data: CustomPagesWidgetModel[] = [
      isGroupDashboard && groupId
        ? cloneDeep({
            ...{
              ...defaultWidgetFeed,
              settings: {
                ...defaultWidgetFeed.settings,
                feedType: WidgetFeedTypeEnum.CustomGroup,
                groupId: groupId,
              },
            },
            widgetId: uuid_v4(),
          })
        : cloneDeep({
            ...defaultWidgetFeed,
            widgetId: uuid_v4(),
          }),
      cloneDeep({
        ...defaultWidgetPublisher,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetBanner,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetCalendar,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetTextBlock,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetSingleUserItem,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetPoll,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetFilesWikies,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetGroups,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetPeople,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetHtml,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetBirthdays,
        widgetId: uuid_v4(),
      }),
      cloneDeep({
        ...defaultWidgetSlider,
        widgetId: uuid_v4(),
      }),
      ...addWidgetIfGroupDashboard(defaultWidgetGroupAdmins),
      ...addWidgetIfGroupDashboard(defaultWidgetGroupMembers),
      ...addWidgetIfNotExists(defaultWidgetGroupInformation, CustomPageWidgetsEnum.WidgetGroupInformation),
    ];

    return data;
  };

  const getWidgetIcon = (widget: CustomPageWidgetsEnum) => {
    const widgetCases: Record<CustomPageWidgetsEnum, string> = {
      [CustomPageWidgetsEnum.WidgetPeople]: icons.people,
      [CustomPageWidgetsEnum.WidgetFeed]: icons.feed,
      [CustomPageWidgetsEnum.WidgetBanner]: icons.banner,
      [CustomPageWidgetsEnum.WidgetCalendar]: icons.calendar,
      [CustomPageWidgetsEnum.WidgetPoll]: icons.poll,
      [CustomPageWidgetsEnum.WidgetGroups]: icons.groups,
      [CustomPageWidgetsEnum.WidgetHtml]: icons.html,
      [CustomPageWidgetsEnum.WidgetSingleUserItem]: icons.post,
      [CustomPageWidgetsEnum.WidgetTextBlock]: icons.text,
      [CustomPageWidgetsEnum.WidgetBirthdays]: icons.birthdays,
      [CustomPageWidgetsEnum.WidgetPublisher]: icons.send,
      [CustomPageWidgetsEnum.WidgetFilesWikies]: icons.documents,
      [CustomPageWidgetsEnum.WidgetGroupAdmins]: icons.groups,
      [CustomPageWidgetsEnum.WidgetGroupInformation]: icons.info,
      [CustomPageWidgetsEnum.WidgetGroupMembers]: icons.groupMembers,
      [CustomPageWidgetsEnum.WidgetSlider]: icons.slider,
      [CustomPageWidgetsEnum.WidgetInvite]: icons.invite,
    };

    return widgetCases[widget];
  };

  const getWidgetTitle = (widget: CustomPageWidgetsEnum) => {
    const { t } = useI18n();
    const widgetCases: Record<CustomPageWidgetsEnum, string> = {
      [CustomPageWidgetsEnum.WidgetPeople]: t('appMenu.people'),
      [CustomPageWidgetsEnum.WidgetFeed]: t('appMenu.feed'),
      [CustomPageWidgetsEnum.WidgetBanner]: t('customPage.widgets.banner'),
      [CustomPageWidgetsEnum.WidgetCalendar]: t('appMenu.calendar'),
      [CustomPageWidgetsEnum.WidgetPoll]: t('feed.poll.poll'),
      [CustomPageWidgetsEnum.WidgetGroups]: t('appMenu.groups'),
      [CustomPageWidgetsEnum.WidgetHtml]: 'HTML',
      [CustomPageWidgetsEnum.WidgetSingleUserItem]: t('feed.post.title'),
      [CustomPageWidgetsEnum.WidgetTextBlock]: t('customPage.widgets.textBlock'),
      [CustomPageWidgetsEnum.WidgetBirthdays]: t('customPage.widgets.birthdays'),
      [CustomPageWidgetsEnum.WidgetPublisher]: t('customPage.widgets.publisher'),
      [CustomPageWidgetsEnum.WidgetFilesWikies]: t('appMenu.docs'),
      [CustomPageWidgetsEnum.WidgetGroupAdmins]: t('customPage.widgets.groupAdmins'),
      [CustomPageWidgetsEnum.WidgetGroupInformation]: t('customPage.widgets.groupInfo'),
      [CustomPageWidgetsEnum.WidgetGroupMembers]: t('customPage.widgets.groupMembers'),
      [CustomPageWidgetsEnum.WidgetSlider]: t('customPage.widgets.slider'),
      [CustomPageWidgetsEnum.WidgetInvite]: '',
    };

    return widgetCases[widget];
  };

  //NOTE: Page options
  const getPageInfoOptions = (page: PageModel, isGroupDashboard: boolean, groupId?: number): PageInfoOptions[] => {
    const { t } = useI18n();
    const groupStore = useGroupsStore();

    const data = [
      {
        title: t('title'),
        action: getAccessToPageEdit(page, isGroupDashboard)
          ? CustomPageInfoEditActionsEnum.EditTitle
          : CustomPageInfoEditActionsEnum.None,
        value: page.title,
        enabled: !isGroupDashboard,
      },
      {
        title: t('group'),
        action: CustomPageInfoEditActionsEnum.None,
        value: groupId ? groupStore.getGroupById(Number(groupId))?.title : '',
        enabled: isGroupDashboard && groupId,
      },
      //NOTE: Removed from display as it currently does not make sense
      /* {
        title: 'URL',
        action: getAccessToPageEdit(page, isGroupDashboard)
          ? customPageInfoEditActionsEnum.EditMainAlias
          : customPageInfoEditActionsEnum.None,
        value: page.mainAlias,
        enabled: !isGroupDashboard
      }, */
      {
        title: t('customPage.options.access'),
        action: getAccessToPageEdit(page, isGroupDashboard)
          ? CustomPageInfoEditActionsEnum.AccessEdit
          : CustomPageInfoEditActionsEnum.None,
        value: getAccessTitle(page.showType),
        enabled: !isGroupDashboard,
      },
      {
        title: t('table.author'),
        action: CustomPageInfoEditActionsEnum.None /* customPageInfoEditActionsEnum.ShowAuthor */,
        value: page.owner.fullName,
        enabled: true,
      },
      {
        title: t('customPage.options.lastEditor'),
        action: CustomPageInfoEditActionsEnum.None /* customPageInfoEditActionsEnum.ShowModifier */,
        value: page.lastEditor.fullName,
        enabled: true,
      },

      {
        title: t('customPage.options.createdDate'),
        action: CustomPageInfoEditActionsEnum.None,
        value: formatDateHelper(page.createdAt, 'year'),
        enabled: true,
      },
      {
        title: t('customPage.options.editedDate'),
        action: CustomPageInfoEditActionsEnum.None,
        value: formatDateHelper(page.lastEditedAt, 'year'),
        enabled: true,
      },
    ] as PageInfoOptions[];

    return data.filter((n) => n.enabled);
  };

  //NOTE: Actions on the page
  const pageInfoOptionsAction = async (
    event: Event,
    action: CustomPageInfoEditActionsEnum,
    router: Router
  ): Promise<void> => {
    const pageStore = useCustomPageStore();
    const editablePage: PageModel | null = pageStore.editablePage;

    if (editablePage) {
      switch (action) {
        case CustomPageInfoEditActionsEnum.None:
          break;

        case CustomPageInfoEditActionsEnum.EditTitle:
          {
            const result = await openTitleChangeModal(null, editablePage.title);
            if (result?.title) {
              pageStore.updatePageTitle(result.title);
            }
          }
          break;

        case CustomPageInfoEditActionsEnum.EditMainAlias:
          {
            const result = await openTitleChangeModal(null, editablePage.mainAlias, false, '', true);
            if (result?.title) {
              pageStore.updatePageMainAlias(result.title);
            }
          }

          break;

        case CustomPageInfoEditActionsEnum.AccessEdit:
          {
            pageInfoControlsAction(event, CustomPageInfoEditControlsEnum.AccessEdit, router);
          }

          break;

        /* case customPageInfoEditActionsEnum.ShowAuthor:
          {
            if (router) {
              await router.push({
                name: ROUTES_NAME.USER_BY_ID,
                params: { id: editablePage.owner.id },
              });
            }
          }

          break; */

        /* case customPageInfoEditActionsEnum.ShowModifier:
          {
            if (router) {
              await router.push({
                name: ROUTES_NAME.USER_BY_ID,
                params: { id: editablePage.owner.id },
              });
            }
          }

          break; */

        default:
          break;
      }
    }
  };

  //NOTE: Page control elements
  const getPageInfoControls = (page: PageModel, isGroupDashboard: boolean): PageInfoControls[] => {
    const { t } = useI18n();

    const data = [
      //NOTE: Display order in the interface - as in the array
      {
        title: t('customPage.options.delete'),
        icon: icons.trash,
        disabled: isGroupDashboard,
        value: CustomPageInfoEditControlsEnum.Delete,
      },
      {
        title: t('customPage.options.cancelEditing'),
        icon: icons.close,
        disabled: false, //NOTE: #1598 unsavedVersion(),
        value: CustomPageInfoEditControlsEnum.Cancel,
      },
      {
        title: t('customPage.options.toDraft'),
        icon: icons.create,
        disabled: isGroupDashboard,
        value: CustomPageInfoEditControlsEnum.ToDraft,
      },
      {
        title: t('customPage.options.saveAndPublish'),
        icon: icons.send,
        disabled: false,
        value: CustomPageInfoEditControlsEnum.Publish,
      },
    ] as PageInfoControls[];

    return data.filter((n) => !n.disabled);
  };

  //NOTE: Actions on the page
  const pageInfoControlsAction = async (
    event: Event,
    action: CustomPageInfoEditControlsEnum,
    router: Router,
    groupId?: undefined | number
  ): Promise<void> => {
    const { t } = useI18n();
    const { handleError } = useErrors();
    const { showSonnerToast } = useToasts();

    const pageStore = useCustomPageStore();

    const editablePage: PageModel | null = pageStore.editablePage;

    if (editablePage) {
      switch (action) {
        case CustomPageInfoEditControlsEnum.AccessEdit:
          {
            const result = await openCustomPageAccessEditPopover(event);
            if (result !== undefined) {
              pageStore.updatePageShowType(result);
            }
          }
          break;

        case CustomPageInfoEditControlsEnum.Delete:
          {
            const removeConfirm = async () => {
              const alert = await alertController.create({
                message: t('customPage.deleteConfirm'),
                buttons: [
                  {
                    text: t('no'),
                    role: 'cancel',
                    cssClass: 'custom-alert-buttons',
                  },
                  {
                    text: t('yes'),
                    cssClass: 'custom-alert-buttons',
                    handler: async () => {
                      const result = await pageStore.deletePage(editablePage.pageId);
                      if (result) {
                        pageStore.resetEditablePage();
                        router?.push({ name: ROUTES_NAME.PAGES });
                        showSonnerToast(t('customPage.pageDeleted'), true);
                      } else {
                        handleError({
                          show: true,
                          error: undefined,
                          message: t('customPage.pageDeleteError'),
                        });
                      }
                    },
                  },
                ],
              });

              await alert.present();
            };

            await removeConfirm();
          }
          break;

        case CustomPageInfoEditControlsEnum.ToDraft:
          {
            preparePageDataForSave(true, router);
          }
          break;

        case CustomPageInfoEditControlsEnum.Cancel:
          {
            pageStore.$patch((state) => {
              state.editablePage = cloneDeep(state.editablePageForReset);
            });

            if (router) {
              if (groupId) {
                await router.push({
                  name: ROUTES_NAME.GROUP_BY_ID,
                  params: { id: groupId },
                });
              } else {
                await router.push({
                  name: ROUTES_NAME.PAGE_BY_ID,
                  params: { id: editablePage.pageId },
                });
              }
            }
          }
          break;

        case CustomPageInfoEditControlsEnum.Publish:
          {
            preparePageDataForSave(false, router, groupId);
          }
          break;

        default:
          break;
      }
    }
  };

  //NOTE: Options for assigning access to the page
  const getAccessEditItems = (): PageAccessEditItems[] => {
    const data = [
      {
        title: getAccessTitle(CustomPageShowTypeEnum.NetworkUsersOnly),
        disabled: false,
        value: CustomPageShowTypeEnum.NetworkUsersOnly,
      },
      {
        title: getAccessTitle(CustomPageShowTypeEnum.ExternalUsersOnly),
        disabled: false,
        value: CustomPageShowTypeEnum.ExternalUsersOnly,
      },
      {
        title: getAccessTitle(CustomPageShowTypeEnum.AllRegisteredUsers),
        disabled: false,
        value: CustomPageShowTypeEnum.AllRegisteredUsers,
      },
      {
        title: getAccessTitle(CustomPageShowTypeEnum.Public),
        disabled: true,
        value: CustomPageShowTypeEnum.Public,
      },
    ] as PageAccessEditItems[];

    return data.filter((n) => !n.disabled);
  };

  //NOTE: Text description of page access (showType)
  const getAccessTitle = (showType: CustomPageShowTypeEnum): string => {
    const { t } = useI18n();
    switch (showType) {
      case CustomPageShowTypeEnum.AllRegisteredUsers:
        return t('customPage.options.showTypes.allRegisteredUsers');

      case CustomPageShowTypeEnum.ExternalUsersOnly:
        return t('customPage.options.showTypes.externalUsersOnly');

      case CustomPageShowTypeEnum.NetworkUsersOnly:
        return t('customPage.options.showTypes.networkUsersOnly');

      case CustomPageShowTypeEnum.Public:
        return t('customPage.options.showTypes.public');
    }
  };

  //NOTE: Is there access to view the page
  /* const _getAccessToViewPage = (page: PageEntity): boolean => {
    const userStore = useUserStore();

    const currentUserId: ComputedRef<number> = computed(
      () => userStore.current?.id ?? 0
    );

    const currentUserRoleId: ComputedRef<number> = computed(
      () => userStore.current?.roleId ?? 0
    );

    switch (page.showType) {
      case customPageShowTypeEnum.NetworkUsersOnly: {
        return (
          currentUserRoleId.value >= 30 || page.owner.id === currentUserId.value
        );
      }

      case customPageShowTypeEnum.ExternalUsersOnly: {
        return (
          currentUserRoleId.value < 30 || page.owner.id === currentUserId.value
        );
      }

      case customPageShowTypeEnum.AllRegisteredUsers: {
        return true;
      }

      case customPageShowTypeEnum.Public: {
        // Not use
        return page.owner.id === currentUserId.value;
      }

      default:
        return false;
    }
  }; */

  //NOTE: Is there access to edit the page
  const getAccessToPageEdit = (page: PageModel | null, isGroupDashboard: boolean): boolean => {
    if (!page) {
      return false;
    }

    if (isGroupDashboard) {
      return page?.canEditDashboard ?? false;
    } else {
      return page?.canEditPage ?? false;
    }
  };

  //NOTE: Check if the page has been modified locally
  const unsavedVersion = (): boolean => {
    //NOTE: true - the modified version is saved
    //NOTE: false - changes on the page are not saved
    const pageStore = useCustomPageStore();

    const editablePage: PageModel | null = pageStore.editablePage;
    const editablePageForReset: PageModel | null = pageStore.editablePageForReset;
    if (editablePage && editablePageForReset) {
      return isEqual(editablePage, editablePageForReset);
    }
    return false;
  };

  //NOTE: Text description, whether the page has been modified locally
  /* const _getPageVisibility = (): string => {
    const pageStore = useCustomPageStore();
    const { t } = useI18n();

    const editablePage: PageEntity | null = pageStore.editablePage;
    if (editablePage) {
      if (editablePage.isPublic) {
        return t('customPage.options.public');
      } else {
        return t('customPage.options.draft');
      }
    } else return t('customPage.options.edit');
  }; */

  //NOTE: Prepare page data for saving
  const preparePageDataForSave = async (isDraft: boolean, router: Router, groupId?: undefined | number) => {
    const { t } = useI18n();
    const { handleError } = useErrors();
    const { showSonnerToast } = useToasts();

    const pageStore = useCustomPageStore();

    const editablePage: PageModel | null = pageStore.editablePage;

    if (editablePage) {
      pageStore.removeAllEmptyRows();
      const result = groupId
        ? await pageStore.updateDashboard(editablePage, groupId)
        : await pageStore.updatePage(editablePage, isDraft);
      if (result) {
        if (isDraft) {
          showSonnerToast(t('customPage.successSavedToDraft'), true);
        } else {
          if (router) {
            if (groupId) {
              await router.push({
                name: ROUTES_NAME.GROUP_BY_ID,
                params: { id: groupId },
              });
            } else {
              await router.push({
                name: ROUTES_NAME.PAGE_BY_ID,
                params: { id: editablePage.pageId },
              });
            }
          }
          showSonnerToast(t('customPage.successPublished'), true);
        }
      } else {
        if (isDraft) {
          handleError({
            show: true,
            error: undefined,
            message: t('customPage.saveToDraftError'),
          });
        } else {
          handleError({
            show: true,
            error: undefined,
            message: t('customPage.publishError'),
          });
        }
      }
    }
  };

  const removeWidgetById = (widgetId: string): void => {
    const pageStore = useCustomPageStore();
    const data: { rows: CustomPagesDataJsonRowModel[] } | undefined = pageStore.editablePage?.customPageData;
    if (data) {
      data.rows.forEach((row) => {
        [
          CustomPageWidgetsPositionEnum.LeftSidebarWidgets,
          CustomPageWidgetsPositionEnum.MainWidgets,
          CustomPageWidgetsPositionEnum.RightSidebarWidgets,
        ].forEach((position) => {
          remove(row[position], { widgetId });
        });
      });
    }
  };

  //NOTE: List of widgets for different display in the editing window
  const getWidgetsListForModalStyling = (): CustomPageWidgetsEnum[] => {
    return [
      CustomPageWidgetsEnum.WidgetSingleUserItem,
      CustomPageWidgetsEnum.WidgetPoll,
      CustomPageWidgetsEnum.WidgetFeed,
    ];
  };

  //NOTE: Check if the page has a publisher
  const pageHavePublisher = (page: PageModel | null): boolean => {
    if (!page?.customPageData?.rows) {
      return false;
    }

    return page.customPageData.rows.some((row) =>
      [
        CustomPageWidgetsPositionEnum.LeftSidebarWidgets,
        CustomPageWidgetsPositionEnum.MainWidgets,
        CustomPageWidgetsPositionEnum.RightSidebarWidgets,
      ].some((position) => row[position].some((widget) => widget.systemName === CustomPageWidgetsEnum.WidgetPublisher))
    );
  };

  return {
    getWidgetsSelectorItems,
    getPageInfoOptions,
    getAccessToPageEdit,
    pageInfoOptionsAction,
    pageInfoControlsAction,
    unsavedVersion,
    getPageInfoControls,
    getAccessEditItems,
    getWidgetsListForModalStyling,
    getWidgetIcon,
    getWidgetTitle,
    removeWidgetById,
    pageHavePublisher,
  };
};
