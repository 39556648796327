import { HubConnectionState, type HubConnection } from '@microsoft/signalr';
import * as signalR from '@microsoft/signalr';
import { useAppStore, useAuthStore, useMeetStore } from '@/store';
import type { WebSocketConnectModel, WebSocketModel, ConnectionConfig, ConnectionStatus } from '@/ws/types';

const defaultConfig: ConnectionConfig = {
  retryCount: Number(import.meta.env.VITE_WEBSOCKETS_RETRY_COUNT) || 3,
  retryDelay: Number(import.meta.env.VITE_WEBSOCKETS_RETRY_DELAY) || 1000,
  automaticReconnect: [1000, 2000, 5000, 10000, 30000],
};

let connection: HubConnection | undefined;
let status: ConnectionStatus = {
  state: HubConnectionState.Disconnected,
  connectionId: null,
  isActive: false,
  retryCount: 0,
  isRetrying: false,
};

// function _getConnectionStatus(): Readonly<ConnectionStatus> {
//   return { ...status };
// }

function _updateConnectionStatus(newStatus: Partial<ConnectionStatus>): void {
  status = { ...status, ...newStatus };

  if (newStatus.state) {
    const connectionState = newStatus.state;
    useAuthStore().setSignalRConnectionStatus(connectionState);

    if (connectionState === HubConnectionState.Connected) {
      useAuthStore().setWsAvailable(true);
    } else if (connectionState === HubConnectionState.Disconnected && status.retryCount >= defaultConfig.retryCount) {
      useAuthStore().setWsAvailable(false);
    }
  }

  if (newStatus.connectionId) {
    useAuthStore().setSignalRConnectionId(newStatus.connectionId);
    useMeetStore().setConnectionId(newStatus.connectionId);
  }

  console.debug(`[DEBUG] [WS] Connection status updated: ${JSON.stringify(status)}`);
}

export function getConnection(): HubConnection | undefined {
  return connection;
}

export async function initConnection(
  model: WebSocketModel,
  config: ConnectionConfig = defaultConfig
): Promise<boolean> {
  try {
    console.debug(`[DEBUG] [WS] Initializing SignalR connection with endpoint: ${model.webSocket}/events`);
    _updateConnectionStatus({
      state: HubConnectionState.Connecting,
      retryCount: 0,
    });

    connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${model.webSocket}/events`)
      .withAutomaticReconnect(config.automaticReconnect) // NEW
      .build();

    console.debug('[DEBUG] [WS] SignalR connection has been initialized');
    return true;
  } catch (err) {
    console.error('[WS] Failed to initialize SignalR connection:', err);
    _updateConnectionStatus({ state: HubConnectionState.Disconnected });
    return false;
  }
}

export async function startConnection(): Promise<boolean> {
  if (!connection) {
    console.error('[WS] Cannot start connection - connection is null');
    return false;
  }

  try {
    useAppStore().setIsLoading(true);
    useAppStore().setIsWaitingForCompleteLogin(false);
    _updateConnectionStatus({ state: HubConnectionState.Connecting });

    console.debug('[DEBUG] [WS] Starting SignalR connection...');
    await connection.start();

    console.debug(`[DEBUG] [WS] SignalR connection started with ID: ${connection.connectionId}`);
    _updateConnectionStatus({
      state: HubConnectionState.Connected,
      connectionId: connection.connectionId || null,
    });

    return true;
  } catch (err) {
    console.error('[WS] SignalR connection start failed:', err);
    useAppStore().setIsLoading(false);
    _updateConnectionStatus({ state: HubConnectionState.Disconnected });
    return false;
  }
}

export async function invokeConnection(model: WebSocketModel): Promise<boolean> {
  if (!connection) {
    console.error('[WS] Cannot invoke connect - connection is null');
    return false;
  }

  try {
    const invokeModel: WebSocketConnectModel = {
      coreId: model.coreId,
      companyRowId: model.companyRowId,
      userId: model.userId,
      userRowId: model.userRowId,
      version: model.version,
    };

    console.debug('[DEBUG] [WS] Invoking connect method on SignalR server');
    await connection.invoke('connect', invokeModel);

    useAppStore().setIsLoading(false);
    if (!connection?.connectionId) {
      throw new Error('Connection ID is null after successful connection');
    }

    console.debug(`[DEBUG] [WS] SignalR connection successful with ID: ${connection.connectionId}`);
    _updateConnectionStatus({
      state: HubConnectionState.Connected,
      connectionId: connection.connectionId,
      isActive: true,
      retryCount: 0,
      isRetrying: false,
    });

    return true;
  } catch (err) {
    console.error('[WS] Failed to invoke connect method on the server:', err);
    useAppStore().setIsLoading(false);
    return false;
  }
}

export async function stopConnection(): Promise<boolean> {
  try {
    if (!connection) {
      console.warn('[WS] Connection is already undefined, nothing to stop');
      return false;
    }

    console.debug(`[DEBUG] [ Stopping WebSocket connection: ${connection.connectionId}...`);
    _updateConnectionStatus({
      state: HubConnectionState.Disconnecting,
      isActive: false,
    });

    await connection.stop();

    _updateConnectionStatus({ state: HubConnectionState.Disconnected });
    console.debug('[DEBUG] [WS] WebSocket connection stopped successfully');
    return true;
  } catch (err) {
    console.error('[WS] Stopping WebSockets failed:', err);
    _updateConnectionStatus({ state: HubConnectionState.Disconnected });
    return false;
  }
}

export async function retryConnection(
  model: WebSocketModel,
  config: ConnectionConfig = defaultConfig
): Promise<boolean> {
  if (status.retryCount >= config.retryCount) {
    console.error(`[WS] Reconnection failed - retry count exceeded (${status.retryCount}/${config.retryCount})`);
    _updateConnectionStatus({
      state: HubConnectionState.Disconnected,
      isRetrying: false,
    });
    return false;
  }

  if (status.isRetrying) {
    console.warn('[WS] Reconnection already in progress, skipping retry attempt');
    return false;
  }

  _updateConnectionStatus({
    isRetrying: true,
    retryCount: status.retryCount + 1,
  });

  console.debug(`[DEBUG] [WS] Attempting reconnection - attempt ${status.retryCount} of ${config.retryCount}`);

  try {
    await stopConnection();

    const initSuccess = await initConnection(model, config);
    if (!initSuccess) {
      throw new Error('Failed to initialize connection during retry');
    }

    const startSuccess = await startConnection();
    if (!startSuccess) {
      throw new Error('Failed to start connection during retry');
    }

    const invokeSuccess = await invokeConnection(model);
    if (!invokeSuccess) {
      throw new Error('Failed to invoke connection during retry');
    }

    _updateConnectionStatus({
      isRetrying: false,
      retryCount: 0,
    });

    return true;
  } catch (err) {
    console.error(`[WS] Reconnection failed - attempt ${status.retryCount} of ${config.retryCount}`, err);
    _updateConnectionStatus({ isRetrying: false });

    // If we didn't exceed the retry count - schedule next retry attempt with delay
    const shouldSchedule = status.retryCount < config.retryCount;
    shouldSchedule
      ? setTimeout(() => retryConnection(model, config), config.retryDelay)
      : useAuthStore().setWsAvailable(false);

    return false;
  }
}
