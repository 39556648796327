<template>
  <template v-if="viewMode === DataViewMode.Grid">
    <div
      v-long-press="openMenu"
      class="grid-item"
      :class="{ active: isActive }"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
      @click="quickAction()"
    >
      <div v-if="isImage" class="item-image">
        <media-image :name="name" :image="file.image" center @on-view="quickAction()" />
      </div>
      <div v-if="!isImage" class="item-icon">
        <div class="icon">
          <icons-provider
            class="file-icon"
            :name="useDocsHelper.getDocumentIcon({ documentType, data: file })"
            :icon-props="{
              width: '70',
              height: '70',
            }"
          />
          <ion-icon v-if="isOfficial" class="avatar-icon default" :icon="star" />
        </div>
      </div>

      <div class="item-label">
        <ion-label>{{ name }}</ion-label>
      </div>
    </div>
  </template>

  <template v-if="viewMode === DataViewMode.List">
    <ion-item
      v-if="documentType !== DocumentTypeEnum.ExternalLink && documentType !== DocumentTypeEnum.Folder"
      v-tooltip.bottom-start="{
        content: name,
        theme: 'info-tooltip',
        disabled: isAnyMobile,
      }"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="quickAction()"
      @contextmenu.prevent="(ev) => (isNativeMobile ? undefined : openMenu(ev))"
    >
      <icons-provider
        v-if="status === null || status !== FileStatusEnum.Loading"
        slot="start"
        class="file-icon"
        :name="useDocsHelper.getDocumentIcon({ documentType, data: file })"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <icons-provider v-else slot="start" :icon-props="{ width: '40', height: '40' }" :name="AppIconsEnum.CircleAnim" />
      <ion-label>
        {{ name }}
        <p>{{ size }}</p>
      </ion-label>
      <ion-buttons v-if="status && status === FileStatusEnum.Loading" slot="end">
        <ion-button @click.stop="stopDownload()">
          <ion-icon slot="icon-only" :icon="closeOutline" />
        </ion-button>
      </ion-buttons>
    </ion-item>

    <ion-item
      v-if="documentType === DocumentTypeEnum.Folder"
      v-tooltip.bottom-start="{
        content: name,
        theme: 'info-tooltip',
        disabled: isAnyMobile,
      }"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="quickAction()"
    >
      <icons-provider
        slot="start"
        class="file-icon"
        :name="useDocsHelper.getDocumentIcon({ documentType, data: file })"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <ion-label
        >{{ name }}
        <p>
          {{ author + ', ' + formatDateHelper(createdAt, 'year') }}<span v-if="group">{{ ', ' + group }}</span>
        </p></ion-label
      >
    </ion-item>

    <ion-item
      v-if="documentType === DocumentTypeEnum.ExternalLink"
      v-tooltip.bottom-start="{
        content: $t('links.title') + `: ${linkUrl}`,
        theme: 'info-tooltip',
        disabled: isAnyMobile,
      }"
      mode="md"
      lines="none"
      button
      class="custom"
      @click="quickAction()"
    >
      <icons-provider
        slot="start"
        class="file-icon"
        :name="useDocsHelper.getDocumentIcon({ documentType, data: file })"
        :icon-props="{
          width: '40',
          height: '40',
        }"
      />
      <ion-label>
        {{ name }}
        <p>{{ linkUrl }}</p>
      </ion-label>
    </ion-item>
  </template>
</template>

<script lang="ts" setup>
import { IonIcon, IonItem, IonLabel, IonButtons, IonButton } from '@ionic/vue';
import { closeOutline, star } from 'ionicons/icons';
import type { ComputedRef, PropType } from 'vue';
import { computed } from 'vue';

import { MediaImage, IconsProvider } from '@/components';
import { longPress } from '@/directives';
import { AppIconsEnum, DocumentTypeEnum, FileStatusEnum, DataViewMode } from '@/enums';
import { formatDateHelper, isAnyMobile, isNativeMobile, useFilesHybrid, useDocs } from '@/helpers';
import { useDocStore } from '@/store';
import type { DocModel, FileModel } from '@/types';

// Props
const props = defineProps({
  viewMode: {
    type: String as PropType<DataViewMode>,
    required: true,
  },
  documentType: {
    type: String as PropType<DocumentTypeEnum>,
    required: true,
  },
  id: {
    type: Number,
    required: true,
  },
  mimeType: {
    type: String || null,
    default: null,
  },
  name: {
    type: String,
    required: true,
  },
  author: {
    type: String,
    required: true,
  },
  editor: {
    type: String,
    required: true,
  },
  group: {
    type: String,
    required: true,
  },
  createdAt: {
    type: String,
    required: true,
  },
  editedAt: {
    type: String,
    required: true,
  },
  size: {
    type: String || null,
    default: null,
  },
  file: {
    type: (Object as PropType<FileModel>) || null,
    default: null,
  },
  icon: {
    type: String,
    required: true,
  },
  status: {
    type: (String as PropType<FileStatusEnum>) || null,
    default: null,
  },
  linkUrl: {
    type: String || null,
    default: null,
  },
  fileMenuIsDisabled: {
    type: Boolean,
    default: false,
  },
  isOfficial: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['onMenuOpen', 'onDownloadStop', 'onQuickAction']);

//#region Variables
const vLongPress = longPress;
const useDocsHelper = useDocs();
const docStore = useDocStore();
const selectedDocs: ComputedRef<DocModel[]> = computed(() => docStore.selectedDocs);
const isActive: ComputedRef<boolean> = computed(() =>
  selectedDocs.value.some((n) => n.data.id === props.id && props.documentType === n.documentType)
);
const isImage = computed(() => {
  return (
    props.file?.mimeType.startsWith('image') && !useFilesHybrid().unsupportedImageFormats.includes(props.file.type)
  );
});
//#endregion

//#region Methods
const quickAction = async () => {
  emit('onQuickAction');
};

const openMenu = async (ev: Event) => {
  if (!props.fileMenuIsDisabled) {
    emit('onMenuOpen', ev);
  }
};

const stopDownload = async () => {
  emit('onDownloadStop');
};
//#endregion
</script>

<style scoped lang="scss">
div.icon {
  position: relative;
}

div.text {
  line-clamp: 1;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem !important;
}

.avatar-icon {
  content: '';
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  background-color: rgba(var(--ion-color-light-rgb-custom), 1);

  &.default {
    width: 18px;
    height: 18px;
    right: -10px;
    bottom: 6px;
    padding: 2px;
    border: 2px solid var(--ion-color-light-background-contrast);
  }
  &.small {
    width: 12px;
    height: 12px;
    right: -8px;
    bottom: 0;
    padding: 1px;
    border: 1px solid var(--ion-color-light-background-contrast);
  }
}

ion-icon {
  color: var(--ion-color-warning);
}

.mobile .icon {
  margin-right: 1rem;
  font-size: 40px;
}
.custom .file-icon {
  margin-right: 1rem;
  font-size: 40px;
}

.grid-item {
  height: 100%;
  background: var(--ion-color-light-background-contrast);
  border-radius: app-radius(md);
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  &.active {
    background-color: var(--ion-color-custom-element-lighter);
  }
  .item-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    .icon {
      font-size: 70px;
      color: var(--ion-color-medium);
    }
  }

  .item-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8rem;
    overflow: hidden;
  }
  .item-label {
    position: relative;
    height: 2rem;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 2rem;
    padding-inline: 0.5rem;
    text-align: center;
    font-size: 0.9rem;
  }
}

ion-item.custom {
  --background: transparent;
  width: 100%;
}
ion-item.mobile.active {
  --background: var(--ion-color-custom-element-lighter);
}
ion-item.mobile .file-image {
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin-right: 1rem;
}

@include respond-to-min-width(2xl) {
  .grid-item {
    .item-label {
      font-size: 1rem;
    }
  }
}
</style>
