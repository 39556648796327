import DOMPurify from 'dompurify';
import type { Directive, DirectiveBinding } from 'vue';

/**
 * Custom directive for safely rendering HTML content
 * Uses DOMPurify to sanitize HTML and prevent XSS attacks
 *
 * @example
 * ```vue
 * <template>
 *   <div v-safe-html="htmlContent"></div>
 * </template>
 * <script setup lang="ts">
 *   const htmlContent = ref('<p>Hello, world!</p>');
 * </script>
 * ```
 */
export const safeHtml: Directive = {
  beforeMount(el: HTMLElement, binding: DirectiveBinding) {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
  updated(el: HTMLElement, binding: DirectiveBinding) {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
};

export default safeHtml;
